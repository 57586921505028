import axios from 'axios';
function getDefaultState() {
    return {
        definitionSets: [],
        definitionSet: null,
        globalDefinitionItems: [],
        workspaceDefinitions: []
    }
}

const getters = {
    definitionSets: state => state.definitionSets,
    definitionSet: state => state.definitionSet,
    globalDefinitionItems: state => state.globalDefinitionItems,
    workspaceDefinitions: state => state.workspaceDefinitions,
}

const actions = {
    getDefinitionSets({ commit, state }, payload) {
        return axios.get(`definitions/project/${payload.project_id}`)
            .then(response => {
                commit("setDefinitionSets", response.data);
                return response;
            }).catch(e => {
                //
            });
    },
    getDefinitionSet({ commit, state }, payload) {
        return axios.get(`definitions/${payload.definition_id}`)
            .then(response => {
                commit("setDefinitionSet", response.data);
                return response;
            }).catch(e => {
                //
            });
    },
    getWorkspaceDefinition({ commit, state }, id) {
        return axios.get(`definitions/workspace/${id}`)
            .then(response => {
                commit("setWorkspaceDefinition", response.data);
                return response;
            }).catch(e => {
                //
            });
    },
    storeDefinitionSet({ commit, state, dispatch }, payload) {
        return axios.post(`definitions/store`, payload)
            .then(response => {
                // dispatch("getDefinitionSet", payload);
                return response;
            }).catch(e => {
                //
            });
    },
    updateDefinitionSet({ commit, state, dispatch }, payload)
    {
        return axios.post(`definitions/${payload.get('definition_id')}/update`, payload)
            .then(response => {
                dispatch("getDefinitionSets", payload);
                return response;
            }).catch(e => {
                //
            });
    },
    deleteDefinitionSet({ commit, state, dispatch }, payload) {
        return axios.delete(`definitions/${payload.definition_id}`)
            .then(response => {
                dispatch("getDefinitionSets", payload);
                return response;
            }).catch(e => {
                //
            });
    },
    duplicateDefinitionSet({ commit, state, dispatch }, payload) {
        return axios.post(`definitions/duplicate/${payload.id}`, payload)
            .then(response => {
                dispatch("getDefinitionSets", payload);
                return response;
            }).catch(e => {
                //
            });
    },
    storeDefinitionItem({ commit, state, dispatch }, payload) {
        return axios.post(`definitions/items/store`, payload)
            .then(response => {
                dispatch("getDefinitionSet", payload);
                return response;
            }).catch(e => {
                //
            });
    },
    updateDefinitionItem({ commit, state, dispatch }, payload) {
        return axios.post(`definitions/items/${payload.id}/update`, payload)
            .then(response => {
                return response;
            }).catch(e => {
                //
            });
    },
    updateDefinitionRowItem({ commit, state, dispatch}, payload) {
        return axios.post(`definitions/items/${payload.definition_item_id}/update/range`, payload)
            .then(response => {
                return response;
            }).catch(e => {
                //
            });
    },
    deleteDefinitionItem({ commit, state, dispatch }, payload) {
        return axios.delete(`definitions/items/${payload.definition_item_id}`)
            .then(response => {
                dispatch("getDefinitionSet", payload);
                return response;
            }).catch(e => {
                //
            });
    },
    duplicateDefinitionItem({ commit, state, dispatch }, payload) {
        return axios.post(`definitions/items/duplicate/${payload.definition_item_id}`, payload)
            .then(response => {
                dispatch("getDefinitionSet", payload);
            }).catch(e => {
                console.log(e);
            });
    },
    loadGlobalLibrariesData({ commit, state }, payload) {
        let hasProject = null;
        if(payload.project) {
            hasProject = `&project=${payload.project}`;
        }
        return axios.get(`definitions/projects/${payload.id}/items?type=${payload.type}${hasProject ? hasProject : ''}`)
            .then(response => {
                commit("setGlobalDefinitionItems", response.data);
                return response;
            })
            .catch(e => {
                //
            });
    },
    storeDraggedItem({ commit, state, dispatch }, payload) {
        return axios.post(`definitions/items/dragged`, payload)
            .then(response => {
                dispatch("getDefinitionSet", payload);
                return response;
            }).catch(e => {
                //
            });
    },
    reorderItem({ commit, state, dispatch }, payload) {
        return axios.post(`definitions/items/reorder`, payload)
            .then(response => {
                dispatch("getDefinitionSet", payload);
                return response;
            }).catch(e => {
                //
            });
    }
};

const mutations = {
    setDefinitionSets(state, value) {
        state.definitionSets = value.data;
    },
    setDefinitionSet(state, value) {
        state.definitionSet = value.data;
    },
    setGlobalDefinitionItems(state, value) {
        state.globalDefinitionItems = value.data;
    },
    setWorkspaceDefinition(state, value) {
        state.workspaceDefinitions = value.data;
    },
};

export default {
    namespaced: true,
    state: getDefaultState,
    getters,
    actions,
    mutations
}
