import axios from 'axios';
export function useTeamwork()
{
    let init = {}
    
    const initDefaultSettings = async (projectId) => {
        if(!projectId) return {};
        const {data} = await axios.get(`projects/${projectId}?solid_object=true`).then(({data})=>data)
        return data?.default_settings
    }

    const BrainstormLink = (projectId, brainstorm_id) => 
    {
        const landing = {
            name: 'brainstorm',
            params: {
                id: projectId,
            }
        }

        const detail = {
            name: 'teamwork',
            params: {
                id: projectId,
                brainstorm_id: brainstorm_id
            }
        }
        
        const BrainstormLink = brainstorm_id ? detail : landing

        return BrainstormLink
    }

    const getLink = (projectId, brainstorm_id, routeName) => 
    {
        const landing = {
            name: routeName,
            params: {
                id: projectId,
                brainstorm_id: brainstorm_id
            }
        }

        return landing
    }


    return {
        initDefaultSettings,
        BrainstormLink,
        getLink
    }
}