<template>
    <svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.24189 7.48528C-0.320212 5.92318 -0.320212 3.39052 1.24189 1.82843C2.80398 0.26633 5.33664 0.26633 6.89874 1.82843C8.46084 3.39052 8.46084 5.92318 6.89874 7.48528C5.33564 9.04495 2.80499 9.04495 1.24189 7.48528ZM1.75892 2.44276C0.527446 3.701 0.543706 5.7177 1.79528 6.95595C3.04691 8.19414 5.06367 8.18872 6.3086 6.94379C7.55353 5.69886 7.55895 3.68211 6.32076 2.43047C5.08251 1.1789 3.06581 1.16264 1.80757 2.39411L1.75892 2.44276ZM2.93894 6.35391L2.37326 5.78822L3.50463 4.65685L2.37326 3.52548L2.93894 2.9598L4.07031 4.09117L5.20168 2.9598L5.76737 3.52548L4.636 4.65685L5.76737 5.78822L5.20168 6.35391L4.07031 5.22254L2.93894 6.35391Z" fill="currentColor"/>
    </svg>
</template>

<script>
export default {
    name: "XCircle"
}
</script>
