<template>
    <svg class="inline" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="1.90493" y="11.1367" width="1.95" height="0.65" transform="rotate(-45 1.90493 11.1367)" stroke="currentColor" stroke-width="0.65"/>
        <rect x="9.25649" y="10.2168" width="0.65" height="1.95" transform="rotate(-45 9.25649 10.2168)" stroke="currentColor" stroke-width="0.65"/>
        <rect x="9.25649" y="3.7832" width="1.95" height="0.65" transform="rotate(-45 9.25649 3.7832)" stroke="currentColor" stroke-width="0.65"/>
        <rect x="1.90493" y="2.86328" width="0.65" height="1.95" transform="rotate(-45 1.90493 2.86328)" stroke="currentColor" stroke-width="0.65"/>
        <rect x="0.25" y="6.75" width="1.5" height="0.5" stroke="currentColor" stroke-width="0.5"/>
        <rect x="6.25" y="11.75" width="0.5" height="1.5" stroke="currentColor" stroke-width="0.5"/>
        <rect x="11.25" y="6.75" width="1.5" height="0.5" stroke="currentColor" stroke-width="0.5"/>
        <rect x="6.25" y="0.75" width="0.5" height="1.5" stroke="currentColor" stroke-width="0.5"/>
        <circle cx="6.5" cy="7" r="4" stroke="currentColor"/>
        <rect x="1.90493" y="11.1367" width="1.95" height="0.65" transform="rotate(-45 1.90493 11.1367)" stroke="currentColor" stroke-width="0.65"/>
        <rect x="9.25649" y="10.2168" width="0.65" height="1.95" transform="rotate(-45 9.25649 10.2168)" stroke="currentColor" stroke-width="0.65"/>
        <rect x="9.25649" y="3.7832" width="1.95" height="0.65" transform="rotate(-45 9.25649 3.7832)" stroke="currentColor" stroke-width="0.65"/>
        <rect x="1.90493" y="2.86328" width="0.65" height="1.95" transform="rotate(-45 1.90493 2.86328)" stroke="currentColor" stroke-width="0.65"/>
        <rect x="0.25" y="6.75" width="1.5" height="0.5" stroke="currentColor" stroke-width="0.5"/>
        <rect x="6.25" y="11.75" width="0.5" height="1.5" stroke="currentColor" stroke-width="0.5"/>
        <rect x="11.25" y="6.75" width="1.5" height="0.5" stroke="currentColor" stroke-width="0.5"/>
        <rect x="6.25" y="0.75" width="0.5" height="1.5" stroke="currentColor" stroke-width="0.5"/>
        <circle cx="6.5" cy="7" r="4" stroke="currentColor"/>
        <rect x="1.90493" y="11.1367" width="1.95" height="0.65" transform="rotate(-45 1.90493 11.1367)" stroke="currentColor" stroke-width="0.65"/>
        <rect x="9.25649" y="10.2168" width="0.65" height="1.95" transform="rotate(-45 9.25649 10.2168)" stroke="currentColor" stroke-width="0.65"/>
        <rect x="9.25649" y="3.7832" width="1.95" height="0.65" transform="rotate(-45 9.25649 3.7832)" stroke="currentColor" stroke-width="0.65"/>
        <rect x="1.90493" y="2.86328" width="0.65" height="1.95" transform="rotate(-45 1.90493 2.86328)" stroke="currentColor" stroke-width="0.65"/>
        <rect x="0.25" y="6.75" width="1.5" height="0.5" stroke="currentColor" stroke-width="0.5"/>
        <rect x="6.25" y="11.75" width="0.5" height="1.5" stroke="currentColor" stroke-width="0.5"/>
        <rect x="11.25" y="6.75" width="1.5" height="0.5" stroke="currentColor" stroke-width="0.5"/>
        <rect x="6.25" y="0.75" width="0.5" height="1.5" stroke="currentColor" stroke-width="0.5"/>
        <circle cx="6.5" cy="7" r="4" stroke="currentColor"/>
    </svg>

</template>
<script>
export default {
    name: "GearIcon"
}
</script>