import axios from 'axios';

function getDefaultState() {
    return {
        projectFullData: [],
        associatedData: [],
        itemAssociations: []
    }
}

const getters = {
    projectFullData: state => state.projectFullData,
    associatedData: state => state.associatedData,
    itemAssociations: state => state.itemAssociations,
}

const actions = {
    getAllProjectModuleCollectionData({ commit, state }, id) {
        return axios.get(`projects/${id}/module-collections`)
            .then(response => {
                commit("setProjectFullData", response.data);
                return response;
            })
            .catch(e => {
                //
            });
    },
    getAssociatedData({ commit, state }, id) {
        return axios.get(`associations/${id}`)
            .then(response => {
                commit("setAssociations", response.data);
                return response;
            })
            .catch(e => {
                //
            })
    },
    getItemAssociations({ commit, state }, id) {
      return axios.get(`projects/scenarios/associations/${id}`)
          .then(response => {
              commit("setItemAssociations", response.data);
          }).catch(e => {
              //
          });
    },
    storeAssociation({ commit, state }, payload) {
        return axios.post(`associations`, payload)
            .then(response => {
                commit("pushedToAssociationArray", response.data);
                return response;
            })
            .catch(e => {
                //
            });
    },
    removeAssociation({ commit, state }, id) {
        return axios.delete(`associations/${id}`)
            .then(response => {
                commit("removeAssociation", id);
                return response;
            })
            .catch(e => {
                //
            })
    }
};

const mutations = {
    setProjectFullData(state, value) {
        state.projectFullData = value.data;
    },
    setAssociations(state, value) {
        state.associatedData = value.data;
    },
    pushedToAssociationArray(state, value) {
      state.associatedData.push(value.data);
    },
    removeAssociation(state, id) {
        let index = state.associatedData.findIndex(item => item.id === id);
        if(index >= 0) {
            state.associatedData.splice(index, 1);
        }
    },
    setItemAssociations(state, value) {
        state.itemAssociations = value.data;
    }
};

export default {
    namespaced: true,
    state: getDefaultState,
    getters,
    actions,
    mutations
}
