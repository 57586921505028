import { computed } from 'vue'
export default function useSVG(props) {

    const activeFill = computed(() => props.active ? '#F9F9F9' : '#3D3D3D');
    const activeStroke = computed(() => props.active ? '#F9F9F9' : '#2B80AD');
    const activeRectFill = computed(() => props.active ? '#2B80AD' : '#F9F9F9');

    return {
        activeFill,
        activeStroke,
        activeRectFill
    }
}