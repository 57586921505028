<template>
    <svg class="inline" fill="none" version="1.1" viewBox="0 0 20.82 15.23" xmlns="http://www.w3.org/2000/svg">
        <g transform="translate(-15.6 -9.784)">
        <g filter="url(#a)">
            <path d="m16.1 23c0.0052-3.784-0.01032-7.569 0.0077-11.35 0.06359-0.9136 0.9997-1.471 1.853-1.347 5.531 0.0051 11.06-0.0103 16.59 0.0077 0.9136 0.06359 1.471 0.9997 1.347 1.853-0.0052 3.664 0.01031 7.329-0.0077 10.99-0.06359 0.9136-0.9997 1.471-1.853 1.347-5.531-0.0052-11.06 0.01032-16.59-0.0077-0.746-0.06204-1.362-0.7433-1.347-1.492z" stroke="currentColor"/>
            </g>
            <path d="m22.36 16.45c1.43 0.0618 2.849-1.26 2.57-2.736-0.2744-1.721-2.483-2.534-3.917-1.67-1.202 0.5909-1.667 2.292-0.8082 3.358 0.4945 0.6718 1.328 1.053 2.155 1.048zm0-3.897c1.061-0.06874 2.042 1.106 1.535 2.114-0.5594 1.288-2.693 1.206-3.126-0.1393-0.3679-1.009 0.5845-2.017 1.591-1.974z" fill="currentColor"/>
            <path d="m19.05 23.1c0.4652-0.1809 0.7863-0.632 1.188-0.9313 1.482-1.299 2.964-2.598 4.446-3.897 1.14 1.135 2.28 2.269 3.419 3.404 0.779 0.3404 0.5487-0.6497 0.06764-0.8672-0.3979-0.3962-0.7959-0.7924-1.194-1.189 1.016-1.107 2.032-2.215 3.047-3.322 1.246 1.137 2.492 2.274 3.738 3.411 0.7935 0.3058 0.5196-0.673 0.02977-0.8695-1.188-1.084-2.376-2.169-3.564-3.253-0.4501-0.2755-0.6964 0.3511-0.9917 0.5904-0.9095 0.9917-1.819 1.983-2.728 2.975-0.5647-0.5398-1.089-1.129-1.679-1.638-0.4326-0.06799-0.6781 0.4191-1.008 0.6332-1.663 1.458-3.326 2.916-4.99 4.374-0.231 0.1871-0.0782 0.5905 0.2194 0.5786z" fill="currentColor"/>
        </g>
        <defs>
        <filter id="a" x=".5996" y=".8008" width="50.8" height="45.2" color-interpolation-filters="sRGB" filterUnits="userSpaceOnUse">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
        <feOffset dy="6"/>
        <feGaussianBlur stdDeviation="7.5"/>
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"/>
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_10514_26925"/>
        <feBlend in="SourceGraphic" in2="effect1_dropShadow_10514_26925" result="shape"/>
        </filter>
        </defs>
    </svg>
</template>

<script>
export default {
    name: "Photograph"
}
</script>