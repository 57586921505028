import { render, staticRenderFns } from "./WorkspaceLeftMenu.vue?vue&type=template&id=876a9afa&scoped=true&"
import script from "./WorkspaceLeftMenu.vue?vue&type=script&setup=true&lang=js&"
export * from "./WorkspaceLeftMenu.vue?vue&type=script&setup=true&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "876a9afa",
  null
  
)

export default component.exports