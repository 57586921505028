<template>
    <svg class="inline" fill="none" version="1.1" viewBox="0 0 19.08 12.61" xmlns="http://www.w3.org/2000/svg">
      <path d="m1.112e-6 0 12.72 1.102e-6v2.102h-12.72zm-4.632e-7 5.255 19.08 1e-5v2.102h-19.08zm-4.632e-7 5.255h12.72v2.102h-12.72z" fill="currentColor" stroke-width="1.002"/>
    </svg>
</template>

<script>
export default {
  name: "MenuAlt",
  props: {
    solid: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isFill() {
      return this.solid ? 'currentColor': 'none'
    }
  }
}
</script>

<style scoped>

</style>
