<template>
    <ul
        class="brainstorm-menu fs-12 lh-25px text-left font-avenir uppercase letter-spacing-h3"
        style="background: #1b1b1d; position: fixed"
    >
        <li
            class="p-1 bs-li flex justify-between items-center relative cursor-pointer"
            v-for="(item, index) in listitems"
            :key="index"
            :class="item.border ? 'bs-li-border-bottom' : ''"
            @mouseover="showSubmenu(item)"
            @mouseleave="hideSubmenu(item)"
        >
            <router-link :to="item.url ? item.url + activeModule +'/'+ mcId : 'javascript:;'" class="w-full h-full block">{{ item.title }} </router-link>
            <img
                v-show="item.subitems.length > 0"
                src="/images/icons/right_arrow.svg"
                class="icon-10-10 ml-5 right-arrow"
                style="margin-right: 15px"
            />
            <!-- submenu -->
            <ul
                class="brainstorm-menu brainstorm-submenu fs-12 lh-25px text-left font-avenir uppercase letter-spacing-h3"
                style="background: #1b1b1d; position: absolute"
                :class="item.child ? 'visible' : 'invisible'"
            >
                <li
                    class="p-1 bs-li flex justify-between items-center cursor-pointer"
                    v-for="(subitem, cIndex) in item.subitems"
                    :key="cIndex"
                    :class="subitem.border ? 'bs-li-border-bottom' : ''"
                    @click="
                        toggleMenu(index, cIndex, subitem.value, subitem.title, item),
                        setMenu(item, subitem.id)
                    "
                >
                    <a>{{ subitem.title }} </a>
                    <img
                        v-show="subitem.subitems.length > 0"
                        src="/images/icons/right_arrow.svg"
                        class="icon-10-10 ml-5"
                        style="margin-right: 15px"
                    />
                </li>
            </ul>
        </li>
    </ul>
</template>
<script>
import { mapGetters} from "vuex";
export default {
    data() {
        return {
            submenu: false,
        };
    },
    props: {
        listitems: {
            type: Array,
        },
        width: {
            type: Number,
        },
        mcId: {
            type: Number,
        },
        placement: {
            type: String,
            default: "bottom",
        },
        
    },
    methods: {
        action(item, command) {
            if (item.title == "Numbers") {
                this.$emit("toggleNumber", command);
            }

            setTimeout(() => {
                this.$emit("closeMenu");
            }, 100);
        },
        showSubmenu(item) {
            if (item.subitems.length > 0) {
                item.child = true;
            }
        },
        hideSubmenu(item) {
            if (item.subitems.length > 0) {
                item.child = false;
            }
        },
        toggleMenu(parent, child = null, value = null, title, item) {
            this.action(item, title)
        },
        setMenu(item, id) {
            
            if (item.title == 'Brainstorm') {
                let active = id != undefined ? id : null
                this.$store.dispatch('build/getActiveBrainstorm', active)
                this.$store.dispatch('build/getActiveModuleCollection', this.mcId)
            }
        }
    },
    computed: {
        ...mapGetters({
            activeModule: "build/navigatorModule",
        }),
    },
};
</script>
<style>
.brainstorm-menu {
    background: #1b1b1d;
    box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    width: 180px;
    z-index: 10;
}
.brainstorm-menu .bs-li {
    opacity: 0.8;
    padding: 10px 0px 10px 20px;
    transition: background-color 0.2s ease-in;
}
.brainstorm-menu .bs-li:hover {
    opacity: 1;
    background: #2892cd;
}
.brainstorm-menu .bs-li-border-bottom {
    border-bottom: 0.1px solid rgb(250, 253, 253, 0.2);
}
.brainstorm-submenu {
    top: 0;
    left: 180px;
}
</style>
