<template>
    <div>
        <div class="mb-6 relative">
            <img v-if="previewURL" :src="previewURL" alt="image" class="block w-full _my_img_size">
            <img v-else :src="(scenario.file) ? scenario?.file?.file_path : '/images/build/demo_image_1.jpg'" alt="image" class="block w-full _my_img_size">
            <label v-if="canEdit(project.cans)"  class="absolute right-1 bottom-1 property-image-icon cursor-pointer">
                <input @input="(e)=>{onChange(e), updateImage(
                        {file, id:scenario.id},
                        ()=>{
                            scenario.file = {file_path: previewURL}
                            previewURL = ''
                        }
                    )}"
                    hidden type="file" accept="image/x-png,image/jpeg" />

                <svg class="pointer-events-none" xmlns="http://www.w3.org/2000/svg" width="18" height="13" viewBox="0 0 18 13" fill="none">
                    <path d="M0 12.3477H12V10.3477H0V12.3477ZM0 7.34766H18V5.34766H0V7.34766ZM0 2.34766H12V0.347656H0V2.34766Z" fill="#E2E2E2"/>
                </svg>
            </label>
        </div>

        <div v-if="scenario">
            <div class="flex items-center">
                <span class="text-primary-one ls-5 font-poppins fs-14 fw-600 mr-1">{{ scenario.index_code }}</span>
                <input
                    type="text" class="focus:outline-none bg-transparent w-full placeholder-secondary-two cursor-pointer text-primary-one fw-600 ls-5 font-poppins fs-14 truncate"
                    placeholder="No title available"
                    v-model="scenario.title"
                    :disabled="!canEdit(project.cans)"
                    @keyup="updateItem(scenario)"
                />
            </div>
            <!-- <textarea
                class="fs-12 w-full bg-transparent prop-item-desc break-words none-resize"
                placeholder="No description available"
                v-model="scenario.description"
                :disabled="!canEdit(project.cans)"
                @keyup="updateItem(scenario)"
            >
            </textarea>
            <hr> -->
            <TextareaAutoHeight 
                v-model="scenario.description"
                :is-disabled="!canEdit(project.cans)"
                @onType="updateItem(scenario)"
            />
        </div>

        <Accordion
            v-for="item in tagList"
            :key="item.id"
            :title="item.title"
        >
        <div v-for="tag in item.tags" :key="tag.id" >
            <div
                class="border border-white fs-14 font-light rounded p-3 mb-3 h-12 truncate"
                :style="{backgroundColor: item.settings.bg_color}"
                :title="tag.title"
            >
                {{ tag.title }}
            </div>
        </div>
        </Accordion>
    </div>
</template>

<script setup>
    import Accordion from './Accordion';
    import TextareaAutoHeight from '@/elements/inputs/TextareaAutoHeight';
    import axios from 'axios';
    import store from '@/store';
    import { watch, ref, computed } from 'vue';
    import usePermission from '@/composable/usePermission.js'
    import { useFile } from '@/composable/useFile.js'

    const { onChange, previewURL, file } = useFile()
    const { canEdit } = usePermission()

    const tagList = ref([]);
    const scenario = ref({});

    const project = computed(()=>{
        return store.getters['project/project']
    })

    watch(() => store.state.programmatic.activeScenarioCollection,
        async (data) => {
        scenario.value = data
        const response = await axios.get(`brainstorms/business-models/tags/by-collection-id/${data.id}`, {
            params: {
                type: 'scenario_collection_id' // module_collection_id
           }
        }).then(({data}) => data.data);
        tagList.value = response.filter(item => item.tags.length > 0);
    }, {immediate:true} );

    const updateItem = (obj) => {
      if(obj.is_module) {
        let data = {
          id: obj.id,
          title: obj.title,
          description: obj.description
        }
        store.dispatch("build/updateModule", data);
      } else {
        store.dispatch("programmatic/updateCollection", obj);
      }
    }


    const updateImage = async ({file, id}, callback) => {
        let formData = new FormData()
        formData.append('id', id)
        formData.append('image', file)
        
        let activeScenarioCollection = store.getters["programmatic/activeScenarioCollection"]

        let objData = Object.assign({}, activeScenarioCollection);
        if(objData.is_module){
            await axios.post('modules/image', formData)
        }else{
            await axios.post('projects/scenarios/collections/image', formData)
        }
        callback()
    }
</script>
