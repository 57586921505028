import axios from 'axios';

const state = {
    auth: null
};

const getters = {
    user: state => state.auth
}

const mutations = {
    MUT_LOGIN: function (state, payload) {
        localStorage.setItem('_token_', payload.data.token);
        localStorage.setItem('_user', JSON.stringify(payload.data.user));
        window.location.href = '/workspace';
    },
    MUT_REGISTRATION: function (state, payload) {
        localStorage.setItem('_token_', payload.data.token);
        localStorage.setItem('_user', JSON.stringify(payload.data.user));
        window.location.href = '/';
    },
    setUser: (state, payload) => state.auth = payload,
};

const actions = {
    ACT_LOGIN: function (context, payload) {
        return axios.post('login', payload)
            .then(response => {
                if(response.data.success) {
                    context.commit('MUT_LOGIN', response.data);
                } else {
                    alert('Invalid email or password')
                }
                return response;
            }).catch(e => {
            //
        });
    },
    ACT_REGISTRATION: function (context, payload) {
        return axios.post('registration', payload)
            .then(response => {
                if(response.data.success) {
                    // context.commit('MUT_REGISTRATION', response.data);
                } else {
                    alert('Registration failed');
                }
                return response;
            }).catch(e => {
            //
        });
    },
    verifyUser: function (context, link) {
        return axios.post(`verify-link/${link}`, {})
            .then(({ data }) => {
                return data.data;
            }).catch(e => {
            //
        });
    },
    getUser: async function({ commit }, userId = null) {
        let { data } = await axios.get(userId ? `user/${userId}` : `user`).then(data => data.data);
        commit('setUser', data);
        return data;
    },
    generatePasswordLink: function (context, payload) {
        return axios.post(`generate-forgot-password-token`, payload)
            .then(({ data }) => {
                if(data.success) {
                    alert('Reset password link has been sent to your email');
                } else {
                    alert('Failed to generate link');
                }
                return data.data;
            }).catch(e => {
                alert('Something went wrong')
            });
    },
    verifyPasswordResetLink: function (context, link) {
        return axios.get(`generate-forgot-password-token/${link}`, {})
            .then(({ data }) => {
                return data.data;
            }).catch(e => {
                //
            });
    },
    generateNewPassword: function (context, payload) {
        return axios.post(`update-password-through-token`, payload)
            .then(({ data }) => {
                return data.data;
            }).catch(e => {
                //
            });
    },
};

export default {
    state,
    mutations,
    getters,
    actions
}
