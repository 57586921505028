<template>
    <div class="space-y-2">
        <a
            v-for="(file, i) in files" 
            :key="i"
            class="flex justify-between items-center rounded p-3 bg-secondary-three"
            :href="(file?.file_path) ? file?.file_path : ''"
            target="_blank"
        > 
            <div class="flex gap-x-3 items-center">
                <Photograph class="w-3 h-3 text-primary-one" />
                <span class="text-primary-one fs-12 fw-400">File</span>
            </div>
            <DownloadBarIcon class="w-3 h-3 text-primary-one" />
        </a>
    </div>
</template>

<script>
export default {
    name: 'FileViewer',
    props: {
        files: Array
    }
}
</script>