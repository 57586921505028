import WorkspaceMenu from '@/components/InstMenu/WorkspaceMenu'
import WorkspaceLeftMenu from '@/components/InstMenu/WorkspaceLeftMenu'

import Dropdown from './Dropdown.vue'
import Draggable from 'vuedraggable'
import RangeSlider from "@/components/programmatic/RangeSlider"
import ProfileLineChart from "@/components/profile/ELineChart"
import ProjectEditModal from "./workspace/project/ProjectEditModal"
import ProjectAddRoleModal from "./workspace/project/AddRoleModal"
import CreateScenario from "./programmatic/CreateScenario";
import EditScenario from "./programmatic/EditScenario";
import RightNavComponentWrapper from "@/components/InstMenu/right-navigation-drawer/components/RightNavComponentWrapper.vue";
import AttachedTags from "@/components/shared/tagList/AttachedTags";

import AreaInputBar from './identifiers/AreaInputBar.vue'
import AreaMeasureInputBar from './identifiers/AreaMeasureInputBar.vue'
import MeasureInputBar from './identifiers/MeasureInputBar.vue'
import IdentifierInputGroup from './identifiers/IdentifierInputGroup.vue'
import IdentifierInputBar from './identifiers/IdentifierInputBar.vue'


const GlobalComponents = {
  install(vue) {
    vue.component('workspace-menu', WorkspaceMenu)
    vue.component('workspace-left-menu', WorkspaceLeftMenu)
    vue.component('dropdown', Dropdown)
    vue.component('area-input-bar', AreaInputBar)
    vue.component('area-measure-input-bar', AreaMeasureInputBar)
    vue.component('measure-input-bar', MeasureInputBar)
    vue.component('identifier-input-group', IdentifierInputGroup)
    vue.component('identifier-input-bar', IdentifierInputBar)
    vue.component('draggable', Draggable)
    vue.component('range-slider', RangeSlider)
    vue.component('profile-line-chart', ProfileLineChart)
    vue.component('p-e-modal', ProjectEditModal)
    vue.component('p-r-modal', ProjectAddRoleModal)
    vue.component('ps-c-modal', CreateScenario)
    vue.component('ps-e-modal', EditScenario)
    vue.component('RightNavComponentWrapper', RightNavComponentWrapper);
    vue.component('AttachedTags', AttachedTags);
  }
}

export default GlobalComponents
