import axios from 'axios';

function getDefaultState() {
    return {
        colorCodes: [],
    }
}

const getters = {
    colorCodes: state => state.colorCodes,
}

const actions = {
    getColorCodes({ commit }) {
        return axios.get(`color-codes`)
            .then(response => {
                commit("setColorCodes", response.data);
                return response;
            }).catch(e => {
                //
            });
    },
};

const mutations = {
    setColorCodes(state, value) {
        state.colorCodes = value.data;
    }
};

export default {
    namespaced: true,
    state: getDefaultState,
    getters,
    actions,
    mutations
}
