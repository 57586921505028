import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)

import Home from '@/views/pages/Home'

//modules
import communicator from "./module/communicator.js"
import other from "./module/other.js"
import workspace from "./module/workspace/workspace.js"
import teamwork from "./module/teamwork"
import visualization from "./module/visualization"
import roadmap from "./module/roadmap"
import auth from "./module/auth"
import dashboard from './module/dashboard'
import survey from './module/survey'
import project from './module/project'
import identification from './module/identification'
import profile from './module/profile';
import projectManagement from '@/views/screens/projectManagement/routes/index.js'

// Middleware
import middleware from './middleware/index';

let router = new Router({
    mode: 'history',
    routes: [
        ...auth,
        ...dashboard,
        ...visualization,
        ...communicator,
        ...other,
        ...teamwork,
        ...roadmap,
        ...workspace,
        ...survey,
        ...project,
        ...identification,
        ...profile,
        ...projectManagement,
        {
            path: '/',
            component: Home,
            name: 'home'
        }
    ]
});

router = middleware(router)
export default router;