<template>
    <RightNavComponentWrapper>
       <TopTitle
            title="Feedback"
            icon-type="layout"
            :is-list="isList"
            :setLayoutType="setLayoutType"
        />

        <div class="grid gap-5 mt-4" :class="isList ? 'grid-cols-1' : 'grid-cols-2'">
            <div v-for="(item, index) in getIdeas" :key="index">
                <p class="fs-14 fw-300 text-primary-one">You are viewing the information from</p>
                <p class="fs-14 fw-900 text-primary-one">{{ getAssessmentTitle(item) }} assessment</p>
                <p class="fs-14 fw-900 text-primary-one">created on {{ getFormattedDate(item) }} </p>
                <AvatarWithName
                    class="mt-3"
                    :isTitleBottom="true"
                    :title="getUserName(item)"
                    subtitle="Idea submitted "
                    :image-path="getAvatar(item)"
                />
                <Paragraph
                    class="mt-3"
                    :is-list="isList"
                    :title="item.title"
                />
                <Paragraph
                    class="mt-1"
                    :is-list="isList"
                    :paragraph="item.description"
                />
                <span class="text-secondary-two fs-10 mt-2">{{ time(item) }}</span>
            </div>
        </div>

        <button v-if="ideas && ideas.length > 2" class="text-primary-four fs-14 fw-500 mt-4 flex justify-center w-full" @click="displayAll = !displayAll">See {{ !displayAll ? 'All' : 'Less' }}</button>
    </RightNavComponentWrapper>
</template>

<script>
import sidebarMixin from '@/components/InstMenu/right-navigation-drawer/mixins.js'
import TopTitle from '@/components/InstMenu/right-navigation-drawer/components/TopTitle.vue'
import AvatarWithName from '@/components/InstMenu/right-navigation-drawer/components/AvatarWithName'
import Paragraph from '@/components/InstMenu/right-navigation-drawer/components/Paragraph.vue'
import ProfileImage from "@/assets/images/icons/profile/profile.png";

export default {
    name : 'Feedback',
    components: {
        TopTitle,
        Comment,
        Paragraph,
        AvatarWithName
    },
    props: {
      ideas: {
        type: Array
      }
    },
    data() {
      return {
        displayAll: false,
        image_path: ProfileImage
      }
    },
    computed: {
      getIdeas() {
        if(this.ideas && this.ideas.length > 0) {
          return this.displayAll ? this.ideas : this.ideas.slice(0, 2);
        }
        return [];
      }
    },
    mixins: [sidebarMixin],
    methods: {
      getAssessmentTitle(item) {
        return item && item.assessment && item.assessment.title ? item.assessment.title : 'Untitled';
      },
      getAvatar(item) {
        return item && item.created_by && item.created_by.image && item.created_by.image.file_path ? item.created_by.image.file_path : this.image_path;
      },
      getUserName(item) {
        if(item && item.created_by) {
          let name = '';
          if(item.created_by.first_name) {
            name = item.created_by.first_name + ' ';
          }
          if(item.created_by.last_name) {
            name = name + item.created_by.last_name;
          }
          if(name) {
            return name;
          }
          return 'Not found';
        }
        return 'Not found';
      },
      time(item) {
        if(item && item.created_at) {
          return this.$lodash.ago(item.created_at);
        }
        return "N/A";
      },
      getFormattedDate(item) {
        return item && item.assessment && item.assessment.created_at ? this.$lodash.format(item.assessment.created_at, 'PPP') : '';
      }
    }
}
</script>
