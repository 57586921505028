export default (router) => {
    const guestRoutes = ['login', 'register', 'reset_password', 'reset_password_email', 'verify_account', 'verify_notice'];
    router.beforeEach((to, from, next) => {
        let routes = guestRoutes.concat(['home', 'survey.page']);
        if (!routes.includes(to.name)) {
            if(!localStorage.getItem('_token_')) {
                next({ name: 'login' });
            }
            next();
        }
        // Guest route can not be accessed when logged in 
        if(guestRoutes.includes(to.name) && localStorage.getItem('_token_')) {
            next({ name: 'workspace_dashboard' });
        }
        next();
    });
}
