<template>
  <Modal v-model="workspaceModal">
    <div class="p-10">
      <div class="flex justify-between pb-5 i-border-b-1 border-primary-one">
        <h3 class="font-poppins text-primary-one fs-26">Create New Workspace </h3>
        <img src="/images/icons/workspace/workspace_icon.svg" alt="workspace">
      </div>
      <div class="i-mt-30">
        <div>
          <h4 class="font-poppins text-secondary-one fs-14">Title</h4>
          <input type="text" class="font-poppins text-secondary-one fs-14 placeholder-secondary-two w-full px-4 h-10 mt-2 i-border-1 border-solid border-primary-one rounded" v-model="name" :placeholder="placeholder" @click="placeholder = '';" v-click-outside="outsideInput">
        </div>
        <div class="i-mt-30">
          <h4 class="font-poppins text-secondary-one fs-14">Collaborator</h4>
          <div class="flex justify-between items-center mt-2 relative">
            <div class="modal-collaborator flex-3 pr-3 relative">
              <input type="text" v-model="responsibleUser.email" class="bg-transparent font-poppins text-secondary-one fs-14 placeholder-secondary-two w-full px-4 h-10 i-border-1 border-solid border-primary-one rounded" placeholder="Type email here">
              <div class="ws-modal-role-selector absolute bg-transparent">
                <select class="fs-14 font-poppins text-secondary-one ws-select-dropdown bg-transparent" v-model="responsibleUser.role">
                  <option class="bg-primary-three" v-for="(role, index) in roles" :key="index" :value="role.value">{{ role.title }}</option>
                </select>
              </div>
            </div>
            <button class="font-poppins fs-14 fw-600 h-10 w-40 flex-1 text-primary-one rounded border-secondary-one bg-transparent i-border-1" @click="addNewResponsible()" :disabled="loading">Add</button>
          </div>
        </div>
        <div class="modal-collaborators">
          <span v-for="(responsible, index) in responsibles" :key="index" :title="getRole(responsible.role)" class="fs-14 text-primary-one font-poppins mr-4 px-4 mt-4 pt-1 rounded i-border-1 border-primary-one">{{ responsible.email }} <span class="ml-2 fs-12 cursor-pointer" style="border: none" @click="removeResponsible(index)">&#10006;</span></span>
        </div>
        <div class="flex i-mt-30">
          <button class="fs-14 fw-600 font-poppins i-pl-35 i-pr-35 rounded bg-secondary-one text-primary-three h-10 w-40 shadow-one" @click="createWorkspace()" :disabled="loading">Create</button>
          <button class="focus:outline-none fs-14 fw-600 font-poppins i-pl-35 i-pr-35 i-border-1 border-secondary-one ml-5 rounded text-primary-one h-10 w-40" @click="closeModal()" :disabled="loading">Cancel</button>
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
import {mapGetters} from "vuex";
import Modal from '@/elements/atom/Modal';

export default {
  name: "WorkspaceCreateModal",
  components: {Modal},
  data() {
    return {
      placeholder: 'Type here',
      name: '',
      responsibleUser: {
        email: '',
        role: 2
      },
      roles: [
        // { value: 1, title: 'Admin' },
        { value: 2, title: 'Editor' },
        { value: 4, title: 'Viewer' }
      ],
      responsibles: []
    }
  },
  computed: {
    ...mapGetters({
      workspaceModal: "workspace/workspaceModal",
      loading: "GET_LOADING_STATE"
    })
  },
  
  methods: {
    closeModal() {
      this.responsibleUser = {
        email: '',
        role: 2
      };
      this.responsibles = [];
      this.name = '';
      // this.$modal.hide('workspace-create-modal');
      this.$store.dispatch("workspace/toggleWorkspaceModal", false);
    },
    addNewResponsible() {
      this.responsibles.push(this.responsibleUser);
      this.responsibleUser = {
        email: '',
        role: this.responsibleUser.role
      };
    },
    removeResponsible(index) {
      this.responsibles.splice(index, 1)
    },
    getRole(role) {
      let roleItem = this.roles.find(item => parseInt(item.value) === parseInt(role));
      if(roleItem) {
        return roleItem.title;
      }
      return '';
    },
    createWorkspace() {
      const self = this;
      self.$store.state.loading = true;
      self.$store.dispatch('workspace/storeWorkspaceData', {
        name: this.name,
        responsibles: this.responsibles
      }).then(response => {
        if(response) {
          self.$store.dispatch("workspace/fetchWorkspaces", 1);
          self.closeModal();
          self.$store.state.loading = false;
        } else {
          self.$store.state.loading = false;
          alert('Something went wrong!!!')
        }
      });
    },
    outsideInput() {
      this.placeholder = 'Type here';
    }
  }
}
</script>

<style scoped>
</style>
