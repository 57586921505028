<template>
<svg class="inline" viewBox="0 0 20 13" fill="none" xmlns="http://www.w3.org/2000/svg">
<g>
    <path d="M3.01128 9.4998H9.13683L10.4423 12.5998H11.7812L6.6933 0.799805H5.52175L0.400391 12.5998H1.72257L3.01128 9.4998ZM6.07405 2.13314L8.68494 8.3998H3.46316L6.07405 2.13314Z" fill="currentColor"/>
    <path d="M18.3954 7.81647C15.5334 7.81647 12.8723 8.03314 12.8723 10.4331C12.8723 12.1665 14.3953 12.7998 15.5836 12.7998C16.8389 12.7998 17.7092 12.3665 18.4288 11.3331H18.4623C18.4623 11.7498 18.5125 12.2165 18.5795 12.5998H19.6004C19.5167 12.1665 19.4665 11.4498 19.4665 10.8498V7.43314C19.4665 5.53314 18.1108 4.5998 16.4205 4.5998C15.0815 4.5998 13.9769 5.08314 13.341 5.71647L13.9769 6.46647C14.5292 5.91647 15.3828 5.5498 16.3033 5.5498C17.659 5.5498 18.3954 6.21647 18.3954 7.56647V7.81647ZM18.3954 8.6998V9.28314C18.3954 10.6665 17.4916 11.8665 15.8849 11.8665C14.9979 11.8665 14.0439 11.4998 14.0439 10.3831C14.0439 8.8998 16.2029 8.6998 18.0606 8.6998H18.3954Z" fill="currentColor"/>
</g>
</svg>
</template>

<script>
export default {
    name: "Aa"
}
</script>
