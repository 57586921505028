<template>
    <div class=" flex gap-x-8">
        <span
            @click="toggleExpansionRightBar()"
            class="capitalize cursor-pointer text-secondary-two fs-12 fw-400" 
            v-for="link in links" 
            :key="link"
        >{{ link }}</span>
    </div>
</template>

<script>
import {  mapMutations } from 'vuex'

export default {
    name: 'Navigator',
    data: () => ({
        links: ['Insights', 'Comments', 'Concepts', 'Diagrammatics']
    }),
    methods: {
        ...mapMutations({
            toggleExpansionRightBar: 'toggleExpansionRightBar'
        }),
    }

}
</script>