import Vue from 'vue'
// import * as vClickOutside from 'v-click-outside-x'
import App from './App.vue'
import VueRouter from 'vue-router'
import store from './store'
import '../src/assets/css/tailwind.css'
import '@splidejs/splide/dist/css/themes/splide-default.min.css'
import VueSplide from '@splidejs/vue-splide'
// import VueEllipseProgress from 'vue-ellipse-progress'
// import VueDragResize from 'vue-drag-resize'
// import VueDraggableResizable from 'vue-draggable-resizable'
import Highcharts from "highcharts";
import Gantt from "highcharts/modules/gantt";
import HighchartsVue from "highcharts-vue";
import GanttPoint from 'highcharts/modules/draggable-points';
import axios from 'axios';
import VueAxios from 'vue-axios';
import router from './routes/routes.js';
import VModal from 'vue-js-modal';
import VueProgressBar from 'vue-progressbar'
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker'
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css'
import Donut from 'vue-css-donut-chart'
import 'vue-css-donut-chart/dist/vcdonut.css'
import panZoom from 'vue-panzoom'
import VueHtml2Canvas from 'vue-html2canvas'

import {ColorPicker, ColorPanel} from 'one-colorpicker'
import VueTippy, { TippyComponent } from "vue-tippy"

Gantt(Highcharts);  
GanttPoint(Highcharts);
Vue.use(HighchartsVue);

// global components
import GlobalComponents from './components/globalComponents'

Vue.config.productionTip = false
Vue.use(VueRouter)
Vue.use(VueSplide)
// Vue.use(VueEllipseProgress)
Vue.use(GlobalComponents)
// Vue.use(vClickOutside)
Vue.use(HighchartsVue)
Vue.use(VueAxios, axios)
Vue.use(VModal)
Vue.component('VueCtkDateTimePicker', VueCtkDateTimePicker)
Vue.use(Donut)
Vue.use(panZoom)
// Vue.use(VueHtml2Canvas)
Vue.use(ColorPanel)
Vue.use(ColorPicker)
Vue.use(VueTippy)
// Vue.use(ApexCharts)
Vue.component("tippy", TippyComponent)

const options = {
  color: '#2A93C0',
  failedColor: '#af0606',
  thickness: '6px',
  transition: {
    speed: '0.00001s',
    opacity: '0.6s',
    termination: 5000
  },
  autoRevert: true,
  location: 'top',
  inverse: false
}
Vue.use(VueProgressBar, options)
axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;

axios.defaults.headers = {
  'Accept': 'application/json',
  'Content-Type': 'application/json',
  'Access-Control-Allow-Origin': '*',
  'Authorization': 'Bearer ' + localStorage.getItem('_token_') || null,
}

axios.interceptors.response.use(response => {
    return response;
 }, error => {
   if (error.response.status === 401 && localStorage.getItem('_token_')) {
    localStorage.removeItem('_token_');
    localStorage.removeItem('_user');
    router.push({
        name: 'login'
    });
   }
   return error;
 });

import lodash from './plugins/lodash';
import directive from './directive';
Vue.use(lodash);
Vue.use(directive);
import icon from './plugins/icon'
import './assets/tailwind.css'
Vue.use(icon);
new Vue({
  render: h => h(App),
  router,
  store
}).$mount('#app')
