/*
------------------------------------------------------
    Registering components globally.
------------------------------------------------------
We have targeted the elements/icons directory abd grab the all .vue files and
splitted the files name from path and registered as component.
Example: <DownloadIcon /> or <download-icon />.
*/
const files = require.context('../elements/icons', true, /\.vue$/i);

export default {
    install: function(Vue) {
        files.keys().forEach(key => {
            let fileName = key.replace('./', '').split('/').map(item => item.charAt(0).toUpperCase() + item.slice(1)).join('').replace('.vue', '');
            Vue.component(fileName, files(key).default);
        });
    }
}