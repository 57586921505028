export default [
    {
        path: '/project/:projectId/roadmap/:roadmapId/visualization',
        component: () => import('@/views/screens/roadmap/Visualization.vue'),
        name: 'roadmapVisualization'
    },
    {
        path: '/project/:projectId/brainstorm/:brainstormId/visualization',
        component: () => import('@/views/screens/brainstorm/Visualization.vue'),
        name: 'brainstormPreview'
    },
    {
        path: '/project/:projectId/canvas/:brainstormId/visualization',
        component: () => import('@/views/screens/teamwork/CanvasVisualization.vue'),
        name: 'canvasPreview'
    },
    {
        path: '/project/:projectId/concept/:brainstormId/visualization',
        component: () => import('@/views/screens/teamwork/ConceptVisualization.vue'),
        name: 'conceptPreview'
    },
    {
        path: '/project/:projectId/diagrammatic/:brainstormId/visualization',
        component: () => import('@/views/screens/teamwork/DiagrammaticVisualization.vue'),
        name: 'diagrammaticPreview'
    },
    {
        path: '/identification/build/:projectId/visualization',
        component: () => import('@/views/screens/discovery/Visualization.vue'),
        name: 'buildVisualization'
    },
    {
        path: '/project/:projectId/scenario/:scenarioId/visualization',
        component: () => import('@/views/screens/programmatic/ScenarioVisualization.vue'),
        name: 'scenarioVisualization'
    }
]