<template>
    <svg viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <line x1="3.66602" y1="10.667" x2="3.66602" y2="0.000327089" stroke="currentColor"/>
        <path d="M1 7.11035L3.80827 10.6659L6.21536 7.11035" stroke="currentColor"/>
    </svg>
</template>
<script>
export default {
    name: "ArrowNarrowDownIcon"
}
</script>