export default[
    {
        path: '/dashboard/workspace/:workspaceId',
        component: () => import('@/views/screens/dashboard/index.vue'),
        name: 'workspaceDashboard'
    },
    {
        path: '/dashboard/workspace/:workspaceId/goal',
        component: () => import('@/views/screens/dashboard/Goal.vue'),
        name: 'workspaceGoal'
    },
    {
        path: '/dashboard/workspace/:workspaceId/project/:projectId',
        component: () => import('@/views/screens/dashboard/index.vue'),
        name: 'projectDashboard'
    },
    {
        path: '/dashboard/workspace/:workspaceId/team/:teamId',
        component: () => import('@/views/screens/dashboard/TeamDashboard.vue'),
        name: 'teamDashboard'
    },
    {
        path: '/dashboard/workspace/:workspaceId/team/:teamId/dashboard/:dashboardId/card/:cardId',
        component: () => import('@/views/screens/dashboard/Configuration.vue'),
        name: 'chartConfiguration'
    },
]