import { isEmpty } from 'lodash'
import store  from '@/store';
import dataSet from './_dataSet';
const projectRequired = Object.keys(dataSet);

const getProject = (to) => {
    let key = dataSet[to.name].projectKey;
    let projectId = to.params[key];
    if(to.name == 'workspace_settings' && dataSet[to.name].query) {
        projectId = to.query[key];
    }
   return store.dispatch('project/getSolidObject', projectId);
}

export default (router) => {
    router.beforeEach( async (to, from, next) => {
        // if(projectRequired.includes(to.name)) {
        //     let project = await getProject(to);
        //     if(isEmpty(project?.cans)) {
        //         next({
        //             name: 'permission.deny'
        //         });
        //     }
        // }
        next();
    });
}
