<template>
    <div class="flex justify-between items-start">
        <div>
            <p class="fs-14 w-64 truncate fw-500 text-secondary-one uppercase">{{ title }}</p>
            <p v-if="boldText" class="fs-20 w-64 truncate fw-400 text-secondary-one">{{ boldText }}</p>
        </div>
        <div class="flex items-center gap-x-4">
            <template v-if="iconType=='layout'">
                <div @click="setLayoutType(true)">
                    <ListView :class="[iconClass, isList ? '' : 'opacity-40']" />
                </div>
                <div @click="setLayoutType(false)">
                    <GridView :class="[iconClass, isList ? 'opacity-40' : '']" />
                </div>
            </template>
            <PlusSquareIcon v-if="iconType=='add'" class="i-w-10 i-h-10 text-secondary-two" />
            <div
                v-if="iconType=='close'"
                @click="$emit('onClickClose')"
            >
                <Close class="i-w-10 i-h-10 text-secondary-two cursor-pointer" />
            </div>
            <template v-if="iconType == 'collapseHandler'">
                <div @click="$emit('onClickExpandIcon')">
                    <ChevronDoubleLeft :class="isExpanded ? 'rotate-180' : ''" class="i-w-10 i-h-10 text-secondary-two cursor-pointer transition duration-200" />
                </div>
                <div @click="$emit('onClickClose')">
                    <Close class="i-w-10 i-h-10 text-secondary-two cursor-pointer" />
                </div>
                
            </template>
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
    name: 'TopTitle',
    data: () => ({
        iconClass: 'w-3 h-3 cursor-pointer text-primary-one'
    }),
    computed: {
        ...mapGetters({
            isExpanded: 'getIsRightBarExpanded'
        })
    },
    props: {
        isList: {
            type: Boolean
        },
        iconType: {
            type: String,
            required: true
        },
        title: {
            type: String,
            required: true
        },
        boldText: {
            type: String,
        },
        setLayoutType: {
            type: Function,
        },
        toggleExpansionRightBar: {
            type: Function,
        },

    }
}
</script>