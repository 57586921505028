import { ref } from 'vue';
import { has } from 'lodash';

export function useFile(config = null) {
    const file = ref(null);
    const previewURL = ref(null);
    const localTarget = ref(null);
    let formData = new FormData;
    let element = ref(null);
    
    // Methods
    const hasFile = ({ files }) => files.length > 0;
    const setCurrentFile = ({ files }) => file.value = files[0];
    const preview = ({ files }) => previewURL.value = URL.createObjectURL(files[0]);
    const appendFile = (key = null) => {
        if(!key && !has(config, 'fileKey')) {
            throw new Error('[appendFIle]: File key is required')
        }
        if(!key && has(config, 'fileKey')) {
            formData.append(config.fileKey, file.value)
        }else {
            formData.append(key, file.value)
        }
    };
    const appends = (data) => {
        Object.keys(data).map(key => {
            formData.append(key, data[key]);
        });
    }
    const clear = () => {
        // Complexity: Not clear all of element so I reconstruct the formData
        formData = null;
        file.value = null
        previewURL.value = null
        formData = new FormData();
    }
    const logger = () => {
        for (var pair of formData.entries()) {
            console.log(`${pair[0]} = ${pair[1]}`); 
        }
    }
    const onChange = ({ target }) => {
        if(!hasFile(target)) {
            return;
        }
        setCurrentFile(target);
        preview(target);
        localTarget.value = target;
    }
    
    return {
        file,
        formData,
        previewURL,
        clear,
        logger, //For debugging
        appends,
        onChange,
        appendFile,
        hasFile
    }
}