<template>
  <modal name="project-edit-modal" @closed="closeModal(() => previewURL = '')" :height="'auto'" :classes="'bg-primary-three'">
    <div class="p-5">
      <div class="flex i-border-b-1 border-secondary-four">
        <h3 class="font-poppins text-primary-one opacity-50 fs-20 cursor-pointer ws-modal-item-selector i-border-b-1 border-primary-one opacity-100">
          {{ type_selected === 1 ? 'Project' : 'Template' }}
        </h3>
<!--        <h3 class="font-poppins text-primary-one opacity-50 fs-20 ws-modal-mr cursor-pointer"-->
<!--            :class="{'ws-modal-item-selector i-border-b-1 border-primary-one opacity-100': type_selected === 2}"-->
<!--            @click="toggleWorkspaceInfo(2)">Template</h3>-->
      </div>
      <div class="mt-5">
        <div>
          <h4 class="font-poppins text-secondary-one fs-14">Title</h4>
          <input type="text" v-model="projectData.name" class="bg-transparent mt-2 font-poppins text-secondary-one fs-14 placeholder-secondary-two w-full px-4 h-10 i-border-1 border-solid border-primary-one rounded" :placeholder="placeholder.name" @click="placeholder.name = '';" v-click-outside="outsideInput">
        </div>
<!--        <div class="i-mt-30">-->
<!--          <h4 class="font-poppins text-secondary-one fs-14">Category</h4>-->
<!--          <select class="fs-14 font-poppins text-secondary-one ws-select-dropdown bg-transparent mt-2 rounded i-border-1 border-solid border-primary-one w-full px-4" v-model="projectData.category">-->
<!--            <option v-for="(category, index) in categories" :key="index" :value="category.value">{{ category.title }}</option>-->
<!--          </select>-->
<!--        </div>-->
        <!--  @selected="setPickerDate" -->
        <div class="flex mt-5 gap-x-3">
          <div>
            <h4 class="font-poppins text-secondary-one fs-14">Start Date</h4>
            <Datepicker
              v-model="projectData.start_date"
              input-class="bg-transparent mt-2 font-poppins text-secondary-one fs-14 placeholder-secondary-two px-4 h-10 i-border-1 border-solid border-primary-one rounded w-full"
              placeholder="dd-mm-yyyy"
              :format="'dd-MM-yyyy'"
          />
          </div>
          <div>
            <h4 class="font-poppins text-secondary-one fs-14">End Date</h4>
            <Datepicker
              v-model="projectData.end_date"
              input-class="bg-transparent mt-2 font-poppins text-secondary-one fs-14 placeholder-secondary-two px-4 h-10 i-border-1 border-solid border-primary-one rounded w-full"
              placeholder="dd-mm-yyyy"
              :format="'dd-MM-yyyy'"
            />
          </div>
        </div>

        <div class="mt-5">
          <h4 class="font-poppins text-secondary-one fs-14">Folder</h4>
          <select class="fs-14 font-poppins text-secondary-one ws-select-dropdown bg-transparent mt-2 rounded i-border-1 border-solid border-primary-one w-full px-4" v-model="projectData.directory_id">
            <option value="">Select folder</option>
            <option v-for="(directory, index) in directories" :key="index" :value="directory.id">{{ directory.name }}</option>
          </select>
        </div>

        <div class="mt-5">
          <h4 class="font-poppins text-secondary-one fs-14">Workspace </h4>
          <select class="fs-14 font-poppins text-secondary-one ws-select-dropdown bg-transparent mt-2 rounded i-border-1 border-solid border-primary-one w-full px-4" v-model="projectData.workspace_id">
            <option value="">Select workspace </option>
            <option v-for="(workspace, index) in workspaces" :key="index" :value="workspace.id">{{ workspace.name }}</option>
          </select>
        </div>

        <div class="mt-5">
          <h4 class="font-poppins text-secondary-one fs-14">Collaborator</h4>
          <div class="flex justify-between items-center mt-2 relative">
            <div class="modal-collaborator flex-3 pr-3 relative">
              <input type="text" class="bg-transparent font-poppins text-secondary-one fs-14 placeholder-secondary-two w-full px-4 h-10 i-border-1 border-solid border-primary-one rounded"
                     :placeholder="placeholder.responsible" @click="placeholder.responsible = '';" v-click-outside="outsideResponsible"
                     v-model="responsible.value">
              <div class="ws-modal-role-selector absolute bg-transparent">
                <select class="fs-14 font-poppins text-secondary-one ws-select-dropdown bg-transparent" v-model="responsible.role">
                  <option v-for="(role, index) in roles" :key="index" :value="role.value">{{ role.title }}</option>
                </select>
              </div>
            </div>
            <button class="font-poppins fs-14 fw-600 h-10 w-40 flex-1 text-primary-one rounded border-secondary-one bg-transparent i-border-1" @click="addNewResponsible()" :disabled="loading">Add</button>
          </div>
          <div class="ws-suggestion-box bg-primary-three rounded" v-if="getUsers.length > 0 && responsible.value">
            <ul>
              <li v-for="(user, index) in getUsers" :key="index" class="fs-14 font-poppins text-primary-one px-4 py-2 cursor-pointer hover:bg-primary-two" @click="chooseUser(user)">
                {{ getUserName(user) }}
              </li>
            </ul>
          </div>
        </div>
        <div class="modal-collaborators">
          <span v-for="(responsible, index) in responsibles" :key="index" :title="getRole(responsible.role)"
                class="fs-14 text-primary-one font-poppins mr-4 px-4 mt-4 pt-1 rounded i-border-1 border-primary-one">
              {{ getResponsibleName(responsible) }}
            <span class="ml-2 fs-12 cursor-pointer" style="border: none" @click="removeResponsible(index)">&#10006;</span></span>
        </div>

        <div class="mt-5 flex gap-6 items-center">
            <label class=" flex gap-3 items-center cursor-pointer">
                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M19.35 10.04C18.67 6.59 15.64 4 12 4 9.11 4 6.6 5.64 5.35 8.04 2.34 8.36 0 10.91 0 14c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96zM19 18H6c-2.21 0-4-1.79-4-4 0-2.05 1.53-3.76 3.56-3.97l1.07-.11.5-.95C8.08 7.14 9.94 6 12 6c2.62 0 4.88 1.86 5.39 4.43l.3 1.5 1.53.11c1.56.1 2.78 1.41 2.78 2.96 0 1.65-1.35 3-3 3zM8 13h2.55v3h2.9v-3H16l-4-4z"/></svg>
                <span>File Upload</span>
                <input @input="(e)=>{onChange(e), fileUpload(file)}" hidden type="file" accept="image/x-png,image/jpeg" />
            </label>
            <img v-if="previewURL" class="w-10 h-8 object-cover" :src="previewURL" />
        </div>

        <div class="flex mt-5">
          <button class="fs-14 fw-600 font-poppins i-pl-35 i-pr-35 rounded bg-secondary-one text-primary-three h-10 w-40 shadow-one" @click="updateProject(() => previewURL = '')" :disabled="loading">Update</button>
          <button class="focus:outline-none fs-14 fw-600 font-poppins i-pl-35 i-pr-35 i-border-1 border-secondary-one ml-5 rounded text-primary-one h-10 w-40" @click="closeModal(()  =>previewURL = '')" :disabled="loading">Cancel</button>
        </div>
      </div>
    </div>
  </modal>
</template>

<script setup>
  import { useFile } from '@/composable/useFile.js'
  let { onChange, previewURL, file } = useFile()
</script>

<script>
import {mapGetters} from "vuex";
import Datepicker from 'vuejs-datepicker';

export default {
  name: "ProjectEditModal",
  props: ['dEditModal', 'selectedProject'],
  components: {
    Datepicker
  },
  data() {
    return {
      type_selected: 1,
      formData: new FormData(),
      roles: [
        // { value: 1, title: 'Admin' },
        { value: 2, title: 'Editor' },
        { value: 4, title: 'Viewer' }
      ],
      responsibles: [],
      responsible: {
        role: 2,
        value: '',
        user_id: '',
        email: '',
        choosed: false,
        is_new: true
      },
      categories: [
        { value: 'business', title: 'Business' },
        { value: 'technology', title: 'Technology' },
        { value: 'software', title: 'Software' },
        { value: 'architecture', title: 'Architecture' },
      ],
      placeholder: {
        name: 'Your Project Name',
        responsible: 'Type email here'
      },
      projectData: {
        name: '',
        category: 'business',
        directory_id: '',
        workspace_id: null
      },
      removedResponsibles: [],
      blobUrl: null
    }
  },
  watch: {
    dEditModal: function (val) {
      if (val) {
        this.openModal();
      } else {
        this.closeModal();
      }
    }
  },
  computed: {
    ...mapGetters({
      workspaces: 'workspace/workspaces',
      directories: "workspace/directories",
      activeWorkspaceObj: "workspace/activeWorkspaceObj",
      workspaceUsers: "workspace/workspaceUsers",
      projectInfo: "workspace/projectInfo",
      loading: "GET_LOADING_STATE"
    }),
    getUsers() {
      if (this.responsible.value && !this.responsible.choosed) {
        let user = JSON.parse(localStorage.getItem('_user'));
        return this.workspaceUsers.filter((item) => {
          return this.responsible.value
              .toLowerCase()
              .split(" ")
              .every((v) => {
                let id = item.w_user_id ? item.w_user_id : item.w_r_email;
                if(item.w_user_email && item.w_user_id !== user.id && this.checkUserExistence(id)) {
                  return item.w_user_email.toLowerCase().includes(v)
                } else if(item.w_r_email && item.w_user_id !== user.id && this.checkUserExistence(id)) {
                  return item.w_r_email.toLowerCase().includes(v)
                }
              });
        });
      } else {
        return [];
      }
    }
  },
  methods: {
    openModal() {
      this.getProject();
      this.$store.dispatch("workspace/getWorkspaceUser", this.activeWorkspaceObj.id);
      this.$modal.show('project-edit-modal');
    },
    closeModal(callback = () => {}) {
      this.responsible = {
        value: '',
        role: 2,
        w_role: null,
        user_id: '',
        email: '',
        choosed: false,
        is_new: true
      };
      this.projectData = {
        name: '',
        category: 'business',
        directory_id: '',
        type: '',
        workspace_id: ''
      }
      this.$modal.hide('project-edit-modal');
      this.$emit('toggleEditModalMode', false);
      callback()
    },
    toggleWorkspaceInfo(type) {
      const self = this;
      self.type_selected = type;
    },
    outsideInput() {
      this.placeholder.name = 'Your Project Name';
    },
    outsideResponsible() {
      this.placeholder.responsible = 'Type email here';
    },
    getUserName(user) {
      let title = '';
      if(user.w_user_first_name) {
        title = user.w_user_first_name;
        if(user.w_user_last_name) {
          title = title + ' ' + user.w_user_last_name;
        }
      } else if(user.w_user_email) {
        title = user.w_user_email;
      } else {
        title = user.w_r_email;
      }
      return title;
    },
    chooseUser(user) {
      if(user.w_user_id) {
        this.responsible.user_id = user.w_user_id;
        this.responsible.w_role = user.w_r_role;
        this.responsible.email = user.w_user_email;
        this.responsible.value = this.getUserName(user);
      } else {
        this.responsible.value = user.w_r_email;
        this.responsible.email = user.w_r_email;
        this.responsible.w_role = user.w_r_role;
      }
      this.responsible.choosed = true;
    },
    addNewResponsible() {
      if(this.responsible.email || this.responsible.user_id) {
        if((this.responsible.w_role == 3 || this.responsible.w_role == 4) && this.responsible.w_role != this.responsible.role) {
          alert('You can not add a workspace viewer as project editor. Make the user as editor in workspace first');
          return;
        }
        this.responsibles.push(this.responsible);
        this.responsible = {
          value: '',
          user_id: '',
          email: '',
          role: this.responsible.role,
          choosed: false,
          is_new: true
        };
      } else {
        alert('Please select user from workspace!!!');
      }
    },
    getRole(role) {
      let roleItem = this.roles.find(item => parseInt(item.value) === parseInt(role));
      if(roleItem) {
        return roleItem.title;
      }
      return '';
    },
    removeResponsible(index) {
      let item = this.responsibles[index];
      if(!item.is_new) {
        this.removedResponsibles.push(item);
      }
      this.responsibles.splice(index, 1);
    },

    appends(data) {
      for(let key in data){
          this.formData.append(key, data[key])
      }
      return this.formData;
    },
    fileUpload(file){
      this.projectData.image = file
      this.blobUrl = URL.createObjectURL(file);
    },

    updateProject(callback = () => {}) {
      const self = this;
      self.$store.state.loading = true;
      self.projectData.responsibles = self.responsibles;
      self.projectData.start_date = this.$lodash.format(self.projectData.start_date, 'yyyy-MM-dd')
      self.projectData.end_date = this.$lodash.format(self.projectData.end_date, 'yyyy-MM-dd')
      self.projectData.type = self.type_selected;
      // self.projectData.workspace_id = self.activeWorkspaceObj.id;
      self.projectData.removedResponsibles = self.removedResponsibles;

      if(this.projectData.image) {
        self.projectData.file = {
          file_path: self.blobUrl
        }
        let fileData = Object.assign({}, self.projectData);
        fileData = self.appends(fileData);
        self.$store.dispatch('workspace/storeProjectFile', fileData);
      }

      if(self.projectData.directory_id==null) delete self.projectData.directory_id

      self.$Progress.start();
      self.$store.dispatch('workspace/updateProjectData', self.projectData).then(response => {
        if(response) {
          self.closeModal();

          if(self.projectData.image) {
            self.projectData.file = {
              file_path: self.blobUrl
            }
            let fileData = Object.assign({}, self.projectData);
            fileData = self.appends(fileData);
            self.$store.dispatch('workspace/storeProjectFile', fileData).then(response => {
              self.$Progress.finish();
              self.$store.dispatch("workspace/getWorkspaceData", {
                type: self.type_selected,
                workspace_id: self.activeWorkspaceObj.id
              });
            });
          } else {
            self.$store.dispatch("workspace/getWorkspaceData", {
              type: self.type_selected,
              workspace_id: self.activeWorkspaceObj.id
            });
            self.$Progress.finish();
          }

          self.$store.state.loading = false;
          self.responsible = {
            value: '',
            user_id: '',
            email: '',
            role: 2,
            choosed: false,
            is_new: true
          };
          self.projectData = {
            name: '',
            category: 'business',
            directory_id: '',
            type: '',
            workspace_id: ''
          }
          self.responsibles = [];

          callback()
        } else {
          self.$store.state.loading = false;
          alert('Something went wrong!!!')
        }
      });
    },
    getProject() {
      const self = this;
      let user = JSON.parse(localStorage.getItem('_user'));
      self.$store.dispatch("workspace/getProjectInfo", self.selectedProject.id).then(response => {
        if(response) {
          let projectInfo = self.projectInfo;
          self.projectData = {
            id: projectInfo.project.id,
            owner_id: projectInfo.project.owner_id,
            created_by: projectInfo.project.created_by,
            name: projectInfo.project.name,
            directory_id: projectInfo.project.directory_id,
            category: projectInfo.project.category,
            type: projectInfo.project.type,
            workspace_id: projectInfo.project.workspace_id,
            start_date: projectInfo.project.start_date,
            end_date: projectInfo.project.end_date

          };
          self.type_selected = projectInfo.project.type;
          let itemIndex = projectInfo.responsibles.findIndex(item => parseInt(item.user_id) === parseInt(self.projectData.owner_id));
          if(itemIndex >= 0) {
            projectInfo.responsibles.splice(itemIndex, 1);
          }
          self.responsibles = projectInfo.responsibles;
        }
      });
    },
    getResponsibleName(responsible) {
      let title = '';
      if(responsible.user) {
        if(responsible.user.first_name) {
          title = responsible.user.first_name;
          if(responsible.user.last_name) {
            title = title + ' ' + responsible.user.last_name;
          }
        } else {
          title = responsible.user.email;
        }
      } else {
        title = responsible.email;
      }
      return title;
    },
    checkUserExistence(id) {
      let user = this.responsibles.find(item => item.user_id === id || item.email === id);
      return !user;
    }
  },
}
</script>

<style scoped>

</style>
