import { render, staticRenderFns } from "./IconFileText.vue?vue&type=template&id=15602671&scoped=true&"
import script from "./IconFileText.vue?vue&type=script&setup=true&lang=js&"
export * from "./IconFileText.vue?vue&type=script&setup=true&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "15602671",
  null
  
)

export default component.exports