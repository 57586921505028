export default [
    {
        path: '/components',
        component: () => import('@/views/pages/Components'),
        name: 'communicator-component'
    },
    {
        path: '/icons',
        component: () => import('@/views/pages/Icons'),
        name: 'communicator-icons'
    },
    {
        path: '/experiment',
        component: () => import('@/views/pages/Experiment'),
        name: 'experiment'
    },
    {
        path: '/permission-deny',
        component: () => import('@/views/pages/PermissionDeny'),
        name: 'permission.deny'
    },
    {
        path: '/contact',
        component: () => import('@/views/pages/Contact'),
        name: 'contact'
    },
    {
        path: '/notifications',
        component: () => import('@/views/screens/notification/Index'),
        name: 'notification'
    },
    {
        path: '/build/:prId/properties/:id/:mcId',
        component: () => import('@/views/screens/build/properties'),
        name: 'build.properties'
    }
]