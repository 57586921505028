const state = {
    moduleSection: 0,
    moduleLevel: 1,
    modulesColorClass: ['identification', 'capability', 'enabler', 'design', 'program', 'plan', 'solution', 'effect', 'outcome'],
    moduleSelections: [
        { title: 'identification', child: [{ level: 0, serial: 0, title: 'Module' }, { level: 1, serial: 0, title: 'Level 1' }, { level: 2, serial: 1, title: 'Level 2' }, { level: 3, serial: 2, title: 'Level 3' }, { level: 4, serial: 3, title: 'Level 4' }] },
        { title: 'capability', child: [{ level: 0, serial: 0, title: 'Module' }, { level: 1, serial: 0, title: 'Level 1' }, { level: 2, serial: 1, title: 'Level 2' }, { level: 3, serial: 2, title: 'Level 3' }, { level: 4, serial: 3, title: 'Level 4' }] },
        { title: 'enabler', child: [{ level: 0, serial: 0, title: 'Module' }, { level: 1, serial: 0, title: 'Level 1' }, { level: 2, serial: 1, title: 'Level 2' }, { level: 3, serial: 2, title: 'Level 3' }, { level: 4, serial: 3, title: 'Level 4' }] },
        { title: 'design', child: [{ level: 0, serial: 0, title: 'Module' }, { level: 1, serial: 0, title: 'Level 1' }, { level: 2, serial: 1, title: 'Level 2' }, { level: 3, serial: 2, title: 'Level 3' }, { level: 4, serial: 3, title: 'Level 4' }] },
        { title: 'program', child: [{ level: 0, serial: 0, title: 'Module' }, { level: 1, serial: 0, title: 'Level 1' }, { level: 2, serial: 1, title: 'Level 2' }, { level: 3, serial: 2, title: 'Level 3' }, { level: 4, serial: 3, title: 'Level 4' }] },
        { title: 'plan', child: [{ level: 0, serial: 0, title: 'Module' }, { level: 1, serial: 0, title: 'Level 1' }, { level: 2, serial: 1, title: 'Level 2' }, { level: 3, serial: 2, title: 'Level 3' }, { level: 4, serial: 3, title: 'Level 4' }] },
        { title: 'solution', child: [{ level: 0, serial: 0, title: 'Module' }, { level: 1, serial: 0, title: 'Level 1' }, { level: 2, serial: 1, title: 'Level 2' }, { level: 3, serial: 2, title: 'Level 3' }, { level: 4, serial: 3, title: 'Level 4' }] },
        { title: 'effect', child: [{ level: 0, serial: 0, title: 'Module' }, { level: 1, serial: 0, title: 'Level 1' }, { level: 2, serial: 1, title: 'Level 2' }, { level: 3, serial: 2, title: 'Level 3' }, { level: 4, serial: 3, title: 'Level 4' }] },
        { title: 'outcome', child: [{ level: 0, serial: 0, title: 'Module' }, { level: 1, serial: 0, title: 'Level 1' }, { level: 2, serial: 1, title: 'Level 2' }, { level: 3, serial: 2, title: 'Level 3' }, { level: 4, serial: 3, title: 'Level 4' }] }
    ]
};

const customGetters = {
    GET_CURRENT_MODULE_Section(state) {
        return state.moduleSection;
    },
    GET_CURRENT_MODULE_LEVEL(state) {
        return state.moduleLevel;
    },
    GET_CURRENT_MODULE_BG(state) {
        return state.modulesColorClass[state.moduleLevel - 1];
    },
    GET_SELECTED_MODULE_SELECTION(state) {
        return state.moduleSelections[state.moduleLevel - 1].child;
    }
};

const customActions = {
    ACT_TOGGLE_MODULE_LEVEL: function(context, option) {
        context.commit("MUT_TOGGLE_MODULE_LEVEL", option);
    },
    ACT_SET_MODULE_LEVEL: function(context, level) {
        context.commit("MUT_SET_MODULE_LEVEL", level);
    }
};

const customMutations = {
    MUT_TOGGLE_MODULE_LEVEL: function(state, moduleNumber) {
        if(state.moduleLevel > 0) {
            state.moduleLevel = moduleNumber;
            state.moduleSection = moduleNumber / 3;
        } else {
            state.moduleLevel = 1;
            state.moduleSection = 1;
        }
    },
    MUT_SET_MODULE_LEVEL(state, level) {
        state.moduleLevel = level;
    }
};

export default {
    state,
    getters: customGetters,
    mutations: customMutations,
    actions: customActions
}
