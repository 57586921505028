export default function usePermission() {

    const _checker = (ability, source = []) => {
        if(!source.length) {
            // new Throw('Source can not empty');
            return false
        }
        return  source.includes(ability);
    };

    const can           = (ability, abilities) => _checker(ability, abilities);
    const cant          = (ability, abilities) => !_checker(ability, abilities);
    
    // Helpers
    const canCreate     = abilities => _checker('create', abilities);
    // const canCreate     = abilities => false;
    const canRead       = abilities => _checker('read', abilities);
    // const canRead       = abilities => false;
    const canEdit       = abilities => _checker('edit', abilities);
    // const canEdit       = abilities => false;
    const canDelete     = abilities => _checker('delete', abilities);
    // const canDelete       = abilities => false;
    const canUpdate     = abilities => _checker('update', abilities);

    return {
        can,
        cant,
        canCreate,
        canEdit,
        canRead,
        canUpdate,
        canDelete
    }
}