<template>
    <RightNavComponentWrapper>
        <TopTitle
            title="Metric"
            icon-type="layout"
            :is-list="isList"
            :setLayoutType="setLayoutType"
        />

        <div v-for="(property, index) in properties" :key="index" :class="{'mt-10': index > 0}">
          <metrics-property
              :active-data="activeData"
              :active-page-data="activePageData"
              :property="property"
              :index="index" />
        </div>
    </RightNavComponentWrapper>
</template>

<script>
import sidebarMixin from '@/components/InstMenu/right-navigation-drawer/mixins.js'
import TopTitle from '@/components/InstMenu/right-navigation-drawer/components/TopTitle.vue'
import Paragraph from '@/components/InstMenu/right-navigation-drawer/components/Paragraph.vue'
import TitleValue from '@/components/InstMenu/right-navigation-drawer/components/TitleValue.vue'
import ShowAll from '@/components/InstMenu/right-navigation-drawer/components/ShowAll.vue'
import SettingsAccordion from '@/components/roadmap/canvas/widgets/SettingsAccordion';
import MetricChart from '@/components/InstMenu/right-navigation-drawer/components/MetricChart.vue';
import MetricsProperty from "@/components/InstMenu/right-navigation-drawer/module/MetricsProperty";

export default {
    name : 'Metrics',
    mixins: [sidebarMixin],
    props: {
      activeData: {
        type: Object
      },
      activePageData: {
        type: Object
      },
      properties: {
        type: Array
      }
    },
    data: () => ({
        scale1: true,
    }),
    components: {
      MetricsProperty,
        TopTitle,
        Paragraph,
        TitleValue,
        ShowAll,
        SettingsAccordion,
        MetricChart
    },
}
</script>
