<template>
    <div class="flex gap-4 justify-between items-center" :style="{'--color': color}">
        <label class="flex items-center justify-between gap-2">
            <input v-if="isRadio" hidden :checked="checked" :name="nameAttr" type="radio" @click="value=1; callBack(value)" />
            <input v-else hidden :checked="checked" :name="nameAttr" type="checkbox" @click="value = !value; callBack(value)" />
            <div class="switch">
                <div class="slider round"></div>
            </div>
            <span class="label_text" v-if="text!=''" :style="{order: -2}">{{ text }}</span>
        </label>
    </div>
</template>

<script setup>
    import { ref, watchEffect } from 'vue'
    defineProps({
        color: {
            type: String,
            default: '#2B80AD'
        },
        checked: {
            type:  [Number, Boolean],
            default: 0
        },
        callBack: {
            type: Function,
            default: ()=>{}
        },
        isRadio: {
            type:  [Number, Boolean],
            default: false
        },
        nameAttr: {
            type: String
        },
        text: {
            type: String
        }
    })
    const value = ref(0)
</script>

<style scoped>
    .switch {
        display: inline-block;
        height: 16px;
        position: relative;
        width: 32px;
    }

    label input {
        display:none;
    }

    .slider {
        background-color: transparent;
        border: 1px solid Silver;
        bottom: 0;
        cursor: pointer;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        transition: .4s;
    }

    .slider:before {
        background-color: #8D8D8D;
        bottom: 2px;
        content: "";
        height: 12px;
        width: 12px;
        left: 2px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        transition: .4s;
    }

    input:checked + .switch + .label_text {
        color: var(--color);
    }
    input:checked + .switch>.slider {
        border-color: var(--color);
    }

    input:checked + .switch>.slider:before {
        right: 2px;
        left: unset;
        background-color: var(--color);
    }

    .slider.round {
        border-radius: 16px;
        height: 100%;
    }

    .slider.round:before {
        border-radius: 50%;
    }
</style>