<template>
  <modal name="ps-edit-modal" @closed="closeModal(() => previewURL = '')" :height="'auto'" :classes="'bg-primary-three'">
    <div class="p-10">
      <div class="flex justify-between i-border-b-1 border-secondary-four">
        <h3 class="font-poppins text-primary-one fs-20 cursor-pointer ws-modal-item-selector">Edit Scenario</h3>
        <img src="/images/icons/workspace/workspace_icon.svg" alt="workspace">
      </div>
      <div class="i-mt-30" v-if="scenario">
        <div>
          <h4 class="font-poppins text-secondary-one fs-14">Title</h4>
          <input type="text"
                 class="bg-transparent mt-2 font-poppins text-secondary-one fs-14 placeholder-secondary-two w-full px-4 h-10 i-border-1 border-solid border-primary-one rounded"
                 v-model="scenario.title" :placeholder="scenarioPlaceholder.title"
                 @click="scenarioPlaceholder.title = ''" v-click-outside="outsideTitleInput">
        </div>
        <div class="i-mt-30">
          <h4 class="font-poppins text-secondary-one fs-14">Category</h4>
          <input type="text"
                 class="bg-transparent mt-2 font-poppins text-secondary-one fs-14 placeholder-secondary-two w-full px-4 h-10 i-border-1 border-solid border-primary-one rounded"
                 v-model="scenario.category" :placeholder="scenarioPlaceholder.category"
                 @click="scenarioPlaceholder.category = ''" v-click-outside="outsideCategoryInput">
        </div>

        <div class="flex justify-between items-center gap-6 mt-5 mb-6">
            <div class="relative flex gap-12 justify-between items-center px-4 py-3 rounded border border-secondary-five">
                <label @click="showColorPanel">
                    <svg width="30" height="16" viewBox="0 0 30 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <mask id="mask0_14924_29940" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="30" height="16">
                        <rect width="30" height="16" fill="#C4C4C4"/>
                      </mask>
                      <g mask="url(#mask0_14924_29940)">
                        <rect x="15.4844" width="14.5161" height="8" fill="#19A77C"/>
                        <rect width="14.5161" height="7" transform="matrix(1 0 0 -1 15.4844 16)" fill="#0085FF"/>
                        <rect width="14.5161" height="8" transform="matrix(-1 0 0 1 14.5156 0)" fill="#D6D6D6"/>
                        <rect x="14.5156" y="16" width="14.5161" height="7" transform="rotate(-180 14.5156 16)" fill="#FE5656"/>
                      </g>
                    </svg>
                    <input ref="colorPicker" type="color" v-model="scenario.settings.color" class="color_picker_wrapper absolute top-0 left-0 opacity-0 cursor-pointer" />
                </label>

                <!-- <Switch :checked="scenario.is_default" :callBack="(res)=>{ scenario.is_default = res }" text="Preview"/> -->
            </div>
            <p>Please select a color for the scenario marker</p>
        </div>

        <div class="i-mt-30 flex gap-6 items-center">
            <label class=" flex gap-3 items-center cursor-pointer">
                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M19.35 10.04C18.67 6.59 15.64 4 12 4 9.11 4 6.6 5.64 5.35 8.04 2.34 8.36 0 10.91 0 14c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96zM19 18H6c-2.21 0-4-1.79-4-4 0-2.05 1.53-3.76 3.56-3.97l1.07-.11.5-.95C8.08 7.14 9.94 6 12 6c2.62 0 4.88 1.86 5.39 4.43l.3 1.5 1.53.11c1.56.1 2.78 1.41 2.78 2.96 0 1.65-1.35 3-3 3zM8 13h2.55v3h2.9v-3H16l-4-4z"/></svg>
                <span>File Upload</span>
                <input @input="(e)=>{onChange(e), fileUpload(file)}" hidden type="file" accept="image/x-png,image/jpeg" />
            </label>
            <img v-if="previewURL" class="w-10 h-8 object-cover" :src="(previewURL) ? previewURL : scenario?.file?.file_path" />
        </div>
        <div class="flex i-mt-30">
          <button
              class="fs-14 fw-600 font-poppins i-pl-35 i-pr-35 rounded bg-secondary-one text-primary-three h-10 w-40 shadow-one"
              @click="updateScenario(() => previewURL = '')" :disabled="loading">Update
          </button>
          <button
              class="focus:outline-none fs-14 fw-600 font-poppins i-pl-35 i-pr-35 i-border-1 border-secondary-one ml-5 rounded text-primary-one h-10 w-40"
              @click="closeModal(() => previewURL = '')" :disabled="loading">Cancel
          </button>
        </div>
      </div>
    </div>
  </modal>
</template>

<script setup>
  import Switch from '@/elements/Switch.vue'
  import { categories } from '@/mock/category'
  import { useFile } from '@/composable/useFile.js'
  const { onChange, previewURL, file } = useFile()
</script>

<script>
import {mapGetters} from "vuex";
export default {
  name: "EditScenario",
  props: ['dModal', 'scenarioId'],
  data() {
    return {
      scenarioPlaceholder: {
        title: 'Your Scenario',
        category: 'Your Category'
      },
      scenario: null,
      formData: new FormData()
    }
  },
  computed: {
    ...mapGetters({
      loading: "GET_LOADING_STATE",
      scenarios: 'programmatic/scenarios',
    }),
  },
  watch: {
    dModal: function (val) {
      if (val) {
        this.$modal.show('ps-edit-modal');
      }
    }
  },
  methods: {
    showColorPanel() {
      this.$refs.colorPicker.click()
    },
    closeModal(callback = () => {}) {
      this.$store.dispatch("programmatic/loadScenarios", this.scenario.project_id);
      this.scenarioPlaceholder = {
        title: 'Your Scenario',
        category: 'Your Category'
      };
      this.$modal.hide('ps-edit-modal');
      this.$emit("hideEditModal");
      callback()
    },
    appends(data) {
        for(let key in data){
            this.formData.append(key, data[key])
        }
    },
    fileUpload(file){
        this.scenario.image = file
    },
    updateScenario(callback = () => {}) {
      const self = this;
      this.appends(this.scenario)

      self.$Progress.start();
      self.$store.state.loading = true;
      self.$store.dispatch('programmatic/updateScenario', this.formData).then(response => {
        self.$store.dispatch("project/getSolidObject", self.$route.params.id)

        self.$store.dispatch("programmatic/loadScenarios", self.$route.params.id);

        if (response) {
          callback()
          self.closeModal();
          self.$store.state.loading = false;
        } else {
          self.$store.state.loading = false;
          alert('Something went wrong!!!')
        }
        self.$Progress.finish();
      });
    },
    outsideTitleInput() {
      this.scenarioPlaceholder.title = 'Your Scenario';
    },
    outsideCategoryInput() {
      this.scenarioPlaceholder.category = 'Your Category';
    },
    setScenario(item) {
      this.scenario = item;
      if(!this.scenario.settings) this.scenario.settings.color = '#888'
    }
  }
}
</script>

<style scoped>

</style>
