<template>
    <RightNavComponentWrapper>
         <TopTitle
            title="Concept"
            icon-type="layout"
            :is-list="isList"
            :setLayoutType="setLayoutType"
        />
        <div class="grid  gap-5" :class="isList ? 'grid-cols-1' : 'grid-cols-2'">
            <div v-for="(concept, index) in getIterables(concepts)" :key="index">
                <img style="width: 360px; height: 187px" src="/images/concept.png" alt="" class="mt-4 contain">

                <Paragraph
                    :title="concept.title"
                    paragraph=" Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur et magna eu eros rutrum facilisis."
                    :is-list="isList"
                    class="mt-5 relative"
                />

                <TitleValue :items="getTitleValueItems(concept.hr_property_collection_id, concept.vr_property_collection_id, properties)" class="mt-4"/>

                <router-link
                    :to="{
                        name: 'teamwork.concepts',
                        params: {id: projectId, brainstorm_id: concept.brainstorm_id},
                        query: {concept_id: concept.id}
                    }"
                    class="text-primary-four fs-14 fw-500 mt-4 block"
                >
                    Edit Now
                </router-link>
            </div>
        </div>
        <hr class="mt-4">
        <div class="flex justify-center" v-if="concepts.length > 2">
            <ShowAll :showAll="showAll" :toggleSeeAll="toggleSeeAll" />
        </div>
    </RightNavComponentWrapper>
</template>

<script>
import sidebarMixin from '@/components/InstMenu/right-navigation-drawer/mixins.js'
import TopTitle from '@/components/InstMenu/right-navigation-drawer/components/TopTitle.vue'
import Paragraph from '@/components/InstMenu/right-navigation-drawer/components/Paragraph.vue'
import TitleValue from '@/components/InstMenu/right-navigation-drawer/components/TitleValue.vue'
import ShowAll from '@/components/InstMenu/right-navigation-drawer/components/ShowAll.vue'

export default {
    name : 'Concept',
    mixins: [sidebarMixin],
    components: {
        TopTitle,
        Paragraph,
        TitleValue,
        ShowAll
    },
    props: {
        concepts: {
            type: Array,
            required: true,
        },
        properties: Array
    },
    data: () => ({
        items: [
            {
                title: 'Horizontal Parameter:',
                value: 'SWOT Analysis',
            },{
                title: 'Vertical Parameter:',
                value: 'Future State',
            },
        ]
    }),
    computed: {
        projectId() {
            return this.$route.params.id
        }
    }

}
</script>
