import axios from 'axios';

function getDefaultState() {
    return {
        data: {},
    }
}

const getters = {
    data: state => state.data,
}

const actions = {
    fetchProfileData({commit, state}) {
        return axios.get(`profile`)
            .then(response => {
                commit('setProfile', response.data);
                return response;
            })
            .catch(error => {
                // TODO error handling
            });
    },
    updateProfileData({ commit, dispatch, state }, payload) {
        return axios.post(`profile`, payload)
          .then(response => {
            //   console.log(response);
            dispatch("fetchProfileData");
            return response;
          })
          .catch(e => {
              console.log(e);
          });
    },

    updateProfileImage({commit, dispatch}, payload) {

        return axios.post(`profile-image`, payload, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        })
            .then(response => {
                dispatch("fetchProfileData");
                return response.data;
            }).catch(e => {
                //
            });
    },
}

const mutations = {
    setProfile(state, value) {
        state.data = value.data
    },
}

export default {
    namespaced: true,
    state: getDefaultState,
    getters,
    actions,
    mutations
}
