function getDefaultState() {
    return {
        onlineUsers: [],
        inBrainstormPage: []
    }
}

const getters = {
    onlineUsers: state => state.onlineUsers,
    inBrainstormPage: state => state.inBrainstormPage,
}

const actions = {
    storeOnlineUsers({ commit, state }, payload) {
        commit("setOnlineUsers", payload);
    },
    landedInBrainstormPage({ commit, state }, payload) {
        commit("landedInBrainstormPage", payload);
    },
    leaveFromBrainstormPage({ commit, state }, userId) {
        commit("leaveFromBrainstormPage", userId);
    }
};

const mutations = {
    setOnlineUsers(state, value) {
        state.onlineUsers = value;
    },
    landedInBrainstormPage(state, value) {
        state.inBrainstormPage = value;
    },
    leaveFromBrainstormPage(state, value) {
        let userIndex = state.inBrainstormPage.findIndex(item => parseInt(item) === parseInt(value));
        if(userIndex >= 0) {
            state.inBrainstormPage.splice(userIndex, 1);
        }
    }
};

export default {
    namespaced: true,
    state: getDefaultState,
    getters,
    actions,
    mutations
}