import axios from 'axios';

function getDefaultState() {
    return {
        projectModule: {},
        moduleData: [],
        properties: [],
        savedProperties: []
    }
}

const getters = {
    projectModule: state => state.projectModule,
    moduleData: state => state.moduleData,
    properties: state => state.properties,
    savedProperties: state => state.savedProperties,
}

const actions = {
    loadModule({ commit, state }, moduleId) {
        return axios.get(`modules/${moduleId}`)
            .then(response => {
                commit("setProjectModule", response.data);
                return response;
            })
            .catch(e => {
                //
            })
    },
    loadModuleCollections({ commit, state }, moduleId) {
        axios
            .get(`modules/build/${moduleId}`)
            .then(response => {
                commit('setModuleData', response.data)
            })
            .catch(error => {
                // TODO error handling
            });
    },
    loadProperties({ commit, state }, module_id) {
        return axios.get(`properties/manage/${module_id}`)
            .then(response => {
                commit("setProperties", response.data);
                return response;
            })
            .catch(e => {
                //
            })
    },
    getPropertiesToModule({ commit, state }, module_id) {
        return axios.get(`properties/manage/${module_id}`)
            .then(response => {
                return response;
            })
            .catch(e => {
                //
            })
    },
    saveProperty({ commit,  dispatch, state }, payload) {
        return axios.post(`properties/manage`, payload)
            .then(response => {
                dispatch("loadProperties", payload.module_id);
                return response;
            })
            .catch(e => {
                //
            })
    },
    updateProperty({ commit,  dispatch, state }, payload) {
        return axios.post(`properties/manage/update-property`, payload)
            .then(response => {
                return response;
            })
            .catch(e => {
                //
            })
    },
    addPropertyCollection({ commit, state }, payload) {
        return axios.post(`properties/manage/add-collection`, payload)
            .then(response => {
                return response;
            })
            .catch((e) => {
                //
            })
    },
    updatePropertyCollectionItem({ commit, state }, payload) {
        return axios.post(`properties/manage/${payload.id}/collectionupdate`, payload)
            .then(response => {
                return response;
            })
            .catch(e => {
                //
            });
    },
    updatePropertyVisibility({ commit, state }, payload) {
        return axios.post(`properties/manage/update-visibility`, payload)
            .then(response => {
                return response;
            })
            .catch(e => {
                //
            })
    },
    getSavedProperties({ commit, state }) {
      return axios.get('properties/manage/saved/items')
          .then(response => {
              commit("setSavedProperties", response.data);
              return response;
          })
          .catch(e => {
              //
          });
    },
    saveAsNewProperty({ commit, state }, payload) {
        return axios.post(`properties/manage/save-as-new`, payload)
            .then(response => {
                return response;
            })
            .catch(e => {
                //
            });
    },
    updateTheSet({ commit, state }, payload) {
        return axios.post(`properties/manage/update-set`, payload)
            .then(response => {
                return response;
            })
            .catch(e => {
                //
            });
    },
    loadAProperty({ commit, state }, payload) {
        return axios.post(`properties/manage/load-a-property`, payload)
            .then(response => {
                return response;
            })
            .catch(e => {
                console.log(e);
            })
    },
    deleteProperty({ commit, dispatch, state }, payload) {
        return axios.delete(`properties/manage/${payload.id}`)
            .then(response => {
                dispatch("loadProperties", payload.module_id);
                return response;
            })
            .catch(e => {
                //
            });
    },
    deletePropertyCollection({ commit, dispatch, state }, payload) {
        return axios.delete(`properties/manage/collection/${payload.id}`)
            .then(response => {
                dispatch("loadProperties", payload.module_id);
                return response;
            })
            .catch(e => {
                //
            });
    },
};

const mutations = {
    setProjectModule(state, value) {
        state.projectModule = value.data;
    },
    setModuleData(state, value) {
        state.moduleData = value.data
    },
    setProperties(state, value) {
        state.properties = value.data
    },
    setSavedProperties(state, value) {
        state.savedProperties = value.data;
    }
};

export default {
    namespaced: true,
    state: getDefaultState,
    getters,
    actions,
    mutations
}
