<template>
  <div class="property-body fs-10 text-secondary-one">
    <div class="p-0 bg-transparent relative">
      <div class="flex justify-start items-center mb-3" v-for="(prop, pindex) in properties" :key="pindex"
           @mouseover="showValue(pindex)" @mouseleave="hideValue(pindex)">
        <p class="prop-items i-mr-15 fs-14 fw-600 text-secondary-one">{{ prop.title }}</p>
        <property-sample v-if="!prop.option || prop.option == 1"
                         :property="prop"
                         :index="index"
                         :p-index="pindex"
                         :item="activeScenarioCollection"
                         @updateParameterData="updateData"/>
        <input-property v-if="prop.option == 2"
                        :property="prop"
                        :index="index"
                        :p-index="pindex"
                        :rootMarginClasses="'ml-0'"
                        :item="activeScenarioCollection"
                        @updateParameterData="updateData" />
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import PropertySample from "@/components/properties/PropertySample";
import InputProperty from "@/components/properties/InputProperty";

export default {
  name: "PropertyParam",
  components: {InputProperty, PropertySample},
  props: ['selectedPropSet', 'activeScenarioCollection', 'properties', 'index'],
  data() {
    return {
      // active : this.properties[0]
    }
  },
  computed: {
    ...mapGetters({
      activeModule: "build/navigatorModule",
      activeBrainstormData: "brainstormv1/activeBrainstormData"
    })
  },
  methods: {
    showValue(pindex) {
      let value = document.getElementById('value-hover-' + this.activeScenarioCollection.id + '-' + pindex)
      value.style.display = 'block'
    },
    hideValue(pindex) {
      let value = document.getElementById('value-hover-' + this.activeScenarioCollection.id + '-' + pindex)
      value.style.display = 'none'
    },
    updateData(obj) {
      let itemParameter = this.activeData.properties.find(data => parseInt(data.property_collection_id) === parseInt(obj.property_collection_id));
      if(itemParameter) {
        itemParameter.sample_value = obj.sample_value;
      } else {
        this.activeData.properties.push({
          scenario_collection_id: this.activeScenarioCollection.id,
          sample_value: obj.sample_value,
          property_collection_id: obj.property_collection_id
        });
      }

      this.$store.dispatch("programmatic/updatePropertyItem", obj);
    },
  },
}
</script>

<style scoped>
.property-body {
  padding-top: 10px;
}

.prop-items {
  width: 185px;
}

.assoc-items {
  width: 250px;
}

.track-box {
  width: 140px;
}

.value-track, .progress-track, .range {
  height: 2px;
}

.range {
  outline: none;
  -webkit-appearance: none;
}

input[type='range']::-webkit-slider-thumb {
  width: 20px;
  -webkit-appearance: none;
  height: 10px !important;
  cursor: pointer;
  background: #00A7FE;
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.15);
  border-radius: 1px;
}
</style>
