import axios from 'axios';

const state = () => {
    return {
       // state area,
       data: '',
    }
}
// getters area
const getters = {

}
// mutations area
const mutations = {

}
// actions area
const actions = {
    // Fetch dashboard data
    async fetchWorkspace(context, workspaceId) {
        return await axios.get(`workspaces/dashboard/initial/${workspaceId}`).then(({data}) => data.data);
    },
    async updateProjectProgress(context, payload) {
        return await axios.post(`workspaces/dashboard/${payload.project_id}/update-progress`, payload).then(({data}) => data.data);
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
