<template>
    <svg class="inline" viewBox="0 0 1 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <line x1="0.5" y1="0.5" x2="0.499999" y2="12.5" stroke="currentColor"/>
    </svg>
</template>

<script>
export default {
    name: "BarIcon"
}
</script>
