import axios from 'axios';

function getDefaultState() {
    return {
        canvasStyle: 0,
        concepts: [],
        concept: {},
        conceptModule: null,
        canvasFirstModule: null,
        canvasSecondModule: null,
        canvasPropValue: {
            canvas_one_h: null,
            canvas_one_v: null,
            canvas_two_h: null,
            canvas_two_v: null
        },
        canvasTwoIsolation: [],
        definitions: [],
        definitionActiveIndex: {
            horizontal: 0,
            vertical: 0
        }
    }
}

const getters = {
    concepts: state => state.concepts,
    concept: state => state.concept,
    conceptModule: state => state.conceptModule,
    canvasStyle: state => state.canvasStyle,
    canvasFirstModule: state => state.canvasFirstModule,
    canvasSecondModule: state => state.canvasSecondModule,
    canvasPropValue: state => state.canvasPropValue,
    canvasTwoIsolation: state => state.canvasTwoIsolation,
    definitions: state => state.definitions,
    definitionActiveIndex: state => state.definitionActiveIndex
}

const actions = {
    getConcepts({ commit }, payload) {
        return axios.get(`concepts/${payload.project_id}`)
            .then(response => {
                commit("setConcepts", response.data);
                return response;
            }).catch(e => {
                //
            });
    },
    getScenario({ commit, state, dispatch }, projectId) {
        return axios.get(`projects/${projectId}?solid_object=true`)
            .then(({data})=>{
                return data.data.scenario
            })
            .catch(e => {
                //
            });
    },
    storeConcept({commit, dispatch, state }, payload) {
        return axios.post(`concepts`, payload)
            .then(response => {
                dispatch("getConcepts", { brainstorm_id: payload.brainstorm_id, project_id: payload.project_id });
                return response;
            }).catch(e => {
                //
            })
    },
    getConcept({ commit, dispatch, state }, id) {
        return axios.get(`concepts/show/${id}`)
            .then(response => {
                commit("setConcept", response.data);
                dispatch("loadParametersDefinition", {
                    parameters: [state.concept.hr_property_collection_id, state.concept.vr_property_collection_id],
                    vertical_parameter: state.concept.vr_property_collection_id
                });
                return response;
            }).catch(e => {
                //
            });
    },
    updateConcept({ commit, dispatch, state }, payload) {
        return axios.post(`concepts/update/${payload.id}`, payload)
            .then(response => {
                if(payload.stop) {
                    //
                } else {
                    dispatch("getConcept", payload.id)
                }
                return response;
            }).catch(e => {
                //
            });
    },
    setConceptModule({ commit, dispatch, state }, payload) {
        state.canvasFirstModule = payload;
    },
    setCanvasStyle({ commit, dispatch, state }, index) {
        state.canvasStyle = index;
    },
    setSideBySideModules({ commit, dispatch, state }, payload) {
        if(payload.index === 0) {
            state.canvasFirstModule = payload.module;
        } else {
            state.canvasSecondModule = payload.module;
        }
    },
    setSideLogicCanvasPropertyParams({ commit, dispatch, state }, payload) {
        if(payload.serial === 1) {
            if(payload.type === 'h') {
                state.canvasPropValue.canvas_one_h = payload.item.id;
            } else {
                state.canvasPropValue.canvas_one_v = payload.item.id;
            }
        } else {
            if(payload.type === 'h') {
                state.canvasPropValue.canvas_two_h = payload.item.id;
            } else {
                state.canvasPropValue.canvas_two_v = payload.item.id;
            }
        }
    },
    setCanvasTwoIsolation({ commit, dispatch, state }, payload) {
        state.canvasTwoIsolation.push(payload);
    },
    removeTheItemIsolation({ commit, dispatch, state }, moduleIndex) {
        state.canvasTwoIsolation.splice(moduleIndex, 1);
    },
    emptyIsolation({ commit, dispatch, state }) {
        state.canvasTwoIsolation = [];
    },
    loadParametersDefinition({ commit, dispatch, state, rootState }, payload) {
        return axios.post(`concepts/definitions`, payload)
        .then(response => {
            response.data.vertical_parameter = payload.vertical_parameter;
            response.data.definition_sets = rootState.definition.definitionSets;
            commit("setDefinitions", response.data);
            return response;
        }).catch(e => {
            //
        });
    }
};

const mutations = {
    setConcepts(state, value) {
        state.concepts = value.data;
    },
    setConcept(state, value) {
        state.concept = value.data;
    },
    setDefinitions(state, value) {
        let definitions = [];
        value.data.forEach(param => {
            // console.log(value.data, def)
            let definition = null;
            value.definition_sets.forEach(def => {
                // console.log(param.definition_id, '***')
                if(param.definition_id && param.definition_id === def.id) {
                    definition = def;
                    definition.parameter_id = param.id;
                }
            });

            definitions.push(definition);
        });

        state.definitionActiveIndex = {
            horizontal: 0,
            vertical: 0
        }
        state.definitions = definitions;
        let verticalDefinition = state.definitions.find(item => item.parameter_id === value.vertical_parameter);
        if(verticalDefinition) {
            verticalDefinition.items.forEach(data => {
               data.range_with_definition = data.range_with_definition.reverse();
            });
        }
    }
};

export default {
    namespaced: true,
    state: getDefaultState,
    getters,
    actions,
    mutations
}
