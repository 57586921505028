import axios from 'axios';

function getDefaultState() {
    return {

    }
}

const getters = {

}

const actions = {
    store({ commit, state }, payload) {
        return axios.post(`newsletter/store`, payload)
            .then(response => {
                return response;
            }).catch(e => {
                //
            });
    },
};

const mutations = {

};

export default {
    namespaced: true,
    state: getDefaultState,
    getters,
    actions,
    mutations
}
