<template>
    <label class="checkboxWrapper relative h-6">
        {{ label }}
        <input type="checkbox" :checked="value" @input="handle">
        <span class="checkmark relative"></span>
    </label>
</template>

<script setup>
    const props = defineProps({
        label: String,
        value: Boolean
    });
    const emits = defineEmits(['input', 'checked', 'click']);
    const handle = (e) => {
        emits('input', e.target.checked);
        emits('checked', e.target.checked);
        emits('click', e.target.checked);
    }
</script>

<style scoped>
.checkboxWrapper {
  display: block;
  position: relative;
  padding-left: 25px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: fit-content;
  display: flex;
  align-items: center;
  }

  .checkboxWrapper input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    position: absolute;
    top: 3px;
    left: 0;
    height: 16px;
    width: 16px;
    display: grid;
    place-content: center;
    border: 1px solid #8D8D8D;
    border-radius: 4px;
  }

  .checkboxWrapper:hover input ~ .checkmark {
    background-color: #fff;
  }

  .checkboxWrapper input:checked ~ .checkmark {
    background-color: #3D3D3D;
  }

  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  .checkboxWrapper input:checked ~ .checkmark:after {
    display: block;
  }

  .checkboxWrapper .checkmark:after {
    content: '';
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iNyIgdmlld0JveD0iMCAwIDEwIDciIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik04LjQzNzUgMS4xMjVMMy44NTQxNCA1LjVMMS41NjI1IDMuMzEyNSIgc3Ryb2tlPSJ3aGl0ZSIgc3Ryb2tlLXdpZHRoPSIxLjUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L3N2Zz4K");
    background-repeat: no-repeat;
    background-position: center;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 10px;
    height: 10px;
  }
</style>
