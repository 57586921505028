import axios from 'axios';

function getDefaultState() {
    return {
        programmatics: [],
        initialProgrammatics: [],
        programmatic: null, // Programmatic is widget
        workspaceLayouts: [],
        reload: false,
        widget: {},
        filterCollection: {},
    }
}

const parameterId = (state) => {
    let parameterId = null;
    if(state.widget.parameters?.length){
        parameterId = state.widget.parameters[0].id;
    }
    return parameterId;
}

const getters = {
    programmatics: state => state.programmatics,
    programmatic: state => state.programmatic,
    workspaceLayouts: state => state.workspaceLayouts,
    reload: state => state.reload,
    initialProgrammatics: state => state.initialProgrammatics,
    parameterId
}

const actions = {
    getProgrammatics({ commit, state }, payload) {
        return axios.get(`roadmaps/project/${payload.project_id}`)
            .then(response => {
                commit("setProgrammatics", response.data);
                return response;
            }).catch(e => {
                //
            });
    },
    getInitialProgrammatics({ commit, state }, payload) {
        return axios.get(`roadmaps/project/initial/${payload.project_id}`)
            .then(response => {
                commit("setInitialProgrammatics", response.data);
                return response;
            }).catch(e => {
                //
            });
    },
    getProgrammatic({ commit, state }, payload) {
        state.reload = false;
        return axios.get(`roadmaps/${payload.roadmap_id}`)
            .then(response => {
                commit("setProgrammatic", response.data);
                if(!payload.from_page) {
                    state.reload = true;
                }
                return response;
            }).catch(e => {
                //
            });
    },
    getFirstModuleProgrammatic({ commit, dispatch, state }, payload) {
        return axios.get(`roadmaps/initial/${payload.roadmap_id}`)
            .then(response => {
                commit("setProgrammatic", response.data);
                dispatch("getExceptInitialModuleProgrammatic", payload);
                return response;
            }).catch(e => {
                //
            });
    },
    getExceptInitialModuleProgrammatic({ commit, dispatch, state }, payload) {
        return axios.get(`roadmaps/rest-except-initial/${payload.roadmap_id}`)
            .then(response => {
                commit("setExceptInitialProgrammatic", response.data);
                return response;
            }).catch(e => {
                //
            });
    },
    storeProgrammatic({ commit, state, dispatch }, payload) {
        return axios.post(`roadmaps/store`, payload)
            .then(response => {
                // dispatch("getProgrammatic", payload);
                return response;
            }).catch(e => {
                //
            });
    },
    updateProgrammatic({ commit, state, dispatch }, payload) {
        return axios.post(`roadmaps/${payload.get('roadmap_id')}/update`, payload)
            .then(response => {
                dispatch("getProgrammatics", payload);
                return response;
            }).catch(e => {
                //
            });
    },
    deleteProgrammatic({ commit, state, dispatch }, payload) {
        return axios.delete(`roadmaps/${payload.id}`)
            .then(response => {
                dispatch("getProgrammatics", payload);
                return response;
            }).catch(e => {
                //
            });
    },
    duplicateProgrammatic({ commit, state, dispatch }, payload) {
        return axios.post(`roadmaps/duplicate/${payload.id}`, payload)
            .then(response => {
                dispatch("getProgrammatics", payload);
                return response;
            }).catch(e => {
                //
            });
    },
    storeLayout({ commit, dispatch, state }, payload) {
        return axios.post(`roadmaps/layout/store`, payload)
            .then(response => {
                return response;
            }).catch(e => {
                //
            });
    },
    updateLayout({ commit, dispatch, state }, payload) {
        return axios.post(`roadmaps/layout/${payload.id}/update`, payload)
            .then(response => {
                return response;
            }).catch(e => {
                //
            });
    },
    deleteLayout({ commit, dispatch, state }, payload) {
      return axios.delete(`roadmaps/layout/${payload.layout_id}`)
          .then(response => {
              dispatch("getProgrammatic", payload);
              return response;
          }).catch(e => {
              //
          });
    },
    duplicateLayout({ commit, dispatch, state }, payload) {
      return axios.post(`roadmaps/layout/duplicate/${payload.layout_id}`, payload)
          .then(response => {
              dispatch("getProgrammatic", payload);
              return response;
          }).catch(e => {
              //
          });
    },
    storeWidget({ commit, dispatch, state }, payload) {
        return axios.post(`roadmaps/widget/store`, payload)
            .then(response => {
                return response;
            }).catch(e => {
                //
            });
    },
    updateWidgetSettings({ commit, state, dispatch }, payload) {
        return axios.post(`roadmaps/widget/${payload.id}/update`, payload)
            .then(response => {
                return response;
            }).catch(e => {
                //
            });
    },
    deleteWidget({ commit, state, dispatch }, payload) {
        return axios.delete(`roadmaps/widget/${payload.id}`)
    },
    getAllLayoutsOfWorkspace({ commit, state, dispatch }, payload) {
        return axios.get(`roadmaps/customize/${payload.project_id}`)
            .then(response => {
                commit("setLayoutOfWorkspace", response.data);
                return response;
            }).catch(e => {
                //
            });
    },
    replaceLayout({ commit }, payload) {
        return axios.post(`roadmaps/customize/layout/${payload.layoutId}`, payload.data)
            .then(response => {
                commit("setLayoutOfWorkspace", response.data);
                return response;
            }).catch(e => {
                //
            });
    },
    orderWidget({ commit, state, dispatch }, payload) {
        // return payload
        return axios.post(`roadmaps/widget/reorder`, payload)
            .then(response => {
                //commit("setLayoutOfWorkspace", response.data);
                return response;
            }).catch(e => {
                //
            });
    },
    storeScenarioCollectionItem({ commit, dispatch, state }, payload) {
        return axios.post(`projects/scenarios/${payload.scenario_id}/store`, payload)
            .then(response => {
                return response;
            })
            .catch(e => {
                //
            });
    },
    loadModuleData({ commit, dispatch, state }, payload) {
        return axios.get(`roadmaps/scenarios/${payload.scenario_id}/modules/${payload.module_id}`)
            .then(response => {
                return response;
            })
            .catch(e => {
                //
            });
    },
    updatePropertyItem({ commit, dispatch, state }, payload) {
        return axios.post(`projects/scenarios/properties/updateCollectionItem`, payload)
            .then(response => {
                return response;
            })
            .catch(e => {
                //
            });
    },

    fetchAssociationByCollection({commit}, arrayOfCollectionId) {
        return axios.get(`associated/scenario-collections/${arrayOfCollectionId}`)
    },

    async getLayoutsByRoadmapId(_context, {roadmapId, moduleId}) {
        return await axios.get(`roadmaps/layouts/${roadmapId}`, {
            params: {
                module_id: moduleId
            }
        }).then(({ data }) => data.data);
    },
    async getWidgetsByLayoutId(_context, layoutId) {
        return await axios.get(`roadmaps/widget/roadmap-layout/${layoutId}`).then(({ data }) => data.data);
    }
};

const mutations = {
    setProgrammatics(state, value) {
        state.programmatics = value.data;
    },
    setInitialProgrammatics(state, value) {
        state.initialProgrammatics = value.data;
    },
    setProgrammatic(state, value) {
        state.programmatic = value.data;
        state.programmatic.modules.forEach(item => {
           if(item.layouts && item.layouts.length > 0) {
               item.layouts[0].expanded = true;
           }
        });
    },
    setExceptInitialProgrammatic(state, value) {
        if(value && value.data && value.data.modules.length > 0) {
            state.programmatic.modules = [...state.programmatic.modules, ...value.data.modules];
        }
    },
    setLayoutOfWorkspace(state, value) {
        state.workspaceLayouts = value.data;
    },
    // Internal dispatch
    setWidget(state, widget) {
        state.widget = widget
    }
};

export default {
    namespaced: true,
    state: getDefaultState,
    getters,
    actions,
    mutations
}
