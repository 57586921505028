<template>
    <RightNavComponentWrapper>
        <TopTitle
            title="Diagrammatic"
            icon-type="layout"
            :is-list="isList"
            :setLayoutType="setLayoutType"
        />
        <div class="grid  gap-5" :class="isList ? 'grid-cols-1' : 'grid-cols-2'">
            <div v-for="(item, index) in getIterables(brainstorms)" :key="index">
                <img style="width: 360px; height: 187px" src="/images/diagrammatic.png" alt="" class="mt-4 contain">

                <Paragraph
                    :title="item.title"
                    paragraph=" Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur et magna eu eros rutrum facilisis."
                    :is-list="isList"
                    class="mt-5 relative"
                />

                <TitleValue :items="getTitleValueItems(item.hr_property_collection_id, item.vr_property_collection_id, properties)" class="mt-4"/>

                <router-link
                    :to="{
                        name: 'teamwork.diagrammatic',
                        params: {id: projectId, brainstorm_id: item.brainstorm_id},
                        query: {diagrammatic_id: item.id}
                    }"
                    class="text-primary-four fs-14 fw-500 mt-4 block"
                >
                    View Now
                </router-link>
            </div>
        </div>
        <hr class="mt-4">
        <div class="flex justify-center" v-if="brainstorms.length > 2">
            <ShowAll :showAll="showAll" :toggleSeeAll="toggleSeeAll"/>
        </div>
    </RightNavComponentWrapper>
</template>

<script>
import sidebarMixin from '@/components/InstMenu/right-navigation-drawer/mixins.js'
import TopTitle from '@/components/InstMenu/right-navigation-drawer/components/TopTitle.vue'
import Paragraph from '@/components/InstMenu/right-navigation-drawer/components/Paragraph.vue'
import TitleValue from '@/components/InstMenu/right-navigation-drawer/components/TitleValue.vue'
import ShowAll from '@/components/InstMenu/right-navigation-drawer/components/ShowAll.vue'

export default {
    name : 'Diagrammatic',
    props: {
        brainstorms: Array,
        properties: Array,
        projectId: [Number, String],
    },
    mixins: [sidebarMixin],
    data: () => ({
        items: [
            {
                title: 'Parameter 1:',
                value: 'Current State Analysis',
            },{
                title: 'Parameter 2:',
                value: 'Future State',
            },
        ]
    }),
    components: {
        TopTitle,
        Paragraph,
        TitleValue,
        ShowAll
    },
}
</script>
