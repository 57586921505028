<template>
    <div>
        <div class="panzoom-exclude flex justify-between items-center">
            <div class="flex items-center cursor-pointer" @click="canEdit(project.cans)&&(localValue = !localValue)">
                <h4 :class="[textClasses]">{{ title }}</h4>
                <ChevronDownIcon class="h-4 w-4 text-secondary-two transition-all duration-200 ml-3" :class="[{'transform rotate-180': localValue}]" />
            </div>
            <div class="flex items-center mb-1">
                <slot name="action" v-if="canEdit(project.cans)"></slot>
                <!-- <img v-if="pIndex == widget.parameters.length - 1 " src="@/assets/images/icons/brainstormV2/plus_circle_outline.svg" alt="icon" class="settings-icon cursor-pointer" @click="newParameter" />
                <img v-if="pIndex != 0 || widget.parameters.length != 1 " src="@/assets/images/icons/brainstormV2/close.svg" alt="icon" class="settings-icon ml-2 cursor-pointer" @click="deleteParameter(pIndex)" /> -->
            </div>
        </div>
        <template v-if="localValue">
            <slot></slot>
        </template>
    </div>
</template>

<script setup>
  import usePermission from '@/composable/usePermission.js'
  const { canCreate, canEdit, canDelete, canRead } = usePermission()
</script>

<script>
    import {mapGetters,mapActions} from "vuex";
    export default {
        name: "SettingsAccordion",
        props: {
            title: {
                type: String,
                default: ''
            },
            value: {
                required: true
            },
            textClasses: {
              type: String,
              default: 'text-secondary-two fs-10 fw-500'
            }
        },
        computed: {
            ...mapGetters({
                project: "project/project"
            }),
            localValue: {
                get() {
                    return this.value
                },
                set(value) {
                 this.$emit('input', value);
                }
            }
        }
    }
</script>
