var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('div',{staticClass:"mb-6 relative"},[(_setup.previewURL)?_c('img',{staticClass:"block w-full _my_img_size",attrs:{"src":_setup.previewURL,"alt":"image"}}):_c('img',{staticClass:"block w-full _my_img_size",attrs:{"src":(_setup.scenario.file) ? _setup.scenario?.file?.file_path : '/images/build/demo_image_1.jpg',"alt":"image"}}),(_setup.canEdit(_setup.project.cans))?_c('label',{staticClass:"absolute right-1 bottom-1 property-image-icon cursor-pointer"},[_c('input',{attrs:{"hidden":"","type":"file","accept":"image/x-png,image/jpeg"},on:{"input":(e)=>{_setup.onChange(e), _setup.updateImage(
                    {file: _setup.file, id:_setup.scenario.id},
                    ()=>{
                        _setup.scenario.file = {file_path: _setup.previewURL}
                        _setup.previewURL = ''
                    }
                )}}}),_c('svg',{staticClass:"pointer-events-none",attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"18","height":"13","viewBox":"0 0 18 13","fill":"none"}},[_c('path',{attrs:{"d":"M0 12.3477H12V10.3477H0V12.3477ZM0 7.34766H18V5.34766H0V7.34766ZM0 2.34766H12V0.347656H0V2.34766Z","fill":"#E2E2E2"}})])]):_vm._e()]),(_setup.scenario)?_c('div',[_c('div',{staticClass:"flex items-center"},[_c('span',{staticClass:"text-primary-one ls-5 font-poppins fs-14 fw-600 mr-1"},[_vm._v(_vm._s(_setup.scenario.index_code))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_setup.scenario.title),expression:"scenario.title"}],staticClass:"focus:outline-none bg-transparent w-full placeholder-secondary-two cursor-pointer text-primary-one fw-600 ls-5 font-poppins fs-14 truncate",attrs:{"type":"text","placeholder":"No title available","disabled":!_setup.canEdit(_setup.project.cans)},domProps:{"value":(_setup.scenario.title)},on:{"keyup":function($event){return _setup.updateItem(_setup.scenario)},"input":function($event){if($event.target.composing)return;_vm.$set(_setup.scenario, "title", $event.target.value)}}})]),_c(_setup.TextareaAutoHeight,{attrs:{"is-disabled":!_setup.canEdit(_setup.project.cans)},on:{"onType":function($event){return _setup.updateItem(_setup.scenario)}},model:{value:(_setup.scenario.description),callback:function ($$v) {_vm.$set(_setup.scenario, "description", $$v)},expression:"scenario.description"}})],1):_vm._e(),_vm._l((_setup.tagList),function(item){return _c(_setup.Accordion,{key:item.id,attrs:{"title":item.title}},_vm._l((item.tags),function(tag){return _c('div',{key:tag.id},[_c('div',{staticClass:"border border-white fs-14 font-light rounded p-3 mb-3 h-12 truncate",style:({backgroundColor: item.settings.bg_color}),attrs:{"title":tag.title}},[_vm._v(" "+_vm._s(tag.title)+" ")])])}),0)})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }