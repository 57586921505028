<template>
    <RightNavComponentWrapper>
        <TopTitle
            title="Association"
            icon-type="layout"
            :is-list="isList"
            :setLayoutType="setLayoutType"
        />
        <div class="grid  gap-5 mt-5" :class="isList ? 'grid-cols-1' : 'grid-cols-2'">
            <div
                v-for="(item, index) in getAssociations"
                :key="index"
                :style="{'background-image': 'url(' + getAssociationImage(item) + ')'}"
                class="association-wrapper i-pt-18 association-bg">
                <div class="flex items-center justify-between relative">
                    <div @click="removeAssociation(item)"><XCircle class="i-icon association-blue-close-btn" /></div>
                    <button class="association-close-btn fs-10 fw-400 i-pl-10 i-pr-10 i-pt-5 i-pb-5 rounded-sm">
                      {{ getAssociationTag(item) }}
                    </button>
                </div>

                <Paragraph
                    title-text-color="text-white"
                    paragraph-text-color="text-white"
                    :title="getAssociationTitle(item)"
                    :paragraph="getAssociationDescription(item)"
                    :is-list="isList"
                    class="mt-10 relative"
                />
            </div>
        </div>

        <button v-if="associations && associations.length > 2" class="text-primary-four fs-14 fw-500 mt-4 flex justify-center w-full" @click="displayAll = !displayAll">See {{ !displayAll ? 'All' : 'Less' }}</button>


    </RightNavComponentWrapper>
</template>

<script>
import TopTitle from '@/components/InstMenu/right-navigation-drawer/components/TopTitle.vue'
import Paragraph from '@/components/InstMenu/right-navigation-drawer/components/Paragraph.vue'
import sidebarMixin from '@/components/InstMenu/right-navigation-drawer/mixins.js'
import {mapGetters} from "vuex";

export default {
    name : 'Associations',
    mixins: [sidebarMixin],
    components: {
        TopTitle,
        Paragraph
    },
    props: {
      associations: {
        type: Array
      },
      activePageData: {
        type: Object
      }
    },
    data() {
      return {
        image: '/images/association-bg-dark.svg',
        displayAll: false
      }
    },
    computed: {
        ...mapGetters({
          colorCodes: "global/colorCodes",
        }),
        getAssociations() {
          if(this.associations && this.associations.length > 0) {
            return this.displayAll ? this.associations : this.associations.slice(0, 2);
          }
          return [];
        }
    },
    methods: {
      getAssociationTitle(item) {
        return item && item.associated && item.associated.title ? item.associated.title : 'Not found';
      },
      getAssociationDescription(item) {
        return item && item.associated && item.associated.description ? item.associated.description : '';
      },
      getAssociationImage(item) {
        return item && item.associated && item.associated.file && item.associated.file.file_path ? item.associated.file.file_path :this.image;
      },
      removeAssociation(item) {
        if(item && item.associated && item.associated.id) {
          let itemIndex = this.associations.findIndex(data => data.id === item.id);
          this.associations.splice(itemIndex, 1);

          this.$store.dispatch("programmatic/addOrRemovesAssociation", {
            associated_id: item.associated.id,
            scenario_collection_id: item.scenario_collection_id,
            project_id: this.activePageData.project_id,
            scenario_id: this.activePageData.scenario_id
          });
        }
      },
      getAssociationTag(item) {
        return item && item.associated && item.associated.tag ? item.associated.tag : 'N/A';
      },
    }
}
</script>

<style >
.association-blue-close-btn {
    @apply  w-5 h-5 text-primary-four opacity-75 cursor-pointer transition duration-100;
}
.association-wrapper {
    @apply px-5 pb-5 relative rounded bg-no-repeat bg-cover;
}
.association-close-btn {
    @apply bg-primary-four text-white uppercase;
}

.association-bg::before {
    content: "";
    border-radius: 5px;
    position: absolute;
    top: 0; left: 0;
    width: 100%; height: 100%;
    background: black;
    opacity: .2;
    background-size: cover;
    background-position: cover;
}
</style>
