<template>
    <RightNavComponentWrapper>
        <TopTitle
            title="current roadmap"
            icon-type="collapseHandler"
            :bold-text="getCurrentPageTitle"
            @onClickClose="toggleRightBar()"
            @onClickExpandIcon="toggleExpansionRightBar()"
        />

        <p class="mt-4 italic fs-14 fw-300 text-secondary-one">You are viewing the information about</p>
        <p class="fs-14 fw-700 text-secondary-one">{{ getContentTitle }}</p>

        <p class="text-primary-one fw-500 fs-13 mt-4">Review Scenario</p>
        <p class="text-primary-one fw-400 fs-10">You have {{ getScenarioCount }} Scenario in project</p>
        <div class="flex items-center i-mt-2">
            <Scenario class="w-5 h-5 text-primary-four" />
            <router-link :to="{ name: 'programmatic.scenario.view', params: { id: project.id, scenario_id: activePageData.scenario_id } }" v-if="activePageData && project && project.id">
              <p class="text-primary-four fw-700 fs-12 i-mt-2"> Go to Current Scenario .</p>
            </router-link>
        </div>

        <hr class="mt-6 mb-2">

        <!-- circular menu -->
        <div class="relative">
            <!-- <RoundAlinedText
              class="absolute"
              :module-name="currentHoveredModule" 
            /> -->
            <CircularMenu 
              @onHover="onHover" 
              :route-name="$route.name"
            />
        </div>
        <hr>
        <!-- circular menu -->
        <Navigator  class="mt-4"/>
    </RightNavComponentWrapper>
</template>
<script>
import {  mapMutations } from 'vuex'
import imports from '@/components/InstMenu/right-navigation-drawer/module/risk-management/import.js'
import menus from '@/components/InstMenu/right-navigation-drawer/components/circulra.menu.js'

export default {
    ...imports,
    name : 'RiskManagement',
    props: {
      activeData: {
        type: Object
      },
      activePageData: {
        type: Object
      },
      project: {
        type: Object
      }
    },
    data: () => ({
        menus,
        selectedModule:'roadmap',
        currentHoveredModule: 'roadmap'
    }),
    computed:  {
      getCurrentPageTitle() {
        return this.activePageData && this.activePageData.title ? this.activePageData.title : 'Not found';
      },
      getContentTitle() {
        return this.activeData && this.activeData.title ? `${this.activeData.index_code} ${this.activeData.title}` : 'Not found';
      },
      getScenarioCount() {
        return this.project && this.project.scenarios ? this.project.scenarios.length : 0;
      },
      displayMenuItem() {
        return this.menus.find(item => item.name == this.currentHoveredModule)
      }
    },
    methods: {
        ...mapMutations({
            toggleRightBar: 'toggleRightBar',
            toggleExpansionRightBar: 'toggleExpansionRightBar'
        }),
        onHover(data) {
            if(data) {
                this.currentHoveredModule = data
            }else {
                this.currentHoveredModule=this.selectedModule
            }
        }
    }
}
</script>

<style>
.module-text-position {
    top: 80px;
    left: 50%;
    transform: translateX(-50%);
}
</style>
