<template>
  <div class="wrapper overflow-y-auto" :style="{'--bg':backgroundImage}">
      <div class="homePageContainer relative h-full w-full overflow-y-auto">
        <div class="content_box">
          <div class="logo">
            <svg width="571" height="72" viewBox="0 0 571 72" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0 69.9968V1.17366H13.9176V69.9968H0Z" fill="white"/>
              <path d="M32.1798 69.9968V1.17366H45.7213L73.9327 47.134V1.17366H86.8629V69.9968H72.8983L45.11 25.1162V69.9968H32.1798Z" fill="white"/>
              <path d="M102.915 47.6035L116.457 46.289C117.272 50.8271 118.917 54.1603 121.394 56.2885C123.901 58.4168 127.271 59.4809 131.503 59.4809C135.985 59.4809 139.355 58.542 141.612 56.6641C143.9 54.755 145.044 52.5328 145.044 49.9977C145.044 48.3703 144.558 46.9932 143.587 45.8665C142.646 44.7085 140.985 43.7069 138.603 42.8619C136.973 42.2986 133.258 41.297 127.459 39.8574C119.999 38.0108 114.764 35.7417 111.755 33.0501C107.523 29.2632 105.407 24.6468 105.407 19.201C105.407 15.6957 106.395 12.4251 108.369 9.38925C110.376 6.32209 113.244 3.99043 116.974 2.39426C120.735 0.798086 125.265 0 130.562 0C139.214 0 145.718 1.8935 150.075 5.68049C154.464 9.46749 156.768 14.522 156.987 20.8441L143.069 21.4544C142.474 17.9178 141.189 15.3827 139.214 13.8491C137.27 12.2843 134.34 11.5018 130.421 11.5018C126.378 11.5018 123.212 12.3312 120.924 13.99C119.45 15.0541 118.714 16.4781 118.714 18.2621C118.714 19.8896 119.403 21.2823 120.782 22.4403C122.538 23.9113 126.801 25.4449 133.572 27.041C140.342 28.6372 145.342 30.296 148.571 32.0173C151.831 33.7074 154.37 36.0391 156.188 39.0123C158.037 41.9543 158.962 45.6004 158.962 49.9508C158.962 53.8943 157.865 57.5874 155.671 61.0301C153.476 64.4728 150.373 67.0392 146.361 68.7293C142.349 70.3881 137.349 71.2174 131.362 71.2174C122.648 71.2174 115.955 69.2144 111.285 65.2083C106.614 61.1709 103.824 55.3027 102.915 47.6035Z" fill="white"/>
              <path d="M191.001 69.9968V12.8163H170.547V1.17366H225.325V12.8163H204.918V69.9968H191.001Z" fill="white"/>
              <path d="M239.214 69.9968V1.17366H268.507C275.873 1.17366 281.218 1.79961 284.54 3.05151C287.894 4.27211 290.574 6.46293 292.581 9.62398C294.587 12.785 295.59 16.3999 295.59 20.4686C295.59 25.6326 294.069 29.9048 291.029 33.2849C287.988 36.6337 283.443 38.7463 277.393 39.6226C280.403 41.3753 282.879 43.3001 284.822 45.397C286.797 47.4939 289.446 51.2183 292.769 56.5702L301.185 69.9968H284.54L274.478 55.021C270.905 49.6691 268.46 46.3046 267.143 44.9275C265.827 43.5192 264.432 42.5646 262.959 42.0638C261.485 41.5318 259.15 41.2657 255.953 41.2657H253.132V69.9968H239.214ZM253.132 30.2803H263.429C270.105 30.2803 274.274 29.9986 275.936 29.4353C277.597 28.8719 278.898 27.9017 279.838 26.5246C280.779 25.1475 281.249 23.4262 281.249 21.3605C281.249 19.0445 280.622 17.1823 279.368 15.7739C278.146 14.3343 276.406 13.4266 274.149 13.0511C273.021 12.8946 269.635 12.8163 263.993 12.8163H253.132V30.2803Z" fill="white"/>
              <path d="M375.729 69.9968H360.589L354.57 54.3637H327.017L321.328 69.9968H306.564L333.412 1.17366H348.129L375.729 69.9968ZM350.104 42.768L340.606 17.2293L331.296 42.768H350.104Z" fill="white"/>
              <path d="M396.342 69.9968V12.8163H375.889V1.17366H430.666V12.8163H410.26V69.9968H396.342Z" fill="white"/>
              <rect x="471.844" y="29.9204" width="14.043" height="39.9618" fill="white"/>
              <rect x="499.93" y="7.94141" width="14.043" height="61.9408" fill="white"/>
              <rect x="556.102" y="29.9204" width="14.043" height="39.9618" fill="white"/>
              <rect x="528.016" y="43.9072" width="14.043" height="25.9752" fill="white"/>
            </svg>
          </div>

          <p class="fs-48 fw-400 text-white mt-10 lowercase description">
            a THOUGHT LEADERSHIP ecosystem for WORKFORCE ACTIVATION, CO-CREATION AND VISUAL COMUNICATION
          </p>
          <div class="loginLink relative fs-36 fw-500 text-white mt-10 capitalize">
                <router-link :to="{ name:'login' }">Join our revolution</router-link>
          </div>
        </div>
      </div>
  </div>
</template>
<script>
  export default {
    created() {
      document.title = 'Home'
    },
    data() {
      return {
        backgroundImage: `url(${require('@/assets/images/homepageBg.webp')})`,
        user: {},
        submitting: false
      }
    },
    methods: {
      submit(e) {
        e.preventDefault();
        const self = this;
        this.submitting = true;
        this.$Progress.start();
        this.$store.dispatch("newsletter/store", this.user)
            .then(response => {
              setTimeout(function () {
                self.submitting = false;
              }, 2000);
              this.$Progress.finish();
              this.user = {};
            })
      }
    }
  }
</script>

<style scoped>
    .wrapper{
      height: 100vh;
      background-image: linear-gradient(rgb(65 161 212 / 95%), rgb(43 128 173 / 89%)), var(--bg);;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }
    .homePageContainer{
      display: grid;
      place-content: center;
      padding: 0 10%;
    }
    .homePageContainer .content_box{
      width: 100%;
      max-width: 1160px;
    }
    .homePageContainer .description{
      line-height: 72px;
    }

    .loginLink{
      width: fit-content;
    }
    .loginLink::before{
      content: '';
      position: absolute;
      top: calc(100% + 10px);
      left: 50%;
      transform: translateX(-50%);
      border-bottom: 3px solid #fff;
      width: 235px;
    }

    @media all and (max-width: 1500px){
      .homePageContainer .description{
        font-size: 32px;
        line-height: 48px;
      }
      .loginLink{
        font-size: 30px;
      }
    }

    @media all and (max-width: 767px){
      .homePageContainer .description{
        font-size: 18px;
        line-height: 27px;
        margin-top: 10px;
      }
      .loginLink{
        margin-top: 10px;
        font-size: 20px;
      }
      .loginLink::before{
        width: 126px;
      }
      .logo svg{
        width: 300px;
      }
    }

</style>