<template>
    <div class="transition-all mb-2">
        <div class="pt-6 flex justify-between items-center cursor-pointer" @click="onVisible">
            <p class="flex-auto fs-14 font-medium font-poppins pr-5"> {{ title }} </p>
            <button class="">
                <svg :class="[{'transform rotate-180': visibility}]" class="transition-all duration-200 h-4 w-4" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.99983 10.4754L12.0065 6.46872L11.0645 5.52539L7.99983 8.59206L4.93583 5.52539L3.99316 6.46806L7.99983 10.4754Z" fill="#000"/>
                </svg>
            </button>
        </div>
        <div class="pt-4 pb-9 border-b border-primary-one" v-show="visibility === true">
            <slot></slot>
        </div>
    </div>
</template>
<script>

export default {
    props: {
        title: String,
        active: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return{
            visibility: this.active
        }
    },
    methods: {
        onVisible() {
            this.visibility = !this.visibility;
        }
    }
}
</script>
