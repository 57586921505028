import axios from 'axios';
import { getFlattenCollections } from '@/store/modules/global/services';

function getDefaultState() {
    return {
        brainstorms: [],
        brainstorm: {},
        activeBrainstormData: {},
        collection_settings: {},
        activeLevelId: null
    }
}

const getters = {
    getActiveLevelId: state => state.activeLevelId,
    brainstorms: state => state.brainstorms,
    activeBrainstormData: state => state.activeBrainstormData,
    brainstorm: state => state.brainstorm,
    collection_settings: state => state.collection_settings,
}

const actions = {
    getBrainstorms({ commit }, payload) {
        return axios.get(`brainstorms/${payload.project_id}/project`)
            .then(response => {
                commit("setBrainstorms", response.data);
                return response;
            }).catch(e => {
                //
            });
    },
    storeBrainstorm({commit, dispatch, state }, payload) {
        return axios.post(`brainstorms`, payload)
            .then(response => {
                dispatch("getBrainstorms", { project_id: payload.project_id });
                return response;
            }).catch(e => {
                // console.log(e);
            })
    },
    getBrainstorm({ commit, dispatch, state }, id) {
      return axios.get(`brainstorms/${id}`)
          .then(response => {
              commit("setBrainstorm", response.data);
          }).catch(e => {
              //
          });
    },
    updateBrainstorm({ commit, dispatch, state }, payload) {
      return axios.post(`brainstorms/${payload.get('id')}/update`, payload)
          .then(response => {
              return response;
          }).catch(e => {
              // console.log(e);
          });
    },
    getActiveBrainstormData({commit, dispatch, state }, payload) {
        return axios.get(`brainstorms/${payload.brainstorm_id}`)
            .then(response => {
                commit("setActiveBrainstormData", response.data);
                return response;
            }).catch(e => {
                // console.log(e);
            })
    },
    deleteBrainstorm({ commit, dispatch, state }, payload) {
        return axios.delete(`brainstorms/${payload.id}`)
            .then(response => {
                commit("deleteBrainstorm", payload.index);
                return response;
            }).catch(e => {
                //
            })
    },
    duplicateBrainstorm({ commit, dispatch, state }, payload) {
        return axios.post(`brainstorms/duplicate/${payload.id}`, payload)
            .then(response => {
                dispatch("getBrainstorms", payload);
            }).catch(e => {
                console.log(e);
            });
    },
    storeItem({ commit, dispatch, state }, payload) {
        return axios.post(`brainstorms/collections`, payload)
            .then(response => {
                dispatch("getActiveBrainstormData", payload);
            }).catch(e => {
                console.log(e);
            })
    },
    regularUpdate({ commit, dispatch, state }, payload) {
        return axios.post(`brainstorms/collections/${payload.id}/update`, payload)
            .then(response => {
                dispatch("getActiveBrainstormData", payload);
            });
    },
    buildParentUpdate({ commit, dispatch, state }, payload) {
        return axios.post(`modules/collections/${payload.id}/parent-update`, payload)
            .then(response => {
                dispatch("getActiveBrainstormData", payload);
            });
    },
    scenarioParentUpdate({ commit, dispatch, state }, payload) {
        return axios.post(`projects/scenarios/${payload.id}/parent-update`, payload)
            .then(response => {
                dispatch("getActiveBrainstormData", payload);
            });
    },
    deleteItem({ commit, dispatch, state }, payload) {
        return axios.delete(`brainstorms/collections/${payload.id}`)
            .then(response => {
                dispatch("getActiveBrainstormData", payload);
                return response;
            }).catch(e => {
                //
            });
    },
    updatePropertyItemFromBuild({ commit, state, dispatch }, payload) {
        return axios.post(`properties/updateFromBuild`, payload)
            .then(response => {
                return response;
            })
            .catch(e => {
                //
            });
    },
    updatePropertyItemFromScenario({ commit, state, dispatch }, payload) {
        return axios.post(`properties/updateFromScenario`, payload)
            .then(response => {
                return response;
            })
            .catch(e => {
                //
            });
    },
    storeSketchItem({ commit, dispatch, state }, payload) {
        return axios.post(`brainstorms/sketches`, payload)
            .then(response => {
                dispatch("getActiveBrainstormData", payload);
            }).catch(e => {
                console.log(e);
            })
    },
    // Brainstorm sharing api
    async invitation(_context, payload) {
        return await axios.post(`brainstorms/invitations/share-or-people`, payload).then(({data}) => data.data)
    },
    async getInvitations(_context, brainstormId) {
        return await axios.get(`brainstorms/invitations/${brainstormId}`).then(({data}) => data.data)
    },
    async deleteInvitation(_context, invitationId) {
        return await axios.delete(`brainstorms/invitations/${invitationId}`).then(({data}) => data.data)
    },

};

const mutations = {
    setActiveLevelId: (state, id) => state.activeLevelId = id,
    setBrainstorms(state, value) {
        state.brainstorms = value.data;
    },
    setActiveBrainstormData(state, value) {
        state.activeBrainstormData = value.data;
        if(state.activeBrainstormData.module_id && state.activeBrainstormData.module) {
            let collectionSettings = JSON.parse(state.activeBrainstormData.module.collection_settings);
            state.collection_settings = collectionSettings;
        }
    },
    setBrainstorm(state, value) {
        state.brainstorm = value.data;
    },
    deleteBrainstorm(state, index) {
        state.brainstorms.splice(index, 1);
    },

    updateLevelData(state, data) {
        let brainstormData = state.activeBrainstormData;
        let collections
        if (brainstormData.collections) {
            collections =  brainstormData.collections;
        }

        if (brainstormData.modules && brainstormData.modules.length && data.isolatedModuleId) {
            collections =  brainstormData.modules.find(
              (module) => module.id == data.isolatedModuleId
            ).collections;
        }
        const flattenCollections = getFlattenCollections(collections);
        const level = flattenCollections.find(el => el.id == data.item.id)

        level.title = data.item.title
        level.description = data.item.description
    }
};

export default {
    namespaced: true,
    state: getDefaultState,
    getters,
    actions,
    mutations
}
