<template>
        <div
            :class="[isExpanded ? 'i-w-840 grid-cols-2' : 'i-w-420 grid-cols-1']"
            class="bg-white absolute top-0 right-0 z-50 grid i-pr-5 i-pl-5 i-pt-5 i-pb-5 gap-y-3 r-navigator overflow-y-scroll"
        >
            <RiskManagement
                :active-data="activeData"
                :active-page-data="activePageData"
                :project="project"
            />
            <Insights
                :insights="insights"
                :active-scenario-collection-id="activeScenarioCollectionId"
            />
            <Concept
                :concepts="concepts"
                :properties="properties"
            />
            <Comments
                :class="[isExpanded ? 'col-start-1 row-start-3' : '']"
                :comments="comments"
                :active-id="activeScenarioCollectionId"
            />
            <Diagrammatic :brainstorms="brainstorms" :properties="properties" :projectId="projectId"/>
            <BasicInformation
                :class="[isExpanded ? 'row-start-1 col-start-2' : '']"
                :project="project"
                :active-page-data="activePageData"
            />
            <Metrics
                :class="[isExpanded ? 'row-start-2 col-start-2' : '']"
                :active-data="activeData"
                :active-page-data="activePageData"
                :properties="properties"
            />
            <Associations
                :class="[isExpanded ? 'row-start-3' : '']"
                :associations="getAssociation"
                :active-page-data="activePageData"
            />
            <Feedback
                :class="[isExpanded ? 'row-start-4 col-start-2' : '']"
                :ideas="assessmentIdeas"
            />
        </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import imports from './imports'

export default {
    ...imports,

    name :'RightNavigationDrawer',

    computed: {
        ...mapGetters({
            project: 'drawer/getProject',
            concepts: 'drawer/getConcepts',
            comments: 'drawer/getComments',
            scenarios: 'drawer/getScenarios',
            insights: 'drawer/getInsights',
            isExpanded: 'getIsRightBarExpanded',
            brainstorms: 'drawer/getBrainstorms',
            properties: "build/modulePropertySet",
            activeRoadmapData: "roadmap/programmatic",
            assessmentIdeas: 'drawer/getAssessmentInsights',
            assessmentsInsights: 'drawer/assessmentsInsights',
            assessmentsComments: 'drawer/getAssessmentComments',
            // associations: 'drawer/getAssociations',
            activeScenarioCollection: "programmatic/activeScenarioCollection",
            activeScenarioCollectionId: 'programmatic/activeScenarioCollectionId',
            associationsByScenarioCollection: 'drawer/associationsByScenarioCollection',
        }),
        projectId() {
            return this.$route.params.id
        },
        activeData() {
          return this.activeScenarioCollection;
        },
        activePageData() {
          return this.activeRoadmapData;
        },
        getAssociation() {
          return this.associationsByScenarioCollection;
        }
    },

    watch: {
        activeScenarioCollectionId: {
            handler: function (val) {
                if(val) {
                    this.fetchAssessmentsComments(val)
                    this.fetchComments({ id: val, key: 'scenario_collection_id' })
                    this.fetchAssessmentsInsights(val)
                    this.fetchAssociationsByScenarioCollection(val)
                    this.fetchInsights(val)
                  // this.fetchAssociations(val)
                }
            },
            immediate: true
        },
        projectId: {
            handler: function (val) {
                this.fetchConcepts(val)
                this.fetchBrainstorms(val)
                this.fetchProject(val)
                // this.fetchScenarios(val)
            },
            immediate: true
        },
    },

    methods: {
        ...mapActions({
            fetchProject: 'drawer/fetchProject',
            fetchInsights: 'drawer/fetchInsights',
            fetchComments: 'drawer/fetchComments',
            fetchConcepts: 'drawer/fetchConcepts',
            fetchScenarios: 'drawer/fetchScenarios',
            fetchBrainstorms: 'drawer/fetchBrainstorms',
            fetchAssociations: 'drawer/fetchAssociations',
            fetchAssessmentsComments: 'drawer/fetchAssessmentsComments',
            fetchAssessmentsInsights: 'drawer/fetchAssessmentsInsights',
            fetchAssociationsByScenarioCollection: 'drawer/fetchAssociationsByScenarioCollection',
        })
    }
}
</script>

<style scoped>
.r-navigator {
  height: 1080px;
  z-index: 999999;
}
</style>
