export default [
    {
        name: "scenarios",
        action: 'manage',
        body: "Remember you can copy a scenario to save a particular time or a configuration without deleting content dna in build",
        footer: "Do it now..."
    },
    {
        name: "teamwork",
        action: null,
        body: "Manage scenarios content, create concepts and view results in the collaboration zone with your team in realtime",
        footer: "Collaborate and author it now..."
    },
    {
        name: "assessment",
        action: "Recurring",
        body: "Create a recurring assessment of a module within a scenario and get feedback that curates your content.",
        footer: "Create one or view feedback..."
    },
    {
        name: "intelligence",
        action: null,
        body: "Configure dashboard setup and monitor your project performance",
        footer: "get insight and recommendations here"
    },
    {
        name: "communication",
        action: "Visual",
        body: "Configure dashboard setup and monitor your project performance",
        footer: "get insight and recommendations here"
    },
    {
        name: "roadmap",
        action: "Programmatic",
        body: "Build any business logic people, metrics, timelines etc. Remember you can reuse your layouts and use them in communications.",
        footer: "See more...",
    },
]