var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"flex items-start justify-start",class:{
        'idnt-fullscreen-tiles-height': _vm.topSelectedButton === 'full_screen',
        'bld-minimize-bar': !_vm.showGroup
    },on:{"click":function($event){return _vm.unselect($event)}}},[_c('div',{staticClass:"flex flex-col justify-between w-10 cursor-pointer bld-shadow-height",class:{
      'input-area-max-scrollable-height bld-full-shadow-height':
      _vm.topSelectedButton === 'full_screen',
    }},[_c('div',{staticClass:"relative flex flex-col items-center shadow-one rounded bg-primary-three mb-1 bld-vertical-tile",class:{'bld-full-vertical-tile': _vm.topSelectedButton === 'full_screen'}},[_c('img',{staticClass:"cursor-pointer icon-24 opacity-40 i-mt-15 i-icon",class:{'opacity-100': _vm.expansionSelf === true || _vm.expansionGlobal === true},attrs:{"src":"/images/icons/build/expand.svg","alt":"expand"},on:{"click":_vm.toggleExpansion}}),_c('div',{staticClass:"flex flex-col items-center i-mt-15",on:{"click":_vm.changeLevel}},[(_vm.levelSelf === 1)?_c('img',{staticClass:"icon-24 i-icon",attrs:{"src":"/images/icons/build/level_1.svg","alt":""}}):(_vm.levelSelf === 2)?_c('img',{staticClass:"icon-24 i-icon",attrs:{"src":"/images/icons/build/level_2.svg","alt":""}}):(_vm.levelSelf === 3)?_c('img',{staticClass:"icon-24 i-icon",attrs:{"src":"/images/icons/build/level_3.svg","alt":""}}):_vm._e()]),_c('div',{staticClass:"bld-l1-bar w-full",class:{'bld-full-l1-bar': _vm.topSelectedButton === 'full_screen'},staticStyle:{"overflow":"hidden"},on:{"click":function($event){_vm.$emit('click');
          _vm.changeTransition('null');
          _vm.groupToggle();}}},[_c('h3',{staticClass:"transform absolute -rotate-90 font-poppins fs-14 justify-end text-secondary-one ellipse",class:[_vm.topSelectedButton === 'full_screen' ? 'bld-item-vertical-text-long' : 'bld-item-vertical-text-short'],attrs:{"title":_vm.identifier.title}},[_vm._v(" "+_vm._s(_vm.getSymbolCode + _vm.numberedTitle + ' ' + _vm.identifier.title)+" ")])])])]),_c('transition',{attrs:{"name":_vm.areaTransitionName,"mode":"out-in"}},[_c('div',{staticClass:"flex flex-col input-area",class:{
          'input-area-max-scrollable-height':
          _vm.topSelectedButton === 'full_screen',
      }},[_c('div',{staticClass:"open-menu",class:{
            'hor-tiles-max':
              (_vm.expansionSelf === true &&
                  _vm.selectedActive[1] == 'identifier') ||
              _vm.expansionGlobal == true,
            'i-h-50':
              _vm.expansionSelf === false && _vm.expansionGlobal == false,
          }},[_c('identifier-input-bar',{ref:"parentInputBar",staticClass:"identifier-input",class:{
              'i-ml-30': _vm.showGroup,
              'ml-0': !_vm.showGroup,
              'hor-tiles-max':
              (_vm.expansionSelf === true &&
                  _vm.selectedActive[1] == 'identifier') ||
              _vm.expansionGlobal == true,
            },style:({
              width: _vm.showGroup ? '275px' : '0px',
              opacity: _vm.showGroup ? 1 : 0,
            }),attrs:{"expansionSelf":_vm.expansionSelf,"expansionGlobal":_vm.expansionGlobal,"identification":_vm.identification,"identifier":_vm.identifier,"index":_vm.index,"codeTitle":_vm.getSymbolCode,"numberedTitle":_vm.numberedTitle,"selected_item":_vm.selectedActive,"data-id":_vm.identifier.id,"appearance":_vm.appearance,"indexTitle":_vm.indexTitle,"contextmenu":_vm.menuHandler,"viewer":_vm.viewer},on:{"add":function($event){return _vm.$emit('add')},"remove":function($event){return _vm.$emit('remove')},"active":_vm.toggleActive,"activePropertyOption":_vm.activePropertyOption}})],1),_c('draggable',_vm._b({staticClass:"scrollable bld-level-scroll overflow-y-auto",class:{
          'input-area-max-scrollable-height':
          _vm.topSelectedButton === 'full_screen',
          'bld-expand-level-scroll': _vm.expansionSelf || _vm.expansionGlobal,
          'except-first-level-scroll': _vm.checkChildExpand,
          'bld-full-level-scroll': _vm.topSelectedButton === 'full_screen',
          'bld-full-expand-level-scroll': (_vm.expansionSelf || _vm.expansionGlobal) && _vm.topSelectedButton === 'full_screen',
          'except-first-level-scroll-full': _vm.checkFullScreenChildExpand
        },attrs:{"disabled":!_setup.canEdit(_vm.project.cans),"group":"area","id":'scroller' + _vm.identifier.id},on:{"start":function($event){return _vm.changeTransition('flip-list')},"end":function($event){_vm.changeTransition('null'), _vm.updateStore($event)}},model:{value:(_vm.identifier.child),callback:function ($$v) {_vm.$set(_vm.identifier, "child", $$v)},expression:"identifier.child"}},'draggable',_vm.dragOptions,false),[_c('transition-group',{attrs:{"mode":"out-in","name":_vm.areaTransitionName,"data-id":_vm.identifier.id}},[_vm._l((_vm.identifier.child),function(l2Collection,index){return _c('area-input-bar',{key:l2Collection.id,ref:"areaInputBar",refInFor:true,staticClass:"list-group-item area open-menu",class:_vm.levelSelf == 1 &&
                _vm.expansionSelf == false &&
                _vm.expansionGlobal == true
                    ? 'area-horizon-height'
                    : '',style:({
                width: _vm.showGroup ? '275px' : '0px',
                opacity: _vm.showGroup ? 1 : 0,
                'margin-left': '20px',
                height: 'auto',
              }),attrs:{"area":l2Collection,"identifier":_vm.identifier.id,"index":index,"pNumberedTitle":_vm.numberedTitle,"expansionSelf":_vm.expansionSelf,"expansionGlobal":_vm.expansionGlobal,"levelGlobal":_vm.levelGlobal,"levelSelf":_vm.levelSelf,"selected_item":_vm.selectedActive,"showGroup":_vm.showGroup,"appearance":_vm.appearance,"indexTitle":_vm.indexTitle,"l1collection":_vm.identifier,"area-data-id":l2Collection.id,"data-id":l2Collection.id,"area-data-index":index,"viewer":_vm.viewer},on:{"active":_vm.toggleActive,"showCntxtmenu":function($event){return _vm.$emit('showCntxtmenu')},"activePropertyOption":_vm.activePropertyOption}})}),_vm._l(({i: 1}),function(item){return _c('div',{key:item,staticClass:"list-group-item area i-mt-25",class:{'d-none' : _vm.identifier.child.length > 0 },style:({
                  width: _vm.showGroup ? '275px' : '0px',
                  opacity: _vm.showGroup ? 1 : 0,
                  'margin-left': '25px',
                  height: '50px',
              })})})],2)],1)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }