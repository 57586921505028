export default {
    toggleRightBar: (state) => state.showRightBar = !state.showRightBar,
    toggleExpansionRightBar: (state) => state.isRightNavBarExpanded = !state.isRightNavBarExpanded,

    setModuleHeight: (state, data) => {
        // state.moduleHeights[data.title] = data.height
        let item = state.moduleHeights.find(height => height.title == data.title)
        if(item) {
            let itemIndex = state.moduleHeights.indexOf(item)
            state.moduleHeights.splice(itemIndex, 1)
        }
        state.moduleHeights.push(data)
        // state.moduleHeights = [...new Set(state.moduleHeights)]
    }
}