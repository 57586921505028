<template>
    <div :class="classes">
        <slot></slot>
    </div>
</template>

<script>
    export default {
        name: 'RightNavComponentWrapper',
        props: {
          classes: {
            default: 'i-w-410 bg-primary-three i-border-1 border-secondary-three i-p-25 rounded-sm'
          }
        }
    }
</script>
