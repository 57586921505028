export default[
    {
        path: '/project/:id/assessment/:assessment_id',
        component: () => import('@/views/screens/assessment/AssessmentSurvey'),
        name: 'intel.assess.survey'
    },
    {
        path: '/project/:id/assessment/:assessment_id/edit',
        component: () => import('@/views/screens/assessment/EditAssessment'),
        name: 'intel.assess.edit'
    },
    {
        path: '/survey',
        component: () => import('@/views/screens/survey/Index'),
        name: 'survey.landing'
    },
    {
        path: '/survey/:survey_id/send',
        component: () => import('@/views/screens/survey/Send'),
        name: 'survey.send'
    },
    {
        path: '/survey/:survey_id/edit',
       component: () => import('@/views/screens/survey/Create'),
        name: 'survey.edit'
    },
    {
        path: '/survey/responses',
        component: () => import('@/views/screens/survey/ResponseList'),
        name: 'survey.responses'
    },
    {
        path: '/survey/page/:survey_id',
        component: () => import('@/views/pages/Survey'),
        name: 'survey.page'
    },
]