<template>
    <svg class="inline" viewBox="0 0 30 29" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="1.09961" y="1.09961" width="27.8" height="27" rx="1.5" stroke="currentColor"/>
    </svg>
</template>

<script>
export default {
    name: "Rectangle"
}
</script>
