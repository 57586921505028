import { render, staticRenderFns } from "./IconLeftAlign.vue?vue&type=template&id=11220e29&scoped=true&"
import script from "./IconLeftAlign.vue?vue&type=script&setup=true&lang=js&"
export * from "./IconLeftAlign.vue?vue&type=script&setup=true&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "11220e29",
  null
  
)

export default component.exports