import { onMounted, computed, ref } from 'vue'
import router from '@/routes/routes.js'
import IconKeyBoard from '@/components/navigation/components/icons/IconKeyBoard.vue'
import IconGear from '@/components/navigation/components/icons/IconGear.vue'
import IconChecklist from '@/components/navigation/components/icons/IconChecklist.vue'
import IconPencilCircle from '@/components/navigation/components/icons/IconPencilCircle.vue'
import IconListBullets from '@/components/navigation/components/icons/IconListBullets.vue'
import IconBriefCase from '@/components/navigation/components/icons/IconBriefCase.vue'
import IconPresenTation from '@/components/navigation/components/icons/IconPresenTation.vue'
import IconFileText from '@/components/navigation/components/icons/IconFileText.vue'
import IconLightBulb from '@/components/navigation/components/icons/IconLightBulb.vue'
import IconLeftAlign from '@/components/navigation/components/icons/IconLeftAlign.vue'
import IconSquarsFour from '@/components/navigation/components/icons/IconSquarsFour.vue'
import IconGridFour from '@/components/navigation/components/icons/IconGridFour.vue'
import IconBarChart from '@/components/navigation/components/icons/IconBarChart.vue'
import IconTarget from '@/components/navigation/components/icons/IconTarget.vue'
import IconLayout from '@/components/navigation/components/icons/IconLayout.vue'
import IconTreeStructure from '@/components/navigation/components/icons/IconTreeStructure.vue'
import IconColumns from '@/components/navigation/components/icons/IconColumns.vue'
import IconArrowsClockWise from '@/components/navigation/components/icons/IconArrowsClockWise.vue'
import axios from 'axios'
import store from '@/store'


const showNavigation = ref(false)

export default function useNavigation()
{
    const route = router.currentRoute
    const projectId = computed(() => {
        let projectId = route.params.prId || route.params.id || route.params.projectId || route.query.projectId
        return projectId
    })
    const workspaceId = ref(null)
    const teams = ref([])
    const defaultIds = ref([])

    const iconComponents = {
        IconKeyBoard,
        IconGear,
        IconChecklist,
        IconPencilCircle,
        IconListBullets,
        IconBriefCase,
        IconPresenTation,
        IconFileText,
        IconLightBulb,
        IconLeftAlign,
        IconSquarsFour,
        IconGridFour,
        IconBarChart,
        IconTarget,
        IconLayout,
        IconTreeStructure,
        IconColumns,
        IconArrowsClockWise
    }

    const _getSolidObject = async (projectId) => {
        if(!projectId) return {};
        const {data} = await axios.get(`projects/${projectId}?solid_object=true`).then(({data})=>data)
        return data
    }

    const getTeams = async() => {
        if(!projectId.value) return []
        let solidObj = await _getSolidObject(projectId.value)
        if(!solidObj) return
        defaultIds.value = solidObj.default_settings
        workspaceId.value = solidObj.workspace_id
        let response = await axios.get(`/workspaces/teams/${workspaceId.value}`).then(({data}) => data.data);
        teams.value = response;
    }

    const getNavigation = () => {
        return {
            gettingStartedMenu: {
              title: 'Configuration',
              menus: [[
                { title: 'Workspace', active: true, icon: 'IconKeyBoard', to: {name: 'workspace_dashboard'}, menu_serial: 0 },
                { title: 'Project Wizard', active: true, icon: 'IconGear', to: '/', menu_serial: 1 },
                { title: 'Make My Plan', active: true, icon: 'IconChecklist', to: '/', menu_serial: 2 },
                {
                    title: 'Style Control',
                    active: true,
                    icon: 'IconPencilCircle',
                    to: {
                        name: 'workspace_settings',
                        params: {
                            id: workspaceId.value
                        },
                        query: {
                            option: 'style',
                            id: projectId.value
                        }
                    },
                    menu_serial: 3
                },
                {
                    title: 'Definition System',
                    active: true,
                    icon: 'IconListBullets',
                    to: {
                        name: 'definition',
                        params: {
                            id: projectId.value
                        }
                    },
                    menu_serial: 4
                }
              ]]
            },

            teamMenu: {
              menus: [
                [
                    {
                        title: 'Goal & Analytics',
                        active: true,
                        icon: 'IconBriefCase',
                        to: {
                            name: 'projectDashboard',
                            params: {
                                workspaceId: workspaceId.value,
                                projectId: projectId.value
                            },
                        },
                        menu_serial: 5
                    },
                    {
                        title: 'Presentation',
                        active: true, icon:
                        'IconPresenTation',
                        to: {
                            name: 'communicator',
                            params: {
                                id: projectId.value,
                                communicator_id: defaultIds.value.communicator_id
                            }
                        },
                        landing_page_to: {
                            name: 'communicators',
                            params: {
                                id: projectId.value,
                            }
                        },
                        landing_page: true,
                        menu_serial: 6
                    },
                    {
                        title: 'Report Creator',
                        active: true,
                        icon: 'IconFileText',
                        to: '/',
                        menu_serial: 7
                    },
                ],
                [
                    {
                        title: 'Brainstorm',
                        active: true,
                        icon: 'IconLightBulb',
                        to: {
                            name: 'teamwork',
                            params: {
                                id: projectId.value,
                                brainstorm_id: defaultIds.value.brainstorm_id
                            }
                        },
                        landing_page_to: {
                            name: 'brainstorm',
                            params: {
                                id: projectId.value,
                            }
                        },
                        landing_page: true,
                        menu_serial: 8
                    },
                    {
                        title: 'Mindmap',
                        active: true,
                        icon: 'IconLeftAlign',
                        to: {
                            name: 'teamwork.mindmap',
                            params: {
                                id: projectId.value,
                                brainstorm_id: defaultIds.value.brainstorm_id
                            }
                        },
                        menu_serial: 9
                    },
                    {
                        title: '2x2 Matrix',
                        active: true,
                        icon: 'IconSquarsFour',
                        to: {
                            name: 'teamwork.concepts',
                            params: {
                                id: projectId.value,
                                brainstorm_id: defaultIds.value.brainstorm_id
                            }
                        },
                        menu_serial: 10
                    },
                    {
                        title: 'Concept Canvas',
                        active: true,
                        icon: 'IconGridFour',
                        to: {
                            name: 'teamwork.canvas',
                            params: {
                                id: projectId.value,
                                brainstorm_id: defaultIds.value.brainstorm_id
                            }
                        },
                        menu_serial: 11
                    },
                    {
                        title: 'Work zone Charts',
                        active: true,
                        icon: 'IconBarChart',
                        to: {
                            name: 'teamwork.diagrammatic',
                            params: {
                                id: projectId.value,
                                brainstorm_id: defaultIds.value.brainstorm_id
                            }
                        },
                        menu_serial: 12
                    },
                ],
                [
                    {
                        title: 'Strategy Roadmap',
                        active: true,
                        icon: 'IconTarget',
                        to: {
                            name: 'roadmap.highlevel.data',
                            params: {
                                id: projectId.value
                            }
                        },
                        menu_serial: 13
                    },
                    {
                        title: 'Strategy Canvas',
                        active: true,
                        icon: 'IconLayout',
                        to: {
                            name: 'roadmap.show',
                            params: {
                                id: projectId.value,
                                roadmap_id: defaultIds.value.roadmap_id
                            }
                        },
                        landing_page_to: {
                            name: 'roadmap',
                            params: {
                                id: projectId.value,
                            }
                        },
                        landing_page: true,
                        menu_serial: 14
                    },
                    {
                        title: 'Implementation Plan',
                        active: true,
                        icon: 'IconChecklist',
                        to: {
                            name: 'implementationPlaning',
                            params: {
                                workspaceId: workspaceId.value,
                                projectId: projectId.value
                            }
                        },
                        menu_serial: 15
                    }
                ],
                [
                    {
                        title: 'Content Framework',
                        active: true,
                        icon: 'IconTreeStructure',
                        to: {
                            name: 'identification.build',
                            params: {
                                id: projectId.value
                            }
                        },
                        menu_serial: 16
                    },
                    {
                        title: 'Scenario Management',
                        active: true,
                        icon: 'IconColumns',
                        to: {
                            name: 'programmatic.scenario.view',
                            params: {
                                id: projectId.value,
                                scenario_id: defaultIds.value.scenario_id
                            }
                        },
                        landing_page_to: {
                            name: 'programmatic.scenario',
                            params: {
                                id: projectId.value
                            }
                        },
                        landing_page: true,
                        menu_serial: 17
                    },
                    {
                        title: 'Recurring Feedback & Surveys',
                        active: true,
                        icon: 'IconArrowsClockWise',
                        to: {
                            name: 'intel.assess',
                            params: {
                                id: projectId.value
                            }
                        },
                        menu_serial: 18
                    }
                ]
              ]
            }
        }
    }

    onMounted(() => {
        getTeams()
    })


    const visibilityToggleMenu = (menu_serial=null) =>
    {
        let teamId=10
        let profileData = store.getters['profile/data']
        let { menu_configuration } = profileData

        if(!menu_configuration){
            menu_configuration = {default_settings: [] }
            menu_configuration.default_settings.push({
                key: teamId,
                inactiveMenus: [menu_serial]
            })
        }else{
            let filteredItem = menu_configuration.default_settings.find(item => item.key == teamId)
            if(!filteredItem){
                menu_configuration.default_settings.push({
                    key: teamId,
                    inactiveMenus: [menu_serial]
                })
            }else{
                let selectedIndex = filteredItem.inactiveMenus.findIndex(item => item == menu_serial)
                if(selectedIndex>=0){
                    filteredItem.inactiveMenus.splice(selectedIndex, 1)
                }else{
                    filteredItem.inactiveMenus.push(menu_serial)
                }
            }
        }

        _saveConfig(menu_configuration, profileData)
    }

    const storeOpenMenu = (openStatus) => {
        let teamId=10
        let profileData = store.getters['profile/data']
        let { menu_configuration } = profileData

        if(!menu_configuration?.default_settings){
            menu_configuration = {
                default_settings: [{
                    key: teamId,
                    inactiveMenus: [],
                    isOpen: openStatus
                }],
                last_open: teamId
            }
        }else{
            let filteredItem = menu_configuration.default_settings.find(item => item.key == teamId)
            if(!filteredItem){
                profileData.menu_configuration.default_settings.push({
                    key: teamId,
                    inactiveMenus: [],
                    isOpen: openStatus
                })
            }else{
                filteredItem.isOpen = openStatus
            }
            profileData.menu_configuration.last_open = teamId
        }

        _saveConfig(menu_configuration, profileData)
    }

    const _saveConfig = async (menu_configuration, profileData) =>
    {
        let payload = {
            menu_configuration
        }

        profileData.menu_configuration = menu_configuration

        await axios.post(`menu-configuration`, payload)
    }


    const checkActiveMenu = (menu_serial) => {
        let teamId=10
        let profileData = store.getters['profile/data']
        let { menu_configuration } = profileData

        if(!menu_configuration){
            return true
        }else{
            let filteredItem = menu_configuration.default_settings.find(item => item.key == teamId)
            if(!filteredItem){
                return true
            }else{
                let selectedIndex = filteredItem.inactiveMenus.findIndex(item => item == menu_serial)
                if(selectedIndex>=0){
                    return false
                }else{
                    return true
                }
            }
        }
    }

    const checkMenuOpen = () => {
        let teamId=10
        let profileData = store.getters['profile/data']
        let { menu_configuration } = profileData

        if(!menu_configuration?.default_settings){
            return false
        }else{
            let filteredItem = menu_configuration.default_settings.find(item => item.key == teamId)
            if(!filteredItem){
                return false
            }else{
                return filteredItem.isOpen
            }
        }
    }

    const getLastOpenTeamId = () => {
        let profileData = store.getters['profile/data']
        let { menu_configuration } = profileData
        if(!menu_configuration?.last_open){
            return false
        }

        return menu_configuration?.last_open
    }


    const storeActiveLink = (serial_no) => {
        let teamId=10
        let profileData = store.getters['profile/data']
        let { menu_configuration } = profileData

        if(!menu_configuration?.default_settings){
            return false
        }else{
            let filteredItem = menu_configuration.default_settings.find(item => item.key == teamId)
            if(!filteredItem){
                return false
            }else{
                return filteredItem.isOpen
            }
        }
    }

    return {
        iconComponents,
        projectId,
        getNavigation,
        teams,
        showNavigation,
        visibilityToggleMenu,
        checkActiveMenu,
        storeOpenMenu,
        checkMenuOpen,
        getLastOpenTeamId
    }
}
