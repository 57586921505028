import axios from 'axios';
import {uniqBy} from 'lodash'

function getDefaultState() {
    return {
        communicators: [],
        communicator: null,
        workspaceLayouts: [],
        templates: [],
        activeTemplate: null,
        clickedLayout: {},
        sidebarComponentName: 'ItemList',
        labels: [],
        assignPeoples: [],
        module:[],
        presentationState: 0,
        presentationLayout: null,
        isolatedModules: [],
        isolatedModule: null,
        categories: [],
    }
}

const getters = {
    categories: state => state.categories,
    communicators: state => state.communicators,
    getAssignPeoples: state => state.assignPeoples,
    getSidebarComponentName: state => state.sidebarComponentName,
    getClickedLayout: state => state.clickedLayout,
    communicator: state => state.communicator,
    modules: (state, getters) => getters.communicator.modules || [],
    module: (state, getters) => id => getters.modules.find(item => item.id == id),
    workspaceLayouts: state => state.workspaceLayouts,
    templates: state => state.templates,
    activeTemplate: state => state.activeTemplate,
    presentationState: state => state.presentationState,
    getPresentationLayout: state => state.presentationLayout,
    filteredTemplate: state => (key=null) => {
        if(key) {
            return state.templates.data.filter(item => item.title.toLowerCase().match(key.toLowerCase()) )
        }
        return  state.templates.data
    },
    templateCategories:( state, getters) => (searchKey=null) => {
        let categorisedTemplate = {}
        let uniqueTemplates
        let templates = getters.filteredTemplate(searchKey)
        if (templates && templates.length) {
            uniqueTemplates = uniqBy(templates, 'category')
        }
        if (uniqueTemplates) {
            uniqueTemplates.forEach(item => {
                categorisedTemplate[item.category] = []
                categorisedTemplate[item.category] = templates.filter(i => i.category == item.category)
            })
        }
        return categorisedTemplate
    },
    categoryWiseTemplate: (state, getters) => (categoryName=null, searchKey=null) => {
        if(categoryName) {
            return ({
                [categoryName]: getters.templateCategories(searchKey)[categoryName]
            })

        }
        return getters.templateCategories(searchKey)
    },
    templateModule: state => {
        return state.communicator?.modules?.find(item => item.id === state.activeTemplate?.module_id);
    },
    getLabels: state => state.labels,
    isolatedModules: state => state.isolatedModules,
    isolatedModule: state => state.isolatedModule,
}

const actions = {
    getCategories({commit, state}) {
        return axios.get(`communicators/categories/list`)
            .then(response => {
                commit("setCategories", response.data);
                return response;
            }).catch(e => {
                //
            });
    },
    createCategory({dispatch, state}) {
        return axios.post(`communicators/categories/store`, {})
            .then(response => {
                dispatch("getCategories");
                return response;
            }).catch(e => {
                //
            });
    },
    updateCategory({dispatch, state}, payload) {
        return axios.post(`communicators/categories/${payload.id}/update`, payload)
            .then(response => {
                return response;
            }).catch(e => {
                //
            });
    },
    getCommunicators({commit, state}, payload) {
        return axios.get(`communicators/project/${payload.project_id}`)
            .then(response => {
                commit("setCommunicators", response.data);
                return response;
            }).catch(e => {
                //
            });
    },
    getCommunicator({commit, state}, payload) {
        state.reload = false;
        return axios.get(`communicators/${payload.communicator_id}`)
            .then(response => {
                commit("setCommunicator", response.data);
                return response;
            }).catch(e => {
                //
            });
    },
    getFirstModuleCommunicator({commit, state}, payload) {
        state.reload = false;
        return axios.get(`communicators/initial/${payload.communicator_id}`)
            .then(response => {
                commit("setCommunicator", response.data);
                return response;
            }).catch(e => {
                //
            });
    },
    getExceptInitialModuleCommunicator({ commit, dispatch, state }, payload) {
        return axios.get(`communicators/rest-except-initial/${payload.communicator_id}`)
            .then(response => {
                commit("setExceptInitialCommunicator", response.data);
                return response;
            }).catch(e => {
                //
            });
    },
    getTemplates({commit, state}) {
        return axios.get(`communicators/pre-build/templates`)
            .then(response => {
                commit("setTemplates", response.data);
                return response;
            }).catch(e => {
                //
            });
    },
    storeCommunicator({commit, state, dispatch}, payload) {
        return axios.post(`communicators/store`, payload)
            .then(response => {
                return response;
            }).catch(e => {
                //
            });
    },
    updateCommunicator({commit, state, dispatch}, payload) {
        return axios.post(`communicators/${payload.get('id')}/update`, payload)
            .then(response => {
                // dispatch("getCommunicators", payload);
                return response;
            }).catch(e => {
                //
            });
    },
    deleteCommunicator({commit, state, dispatch}, payload) {
        return axios.delete(`communicators/${payload.id}`)
            .then(response => {
                dispatch("getCommunicators", payload);
                return response;
            }).catch(e => {
                //
            });
    },
    duplicateCommunicator({commit, state, dispatch}, payload) {
        return axios.post(`communicators/duplicate/${payload.id}`, payload)
            .then(response => {
                dispatch("getCommunicators", payload);
                return response;
            }).catch(e => {
                //
            });
    },
    storeLayout({commit, dispatch, state}, payload) {
        return axios.post(`communicators/layout/store`, payload)
            .then(response => {
                return response;
            }).catch(e => {
                //
            });
    },
    updateLayout({commit, dispatch, state}, payload) {
        return axios.post(`communicators/layout/${payload.id}/update`, payload)
            .then(response => {
                return response;
            }).catch(e => {
                //
            });
    },
    updateLayoutSetting(_, payload) {
        return axios.post(`communicators/layout/${payload.id}/update`, payload.data)
    },
    updateLayoutImage(_, payload) {
        return axios.post(`communicators/layout/${payload.id}/image-update`, payload.data)
    },
    uploadTemplateToLibrary(_, payload) {
        return axios.post('communicators/libraries/store-into-library', payload)
    },
    uploadScreenshot(_, payload) {
        return axios.post(`communicators/templates/${payload.id}/template-cover-update`, payload.formData)
    },

    fetchLibrary() {
        return axios.get('communicators/libraries/own')
    },

    importTemplate(_, payload) {
        return axios.post('communicators/libraries/import-library', payload)
    },
    deleteLayout({commit, dispatch, state}, payload) {
        return axios.delete(`communicators/layout/${payload.layout_id}`)
        .then(response => {
            dispatch("getCommunicator", payload);
            return response;
        }).catch(e => {
            //
        });
    },
    duplicateLayout({commit, dispatch, state}, payload) {
        return axios.post(`communicators/layout/duplicate/${payload.layout_id}`, payload)
            .then(response => {
                dispatch("getCommunicator", payload);
                return response;
            }).catch(e => {
                //
            });
    },
    getAllLayoutsOfWorkspace({commit, state, dispatch}, payload) {
        return axios.get(`communicators/customize/${payload.project_id}`)
            .then(response => {
                commit("setLayoutOfWorkspace", response.data);
                return response;
            }).catch(e => {
                //
            });
    },
    replaceLayout({commit, state, dispatch}, payload) {
        return axios.post(`communicators/customize/layout/${payload.layoutId}`, payload.data)
            .then(response => {
                commit("setLayoutOfWorkspace", response.data);
                return response;
            }).catch(e => {
                //
            });
    },
    addTemplate({commit, dispatch}, payload) {
        return axios.post(`communicators/templates/store`, payload)
            .then(response => {
                return response;
            }).catch(e => {
                //
            });
    },
    updateTemplate({commit, dispatch}, payload) {
        return axios.post(`communicators/templates/${payload.id}/setup`, payload)
            .then(response => {
                return response;
            }).catch(e => {
                //
            });
    },

    modifyTemplate(_, payload) {
        return axios.post(`communicators/templates/${payload.id}/update`, payload)
    },

    //used in introduction template
    updatePeopleImage(_, payload) {
        return axios.post(`communicators/templates/${payload.id}/people-image-update`, payload.data)
    },
    //used in title-content template
    updateTemplateImage(_, payload) {
        return axios.post(`communicators/templates/${payload.id}/template-image-update`, payload.data)
    },
    orderTemplate({ commit, state, dispatch }, payload) {
        // return payload
        return axios.post(`communicators/templates/${payload.layout_id}/reorder`, payload.data)
            .then(response => {
                //commit("setLayoutOfWorkspace", response.data);
                return response;
            }).catch(e => {
                //
            });
    },
    updateTemplateBackground(_, payload) {
        return axios.post(`communicators/templates/${payload.id}/image-update`, payload.data)
    },
    deleteTemplateBackground(_, payload) {
        return axios.delete(`communicators/templates/background/${payload.id}`)
    },

    getResponsible(_, projectId) {
        return axios.get(`projects/workspace-responsible/${projectId}/`)
    },

    deleteTemplate({commit, dispatch}, payload) {
        return axios.delete(`communicators/templates/${payload.id}`)
            .then(response => {
                return response;
            }).catch(e => {
                //
            });
    },
    copyTemplate({commit, dispatch}, payload) {
        return axios.post(`communicators/templates/${payload.id}/duplicate`, payload)
            .then(response => {
                return response;
            }).catch(e => {
                //
            });
    },
    updateImageTemplate({commit, dispatch}, payload) {
        return axios.post(`communicators/templates/${payload.get("id")}/image-update`, payload, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        })
            .then(response => {
                return response.data;
            }).catch(e => {
                //
            });
    },

    updatePeopleImageTemplate({commit, dispatch}, payload) {
        return axios.post(`communicators/templates/${payload.get("id")}/people-image-update`, payload, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        })
            .then(response => {
                return response.data;
            }).catch(e => {
                //
            });
    },

    deleteTemplateImage(_, templateId) {
        return axios.delete('communicators/templates/background/'+templateId)
    },

    toggleTemplate({commit}, payload) {
        commit("setActiveTemplate", payload);
    },

    fetchLabels({commit}, templateId) {
        axios.get('/communicators/templates/peoples/labels/'+templateId).then(res=> {
            commit('setLabels', res.data.data)
        })
    },

    storeLabels({commit, dispatch}, payload) {
        axios.post('/communicators/templates/peoples/labels/store', payload).then(res=> {
            if(res.status) {
                dispatch('fetchLabels', payload.communicator_module_template_id)
            }
        })
    },
    deleteLabel({commit, dispatch}, data) {
        axios.delete('/communicators/templates/peoples/labels/'+ data.labelId).then(res=> {
            if(res.status) {
                dispatch('fetchLabels', data.communicator_module_template_id)
            }
        })
    },
    updateLabel({commit, dispatch}, data) {
        axios.post(`/communicators/templates/peoples/labels/${data.id}/update`, data).then(res=> {
            if(res.status) {
                dispatch('fetchLabels', data.communicator_module_template_id)
            }
        })
    },
    fetchAssignedPeoples({commit}, templateId) {
        axios.get('/communicators/templates/peoples/'+templateId).then(res=> {
            commit('setAssignPeoples', res.data.data)
        })
    },
    storePeople({commit, dispatch}, data) {
        axios.post(`/communicators/templates/peoples/store`, data).then(res=> {
            if(res.status) {
                dispatch('fetchAssignedPeoples', data.communicator_module_template_id)
            }
        })
    },
    deleteAssignedPeople({commit, dispatch}, data) {
        axios.delete('/communicators/templates/peoples/'+ data.assignId).then(res=> {
            if(res.status) {
                dispatch('fetchAssignedPeoples', data.communicator_module_template_id)
            }
        })
    }
};

const mutations = {
    setClickedLayout: (state, data) => state.clickedLayout = data,
    setAssignPeoples: (state, data) => state.assignPeoples = data,
    setCategories(state, value) {
        state.categories = value.data;
    },
    setCommunicators(state, value) {
        state.communicators = value.data;
    },
    setSidebarComponentName: (state, name) => state.sidebarComponentName = name,
    setCommunicator(state, value) {
        state.communicator = value.data;
        state.communicator.modules.forEach(item => {
            if (item.layouts && item.layouts.length > 0) {
                item.layouts[0].expanded = true;
            }
        });
    },
    setExceptInitialCommunicator(state, value) {
        if(value && value.data && value.data.modules.length > 0) {
            state.communicator.modules = [...state.communicator.modules, ...value.data.modules];
        }
    },
    setLayoutOfWorkspace(state, value) {
        state.workspaceLayouts = value.data;
    },
    setTemplates(state, value) {
        state.templates = value;
    },
    setActiveTemplate(state, value) {
        state.activeTemplate = value;
    },
    setLabels(state, payload) {
        state.labels = payload
    },
    setPresentationMode(state) {
        if(state.presentationState === 2) {
            state.presentationState = 0;
        } else {
            state.presentationState++;
        }
    },
    setPresentationLayout(state, payload) {
        state.presentationLayout = payload;
    },
    setModuleIsolation(state, payload) {
        state.isolatedModule = payload;
    },
    setModuleItemIsolation(state, payload) {
        state.isolatedModules = payload;
    }
};

export default {
    namespaced: true,
    state: getDefaultState,
    getters,
    actions,
    mutations
}
