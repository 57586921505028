<template>
    <div class="p-10" style="width: 6000px">
      <div class="flex justify-between pb-5 i-border-b-1 border-primary-one">
        <h3 class="font-poppins text-primary-one fs-26">Edit Workspace </h3>
      </div>

      <div class="i-mt-30">
        <div>
            <h4 class="font-poppins text-secondary-one fs-14">Title</h4>
            <input
                type="text"
                class="bg-transparent mt-2 font-poppins text-secondary-one fs-14 placeholder-secondary-two w-full px-4 h-10 i-border-1 border-solid border-primary-one rounded"
                v-model="workspace.name"
                placeholder="Type title here"
            >
        </div>

        <div class="i-mt-30">
          <h4 class="font-poppins text-secondary-one fs-14">Collaborator</h4>
          <div class="flex justify-between items-center mt-2 relative">
            <div class="modal-collaborator flex-3 pr-3 relative">
              <input type="text" v-model="responsibleUser.email"
                     class="bg-transparent font-poppins text-secondary-one fs-14 placeholder-secondary-two w-full px-4 h-10 i-border-1 border-solid border-primary-one rounded" placeholder="Type email here">
              <div class="ws-modal-role-selector absolute bg-transparent">
                <select class="fs-14 font-poppins text-secondary-one ws-select-dropdown bg-transparent" v-model="responsibleUser.role">
                  <option class="bg-primary-three" v-for="(role, index) in roles" :key="index" :value="role.value">{{ role.title }}</option>
                </select>
              </div>
            </div>
            <button class="font-poppins fs-14 fw-600 h-10 w-40 flex-1 text-primary-one rounded border-secondary-one bg-transparent i-border-1" @click="addNewResponsible()" :disabled="loading">Add</button>
          </div>
        </div>

        <div class="">
          <div class="modal-collaborators">
            <span v-for="(responsible, index) in workspace.responsibles" :key="index"
                class="fs-14 text-primary-one font-poppins mr-4 px-4 mt-4 pt-1 rounded i-border-1 border-primary-one" :title="getRole(responsible.role)">
              {{ responsible && responsible.user_id && responsible.user ? getUser(responsible.user) : responsible.email }}
              <span class="ml-2 fs-12 cursor-pointer" style="border: none" @click="removeOldUser(index)">&#10006;</span>
            </span>
            <span v-for="(responsible, nIndex) in responsibles" :key="nIndex"
                  class="fs-14 text-primary-one font-poppins mr-4 px-4 mt-4 pt-1 rounded i-border-1 border-primary-one" :title="getRole(responsible.role)">
              {{ responsible.email }}
              <span class="ml-2 fs-12 cursor-pointer" style="border: none" @click="removeNewUser(nIndex)">&#10006;</span>
            </span>
          </div>
        </div>

        <div class="flex i-mt-30">
          <button class="fs-14 fw-600 font-poppins i-pl-35 i-pr-35 rounded bg-secondary-one text-primary-three h-10 w-40 shadow-one" @click="editWorkspace()" :disabled="loading">Update</button>
          <button class="focus:outline-none fs-14 fw-600 font-poppins i-pl-35 i-pr-35 i-border-1 border-secondary-one ml-5 rounded text-primary-one h-10 w-40" @click="$emit('close')" :disabled="loading">Cancel</button>
        </div>
      </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "EditWorkspace",
  props: ['contextWorkspace'],
  data() {
    return {
      placeholder: {
        title: 'Type title here',
        description: 'Your description here',
        responsible: 'Type email here'
      },
      responsibleUser: {
        email: '',
        role: 2
      },
      workspace: {
        title: '',
        description: ''
      },
      roles: [
        // { value: 1, title: 'Admin' },
        { value: 2, title: 'Editor' },
        { value: 4, title: 'Viewer' }
      ],
      responsibles: [],
      removedResponsible: []
    }
  },
  computed: {
    ...mapGetters({
      loading: "GET_LOADING_STATE",
    })
  },
  methods: {
    closeModal() {
      this.$emit("toggleEditModal");
    },
    outsideTitleInput() {
      this.placeholder.title = 'Type title here';
    },
    outsideDescription() {
      this.placeholder.description = 'Type description here';
    },
    editWorkspace() {
      const self = this;
      let data = {
        id: this.workspace.id,
        name: this.workspace.name,
        new_responsible: this.responsibles,
        removed_responsible: this.removedResponsible.map(item => item.id)
      };
      self.$Progress.start();
      self.$store.dispatch("workspace/updateWorkspace", data).then(response => {
        self.$store.dispatch("workspace/fetchWorkspaces", {workspace_id: this.workspace.id})
        self.$Progress.finish();
        self.$emit('close')
      });
    },
    getUser(user) {
      if(user && user.first_name) {
        let name = user.first_name;
        if(user.last_name) {
          name = name + ' ' + user.last_name;
        }
        return name;
      }
      return user.email;
    },
    addNewResponsible() {
      let alreadyUser = this.workspace.responsibles.findIndex(item => item.email === this.responsibleUser.email || (item.user_id && item.user.email === this.responsibleUser.email));
      if(alreadyUser < 0) {
        let inNewUser = this.responsibles.findIndex(item => item.email === this.responsibleUser.email);
        if(inNewUser < 0) {
          let findUser = this.removedResponsible.find(item => item.email === this.responsibleUser.email || (item.user_id && item.user.email === this.responsibleUser.email));
          if(findUser) {
            this.workspace.responsibles.push(findUser);
          } else {
            this.responsibles.push(this.responsibleUser);
            this.responsibleUser = {
              email: '',
              role: this.responsibleUser.role
            };
          }
        } else {
          alert('User already in the workspace');
        }
      } else {
        alert('User already in the workspace');
      }
    },
    getRole(role) {
      let roleItem = this.roles.find(item => parseInt(item.value) === parseInt(role));
      if(roleItem) {
        return roleItem.title;
      }
      return '';
    },
    removeNewUser(index) {
      this.responsibles.splice(index, 1);
    },
    removeOldUser(index) {
      this.removedResponsible.push(this.workspace.responsibles[index]);
      this.workspace.responsibles.splice(index, 1);
    }
  },
  mounted() {
    if(this.contextWorkspace) {
        this.workspace = this.contextWorkspace;
    }
  }
}
</script>

<style scoped>
.h-6r {
  height: 6rem;
}
.checkbox {
  width: 16px;
  height: 16px;
  border-radius: 4px;
  border: 2px;
}
.custom-border {
  border: 2px solid #8d8d8d;
}
</style>
