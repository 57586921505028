import RiskManagement from '@/components/InstMenu/right-navigation-drawer/module/RiskManagement.vue'
import Insights from '@/components/InstMenu/right-navigation-drawer/module/Insights.vue'
import Comments from '@/components/InstMenu/right-navigation-drawer/module/Comments.vue'
import Concept from '@/components/InstMenu/right-navigation-drawer/module/Concept.vue'
import Diagrammatic from '@/components/InstMenu/right-navigation-drawer/module/Diagrammatic.vue'
import BasicInformation from '@/components/InstMenu/right-navigation-drawer/module/BasicInformation.vue'
import Metrics from '@/components/InstMenu/right-navigation-drawer/module/Metrics.vue'
import SocialCapability from '@/components/InstMenu/right-navigation-drawer/module/SocialCapability.vue'
import Associations from '@/components/InstMenu/right-navigation-drawer/module/Associations.vue'
import Feedback from '@/components/InstMenu/right-navigation-drawer/module/Feedback.vue'
export default {
    components: {
        RiskManagement,
        Insights,
        Comments,
        Concept,
        Diagrammatic,
        Metrics,
        SocialCapability,
        BasicInformation,
        Associations,
        Feedback,
    },
}