export default {
    getBuildModuleBgClass: function(level, buttonSelection) {
        let className = '';
        if(buttonSelection === 'full_screen') {
            className = 'collection-fullscreen';
        }
        return className;
    },
    selectedCheckAndBg: function(level, expansionSelf, expansionGlobal, checkSelected, buttonSelection) {
        let expansion = (expansionSelf === true && checkSelected === true || expansionGlobal === true);
        let selected = checkSelected === true;
        let className = '';
        if(expansion) {
            className = className + ' hor-tiles-max';
        }
        if(selected) {
            className = className + ' i-border-1';
        }
        return className;
    },
    selectedThirdLevelAndBg: function(level, expansionSelf, expansionGlobal, checkSelected, checkParentSelected, buttonSelection) {
        let expansion = (expansionSelf === true && checkSelected === true) || (expansionSelf === true && checkParentSelected === true) || expansionGlobal === true;
        let selected = checkSelected === true;
        let className = '';
        if(expansion) {
            className = className + ' hor-tiles-max';
        }
        if(selected) {
            className = className + ' i-border-1';
        }
        return className;
    },
    selectedFourthLevelAndBg: function(level, expansionSelf, expansionGlobal, checkSelected, checkParentSelected, checkAreaSelected, buttonSelection) {
        let expansion = (expansionSelf === true && checkSelected === true) || (expansionSelf === true && checkAreaSelected === true)
            || (expansionSelf === true && checkParentSelected === true) || expansionGlobal === true;
        return expansion;
    }
}
