<template>
  <transition name="fade">
    <div class="fixed inset-0 overflow-y-auto modal-bg" v-if="value" style="z-index: 900000">
        <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div
                @click="closeDialog"
                class="fixed inset-0 transition-opacity"
                aria-hidden="true"
            >
                <div class="absolute inset-0"></div>
            </div>
            <!-- This element is to trick the browser into centering the modal contents. -->
            <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true" >&#8203;</span>
            <div :class="[{'shakeX':bounceIn}, overflow]" class="inline-block align-bottom bg-white rounded-lg text-left shadow-xl transform transition-all sm:my-8 sm:align-middle">
                <slot></slot>
            </div>
        </div>
    </div>
  </transition>
</template>

<script>

export default {
    name: "Modal",
    props: {
        value: {
            type: Boolean
        },
        persistent: {
            type: Boolean,
            default: () => false
        },
        overflow: {
            type: String,
            default: () => 'overflow-hidden'
        }
    },
    data() {
        return {
            bounceIn: false
        }
    },
    methods: {
        closeDialog() {
            if(!this.persistent) {
                this.$emit('input', false);
                this.$emit('close')
                return;
            }
            this.bounceIn = true;
            setTimeout(() =>  this.bounceIn = false, 400)
        }
    }
};
</script>

<style scoped>
    .modal-bg {
        background: rgba(40, 40, 44, 0.225);
        /* backdrop-filter: blur(1px); */
    }
    /* Fade transition used for dialog */
    .fade-enter-active {
        animation: topInAnimation 0.3s ease-in-out;
    }

    .fade-leave-active {
        animation: topOutAnimation 0.3s ease-in-out;
    }

    @keyframes topInAnimation {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }

    @keyframes topOutAnimation {
        from {
            opacity: 1;
        }
        to {
            opacity: 0;
        }
    }

     .shakeX {
        animation: .2s shakeX ease-out;
        animation-iteration-count: 2;
    }
    @keyframes shakeX {
        0%   { transform: translateX(0); }
        50%  { transform: translateX(-10px); }
        100% { transform: translateX(0); }
    }
</style>