export default  [
    {
        path: '/project/:id/teamwork/:brainstorm_id',
        component: () => import('@/views/screens/teamwork/Index.vue'),
        name: 'teamwork',
        redirect: { name: 'teamwork.brainstorm' },
        children: [
            {
                path: 'brainstorm',
                component: () => import('@/views/screens/teamwork/Mindmap.vue'),
                name: 'teamwork.brainstorm',
            },
            {
                path: 'mindmap',
                component: () => import('@/views/screens/teamwork/Mindmap.vue'),
                name: 'teamwork.mindmap',
            },
            {
                path: 'canvas',
                component: () => import('@/views/screens/teamwork/Mindmap.vue'),
                name: 'teamwork.canvas',
            },
            {
                path: 'concepts',
                component: () => import('@/views/screens/teamwork/Mindmap.vue'),
                name: 'teamwork.concepts',
            },
            {
                path: 'diagrammatic',
                component: () => import('@/views/screens/teamwork/Mindmap.vue'),
                name: 'teamwork.diagrammatic',
            }
        ]
    }
]
