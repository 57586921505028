import mutations from './mutations';
import actions from './actions'
import getters from './getters'
export default {
    state: () => ({
        project: {},
        comments: [],
        insights: [],
        concepts: [],
        scenarios: [],
        setInsights: [],
        brainstorms: [],
        associations: [],
        assessmentsComments: [],
        assessmentsInsights: [],
        openInsightCreateForm: false,
        associationsByScenarioCollection: [],
    }),
    getters,
    actions,
    mutations,
    namespaced: true,
}
