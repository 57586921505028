<template>
    <RightNavComponentWrapper>
        <TopTitle
            title="INSIGHTS"
            icon-type="layout"
            :is-list="isList"
            :setLayoutType="setLayoutType"
        />
        <!-- :class="[item.files.length > 0 ? 'col-span-2' : '']" -->

        <div class="grid grid-cols-2 gap-5 mt-4">
            <div
                :key="index"
                v-for="(item, index) in insights" 
                class="rounded i-p-15 bg-white shadow-two"
                :class="[getColspan(item)]"
            >
                <Insight
                    @updateItem="updateItem"
                    :item="item"
                >
                    <FileViewer
                        class="mt-3"
                        v-if="item.files.length > 0"
                        :files="item.files"
                    />
                </Insight>
            </div>
        </div>
        <button @click="submitInsight" :disabled="loading" class="mt-5 i-w-360 border border-dashed border-secondary-two h-10 rounded text-secondary-one fs-12">
          <div  class="flex items-center justify-center">
               <PlusIcon class="w-2 h-2 text-secondary-one mr-2" /> Add new
          </div>
        </button>
    </RightNavComponentWrapper>
</template>

<script>
import sidebarMixin from '@/components/InstMenu/right-navigation-drawer/mixins.js'
import TopTitle from '@/components/InstMenu/right-navigation-drawer/components/TopTitle.vue'
import FileViewer from '@/components/InstMenu/right-navigation-drawer/components/FileViewer.vue'
import Insight from '@/components/InstMenu/right-navigation-drawer/module/comments/Insight.vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
    name : 'Insights',
    mixins: [sidebarMixin],
    components: {
        TopTitle,
        Comment,
        FileViewer,
        Insight
    },
    data: () => ({
        loading: false
    }),
    computed: {
        ...mapGetters({
            openCreateForm: 'drawer/getOpenInsightsCreateForm'
        })
    },
    methods: {
        ...mapActions({
            storeInsights: 'drawer/storeInsights',
            updateInsights: 'drawer/updateInsights'
        }),
        ...mapMutations({
            setInsight: 'drawer/setInsight',
        }),
        async submitInsight() {
            this.loading = true
            let form = {}
            // form.module_collection_id=null  
            form.scenario_collection_id= this.activeScenarioCollectionId
            form.title = 'Title'
            form.description = 'Description'
            let fd = new FormData()
            Object.keys(form).forEach(key => fd.append(key, form[key]))
            Array.from(fd, (item) => {console.log(item)})
            this.storeInsights({data: fd, activeScenarioCollectionId: this.activeScenarioCollectionId})
            .finally(() => {
                this.loading=false
            })
        },
        resetInsightForm() {
            Object.keys(this.insightForm).forEach(key => this.insightForm[key] = '')
        },
        async updateItem(data) {
            const {item, file} = data

            let fd = new FormData()
            fd.append('scenario_collection_id', item.scenario_collection_id)
            // fd.append('module_collection_id', null)
            fd.append('title', item.title)
            fd.append('description', item.description)
            if(file){
                fd.append('file_path', file)
            }
            this.updateInsights({data: fd, id: item.id})
            .then(res => {
                if(file && res.data.status) {
                    this.setInsight(res.data.data)
                }
            })
        },

        getColspan(item) {
            return item?.files.length ? 'col-span-2' : 'col-span-1'
        }
    },
    props: {
        activeScenarioCollectionId: [Number, String],
        insights: Array
    },
    watch:{
        openCreateForm() {
            this.resetInsightForm()
        }
    }
}
</script>