import { random, take, isEmpty, cloneDeep, capitalize } from 'lodash';
import { format as dateFormat, formatDistanceToNow, startOfMonth, endOfMonth, eachMonthOfInterval, parse, getDaysInMonth } from 'date-fns'
export const dateDefer = (date1, date2) => {
    if(!isEmpty(date1) && !isEmpty(date2)) {
        const diffTime = Math.abs(new Date(date2) - new Date(date1));
        return  Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    }
    return null;
};

export const maxDate = (dates) =>  {
    let xx = dates.filter(item => Boolean(item))
    return xx.reduce((acc, date) => (acc && new Date(acc) > new Date(date) ? acc : date ), '');
};
 // will check : const maxDate = (...dates) => new Date(Math.max.apply(null, ...dates));
export const isDate = d => d instanceof Date && !isNaN(d);
export const minDate = (dates) =>  {
    let yy = dates.filter(item => Boolean(item));
   return yy.reduce((acc, date) => (acc && new Date(acc) < new Date(date) ? acc : date ), '')
};

export const startDateOfYear = (dates) => {
    let d = new Date(minDate(dates));
    if(!isDate(d)) return;
    return `01-01-${d.getFullYear()}`
 }

export const endDateOfYear = (dates) => {
   let d = new Date(maxDate(dates));
   if(!isDate(d)) return;
   return `12-31-${d.getFullYear()}`
}

export const differenceInCalendarYears = (startDate, endDate) => {
    if(isEmpty(startDate) || isEmpty(endDate)) {
        return;
    }
    const start = new Date(startDate);
    const end = new Date(endDate);
     return  end.getFullYear() - start.getFullYear() + 1;
}
export const fullName = (obj) => {
    if(!obj.first_name && !obj.last_name) {
        return null;
    }
    let fName = obj.first_name || '';
    let lName = obj.last_name || '';
    return capitalize(`${fName} ${lName}`)
}

export const numberFormat = (num, size = 2) => {
    num = num.toString();
    while (num.length < size) num = "0" + num;
    return num;
}

export const nextIndex = (currentIndex, length) => {
    return ++currentIndex % length;
}

export const prevIndex = (currentIndex, length) => {
    let previousIndex = --currentIndex % length;
    return previousIndex < 0 ? length - 1 : previousIndex
}

export const getUnixTimeStamp = () => {
    return Math.round(+new Date()/1000);
}

//example: findMinMaxDate(['2022-10-01', '2022-10-03', '2022-10-01'], 'max');
export const  findMinMaxDate = (dates, type) => {
    const sortedDates = dates.sort((a, b) => new Date(a) - new Date(b));
    if (type === 'min') {
        return sortedDates[0];
    } else if (type === 'max') {
        return sortedDates[sortedDates.length - 1];
    }
}

export const getContrastColor = (color) => {
    // check color format
    var hexFormat = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;
    var rgbFormat = /^rgb\((\d{1,3}),\s*(\d{1,3}),\s*(\d{1,3})\)$/;
    var R,G,B;
    if (hexFormat.test(color)) {
        // convert hex to rgb
        let c = color.substring(1).split('');
        if (c.length === 3) {
            c = [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c = '0x' + c.join('');
        R = (c>>16)&255;
        G = (c>>8)&255;
        B = c&255;
    } else if (rgbFormat.test(color)) {
        // extract R, G, B values from rgb
        let c = color.substring(4).split(")")[0].split(",");
        R = parseInt(c[0]);
        G = parseInt(c[1]);
        B = parseInt(c[2]);
    } else {
        // unknown color format
        return "unknown";
    }
    // calculate luminance
    var luminance = (0.2126 * R + 0.7152 * G + 0.0722 * B) / 255;
    // return contrast color
    if (luminance > 0.5) {
        return "black";
    } else {
        return "white";
    }
}

//filter items based on {key}
//take unique item and only if {key} has valid value 
export const pickUniqueItems = (items, key) => {
    return items.filter(item => item[key] && item[key] != null) // filter items with a valid id
    .reduce((acc, curr) => {
        if (!acc.find(item => item[key] === curr[key])) { // check if the id is already in the accumulator array
            acc.push(curr); // if not, add the current item to the accumulator
        }
        return acc;
    }, []);
}

export const generateMonthList = (startDate, endDate, format='MMMyyyy') => {
    const start = startOfMonth(startDate);
    const end = endOfMonth(endDate);
    if(start > end) return []
    const months = eachMonthOfInterval({ start, end });

    return months.map(month => dateFormat(month, format));
}

export const firstNchar = (str, n) => take(str, n).join('')

export const dateParser = (str, format='MMMyyyy') => parse(str, format, new Date());

export const getMonthsTotalDate = date => getDaysInMonth(date) 
export default {
    install : function(Vue){
        Vue.mixin({
            computed : {
                "$lodash" : function(){
                    return {
                        random: (...arg)        => random(...arg),
                        isEmpty: (...arg)       => isEmpty(...arg),
                        cloneDeep: (...arg)     => cloneDeep(...arg),
                        dateDefer: (...arg)     => dateDefer(...arg),
                        first: arr              => arr[0],
                        last: arr               => arr[arr.length - 1],
                        format: (date, format)  => dateFormat(new Date(date), format),
                        ago: (date)             => formatDistanceToNow(new Date(date), {includeSeconds: true, addSuffix: true})
                    };
                }
            }
        });
    }
}
