import axios from "axios";

function getDefaultState() {
    return {
        projects: [],
        projectStyles: [],
        project: {},
        buildGlobalProjects: [],
        globalLibrariesData: [],
        globalModules: []
    }
}

const getters = {
    projects: state => state.projects,
    projectStyles: state => state.projectStyles,
    project: state => state.project,
    buildGlobalProjects: state => state.buildGlobalProjects,
    globalLibrariesData: state => state.globalLibrariesData,
    globalModules: state => state.globalModules,
}

const actions = {
    loadBuildGlobalProjects({ commit, state }, projectId) {
        return axios.get(`workspaces/projects/${projectId}?from_global_library=true`)
            .then(response => {
                commit('setBuildGlobalProjects', response.data);
            }).catch(e => {
                console.log(e);
            })
    },
    loadGlobalModulesData({ commit, state }, payload) {
        let hasProject = null;
        if(payload.project) {
            hasProject = `&project=${payload.project}`;
        }
        return axios.get(`workspaces/projects/${payload.id}/modules?${hasProject}`)
            .then(response => {
                commit("setGlobalModules", response.data);
                return response;
            })
            .catch(e => {
                //
            });
    },
    loadGlobalLibrariesData({ commit, state }, payload) {
        let hasProject = null;
        if(payload.project) {
            hasProject = `&project=${payload.project}`;
        }
        return axios.get(`workspaces/projects/${payload.id}/level-collections?level=${payload.level}${hasProject}`)
            .then(response => {
                commit("setLibraries", response.data);
                return response;
            })
            .catch(e => {
                //
            });
    },
    updateModule({ commit, state }, payload) {
      return axios.post(`modules/${payload.id}/update`, payload)
          .then(response => {
              //
          }).catch(e => {
              console.log(e);
          });
    },
    updateModuleSettings({ commit, state }, payload) {
        return axios.post(`modules/${payload.id}/settings`, payload)
            .then(response => {
                return response;
            })
            .catch(e => {
                //
            })
    },
    saveNewProjectStyle({ commit, state }, payload) {
        return axios.post(`projects/${payload.project_id}/new-style`, payload)
            .then(response => {
                return response;
            })
            .catch(e => {
                //
            })
    },
    loadStyles({ commit, state }, moduleNumber) {
        return axios.get(`projects/load/styles?moduleNumber=${moduleNumber}`)
            .then(response => {
                commit('setStyles', response.data);
                return response;
            })
            .catch(e => {
                //
            })
    },
    saveToAStyle({ commit, state }, payload) {
        return axios.post(`projects/load/styles`, payload)
            .then(response => {
                return response;
            })
            .catch(e => {
                //
            })
    },
    deleteStyle({ commit, state }, id) {
        return axios.delete(`projects/load/delete-style/${id}`)
            .then(response => {
                return response;
            })
            .catch(e => {
                //
            })
    },
    loadSavedStyle({ commit, state }, payload) {
        return axios.post(`projects/load/saves-style`, payload)
            .then(response => {
                return response;
            })
            .catch(e => {
                //
            })
    },
    getSolidObject({ commit, state }, id) {
        return axios.get(`projects/${id}?solid_object=true`)
            .then(({ data }) => {
                commit("setProject", data.data);
                return data.data;
            })
            .catch(e => {
                //
            })
    },
    duplicateProject({ commit, state }, payload) {
        return axios.post(`projects/duplicate/${payload.id}`, payload)
            .then(response => {
                return response;
            })
            .catch(e => {
                //
            })
    }
};

const mutations = {
    setProjects(state, value) {
        state.projects = value.data
    },
    setStyles(state, value) {
        state.projectStyles = value.data;
    },
    setProject(state, payload) {
        state.project = payload;
    },
    setBuildGlobalProjects(state, value) {
        state.buildGlobalProjects = value.data;
    },
    setLibraries(state, value) {
        state.globalLibrariesData = value.data;
    },
    setGlobalModules(state, value) {
        state.globalModules = value.data;
    }
};

export default {
    namespaced: true,
    state: getDefaultState,
    getters,
    actions,
    mutations
}
