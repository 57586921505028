<template>
    <svg width="40" height="40" viewBox="0 0 40 40" class="cursor-pointer" fill="none" xmlns="http://www.w3.org/2000/svg">
        <title>{{ title }}</title>
        <rect x="0.5" y="0.5" width="39" height="39" rx="3.5" :fill="activeRectFill" :stroke="activeStroke"/>
        <rect x="9.5" y="10.5" width="21" height="19" rx="1.5" :stroke="activeFill"/>
        <path d="M21.8974 16.1079C22.0413 15.964 22.2745 15.964 22.4184 16.1079L23.8921 17.5816C24.036 17.7255 24.036 17.9587 23.8921 18.1026L19.1026 22.8921C19.0335 22.9612 18.9398 23 18.8421 23H17.3684C17.1649 23 17 22.8351 17 22.6316V21.1579C17 21.0602 17.0388 20.9665 17.1079 20.8974L20.792 17.2132L21.8974 16.1079ZM21.0526 17.9947L17.7368 21.3105V22.2632H18.6895L22.0053 18.9474L21.0526 17.9947ZM22.5263 18.4263L23.1106 17.8421L22.1579 16.8894L21.5737 17.4737L22.5263 18.4263Z" :fill="activeFill"/>
    </svg>
</template>

<script setup>
    import useSVG from '@/composable/icons/useSVG';
    const props = defineProps({
        title: String,
        active: Boolean
    });
    const { activeFill, activeStroke,  activeRectFill} =  useSVG(props);
</script>