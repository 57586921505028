<template>
<svg class="inline" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8 1.5V11.5" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M3.5 7L8 11.5L12.5 7" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M3 13.5H13" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
</template>

<script>
export default {
    name: "Aa"
}
</script>
