<template>
    <svg width="40" height="40" viewBox="0 0 40 40" class="cursor-pointer" fill="none" xmlns="http://www.w3.org/2000/svg">
        <title>{{ title }}</title>
        <rect x="0.5" y="0.5" width="39" height="39" rx="3.5" :fill="activeRectFill" :stroke="activeStroke"/>
        <rect x="10.5" y="10.5" width="19" height="12" :stroke="activeFill" stroke-linecap="round"/>
        <line x1="13.5" y1="29.5" x2="26.5" y2="29.5" :stroke="activeFill"/>
        <line x1="20" y1="29" x2="20" y2="23" :stroke="activeFill"/>
        <path d="M20 16.1H22.9409L20.2625 19.3542L20.4962 17.4613L20.5655 16.9H20H17.0591L19.7375 13.6458L19.5038 15.5387L19.4345 16.1H20Z" :fill="activeFill" :stroke="activeFill" stroke-linecap="round"/>
    </svg>
</template>

<script setup>
    import useSVG from '@/composable/icons/useSVG';
    const props = defineProps({
        title: String,
        active: Boolean
    });
    const { activeFill, activeStroke,  activeRectFill} =  useSVG(props);
</script>