import axios from 'axios';
import route from '../../../routes/routes';

function getDefaultState() {
    return {
        workspaceModules: [],
        projectModules: [],
        selectedModule: '',
        libraryList: [],
        scenarios: [],
        scenarioCollections: [],
        activeScenarioCollection: {},
        collectionProps: {},
        propertyChangeable: false,
        visualCollections: [],
        selectedFilterProperties: [],
        filterMinValues: [],
        filterMaxValues: [],
        visualizationData: null
    }
}

const getters = {
    workspaceModules: state => state.workspaceModules,
    projectModules: state => state.projectModules,
    selectedModule: state => state.selectedModule,
    libraryList: state => state.libraryList,
    scenarios: state => state.scenarios,
    scenarioCollections: state => state.scenarioCollections,
    activeScenarioCollection: state => state.activeScenarioCollection,
    activeScenarioCollectionId: state => {
        if(state.activeScenarioCollection.id) {
            return state.activeScenarioCollection.id
        }
    },
    collectionProps: state => state.collectionProps,
    propertyChangeable: state => state.propertyChangeable,
    visualCollections: state => state.visualCollections,
    selectedFilterProperties: state => state.selectedFilterProperties,
    filterMinValues: state => state.filterMinValues,
    filterMaxValues: state => state.filterMaxValues,
}

const actions = {
    updateCollectionDate({ commit, state }, formData) {
        return axios.post('projects/scenarios/collection/multi-date-change', formData)
    },
    loadWorkspaceModules({ commit, state }, id) {
        return axios.get(`workspaces/${id}/modules`)
            .then(response => {
                commit("setWorkspaceModules", response.data);
                return response;
            })
            .catch(e => {
                //
            })
    },
    getModules({ commit, state }, id) {
        return axios.get(`modules/${id}/project?all=true`)
            .then(response => {
                commit("setModules", response.data);
                return response.data;
            })
            .catch(e => {
                // TODO
            });
    },
    toggleModuleSelection({ commit, state }, id) {
        state.selectedModule = id;
    },
    loadGlobalLibraries({ commit, state }, id) {
        return axios.get(`workspaces/${id}/collections`)
            .then(response => {
                commit("setLibraries", response.data);
                return response;
            })
            .catch(e => {
                //
            });
    },
    loadNonAdaptedList({ commit, state }, payload) {
      return axios.get(`projects/scenarios/${payload.project_id}/non-adapted/${payload.scenario_id}`)
          .then(response => {
              commit("setLibraries", response.data);
              return response;
          })
          .catch(e => {
              //
          })
    },
    loadTemplate({ commit, state }) {
        state.libraryList = [];
    },
    loadScenarios({ commit, state }, id) {
      return axios.get(`projects/scenarios/${id}`)
          .then(response => {
              commit("setScenarios", response.data);
          })
          .catch(e => {
              //
          });
    },
    storeScenario({ commit, dispatch, state }, payload) {
        return axios.post(`projects/scenarios`, payload, {'content-type': 'multipart/form-data'})
            .then(response => {
                // dispatch("loadScenarios", payload.project_id);
                return response;
            })
            .catch(e => {
                //
            });
    },
    updateScenario({ commit, dispatch, state }, payload) {
        return axios.post(`projects/scenarios/${payload.get('id')}/update`, payload)
            .then(response => {
                return response;
            })
            .catch(e => {
                //
            });
    },
    deleteScenario({ commit, state }, id) {
        return axios.delete(`projects/scenarios/${id}`)
            .then(response => {
                return response;
            })
            .catch(e => {
                //
            });
    },
    loadScenarioCollection({ commit, state }, payload) {
        return axios.get(`projects/scenarios/${payload.project_id}/scenario/${payload.scenario_id}`)
            .then(response => {
                if(!payload.is_return) {
                    commit("setScenarioCollections", response.data);
                }
                return response;
            })
            .catch(e => {
                //
            });
    },
    loadVisualizationData(_, projectId) {
        return axios.get(`projects/scenarios/${projectId}/scenario`)
    },
    updateCollection({ commit, state }, payload) {
        return axios.post(`projects/scenarios/${payload.scenario_id}/update/${payload.id}`, payload)
            .then(response => {
                return response;
            })
            .catch(e => {
                //
            })
    },
    deleteLevelData({ commit, dispatch, state }, payload) {
        return axios.delete(`projects/scenarios/${payload.scenario_id}/level-data/${payload.level_id}`)
            .then(response => {
                dispatch("loadScenarioCollection", payload);
                return response;
            })
            .catch(e => {
                //
            });
    },
    getScenarioProperties({ commit, state }, id) {
        return axios.get(`projects/scenarios/properties/${id}`)
            .then(response => {
                commit("setScenarioProperties", response.data);
                return response.data;
            })
            .catch(e => {
                // TODO
            });
    },
    getActiveScenarioCollection({ commit, state }, id) {
        return axios.get(`projects/scenarios/getActiveScenarioCollection/${id}`)
            .then(response => {
                commit("setActiveScenarioCollection", response.data);
                return response.data;
            })
            .catch(e => {
                // TODO
            });
    },
    storeDataFromLocalList({ commit, dispatch, state }, payload) {
        return axios.post(`projects/scenarios/${payload.scenario_id}/drag`, payload)
            .then(response => {
                // dispatch("loadScenarioCollection", payload);
                return response;
            })
            .catch(e => {
                //
            });
    },
    updatePropertyItem({ commit, dispatch, state }, payload) {
        return axios.post(`projects/scenarios/properties/updateCollectionItem`, payload)
            .then(response => {
                dispatch("loadScenarioCollection", payload);
                return response;
            })
            .catch(e => {
                //
            });
    },
    storeDataFromLibrary({ commit, dispatch, state }, payload) {
        return axios.post(`projects/scenarios/${payload.scenario_id}/drag`, payload)
            .then(response => {
                // dispatch("loadScenarioCollection", payload);
                if(payload.nonAdapted) {
                    dispatch("loadNonAdaptedList", payload);
                }
                return response;
            })
            .catch(e => {
                //
            });
    },
    reOrderItem({ commit, dispatch, state }, payload) {
        return axios.post(`projects/scenarios/${payload.scenario_id}/reorder`, payload)
            .then(response => {
                // if(payload.reload) {
                //     dispatch("loadScenarioCollection", payload);
                // }
                return response;
            })
            .catch(e => {
                //
            })
    },
    storeScenarioCollectionItem({ commit, dispatch, state }, payload) {
        return axios.post(`projects/scenarios/${payload.scenario_id}/store`, payload)
            .then(response => {
                if(response && response.data && response.data.status) {
                    dispatch("loadScenarioCollection", payload);
                }
                return response;
            })
            .catch(e => {
                //
            });
    },
    propertyChangeable({ commit, state }, status) {
        state.propertyChangeable = status;
    },
    getScenarioVisualData({ commit, state }, payload) {
        return axios.get(`projects/scenarios/${payload.project_id}/visual/${payload.scenario_id}`)
            .then(response => {
                commit("setVisualCollections", response.data);
                return response;
            })
            .catch(e => {
                console.log(e);
            });
    },
    addOrRemovesAssociation({ commit, dispatch, state }, payload) {
        return axios.post(`projects/scenarios/${payload.project_id}/visual/${payload.scenario_id}/associations`, payload)
            .then(response => {
                dispatch("getScenarioVisualData", payload);
                return response;
            })
            .catch(e => {
                console.log(e);
            });
    },
    getSelectedFilterProperties({ commit, state }, payload) {
        commit("setSelectedFilterProperties", payload);
    },
    getFilterMinValues({ commit, state }, payload) {
        commit("setFilterMinValues", payload);
    },
    getFilterMaxValues({ commit, state }, payload) {
        commit("setFilterMaxValues", payload);
    },
    duplicate({ commit, dispatch, state }, payload) {
        return axios.post(`projects/scenarios/duplicate/${payload.id}`, payload)
            .then(response => {
                dispatch("loadScenarios", payload.project_id);
            }).catch(e => {
                //
            });
    },
    addModule({ commit, dispatch, state }, payload) {
        return axios.post(`modules`, payload)
            .then(response => {
                return response;
            }).catch(e => {
                console.log(e);
            });
    },
    createScenarioByFilteredCollection(context, payload) {
        return axios.post('projects/scenarios/create/from-auto-filter', payload).then(response => {
            return response;
        });
    }
}

const mutations = {
    setVisualizationData(state, data) {
        state.visualizationData = data;
    },
    setWorkspaceModules(state, value) {
        state.workspaceModules = value.data;
    },
    setModules(state, value) {
        state.projectModules = value.data;
    },
    setLibraries(state, value) {
        state.libraryList = value.data;
    },
    setScenarios(state, value) {
        state.scenarios = value.data;
    },
    setScenarioCollections(state, value) {
        let oldScenarioCollections = state.scenarioCollections;
        if(oldScenarioCollections.length > 0) {
            value.data.forEach((item, index) => {
                item.expandedLevel = oldScenarioCollections[index].expandedLevel;
            });
        }
        state.scenarioCollections = value.data;
    },
    setScenarioProperties(state, value) {
        state.collectionProps = value.data;
    },
    setActiveScenarioCollection(state, value) {
        state.activeScenarioCollection = {};
        state.activeScenarioCollection = value.data;
    },
    setActiveBrainstormScenarioCollection(state, value) {
        state.activeScenarioCollection = {};
        state.activeScenarioCollection = value;
    },
    updateActiveItemParameterValue(state, payload) {
        if(state.activeScenarioCollection && state.activeScenarioCollection.properties) {
            let parameter = state.activeScenarioCollection.properties.find(item => item.property_collection_id == payload.property_collection_id);
            if(parameter) {
                parameter.sample_value = payload.sample_value;
            }
        }
    },
    setVisualCollections(state, value) {
        state.visualCollections = value.data;
    },
    setSelectedFilterProperties(state, value) {
        state.selectedFilterProperties[value.mIndex] = value.property_id;
    },
    setFilterMinValues(state, value) {
        state.filterMinValues[value.mIndex] = value.min;
    },
    setFilterMaxValues(state, value) {
        state.filterMaxValues[value.mIndex] = value.max;
    },
};

export default {
    namespaced: true,
    state: getDefaultState,
    getters,
    actions,
    mutations
}
