<template>
    <svg viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5 0V10M0 5H10" stroke="currentColor"/>
    </svg>
</template>

<script>
export default {
    name: "PlusIcon"
}
</script>
