<template>
    <div>
        <div
            class="flex flex-row mt-8 i-h-50 bld-level-w cursor-pointer"
            :class="{
              'hor-tiles-max':
              (expansionSelf == true && checkSelected == true) ||
              expansionGlobal == true,
              'cursor-pointer': checkSelected == false,
            }"
        >
            <div class="inputWrapper relative i-h-50 bld-level-w flex flex-col border-solid bg-div cursor-pointer border-color-transparent"
              :class="{
                'hor-tiles-max': ((expansionSelf === true && checkSelected === true) || expansionGlobal === true),
                'i-border-1 border-primary-one active': checkSelected || appearance == 1,
                'i-border-b-1 border-primary-one' : appearance == 2,
                'shadow-two' : appearance == 0,
                'rounded i-border-1' : appearance != 2,
                'border-secondary-five' : (appearance == 1 || appearance == 2) && !checkSelected,
                'border-primary-one rounded' : checkSelected
              }"
              :style="{'background': appearance == 0 ? getBg() : 'transparent' }"
              @click="toggleActive([area, 'area']), activePropertyOption()"
            >
              
              <div v-show="area.id == activeModuleCollection.id" class="action_items flex">
                <button @click="menuHandler($event, area)" title="Information">
                  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.000650114 6.66667C0.000650275 2.98477 2.98542 -4.5235e-07 6.66732 -2.91409e-07C10.3492 -1.30468e-07 13.334 2.98477 13.334 6.66667C13.334 10.3486 10.3492 13.3333 6.66732 13.3333C2.98709 13.3293 0.00469163 10.3469 0.000650114 6.66667ZM6.55265 1.33333C3.61849 1.36488 1.26095 3.76074 1.27665 6.69503C1.2925 9.62931 3.67565 11.9997 6.60999 11.9997C9.54431 11.9997 11.9275 9.62931 11.9433 6.69503C11.959 3.76074 9.60148 1.36488 6.66732 1.33333L6.55265 1.33333Z" fill="#8D8D8D"/>
                    <path d="M6.50431 5.072C6.26431 5.072 6.06698 5.00267 5.91231 4.864C5.76298 4.72 5.68831 4.544 5.68831 4.336C5.68831 4.12267 5.76298 3.94667 5.91231 3.808C6.06698 3.664 6.26431 3.592 6.50431 3.592C6.73898 3.592 6.93098 3.664 7.08031 3.808C7.23498 3.94667 7.31231 4.12267 7.31231 4.336C7.31231 4.544 7.23498 4.72 7.08031 4.864C6.93098 5.00267 6.73898 5.072 6.50431 5.072ZM7.18431 5.536V10H5.81631V5.536H7.18431Z" fill="#8D8D8D"/>
                  </svg>
                </button>
                <button @click="canCreate(project.cans)&&createNewData(area)" title="Create">
                  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.000650114 6.66667C0.000650275 2.98477 2.98542 -4.5235e-07 6.66732 -2.91409e-07C10.3492 -1.30468e-07 13.334 2.98477 13.334 6.66667C13.334 10.3486 10.3492 13.3333 6.66732 13.3333C2.98709 13.3293 0.00469163 10.3469 0.000650114 6.66667ZM6.55265 1.33333C3.61849 1.36488 1.26095 3.76074 1.27665 6.69503C1.2925 9.62931 3.67565 11.9997 6.60999 11.9997C9.54431 11.9997 11.9275 9.62931 11.9433 6.69503C11.959 3.76074 9.60148 1.36488 6.66732 1.33333L6.55265 1.33333ZM3.33398 7.33333L3.33398 6L6.00065 6L6.00065 3.33333L7.33398 3.33333L7.33398 6L10.0007 6L10.0007 7.33333L7.33398 7.33333L7.33398 10L6.00065 10L6.00065 7.33333L3.33398 7.33333Z" fill="#8D8D8D"/>
                  </svg>
                </button>
                <button title="Delete" @click="canDelete(project.cans)&&rmArea($event, area)">
                  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.000650114 6.66691C0.000650275 2.98501 2.98542 0.000243688 6.66732 0.000243849C10.3492 0.00024401 13.334 2.98501 13.334 6.66691C13.334 10.3488 10.3492 13.3336 6.66732 13.3336C2.98709 13.3295 0.00469163 10.3471 0.000650114 6.66691ZM6.55265 1.33358C3.61849 1.36512 1.26095 3.76098 1.27665 6.69527C1.2925 9.62956 3.67565 11.9999 6.60999 11.9999C9.54431 11.9999 11.9275 9.62956 11.9433 6.69527C11.959 3.76098 9.60148 1.36512 6.66732 1.33358L6.55265 1.33358ZM3.33398 7.33358L3.33398 6.00024L6.00065 6.00024L7.33398 6.00024L10.0007 6.00024L10.0007 7.33358L7.33398 7.33358L6.00065 7.33358L3.33398 7.33358Z" fill="#8D8D8D"/>
                  </svg>
                </button>
              </div>

              <div class="flex justify-start items-center i-pl-15 i-pr-15 cursor-pointer">
                  <h4
                    class="fs-13 fw-600 font-poppins text-primary-three i-pt-5"
                    :class="appearance == 0 ? 'text-primary-three' : 'text-secondary-one'"
                    v-show="indexTitle"
                  >
                    {{ getSymbolCode + numberedTitle }}
                  </h4>
                  <input
                      v-on:blur="$emit('close-menu')"
                      type="text"
                      :style="{ 'padding-left': indexTitle && (moduleSettings.index_id || moduleSettings.index_number)  ? '5px' : '0px' }"
                      @keyup="temporaryUpdate($event, area)"
                      @change="regularUpdate($event, area)"
                      v-model="area.title"
                      class="block w-full fw-600 focus:outline-none pl-2 bg-transparent fs-13 font-poppins text-primary-three i-mt-5 placeholder-secondary-five ellipse cursor-pointer"
                      :class="{
                          'py-2': (expansionSelf === true && checkSelected == true) || expansionGlobal == true,
                          'py-3': !((expansionSelf === true && checkSelected == true) || expansionGlobal == true),
                          'cursor-pointer': checkSelected,
                          'text-primary-three': appearance == 0,
                          'text-secondary-one': appearance != 0
                      }"
                      placeholder="Child"
                      :id="'l2_' + area.id"
                      autocomplete="off"
                      :disabled="!canEdit(project.cans)"
                  />
<!--                  <h3 v-if="!editingMode" @click="enableEditingMode()" class="block w-full fw-600 focus:outline-none pl-2 bg-transparent fs-13 font-poppins text-primary-three i-mt-5 placeholder-secondary-two cursor-pointer"-->
<!--                      :style="{ 'padding-left': indexTitle && (moduleSettings.index_id || moduleSettings.index_number)  ? '5px' : '0px' }"-->
<!--                      :class="{-->
<!--                        'py-2': (expansionSelf === true && checkSelected == true) || expansionGlobal == true,-->
<!--                        'py-3': !((expansionSelf === true && checkSelected == true) || expansionGlobal == true),-->
<!--                        'text-primary-three': appearance == 0,-->
<!--                        'text-secondary-one': appearance != 0-->
<!--                      }"-->
<!--                      :title="area.title">-->
<!--                    {{ area.title.length > 28 ? area.title.substr(0, 28) + '...' : area.title }}-->
<!--                  </h3>-->
              </div>
              <div
                  class="flex justify-start"
                  v-if="
                      (expansionSelf === true && checkSelected == true) ||
                      expansionGlobal == true
                  "
              >
                  <textarea
                      class="fs-12 cursor-text font-poppins text-primary-three i-pl-15 i-pr-15 w-full bg-transparent bld-txtarea mb-3"
                      :class="{
                        'cursor-pointer': checkSelected == false,
                        'text-primary-three bld-content-area': appearance == 0,
                        'text-secondary-one bld-content-area-alt': appearance != 0
                      }"
                      placeholder="Add descriptive text"
                      @keyup="temporaryUpdate($event, area)"
                      @change="regularUpdate($event, area)"
                      v-model="area.description"
                      :disabled="!canEdit(project.cans)"
                      style="margin-top: -6px;"
                      autocomplete="off"
                  >
                  </textarea>
              </div>
            </div>
        </div>
        <draggable
            :disabled="!canEdit(project.cans)"
            @start="changeTransition('flip-list')"
            v-bind="dragOptions"
            group="area_measure"
            :clone="cloneTheAreaMeasure"
            @end="updateStore($event), changeTransition('null')">
            <transition-group
                mode="out-in"
                :name="transName"
                :area-parent-data-id="area.id"
                :identifier-parent-id="identifier"
            >
                <area-measure-input-bar
                    v-for="(l3Collection, index) in area.child"
                    :key="l3Collection.id"
                    :index="index"
                    :area="area.id"
                    :identifier="identifier"
                    :areaMeasure="l3Collection"
                    :pNumberedTitle="numberedTitle"
                    :expansionSelf="
                        expansionSelf == true && checkSelected == true
                            ? true
                            : expansionSelf
                    "
                    :expansionGlobal="expansionGlobal"
                    :levelSelf="levelSelf"
                    :levelGlobal="levelGlobal"
                    :selected_item="selected_item"
                    :area-cmp-measure-data-id="l3Collection.id"
                    :data-id="l3Collection.id"
                    :viewer="viewer"
                    :appearance="appearance"
                    :indexTitle="indexTitle"
                    @add="addAreaMeasure"
                    @active="toggleActive"
                    @showCntxtmenu="$emit('showCntxtmenu')"
                    @activePropertyOption="activePropertyOption"
                >
                </area-measure-input-bar>
            </transition-group>
        </draggable>
    </div>
</template>

<script setup>
  import usePermission from '@/composable/usePermission.js'
  const { canCreate, canEdit, canDelete } = usePermission()
</script>

<script>
// import $ from "jquery";
import { mapActions, mapGetters } from "vuex";
import buildCommon from "../../common/build";
export default {
    data() {
        return {
            actionDiv: true,
            buildCommon: null,
            transName: null,
            levelColorCodes: [],
            editingMode: false
        };
    },
    props: [
        "identifier",
        "l1collection",
        "area",
        "index",
        "pNumberedTitle",
        "expansionSelf",
        "expansionGlobal",
        "levelSelf",
        "levelGlobal",
        "selected_item",
        "viewer",
        "appearance",
        "indexTitle",
        "showGroup"
    ],
    computed: {
        ...mapGetters({
            areaMeasuresList: "AreaMeasures/data",
            expansion: "GET_ELEMENT_DETAIL_STATUS",
            selectedLevel: "GET_SELECTED_LEVEL",
            activeModuleBg: "GET_CURRENT_MODULE_BG",
            moduleSettings: "build/currentSettings",
            activeModuleCollection: "build/activeModuleCollection",
            colorCodes: "global/colorCodes",
            project: "project/project"
        }),
        getSymbolCode() {
            let settings = this.moduleSettings.collections[2]
            let showCode = this.moduleSettings.index_id
            let code = showCode ? settings.index_id : ''
            return code
        },
        numberedTitle() {
            let showNumber = this.moduleSettings.index_number
            let title = showNumber ? this.pNumberedTitle +'.'+ (this.index + 1) : ''
            return title;
        },
        loadChilds() {
            const self = this;
            return this.$store.getters["AreaMeasures/data"].filter(function (
                el
            ) {
                return parseInt(el.parent) === parseInt(self.area.id);
            });
        },
        checkSelected() {
            if (
                this.selected_item[1] == "area" &&
                this.selected_item[0].id == this.area.id
            ) {
                return true;
            }
            return false;
        },
      dragOptions() {
        return {
          animation: 300,
          // group: "description",
          disabled: this.viewer,
          ghostClass: "ghost",
          class: 'cursor-default'
        };
      },
    },
    methods: {
        ...mapActions({
            storeAreaMeasure: "AreaMeasures/storeData",
            updateAreaMeasureParent: "AreaMeasures/updateParent",
            destroyAreaMeasure: "AreaMeasures/destroyData",
            storeMeasure: "Measures/storeData",
            destroyMeasure: "Measures/destroyData",
            topSelectedButton: "GET_IDENTIFIER_TOP_OPTION",
        }),
        menuHandler(e, item) {
          if(!this.viewer) {
            item.top = e.clientY
            item.left = e.clientX
            this.$store.dispatch('build/getContxtmenuCollection', item)
            this.$emit('showCntxtmenu')
          }
        },
        enableEditingMode() {
          const self = this;
          setTimeout(function () {
            self.editingMode = true;
            let itemId = self.area.id;
            setTimeout(function () {
              let element = document.getElementById('l2_' + itemId);
              if(element) {
                element.focus();
                element.click();
              }
            }, 100);
          }, 100);
        },
        enteringNonEditableMode() {
          this.editingMode = false;
        },
        activePropertyOption() {
          this.$emit('activePropertyOption');
        },
        getLevelWiseColorCode(level) {
          let indexLevel = level - 1;
          return this.colorCodes.slice((indexLevel * 9), ((indexLevel * 9) + 9));
        },
        convertInt(int, length) {
            var len = length - ("" + int).length;
            return (len > 0 ? new Array(++len).join("0") : "") + int;
        },
        toggleActive(arr) {
            this.$emit("active", arr);
            // this.$store.dispatch("build/getProperties", arr[0]);
            // this.$store.dispatch("build/getActiveModuleCollection", arr[0])
        },
        changeTransition(value) {
            return (this.transName = value);
        },
        rmArea(e, area) {
            if(this.viewer) {
                return;
            }
            if (this.l1collection.child.length <= 1) {
              alert('At least one second level item must exist!')
              return false
            }
            this.$store.dispatch("build/deleteItem", area);
        },
        addAreaMeasure() {
            // this.changeTransition("slide");

            let newAreaMeasure = {
                title: "Area Measure",
                parent: this.area.id,
            };
            this.storeAreaMeasure(newAreaMeasure);

            let newMeasure = {
                title: "Measure",
                parent: this.$store.getters["AreaMeasures/entry"].id,
            };
            this.storeMeasure(newMeasure);

            // $("#scroller" + this.area.id).animate(
            //     {
            //         scrollTop: $("#scroller" + this.area.id)[0].scrollHeight,
            //     },
            //     800
            // );
        },
        cloneTheAreaMeasure({ id }) {
            let index = this.areaMeasuresList.findIndex(
                (areameasure) => areameasure.id === id
            );
            if (index >= 0) {
                return this.areaMeasuresList[index];
            }
        },
        createNewData(obj) {
            if(this.viewer) {
                return;
            }
            let itemObj = Object.assign({}, obj);
            let title = this.moduleSettings.collections[2].title;
            this.$Progress.start();
            itemObj.from_right_side = false;
            itemObj.title = title;
            itemObj.item_order = itemObj.order + 1;
            itemObj.relation = {
                  l1: this.identifier
              };
            this.$store.dispatch("build/storeData", itemObj).then(response => {
              this.$Progress.finish();
            });
        },
        updateStore(e) {
          this.$Progress.start();
          let oldParent = e.from.getAttribute("area-parent-data-id");
          let newParent = e.to.getAttribute("area-parent-data-id");
          let itemId = e.item.getAttribute("area-cmp-measure-data-id");
          let data = {
            parent_id: parseInt(newParent),
            old_parent: parseInt(oldParent),
            id: parseInt(itemId),
            index: e.newIndex,
            old_index: e.oldIndex,
          };

          this.$store.dispatch("build/reOrderItem", data).then(response => {
            this.$Progress.finish();
          });
        },
        temporaryUpdate(e, obj) {
            if(this.viewer) {
                return;
            }
            if (e.keyCode !== 46) {
              this.$store.dispatch("build/temporaryUpdate", obj);
            }
        },
        regularUpdate(e, obj) {
          if(this.viewer) {
            return;
          }
          if (e.keyCode !== 46) {
            let item = {
              title: obj.title,
              description: obj.description,
              id: obj.id
            };
            this.$store.dispatch("build/regularUpdate", item);
          }
        },
        getBg() {
          if (this.area.background_color) {
            return this.area.background_color
          }
          let settings = this.moduleSettings.collections[2];
          if (settings) {
            if(settings.custom_bg) {
              return settings.custom_bg;
            }
            return this.levelColorCodes[settings.color];
          }
          return "";
        },
    },
    created() {
      this.buildCommon = buildCommon;
      this.levelColorCodes = this.getLevelWiseColorCode(2);
    }
};
</script>

<style scoped>
  .action_items{
    gap: 5px;
    visibility: hidden;
    position: absolute;
    top: 100%;
    left: 0;
    padding: 6px 0;
    z-index: 10;
  }
  .inputWrapper:is(.active) .action_items{
    visibility: visible;
  }

/* animation for  flip area  */
.flip-list-move {
  transition: transform 0.5s;
}

.flip-list-leave-active {
  display: none;
}

.no-move {
  transition: transform 0s;
}

.ghost {
  opacity: 0.3 !important;
}

.list-group {
  min-height: 20px;
}

.list-group-item {
  /*cursor: move;*/
}

.list-group-item i {
  cursor: pointer;
}

.slide-leave-active,
.slide-enter-active {
  transition: 0.4s;
}

.slide-enter {
  opacity: 0 !important;
  transform: translate(0%, -100%);
}

.slide-leave-to {
  opacity: 0 !important;
  transform: translate(0%, -100%);
}

/* animation for  slide area  */
.fade-leave-active,
.fade-enter-active {
  transition: 0.5s;
}

.fade-enter {
  opacity: 0;
}

.fade-leave-to {
  opacity: 0;
}
</style>

