import axios from 'axios';
function getDefaultState() {
    return {
        assigns: [],
    }
}

const getters = {
    getAssigns: (state) => (widgetId) => {
        state.assigns[widgetId]
    },
    getAssign: (state) => (widgetId, collectionId) => {
        if(!state.assigns.length) return []
        return state.assigns.filter(assign => {
            if(assign.roadmap_widget_id == widgetId && assign.scenario_collection_id == collectionId) return assign
        })
    }
}

const actions = {
    async fetchAssigns({ commit, state }, widgetId) {
        let response = await axios.get('roadmaps/timelines/'+widgetId)
        commit("setAssigns", response.data);
    },

    addAssign({commit, dispatch}, payload) {
        // commit('addRemoveAssign', payload)
        axios.post('roadmaps/timelines', payload)
        .then(res => {
            commit('addRemoveAssign', payload)
        })
    }
};

const mutations = {
    setAssigns(state, payload) {
        state.assigns = [...payload.data, ...state.assigns];
    },

    resetAssign(state) {
        state.assigns = []
    },

    addRemoveAssign(state, data) {
        if(!state.assigns.length) {
            state.assigns.push(data)
            return
        }
        let assignOfClickedIndex
        let allAssignAgainstWidgetAndCollection = state.assigns.filter(i => i.scenario_collection_id == data.scenario_collection_id && i.roadmap_widget_id == data.roadmap_widget_id)
        if(allAssignAgainstWidgetAndCollection.length) {
          assignOfClickedIndex = allAssignAgainstWidgetAndCollection.find(i => i.assign_index == data.assign_index)
        }
        if(!allAssignAgainstWidgetAndCollection.length) {
            state.assigns.push(data)
            return
        }
        if(data.status && assignOfClickedIndex) {
            assignOfClickedIndex.status = 1
        }else {
            state.assigns.push(data)
        }
        if(!data.status && assignOfClickedIndex) {
            assignOfClickedIndex.status = 0
        }
    }
   
};

export default {
    namespaced: true,
    state: getDefaultState,
    getters,
    actions,
    mutations
}
