<template>
  <div
      class="flex items-start justify-start"
      :class="{
          'idnt-fullscreen-tiles-height': topSelectedButton === 'full_screen',
          'bld-minimize-bar': !showGroup
      }"
      @click="unselect($event)"
  >
    <div
      class="flex flex-col justify-between w-10 cursor-pointer bld-shadow-height"
      :class="{
        'input-area-max-scrollable-height bld-full-shadow-height':
        topSelectedButton === 'full_screen',
      }"
    >
      <div class="relative flex flex-col items-center shadow-one rounded bg-primary-three mb-1 bld-vertical-tile" :class="{'bld-full-vertical-tile': topSelectedButton === 'full_screen'}">
        <img
          src="/images/icons/build/expand.svg"
          alt="expand"
          @click="toggleExpansion"
          class="cursor-pointer icon-24 opacity-40 i-mt-15 i-icon"
          :class="{'opacity-100': expansionSelf === true || expansionGlobal === true}"
        />
        <div class="flex flex-col items-center i-mt-15" @click="changeLevel">
          <img
            v-if="levelSelf === 1"
            src="/images/icons/build/level_1.svg"
            alt=""
            class="icon-24 i-icon"
          />
          <img
            v-else-if="levelSelf === 2"
            src="/images/icons/build/level_2.svg"
            alt=""
            class="icon-24 i-icon"
          />
          <img
            v-else-if="levelSelf === 3"
            src="/images/icons/build/level_3.svg"
            alt=""
            class="icon-24 i-icon"
          />
        </div>
        <div
          style="overflow: hidden"
          class="bld-l1-bar w-full"
          :class="{'bld-full-l1-bar': topSelectedButton === 'full_screen'}"
          @click="
            $emit('click');
            changeTransition('null');
            groupToggle();
          "
        >
          <h3
            :class="[topSelectedButton === 'full_screen' ? 'bld-item-vertical-text-long' : 'bld-item-vertical-text-short']"
            class="transform absolute -rotate-90 font-poppins fs-14 justify-end text-secondary-one ellipse"
            :title="identifier.title"
          >
            {{ getSymbolCode + numberedTitle + ' ' + identifier.title }}
          </h3>
        </div>
      </div>
    </div>
    <transition :name="areaTransitionName" mode="out-in">
      <div
        class="flex flex-col input-area"
        :class="{
            'input-area-max-scrollable-height':
            topSelectedButton === 'full_screen',
        }"
      >
        <div
            class="open-menu"
            :class="{
              'hor-tiles-max':
                (expansionSelf === true &&
                    selectedActive[1] == 'identifier') ||
                expansionGlobal == true,
              'i-h-50':
                expansionSelf === false && expansionGlobal == false,
            }"
        >
          <identifier-input-bar
              :expansionSelf="expansionSelf"
              :expansionGlobal="expansionGlobal"
              :identification="identification"
              :identifier="identifier"
              :index="index"
              :codeTitle="getSymbolCode"
              :numberedTitle="numberedTitle"
              :selected_item="selectedActive"
              :data-id="identifier.id"
              :appearance="appearance"
              :indexTitle="indexTitle"
              @add="$emit('add')"
              @remove="$emit('remove')"
              @active="toggleActive"
              :contextmenu="menuHandler"
              @activePropertyOption="activePropertyOption"
              class="identifier-input"
              :style="{
                width: showGroup ? '275px' : '0px',
                opacity: showGroup ? 1 : 0,
              }"
              :class="{
                'i-ml-30': showGroup,
                'ml-0': !showGroup,
                'hor-tiles-max':
                (expansionSelf === true &&
                    selectedActive[1] == 'identifier') ||
                expansionGlobal == true,
              }"
              :viewer="viewer"
              ref="parentInputBar"
          />
        </div>

        <draggable
          :disabled="!canEdit(project.cans)"
          v-model="identifier.child"
          group="area"
          @start="changeTransition('flip-list')"
          @end="changeTransition('null'), updateStore($event)"
          v-bind="dragOptions"
          class="scrollable bld-level-scroll overflow-y-auto"
          :class="{
            'input-area-max-scrollable-height':
            topSelectedButton === 'full_screen',
            'bld-expand-level-scroll': expansionSelf || expansionGlobal,
            'except-first-level-scroll': checkChildExpand,
            'bld-full-level-scroll': topSelectedButton === 'full_screen',
            'bld-full-expand-level-scroll': (expansionSelf || expansionGlobal) && topSelectedButton === 'full_screen',
            'except-first-level-scroll-full': checkFullScreenChildExpand
          }"
          :id="'scroller' + identifier.id"
        >
          <transition-group
            mode="out-in"
            :name="areaTransitionName"
            :data-id="identifier.id"
          >
            <area-input-bar
                class="list-group-item area open-menu"
                :class="
                  levelSelf == 1 &&
                  expansionSelf == false &&
                  expansionGlobal == true
                      ? 'area-horizon-height'
                      : ''
                "
                v-for="(l2Collection, index) in identifier.child"
                :key="l2Collection.id"
                :area="l2Collection"
                :identifier="identifier.id"
                :index="index"
                :pNumberedTitle="numberedTitle"
                :expansionSelf="expansionSelf"
                :expansionGlobal="expansionGlobal"
                :levelGlobal="levelGlobal"
                :levelSelf="levelSelf"
                :selected_item="selectedActive"
                :showGroup="showGroup"
                :appearance="appearance"
                :indexTitle="indexTitle"
                :l1collection="identifier"
                @active="toggleActive"
                @showCntxtmenu="$emit('showCntxtmenu')"
                @activePropertyOption="activePropertyOption"
                :style="{
                  width: showGroup ? '275px' : '0px',
                  opacity: showGroup ? 1 : 0,
                  'margin-left': '20px',
                  height: 'auto',
                }"
                ref="areaInputBar"
                :area-data-id="l2Collection.id"
                :data-id="l2Collection.id"
                :area-data-index="index"
                :viewer="viewer"
            />
            <div
                class="list-group-item area i-mt-25"
                :style="{
                    width: showGroup ? '275px' : '0px',
                    opacity: showGroup ? 1 : 0,
                    'margin-left': '25px',
                    height: '50px',
                }"
                v-for="(item) in {i: 1}" :key="item" :class="{'d-none' : identifier.child.length > 0 }">
            </div>
          </transition-group>
        </draggable>
      </div>
    </transition>
  </div>
</template>

<script setup>
  import usePermission from '@/composable/usePermission.js'
  const { canEdit } = usePermission()
</script>

<script>
  import $ from "jquery";
  import {mapActions, mapGetters} from "vuex";
  import buildCommon from "../../common/build";

  export default {
    created() {
      this.buildCommon = buildCommon;
      this.levelColorCodes = this.getLevelWiseColorCode(0);
    },
    data() {
      return {
        buildCommon: null,
        showGroup: false,
        showNumber: true,
        expansion: this.$store.getters.GET_ELEMENT_DETAIL_STATUS,
        expansionSelf: false,
        expansionGlobal: false,
        levelSelf: 1,
        levelGlobal: 1,
        selectedActive: [],
        areaCounts: this.identifier.child.length,
        areaTransitionName: null,
        minArea: 1,
        maxArea: 5,
        levelColorCodes: []
      };
    },
    props: ["identification", "identifier", "index", "viewer", "module_id", "appearance", "indexTitle"],
    computed: {
      ...mapGetters({
        topSelectedButton: "GET_IDENTIFIER_TOP_OPTION",
        selectedLevel: "GET_SELECTED_LEVEL",
        areasList: "Areas/data",
        areaMeasuresList: "AreaMeasures/data",
        measuresList: "Measures/data",
        activeModuleBg: "GET_CURRENT_MODULE_BG",
        moduleSettings: "build/currentSettings",
        brainstorms: "build/brainstorms",
        colorCodes: "global/colorCodes",
        project: "project/project",
        activeModuleCollection: "build/activeModuleCollection",
      }),
      dragOptions() {
        return {
          animation: 300,
          // group: "description",
          disabled: this.viewer,
          ghostClass: "ghost",
          class: 'cursor-default'
        };
      },
      getSymbolCode() {
        let settings = this.moduleSettings.collections[1]
        let showCode = this.moduleSettings.index_id
        let code = showCode ? settings.index_id : ''
        return code
      },
      numberedTitle() {
        let settings = this.moduleSettings.collections[1]
        let showNumber = this.moduleSettings.index_number
        let title = ''
        let index_number = settings.index_number
        let formated_index_number = parseInt(index_number) + this.index

        if (showNumber) {
          title = title + formated_index_number
        }

        title = showNumber ? title : '';

        return title;
      },
      loadChilds() {
        const self = this;
        return this.$store.getters["Areas/data"].filter(function (el) {
          return parseInt(el.parent) === parseInt(self.identifier.id);
        });
      },
      checkChildExpand() {
        if(this.topSelectedButton !== 'full_screen' && (this.expansionSelf || this.expansionGlobal) && !this.activeModuleCollection) {
          if(this.activeModuleCollection.id !== this.identifier.id) {
            return true;
          }
        }
        return false;
      },
      checkFullScreenChildExpand() {
        if(this.topSelectedButton === 'full_screen' && (this.expansionSelf || this.expansionGlobal)) {
          if(this.activeModuleCollection.id !== this.identifier.id) {
            return true;
          }
        }
        return false;
      }
    },
    watch: {
      brainstorms: function (val) {
        this.withBrainstorm(val)
      },
    },
    methods: {
      activePropertyOption() {
        this.$emit('activePropertyOption');
      },
      getLevelWiseColorCode(level) {
        let indexLevel = level - 1;
        return this.colorCodes.slice((indexLevel * 9), ((indexLevel * 9) + 9));
      },
      toggleExpansion() {
        let selected = this.selectedActive;
        if (selected.length > 0 && selected[1] != "identifier") {
          this.expansionGlobal = false;
          this.expansionSelf = !this.expansionSelf;
        } else {
          this.expansionGlobal = !this.expansionGlobal;
          this.expansionSelf = false;
        }

        let value = {
          index: this.index,
          expanded:  this.expansionGlobal
        };
        this.$emit("toggleExpansion", value);
      },
      toggleActive(arr) {
        this.selectedActive = arr;
        this.levelGlobal = 1;
        this.$emit('toggleToBottomItemSelection', this.selectedActive);
        this.$store.dispatch("build/getActiveModuleCollection", arr[0]);
        this.$store.commit("programmatic/setActiveBrainstormScenarioCollection", arr[0])
      },
      toggleNumber(command) {
        command == "Show"
            ? (this.showNumber = true)
            : (this.showNumber = false);
      },
      convertInt(int, length) {
        var len = length - ("" + int).length;
        return (len > 0 ? new Array(++len).join("0") : "") + int;
      },
      changeTransition(value) {
        return (this.areaTransitionName = value);
      },
      updateStore(e) {
        this.$Progress.start();
        let oldParent = e.from.getAttribute("data-id");
        let newParent = e.to.getAttribute("data-id");
        let itemId = e.item.getAttribute("area-data-id");

        let data = {
          parent_id: parseInt(newParent),
          old_parent: parseInt(oldParent),
          id: parseInt(itemId),
          index: e.newIndex,
          old_index: e.oldIndex
        };

        this.$store.dispatch("build/reOrderItem", data).then(response => {
          this.$Progress.finish();
        });
      },
      cloneTheArea({id}) {
        let index = this.areasList.findIndex((area) => area.id === id);
        if (index >= 0) {
          return this.areasList[index];
        }
      },
      groupToggle() {
        this.showGroup = !this.showGroup;
      },
      setMenu(top, left) {
        this.menuOptions.top = top + "px";
        this.menuOptions.left = left + 5 + "px";
      },
      changeLevel() {
        let lower = 1;
        let higher = 3;
        let selected = this.selectedActive;
        if (selected.length > 0 && selected[1] != "identifier") {
          this.levelGlobal = 1;
          if (this.levelSelf < higher) {
            this.levelSelf++;
          } else {
            this.levelSelf = lower;
          }
        } else {
          if (this.levelGlobal < higher && this.levelSelf < higher) {
            // console.log(this.levelSelf);
            this.levelGlobal++;
            this.levelSelf++;
          } else {
            this.levelGlobal = lower;
            this.levelSelf = lower;
          }
        }

        // console.log(this.levelSelf, this.levelGlobal);
      },
      unselect(e) {
        let clicked = null;
        if (e.target.classList.contains("scrollable")) {
          clicked = null;
        } else if (e.target.classList["value"] == "") {
          clicked = null;
        } else {
          clicked = "selected";
        }
        // console.log(clicked);

        if (clicked == null) {
          this.selectedActive = [];
          this.levelSelf = 1;
          this.levelGlobal = 1;
        }
      },
      getBg() {
        let settings = this.moduleSettings.collections[1];
        if (settings) {
          return settings.color;
        }
        return "";
      },
      withBrainstorm(vals) {
        this.dropdownItems[0].subitems.splice(2)
        vals.forEach(element => {
          let data = {}
          data.id = element.id
          data.title = element.title
          data.border = true
          data.subitems = []
          this.dropdownItems[0].subitems.push(data)
        });
      },
      menuHandler(e, item) {
        if(!this.viewer) {
          item.top = e.clientY
          item.left = e.clientX
          this.$emit('showCntxtmenu')
          this.$store.dispatch('build/getContxtmenuCollection', item)
        }
      },
      setMasterView() {
        this.$emit('resetView')
      }
    },
  };
</script>

<style>
.input-area,
.list-group-item,
.identifier-input {
  -webkit-transition: width 0.4s ease-in-out, opacity 0.4s ease-in-out,
  margin 0.4s ease-in-out;
  -moz-transition: width 0.4s ease-in-out, opacity 0.4s ease-in-out,
  margin 0.4s ease-in-out;
  -o-transition: width 0.4s ease-in-out, opacity 0.4s ease-in-out,
  margin 0.4s ease-in-out;
  transition: width 0.4s ease-in-out, opacity 0.4s ease-in-out,
  margin 0.4s ease-in-out;
}

/* animation for  flip area  */
.flip-list-move {
  transition: transform 0.5s;
}

.flip-list-leave-active {
  display: none;
}

.no-move {
  transition: transform 0s;
}

.ghost {
  opacity: 0.3 !important;
}

.list-group {
  min-height: 20px;
}

.list-group-item {
  /*cursor: move;*/
}

.list-group-item i {
  cursor: pointer;
}

.slide-leave-active,
.slide-enter-active {
  transition: 0.4s;
}

.slide-enter {
  opacity: 0 !important;
  transform: translate(0%, -100%);
}

.slide-leave-to {
  opacity: 0 !important;
  transform: translate(0%, -100%);
}

/* animation for  slide area  */
.fade-leave-active,
.fade-enter-active {
  transition: 0.5s;
}

.fade-enter {
  opacity: 0;
  /* transform: translate(100%, 0%); */
}

.fade-leave-to {
  opacity: 0;
  /* transform: translate(100%, 0%); */
}

.scrollable::-webkit-scrollbar {
  display: none;
}

/* brainstorm menu */
.brainstorm-menu {
  background: #1b1b1d;
  box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  width: 180px;
  z-index: 10;
}

.brainstorm-menu .bs-li {
  opacity: 0.8;
  padding: 10px 0px 10px 20px;
}

.brainstorm-menu .bs-li:hover {
  opacity: 1;
}

.brainstorm-menu .bs-li-border-bottom {
  border-bottom: 0.1px solid rgb(250, 253, 253, 0.2);
}
</style>
