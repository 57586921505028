import Vue from 'vue'
import Vuex from 'vuex'
import module_selection from "@/store/modules/programmatic/module_selection";
import prog_popup from "@/store/modules/programmatic/popup";
import auth from '@/store/modules/auth/index';
import build from '@/store/modules/build/index';
import workspace from '@/store/modules/workspace/index';
import profile from '@/store/modules/profile/index';
import project from '@/store/modules/project/index';
import manageProperty from '@/store/modules/property_manage/index';
import insight from '@/store/modules/insight/index';
import association from "@/store/modules/association/index";
import programmatic from "@/store/modules/programmatic/index";
import assessment from "@/store/modules/assessment/index";
import survey from "@/store/modules/survey/index";
import global from "@/store/modules/global/index";
import newsletter from "@/store/modules/newsletter/index";
import notification from "@/store/modules/notification/index";
import brainstormv1 from "@/store/modules/brainstorm/index";
import concept from "@/store/modules/concept/index";
import socket from "@/store/modules/socket/index";
import intel from "@/store/modules/intel/index";
import roadmap from "@/store/modules/roadmap/index";
import timeline from "@/store/modules/timeline/index";
import people from "@/store/modules/people/index";
import definition from "@/store/modules/definition/index";
import communicator from "@/store/modules/communicator/index";
import communicatorMaster from "@/store/modules/communicator/master";
import identification_option from "@/store/modules/Identifications/identification_option";

import drawer from "@/store/modules/rightNavDrawer/index";
import dashboard from './modules/dashboard'
import stickyNote from './modules/stickyNote'

// Root states
import mutations from './mutations';
import actions from './actions'
Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    identification_option,
    module_selection,
    prog_popup,
    auth,
    build,
    workspace,
    profile,
    project,
    manageProperty,
    insight,
    association,
    programmatic,
    assessment,
    survey,
    global,
    newsletter,
    notification,
    brainstormv1,
    concept,
    socket,
    intel,
    roadmap,
    timeline,
    people,
    definition,
    communicator,
    drawer,
    communicatorMaster,
    dashboard,
    stickyNote
  },
  state: () => ({
    loading: false,
    moduleHeights: [],
    showRightBar: false,
    isRightNavBarExpanded: false
  }),
  getters: {
    GET_LOADING_STATE: function (state) {
      return state.loading;
    },
    getModuleHeight: (state) => (moduleTitle) => {
      let item = state.moduleHeights.find(height => height.title == moduleTitle)
      if(item) {
        return item.height
      }
    },
    getRightBarVisibility: state => state.showRightBar,
    getIsRightBarExpanded: state => state.isRightNavBarExpanded,
  },
  mutations,
  actions
})
