import axios from 'axios';

function getDefaultState() {
    return {
        labels: [],
        people: []
    }
}

const getters = {
    getPeople: state => state.people,
    getLabels: (state) => (widgetId) => {
        if(!state.labels.length) {return []}
        return state.labels.filter(label => label.roadmap_widget_id == widgetId)
    }
}

const actions = {
    getLabels({ commit }, widgetId) {
        return axios.get(`roadmaps/peoples/labels/${widgetId}`)
            .then(response => {
                commit("removePreviousLabels", widgetId)
                commit("setLabels", response.data.data);
                return response;
            }).catch(e => {
                //
            });
    },

    createLabel({ commit, dispatch }, data) {
        return axios.post('roadmaps/peoples/labels/store', data)
            .then(response => {
                dispatch("getLabels", data.roadmap_widget_id);
                return response;
            }).catch(e => {
                //
            });
    },

    deleteLabel({ commit, dispatch }, data) {
        return axios.delete('roadmaps/peoples/labels/'+data.id)
            .then(response => {
                dispatch("getLabels", data.widgetId);
                return response;
            }).catch(e => {
                //
            });
    },

    updateLabel({ commit }, data) {
        return axios.post(`roadmaps/peoples/labels/${data.id}/update`, data)
            .then(response => {
                return response;
            }).catch(e => {
                //
            });
    },
    savePeople({ commit, state }, payload) {
        return axios.post(`roadmaps/peoples`, payload);
    },
    uploadPeopleImage(context, payload) {
        return axios.post(`roadmaps/peoples/update/image`, payload);
    },
    getPeoples({ commit, state }, widgetId) {
        return axios.get(`roadmaps/peoples/${widgetId}`);
    },
    updatePeople({commit, state}, {peopleId, payload}) {
        return axios.post(`roadmaps/peoples/${peopleId}`, payload);
    },
    getResponsible({ commit }, projectId) {
        axios.get(`workspaces/projects/${projectId}/responsibles`)
        .then(res => {
            commit('setPeople', res.data.data)
        })
    }
};

const mutations = {
    setLabels: (state, data) => state.labels = [...state.labels, ...data],
    setPeople: (state, data) => state.people = data,
    removePreviousLabels:(state, widgetId) => state.labels = state.labels.filter(label => label.roadmap_widget_id != widgetId)
};

export default {
    namespaced: true,
    state: getDefaultState,
    getters,
    actions,
    mutations
}
