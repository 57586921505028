export default {
    'projectDashboard': {
        projectKey: 'projectId'
    },
    'buildVisualization': {
        projectKey: 'projectId'
    },
    'scenarioVisualization': {
        projectKey: 'projectId'
    },
    'roadmapVisualization': {
        projectKey: 'projectId'
    },
    'identification.build': {
        projectKey: 'id'
    },
    'programmatic.scenario': {
        projectKey: 'id'
    },
    'programmatic.scenario.view': {
        projectKey: 'id'
    },

    'roadmap': {
        projectKey: 'id'
    },
    'roadmap.show': {
        projectKey: 'id'
    },
    'definition': {
        projectKey: 'id'
    },
    'definition.show': {
        projectKey: 'id'
    },
    'intel.assess': {
        projectKey: 'id'
    },
    'intel.assess.edit': {
        projectKey: 'id'
    },
    'build.properties': {
        projectKey: 'prId'
    },
    // 'workspace_settings': {
    //     projectKey: 'id',
    //     query: true
    // }
}

