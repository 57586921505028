<template>
    <svg viewBox="0 0 20 13" :fill="isFill" xmlns="http://www.w3.org/2000/svg">
      <path d="M-1.22397e-05 0.000404611L12.7171 0.000405714L12.7171 2.10239L-1.2425e-05 2.10239L-1.22397e-05 0.000404611ZM-1.27029e-05 5.25536L19.0757 5.25536L19.0757 7.35735L-1.28882e-05 7.35735L-1.27029e-05 5.25536ZM-1.31661e-05 10.5103L12.7171 10.5103L12.7171 12.6123L-1.33514e-05 12.6123L-1.31661e-05 10.5103Z" stroke="currentColor"/>
    </svg>
</template>

<script>
export default {
  name: "MenuAlt",
  props: {
    solid: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isFill() {
      return this.solid ? 'currentColor': 'none'
    }
  }
}
</script>

<style scoped>

</style>
