import axios from 'axios';

export default {
    fetchConcepts: ({state, commit }, projectId) => {
        axios.get(`concepts/${projectId}`)
        .then(res => {
            commit('setConcepts', res.data.data)
        })
    },
    //intel configuration
    fetchBrainstorms: ({state, commit }, projectId) => {
        axios.get(`brainstorms/intels/${projectId}`)
        .then(res => {
            commit('setBrainstorms', res.data.data)
        })
    },

    fetchProject: ({state, commit }, projectId) => {
        axios.get(`projects/${projectId}`)
        .then(res => {
            commit('setProject', res.data.data)
        })
    },

    fetchScenarios: ({state, commit }, projectId) => {
        axios.get(`projects/scenarios/${projectId}`)
        .then(res => {
            commit('setScenarios', res.data.data)
        })
    },

    fetchComments: ({state, commit }, payload) => {
       axios.get(`projects/comments/${payload.id}?key=${payload.key}`)
        .then(res => {
            commit('setComments', res.data.data)
        })
    },

    storeComment: ({state, commit }, payload) => {
        return axios.post(`projects/comments`, payload)
        .then(res => {
            return res;
        })
    },

    fetchAssessmentsComments: ({state, commit }, scenarioCollectionId) => {
        axios.get(`projects/assessments/assessment-comments/${scenarioCollectionId}`)
        .then(res => {
            commit('setAssessmentComments', res.data.data)
        })
    },

    fetchAssessmentsInsights: ({state, commit }, scenarioCollectionId) => {
        axios.get(`projects/assessments/assessment-insights/${scenarioCollectionId}/scenario-collection`)
        .then(res => {
            commit('setAssessmentInsights', res.data.data)
        })
    },

    fetchAssociations: ({state, commit }, scenarioCollectionId) => {
        axios.get(`associations/${scenarioCollectionId}`)
        .then(res => {
            commit('setAssociations', res.data.data)
        })
    },

    fetchAssociationsByScenarioCollection: ({state, commit }, scenarioCollectionId) => {
        axios.get(`associations/${scenarioCollectionId}/associated`)
        .then(res => {
            commit('setAssociationsByScenarioCollection', res.data.data)
        })
    },
    fetchInsights: ({state, commit }, scenarioCollectionId) => {
        axios.get(`insight/scenario-collection-wise-insight/${scenarioCollectionId}`)
        .then(res => {
            commit('setInsights', res.data.data)
        })
    },
    storeInsights: ({state, commit,dispatch }, payload) => {
        return axios.post('insight/store', payload.data)
        .then(res => {
            dispatch('fetchInsights', payload.activeScenarioCollectionId)
            return res.data
        })
    },
    updateInsights: ({state }, payload) => {
        return axios.post(`insight/${payload.id}/update`, payload.data)
    },
}
