<template>
    <svg class="inline" version="1.1" viewBox="0 0 3.625 3.625" xmlns="http://www.w3.org/2000/svg">
        <g transform="translate(-115.2 -167)">
            <g transform="matrix(.2646 0 0 .2646 114.3 166.1)" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.2">
                <path d="m8.75 3.75h-5v5h5z"/>
                <path d="m16.25 3.75h-5v5h5z"/>
                <path d="m8.75 11.25h-5v5h5z"/>
                <path d="m16.25 11.25h-5v5h5z"/>
            </g>
        </g>
    </svg> 
</template>

<script>
export default {
    name: "GridView"
}
</script>