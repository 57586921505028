<template>
    <RightNavComponentWrapper>
        <TopTitle
            title="BASIC INFORMATION"
            icon-type="close"
            :is-list="isList"
            @onClickClose="toggleRightBar()"
            :setLayoutType="setLayoutType"
        />
        <div class="mt-2 space-y-4">
            <p class="fs-14 fw-400 text-secondary-two">Project Name: <span class="text-primary-one">{{ project && project.name ? project.name : 'Not found' }}</span></p>
            <p class="fs-14 fw-400 text-secondary-two">Scenario: <span class="text-primary-one">{{ getScenario }}</span></p>
        </div>
        <hr class="mt-5">
        <div class="mt-4 border border-secondary-four rounded flex pr-2 pt-4 px-3 pb-3 gap-x-4">
            <div class="flex overflow-x-auto" style="width: 270px;">
              <div class="flex flex-col justify-center items-center"
                   v-for="(item, index) in getResponsibles"
                   :title="getUsername(item)"
                   :key="index">
                <div class="border-r border-secondary-four pr-5">
                  <img :src="getUserImage(item)" class="i-h-30 i-w-30 rounded-full" alt="image" />
                </div>
                <span class="fs-12 fw-500 mt-1 usr-name ellipse">{{ getUsername(item) }}</span>

                <span class="italic text-secondary-two fs-10 mt-2">{{ getRole(item) }}</span>
              </div>
            </div>
            <div class="flex flex-col">
                <router-link :to="{ name: 'workspace.members'}">
                  <div class="border-r border-secondary-four pr-5">
                    <div class="i-h-30 i-w-30 rounded-full bg-secondary-four flex justify-center items-center">
                      <PlusIcon class="w-2 h-2 text-secondary-one " />
                    </div>
                  </div>
                </router-link>
            </div>
        </div>
        <hr class="mt-5">
        <TitleValue :items="getProjectTimeline" class="mt-4"/>
        <hr class="mt-5">
        <Navigator class="mt-3"/>
    </RightNavComponentWrapper>
</template>

<script>
import sidebarMixin from '@/components/InstMenu/right-navigation-drawer/mixins.js'
import TopTitle from '@/components/InstMenu/right-navigation-drawer/components/TopTitle.vue'
import AvatarWithName from '@/components/InstMenu/right-navigation-drawer/components/AvatarWithName'
import TitleValue from '@/components/InstMenu/right-navigation-drawer/components/TitleValue.vue'
import Navigator from '@/components/InstMenu/right-navigation-drawer/components/Navigator.vue'
import ProfileImage from "@/assets/images/icons/profile/profile.png";
import { mapMutations } from 'vuex'

export default {
    name : 'BasicInformation',
    mixins: [sidebarMixin],
    components: {
        TopTitle,
        Comment,
        AvatarWithName,
        TitleValue,
        Navigator
    },
    props: {
      project: {
        type: Object
      },
      activePageData: {
        type: Object
      }
    },
    data: () => ({
      image_path: ProfileImage,
      roles: ['Admin', 'Editor', 'Contributor', 'Viewer']
    }),
    computed: {
      getScenario() {
        if(this.activePageData && this.activePageData.scenario_id && this.project && this.project.scenarios && this.project.scenarios.length > 0) {
          let scenario = this.project.scenarios.find(item => item.id === this.activePageData.scenario_id);
          if(scenario && scenario.title) {
            return scenario.title;
          }
        }
        return 'Not found';
      },
      getProjectTimeline() {
        let items = [];

        let startDate = {
          title: 'Start Date',
          value: this.project && this.project.start_date ? this.$lodash.format(this.project.start_date, 'dd/MM/yyyy')  : ''
        };

        let endDate = {
          title: 'End Date',
          value: this.project && this.project.end_date ? this.$lodash.format(this.project.end_date, 'dd/MM/yyyy') : ''
        };

        let duration = {
          title: 'Duration',
          value: this.project && this.project.start_date && this.project.end_date ? `${this.$lodash.dateDefer(this.project.start_date, this.project.end_date)} Days` : `0 Days`
        };

        items.push(startDate, endDate, duration);
        return items;
      },
      getResponsibles() {
        return this.project && this.project.responsibles ? this.project.responsibles : [];
      }
    },
    methods: {
      ...mapMutations({
            toggleRightBar: 'toggleRightBar',
            toggleExpansionRightBar: 'toggleExpansionRightBar'
        }),
      getUsername(item) {
        let user = item.user;
        if(user) {
          if(user) {
            let name = '';
            if(user.first_name) {
              name = user.first_name + ' ';
            }
            if(user.last_name) {
              name = name + user.last_name;
            }
            return name;
          }
        }
        return 'Not found';
      },
      getUserImage(item) {
        let user = item.user;
        return user && user.image && user.image.file_path ? user.image.file_path : this.image_path;
      },
      getRole(item) {
        return item ? this.roles[item.role - 1] : 'N/A';
      }
    }
}
</script>
<style scoped>
.usr-name {
  width: 60px;
}
</style>
