import ScenarioBtn from '@/components/InstMenu/right-navigation-drawer/module/risk-management/ScenarioBtn.vue'
import Navigator from '@/components/InstMenu/right-navigation-drawer/components/Navigator.vue'
import CircularMenu from '@/components/InstMenu/right-navigation-drawer/module/risk-management/circular-menu/CircularMenu.vue'
import TopTitle from '@/components/InstMenu/right-navigation-drawer/components/TopTitle.vue'


export default {
    components: {
        ScenarioBtn,
        Navigator,
        TopTitle,
        CircularMenu,
    }
}