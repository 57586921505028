import { render, staticRenderFns } from "./IconBarChart.vue?vue&type=template&id=4f9f30d4&scoped=true&"
import script from "./IconBarChart.vue?vue&type=script&setup=true&lang=js&"
export * from "./IconBarChart.vue?vue&type=script&setup=true&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4f9f30d4",
  null
  
)

export default component.exports