<template>
   <svg class="inline" version="1.1" viewBox="0 0 4.763 3.704" xmlns="http://www.w3.org/2000/svg">
    <g transform="translate(-112.1 -128.9)">
    <g transform="matrix(.2646 0 0 .2646 112.1 128.9)" fill="currentColor">
    <path d="m16.59 1.224e-6 -7 7 7 7 1.41-1.41-5.59-5.59 5.59-5.59z"/>
    <path d="m7 1.224e-6 -7 7 7 7 1.41-1.41-5.59-5.59 5.59-5.59z"/>
    </g>
    </g>
    </svg>
</template>

<script>
export default {
    name: "DownloadIcon"
}
</script>
