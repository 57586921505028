import axios from 'axios';

function getDefaultState() {
    return {
        workspaces: [],
        workspaceModal: false,
        activeWorkspace: 0,
        directories: [],
        workspaceInfos: [],
        templates: [],
        workspaceUsers: [],
        projectInfo: {},
        projectSelection: null,
        activeProject: {},
        workspaceSettingsInfo: [],
        iconsVisibility: false,
        selectedProjectId: false,
        selected_folder: 'all',
        template_list: []
    }
}

const getters = {
    workspaces: state => state.workspaces,
    workspaceModal: state => state.workspaceModal,
    activeWorkspace: state => state.activeWorkspace,
    activeWorkspaceObj: state => state.workspaces[state.activeWorkspace],
    directories: state => state.directories,
    workspaceInfos: state => state.workspaceInfos,
    workspaceUsers: state => state.workspaceUsers,
    projectInfo: state => state.projectInfo,
    projectSelection: state => state.projectSelection,
    activeProject: state => state.activeProject,
    workspaceSettingsInfo: state => state.workspaceSettingsInfo,
    iconsVisibility: state => state.iconsVisibility,
    selected_folder: state => state.selected_folder,
    templates: state => state.templates,
    template_list: state => state.template_list,
}

const actions = {
    toggleWorkspace({ commit, state }, index) {
      state.activeWorkspace = index;
      state.activeWorkspaceObj = state.workspaces[index];
    },
    toggleWorkspaceModal({ commit, state }, status) {
        state.workspaceModal = status;
    },
    fetchWorkspaces({commit, state}, id) {
        return axios.get(`workspaces/1/company`)
            .then(response => {
                commit('setWorkspace', response.data);
                return response;
            })
            .catch(error => {
                // TODO error handling
            });
    },
    storeWorkspaceData({ commit, state }, payload) {
        return axios.post(`workspaces`, payload)
            .then(response => {
                return response;
            })
            .catch(e => {
                //
            });
    },
    updateWorkspace({ commit, state, dispatch }, payload) {
      return axios.post(`workspaces/${payload.id}/update`, payload)
          .then(response => {
              return response;
          }).catch(e => {
              //
          });
    },
    deleteWorkspace({ commit, state, dispatch }, payload) {
        return axios.delete(`workspaces/${payload.id}`)
            .then(response => {
                dispatch("fetchWorkspaces");
                return response;
            }).catch(e => {
                //
            });
    },
    createDirectory({ commit, state }, payload) {
        return axios.post(`workspaces/create-directory`, payload)
            .then(response => {
                return response;
            })
            .catch(e => {
                // TODO
            });
    },
    getDirectories({ commit, state }, id) {
        return axios.get(`workspaces/${id}/directories`)
            .then(response => {
                commit("setDirectories", response.data);
                return response;
            })
            .catch(e => {
                // TODO
            })
    },
    getWorkspaceData({ commit, state }, payload) {
        return axios.get(`workspaces/${payload.workspace_id}/directories-info?type=${payload.type}`)
            .then(response => {
                if(payload.type === 1) {
                    commit("setWorkspaceInfos", response.data);
                } else {
                    commit("setTemplates", response.data);
                }
                return response.data;
            })
            .catch(e => {
                // TODO
            })
    },
    getTemplates({ commit, state }, payload) {
        return axios.get(`workspaces/template/list?workspace_id=${payload.workspace_id}`)
            .then(response => {
                commit("setTemplateLists", response.data);
                return response.data;
            })
            .catch(e => {
                // TODO
            })
    },
    getWorkspaceUser({ commit, state }, id) {
        return axios.get(`workspaces/${id}/users`)
            .then(response => {
                commit("setWorkspaceUsers", response.data);
                return response;
            })
            .catch(e => {
                // TODO
            });
    },
    storeProjectData(context, payload) {
        return axios.post(`projects`, payload);
    },
    storeProjectFile(context, payload) {
        return axios.post(`projects/upload/file`, payload);
    },
    updateProjectData({ commit, state }, payload) {
        return axios.post(`projects/${payload.id}/update`, payload)
          .then(response => {
              return response;
          })
          .catch(e => {
              console.log(e);
          });
    },
    deleteProject({ commit, state }, id) {
        return axios.delete(`projects/${id}`)
            .then(response => {
                return response;
            })
            .catch(e => {
                // TODO
            });
    },
    deleteDirectory({ commit, state }, id) {
        return axios.delete(`workspaces/directory/${id}`)
            .then(response => {
                return response;
            })
            .catch(e => {
                // TODO
            });
    },
    getProjectInfo({ commit, state }, id) {
        return axios.get(`projects/${id}/edit-info`)
            .then(response => {
                commit("setProjectInfo", response.data);
                return response;
            })
            .catch(e => {
                // TODO
            })
    },
    getActiveProject({ commit, state }, id) {
        return axios.get(`projects/${id}`)
            .then(response => {
                commit("setActiveProject", response.data);
                return response;
            })
            .catch(e => {
                // TODO
            })
    },
    projectSelection({ commit, state }, id) {
        state.projectSelection = id;
    },
    getWorkspaceSettingsInfo({ commit, state }, workspaceId) {
        return axios.get(`workspaces/${workspaceId}/settings-info`)
            .then(response => {
                commit("setWorkspaceSettingsInfo", response.data);
                return response;
            })
            .catch(e => {
                //
            });
    },
    getWorkspaceMembersProjectInfo({ commit, state }, workspaceId) {
        return axios.get(`workspaces/${workspaceId}/members-info`)
            .then(response => {
                commit("setWorkspaceMembersProjectInfo", response.data);
                return response;
            })
            .catch(e => {
                //
            });
    },
    updateRole({ commit, state }, payload) {
        return axios.post(`projects/role`, payload)
            .then(response => {
                return response;
            })
            .catch(e => {
                //
            });
    },

    deleteProjectResponsible({ commit, state }, id) {
        return axios.delete(`projects/${id}/responsible`)
            .then(response => {
                return response;
            })
            .catch(e => {
                //
            });
    },

    deleteWorkspaceResponsibles(context, {workspace_id, responsibleIds}) {
        return axios.post(`workspaces/multi-delete-responsible/${workspace_id}`, {responsibles: responsibleIds})
    },

    deleteProjectResponsibles(context, {projectId, ids}) {
        return axios.post(`projects/${projectId}/multi-delete-responsible`, {responsible_id: ids})
    },

    addProjectResponsible({ commit, state }, payload) {
        return axios.post(`projects/${payload.project_id}/responsible`, payload)
            .then(response => {
                return response;
            })
            .catch(e => {
                //
            })
    }
};

const mutations = {
    setProjectId(state, value) {
        state.selectedProjectId = value
    },
    setWorkspace(state, value) {
        state.workspaces = value.data
    },
    setWorkspaceInfos(state, value) {
        state.workspaceInfos = value.data;
    },
    setTemplates(state, value) {
        state.templates = value.data;
    },
    setTemplateLists(state, value) {
        state.template_list = value.data;
    },
    setDirectories(state, value) {
        state.directories = value.data;
    },
    setWorkspaceUsers(state, value) {
        state.workspaceUsers = value.data;
    },
    setProjectInfo(state, value) {
        state.projectInfo = value.data;
    },
    setActiveProject(state, value) {
        state.activeProject = value.data;
    },
    setWorkspaceSettingsInfo(state, value) {
        state.workspaceSettingsInfo = value.data
    },
    setIconsVisibility(state, value) {
        state.iconsVisibility = value;
    }
};

export default {
    namespaced: true,
    state: getDefaultState,
    getters,
    actions,
    mutations
}
