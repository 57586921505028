import { ref, reactive, computed } from 'vue'
import Production from './Production';
import Manage from './Manage'
import Teamwork from './Teamwork';

const components = [Production, Teamwork, Manage]
const currentComponentIndex = ref(0)
const currentMenu = computed(() => {
    return components[currentComponentIndex.value]
})

export default function useCircularMenu() {

    const setCurrentComponentIndex = (val) => {
        currentComponentIndex.value = val
    } 

    return {
        currentMenu,
        currentComponentIndex,
        setCurrentComponentIndex
    }
}