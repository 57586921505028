<template>
    <div>
        <div class="menu_right right" :class="{'w-full': hideFeature}">
            <div class="flex items-center i-menu justify-between w-full gap-x-4" >
                <div class="text" v-if="!hideFeature">
                    <h2 class="font-poppins uppercase fw-700 fs-16 text-primary-one">INST. {{ getMenuTitle }}</h2>
                    <h2 class="font-poppins fw-600 fs-14 text-primary-one menu-project-title ellipse"
                        style="width: 280px;"
                        v-if="!hideProject">{{ project && project.name ? project.name : '' }}
                    </h2>
                </div>
                <slot v-else name="workspace"></slot>
                <div class="flex items-center">
                    <div class="relative ws-avatar-menu flex-shrink-0" @click="accountDropdown = !accountDropdown"
                        v-click-outside="hideAccountDropdown">
                            <img
                                :src="profileData.image ? `${profileData.image}` : image_path" alt="avatar"
                                style="width: 40px;height: 40px;object-fit: cover;"
                                class="i-user-avatar rounded-full cursor-pointer border-2 border-white"
                            >
                        <div class="absolute ws-profile-menu flex flex-col py-4 px-5 bg-primary-three shadow-one rounded-lg"
                            v-if="accountDropdown">
                            <router-link :to="{ name: 'workspace_dashboard' }">
                                <a class="text-primary-one opacity-60 font-poppins fs-14 cursor-pointer i-profile-menu">Workspace</a>
                            </router-link>
                            <router-link :to="{ name: 'workspace.myProfile' }" class="mt-4 text-primary-one opacity-60 font-poppins fs-14 cursor-pointer i-profile-menu">My Profile</router-link>
                            <router-link :to="{ name: 'workspace.settings' }" class="text-primary-one opacity-60 mt-4 font-poppins fs-14 cursor-pointer i-profile-menu">Settings</router-link>
                            <a class="text-primary-one opacity-60 mt-4 font-poppins fs-14 cursor-pointer i-profile-menu"
                            @click="logout()">Logout</a>
                        </div>
                    </div>
                    <div class="relative" v-if="rightNavRouteList.includes($route.name)" @click="toggleRightBar()">
                        <ChevronDoubleLeft  class="i-w-14 absolute text-secondary-one cursor-pointer ml-4 -i-mt-3" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapMutations, mapActions, mapGetters} from "vuex";
import ProfileImage from "../../assets/images/icons/profile/profile.png";

export default {
    name: "WorkspaceMenu",
    props: {
        hideProject: Boolean,
        hideFeature: Boolean
    },
    data() {
        return {
            accountDropdown: false,
            menus: [
                {placeholder: 'workspace_dashboard', title: 'Workspace'},
                {placeholder: 'workspace_settings', title: 'Workspace'},
                {placeholder: 'profile', title: 'Profile'},
                {placeholder: 'identification.build', title: 'Build'},
                {placeholder: 'programmatic.scenario', title: 'Programmatic'},
                {placeholder: 'programmatic.scenario.view', title: 'Programmatic'},
                {placeholder: 'intel.assess', title: 'Assessment'},
                {placeholder: 'intel.assess.survey', title: 'Assessment'},
                {placeholder: 'brainstorm', title: 'Brainstorm'},
                {placeholder: 'brainstorm.show', title: 'Brainstorm'},
                {placeholder: 'definition', title: 'Definition'},
                {placeholder: 'definition.show', title: 'Definition'},
                {placeholder: 'roadmap', title: 'Roadmap'},
                {placeholder: 'roadmap.show', title: 'Roadmap'},
                {placeholder: 'communicators', title: 'Communicator'},
                {placeholder: 'communicator', title: 'Communicator'},
                {placeholder: 'implementationPlaning', title: 'Planning'},
                {placeholder: 'roadmap.highlevel.data', title: 'High Level Plan'},
                {placeholder: 'planning', title: 'Roadmap Concept'},
            ],
            rightNavRouteList: ['roadmap.show'],
            image_path: ProfileImage,
        }
    },
    computed: {
        ...mapGetters({
            project: "project/project",
            profileData: "profile/data",
        }),
        getMenuTitle() {
            let instMenuObj = this.menus.find(item => item.placeholder === this.$route.name);
            if (instMenuObj) {
                return instMenuObj.title;
            }
            return 'Workspace';
        }
    },
    created() {
        this.fetchProfileData();
    },
    methods: {
        ...mapMutations({
            toggleRightBar: 'toggleRightBar'
        }),
        ...mapActions({
            fetchProfileData: "profile/fetchProfileData",
        }),
        hideAccountDropdown() {
            this.accountDropdown = false;
        },
        logout() {
            this.accountDropdown = false;
            localStorage.removeItem('_user');
            localStorage.removeItem('_token_');
            this.$router.push({name: 'login'});
        }
    }
}
</script>
