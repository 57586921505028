<template>
    <svg width="246" height="246" viewBox="0 0 246 246" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M123 0C190.931 8.90808e-06 246 55.069 246 123C246 190.931 190.931 246 123 246C55.0689 246 -8.90808e-06 190.931 0 123C8.90808e-06 55.069 55.069 -8.90808e-06 123 0Z" fill="white"/>

        <router-link
            content="Mindmap and Content Wall "  
            v-tippy="{ animateFill: false, theme : 'light bordered' }"
            :to="_MindmapLink" 
            class="svg-parent"
        >
            <path d="M188.086 22.8906C203.952 33.1236 217.193 46.9879 226.692 63.3691C236.191 79.7504 241.647 98.1291 242.648 116.982C242.791 119.687 240.587 121.937 237.812 121.946L201.612 122.062C198.87 122.071 196.588 119.839 196.343 117.003C195.418 106.292 192.144 95.8885 186.726 86.5448C181.308 77.201 173.905 69.1923 165.068 63.0682C162.728 61.4469 161.924 58.3583 163.294 55.9826L181.376 24.6223C182.763 22.218 185.81 21.4223 188.086 22.8906Z" fill="currentColor"/>
            <g class="svg-child">
                <path d="M201.013 69.4387H206.572V71.3137H201.013V69.4387Z" fill="currentColor"/>
                <path d="M206.572 73.1887H201.013V75.0637H206.572V73.1887Z" fill="currentColor"/>
                <path d="M201.013 76.9387H206.572V78.8137H201.013V76.9387Z" fill="currentColor"/>
                <path d="M206.572 80.6887H201.013V82.5637H206.572V80.6887Z" fill="currentColor"/>
                <path d="M209.352 69.4387H214.911V71.3137H209.352V69.4387Z" fill="currentColor"/>
                <path d="M214.911 73.1887H209.352V75.0637H214.911V73.1887Z" fill="currentColor"/>
                <path d="M209.352 76.9387H214.911V78.8137H209.352V76.9387Z" fill="currentColor"/>
                <path d="M214.911 80.6887H209.352V82.5637H214.911V80.6887Z" fill="currentColor"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M197.926 66C197.415 66 197 66.4197 197 66.9375V85.0625C197 85.5803 197.415 86 197.926 86H217.074C217.585 86 218 85.5803 218 85.0625V66.9375C218 66.4197 217.585 66 217.074 66H197.926ZM217.074 66.9375H197.926L197.926 85.0625L217.074 85.0625V66.9375Z" fill="currentColor"/>
            </g>
        </router-link>

        <router-link
            content="Business Concept Canvas"  
            v-tippy="{ animateFill: false, theme : 'light bordered' }"
            :to="_CanvasLink" 
            class="svg-parent"
        >
            <path d="M69.2266 16.7554C86.0224 8.23009 104.623 3.78282 123.515 3.81462C142.407 3.84642 160.992 8.35631 177.759 16.9381C180.171 18.1723 181.009 21.2079 179.623 23.6125L161.547 54.9761C160.177 57.3521 157.102 58.2039 154.526 56.9919C144.823 52.4252 134.211 50.0327 123.437 50.0145C112.663 49.9964 102.044 52.3532 92.3249 56.8872C89.7454 58.0906 86.6725 57.2284 85.3111 54.8478L67.3401 23.4235C65.9624 21.0143 66.811 17.9815 69.2266 16.7554Z" fill="currentColor"/>
            <g class="svg-child">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M116.013 31.8136V22.4387H128.983V31.8136H116.013ZM116.94 23.3762H128.057V30.8761H116.94V23.3762Z" fill="currentColor"/>
                <path d="M116.013 33.6886H121.572V36.5011H116.013V33.6886Z" fill="currentColor"/>
                <path d="M128.983 33.6886H123.425V36.5011H128.983V33.6886Z" fill="currentColor"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M112.275 19.2696C112.445 19.103 112.673 19 112.926 19H132.074C132.461 19 132.794 19.2422 132.931 19.5859C132.96 19.6583 132.982 19.7352 132.993 19.8157L133 19.9375V38.0625C133 38.5802 132.584 39 132.074 39H112.926C112.416 39 112 38.5802 112 38.0625V19.9375C112 19.7896 112.033 19.6496 112.094 19.5251C112.141 19.4294 112.203 19.3433 112.275 19.2696ZM112.926 19.9375H132.074V38.0625H112.926V19.9375Z" fill="currentColor"/>
            </g>
        </router-link>

        <router-link
            content="Concepts and Authoring"  
            v-tippy="{ animateFill: false, theme : 'light bordered' }"
            :to="_ConceptsLink" 
            class="svg-parent"
        >
            <path d="M242.685 128.877C241.807 147.718 236.479 166.115 227.102 182.546C217.725 198.977 204.595 212.922 188.819 223.261C186.554 224.746 183.501 223.973 182.097 221.579L163.785 190.352C162.398 187.986 163.179 184.892 165.507 183.253C174.29 177.071 181.628 169.018 186.976 159.646C192.325 150.275 195.526 139.861 196.381 129.154C196.608 126.317 198.875 124.07 201.617 124.061L237.817 123.944C240.592 123.935 242.811 126.171 242.685 128.877Z" fill="currentColor"/>
            <g class="svg-child">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M198.087 164.439V170.064H204.572V164.439H198.087ZM203.646 165.376H199.013V169.126H203.646V165.376Z" fill="currentColor"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M206.425 164.439H212.911V170.064H206.425V164.439ZM207.352 165.376H211.984V169.126H207.352V165.376Z" fill="currentColor"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M198.087 171.939V177.564H204.572V171.939H198.087ZM203.646 172.876H199.013V176.626H203.646V172.876Z" fill="currentColor"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M206.425 171.939H212.911V177.564H206.425V171.939ZM207.352 172.876H211.984V176.626H207.352V172.876Z" fill="currentColor"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M195.926 161C195.415 161 195 161.42 195 161.938V180.063C195 180.58 195.415 181 195.926 181H215.074C215.585 181 216 180.58 216 180.062V161.938C216 161.42 215.585 161 215.074 161H195.926ZM215.074 161.938H195.926L195.926 180.063L215.074 180.062V161.938Z" fill="currentColor"/>
            </g>
        </router-link>

        <router-link
            content="Workzone Charts"  
            v-tippy="{ animateFill: false, theme : 'light bordered' }" 
            :to="_ChartsLink" 
            class="svg-parent"
        >
            <path d="M178.555 229.279C161.608 238.114 142.76 242.769 123.591 242.813C104.421 242.858 85.5518 238.291 68.5638 229.534C66.1559 228.293 65.3261 225.255 66.7189 222.855L84.8849 191.543C86.2611 189.171 89.3393 188.328 91.9113 189.547C101.754 194.214 112.539 196.639 123.483 196.614C134.427 196.588 145.201 194.113 155.022 189.4C157.588 188.169 160.67 188.998 162.057 191.363L180.369 222.59C181.773 224.985 180.957 228.026 178.555 229.279Z" fill="currentColor"/>
            <g class="svg-child">
                <path d="M124.881 214.688H123.116V225.938H124.881V214.688Z" fill="currentColor"/>
                <path d="M119.587 218.438H117.822V225.938H119.587V218.438Z" fill="currentColor"/>
                <path d="M128.411 221.25H130.175V225.938H128.411V221.25Z" fill="currentColor"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M114 210.938C114 210.42 114.395 210 114.882 210H133.118C133.605 210 134 210.42 134 210.938V229.062C134 229.58 133.605 230 133.118 230H114.882C114.395 230 114 229.58 114 229.063V210.938ZM114.882 210.938H133.118V229.062L114.882 229.063L114.882 210.938Z" fill="currentColor"/>
            </g>
        </router-link>

        <g class="svg-parent">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M58.3306 223.601C42.4133 213.287 29.1541 199.315 19.6814 182.818C10.2088 166.32 4.82597 147.826 3.94263 128.88C3.81646 126.174 6.03498 123.938 8.81034 123.947L45.0101 124.063C47.7525 124.072 50.0193 126.319 50.2461 129.156C51.1073 139.926 54.3415 150.399 59.7465 159.813C65.1515 169.226 72.5663 177.299 81.4338 183.472C83.7699 185.098 84.5676 188.188 83.1927 190.561L65.0442 221.883C63.6528 224.285 60.6041 225.074 58.3306 223.601Z" fill="currentColor"/>
            <g class="svg-child">
                <path d="M35.4444 173C35.4444 172.632 35.7429 172.333 36.1111 172.333H39.3333V169.111C39.3333 168.743 39.6318 168.444 40 168.444C40.3682 168.444 40.6667 168.743 40.6667 169.111V172.333H43.8889C44.2571 172.333 44.5556 172.632 44.5556 173C44.5556 173.368 44.2571 173.667 43.8889 173.667H40.6667V176.889C40.6667 177.257 40.3682 177.556 40 177.556C39.6318 177.556 39.3333 177.257 39.3333 176.889V173.667H36.1111C35.7429 173.667 35.4444 173.368 35.4444 173Z" fill="currentColor"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M30 173C30 167.477 34.4772 163 40 163C45.5228 163 50 167.477 50 173C50 178.523 45.5228 183 40 183C34.4772 183 30 178.523 30 173ZM40 164.333C35.2135 164.333 31.3333 168.214 31.3333 173C31.3333 177.786 35.2135 181.667 40 181.667C44.7865 181.667 48.6667 177.786 48.6667 173C48.6667 168.214 44.7865 164.333 40 164.333Z" fill="currentColor"/>
            </g>
        </g>

        <router-link
            content="Data Driven Brainstorming"  
            v-tippy="{ animateFill: false, theme : 'light bordered' }"
            :to="_BrainstormLink"
            class="svg-parent"
        >
            <path d="M3.98323 116.969C4.98915 98.0489 10.4821 79.6082 20.0441 63.1856C29.606 46.7629 42.9316 32.8828 58.8891 22.6682C61.1707 21.2077 64.215 22.0138 65.5931 24.4229L83.5677 55.8451C84.9294 58.2254 84.1146 61.3113 81.7695 62.9245C72.878 69.0415 65.4251 77.062 59.9696 86.4319C54.514 95.8018 51.2179 106.243 50.2875 116.995C50.0421 119.831 47.7608 122.063 45.0184 122.053L8.81861 121.933C6.04328 121.924 3.83937 119.674 3.98323 116.969Z" fill="currentColor"/>
            <g class="svg-child">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M31 67H49V85H31V67ZM30 67C30 66.4477 30.4477 66 31 66H49C49.5523 66 50 66.4477 50 67V85C50 85.5523 49.5523 86 49 86H31C30.4477 86 30 85.5523 30 85V67ZM37.84 78.0995C36.4708 77.3219 35.5901 75.9201 35.59 74.363C35.5898 71.9817 37.5743 70.031 40.0598 69.999C42.5685 69.999 44.59 71.9593 44.59 74.363C44.5899 75.9201 43.7091 77.3219 42.34 78.0995V79.818C42.34 80.1192 42.0881 80.3635 41.7775 80.3635H38.4025C38.0918 80.3635 37.84 80.1192 37.84 79.818V78.0995ZM41.5369 77.3196C42.7158 76.7773 43.4649 75.6284 43.465 74.363C43.465 72.5489 41.9606 71.0899 40.0675 71.0899C38.1923 71.114 36.7148 72.5661 36.715 74.363C36.7151 75.6284 37.4642 76.7773 38.6431 77.3196C38.8397 77.4098 38.9651 77.6019 38.965 77.8129V79.2725H41.215V77.8129C41.2149 77.6019 41.3403 77.4099 41.5369 77.3196ZM37.8389 81.999V80.908H42.34V81.999H37.8389Z" fill="currentColor"/>
            </g>
        </router-link>
        
    </svg>
</template>

<script setup>
    import store from '@/store';
    import { computed, ref, onMounted, watchEffect } from 'vue';
    import { useTeamwork } from '@/components/shared/stickyMenu/useTeamwork.js'

    const _BrainstormLink = ref({name: ''})
    const _MindmapLink    = ref({name: ''})
    const _CanvasLink     = ref({name: ''})
    const _ConceptsLink   = ref({name: ''})
    const _ChartsLink     = ref({name: ''})

    const { initDefaultSettings, BrainstormLink, getLink } = useTeamwork()

    const workspace = computed(() => store.getters['workspace/activeWorkspaceObj']);
    watchEffect(async () => 
    {
        let project = store.getters['project/project']
        let Ids = await initDefaultSettings(project.id)

        _BrainstormLink.value = BrainstormLink(project.id, Ids.brainstorm_id)
        _MindmapLink.value    = getLink(project.id, Ids.brainstorm_id, 'teamwork.mindmap')
        _CanvasLink.value     = getLink(project.id, Ids.brainstorm_id, 'teamwork.canvas')
        _ConceptsLink.value   = getLink(project.id, Ids.brainstorm_id, 'teamwork.concepts')
        _ChartsLink.value     = getLink(project.id, Ids.brainstorm_id, 'teamwork.diagrammatic')

        return project;
    })

</script>

<style>
    a[href="/"] {
    pointer-events: none;
    opacity: 0.6;
    cursor: not-allowed;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
}
</style>