import axios from 'axios';

function getDefaultState() {
    return {
        surveys: [],
        survey: {},
        activeListIndex: 0,
        responses: [],
        activeSurvey: null
    }
}

const getters = {
    surveys: state => state.surveys,
    survey: state => state.survey,
    activeListIndex: state => state.activeListIndex,
    responses: state => state.responses,
    activeSurvey: state => state.activeSurvey,
}

const actions = {
    getSurveys({ commit, state }) {
        return axios.get(`surveys`)
            .then(response => {
                commit("setSurveys", response.data);
                return response;
            }).catch(e => {
                //
            });
    },
    getActiveListIndex({ commit, state }, index) {
        commit("setActiveListIndex", index)
    },
    storeSurvey({ commit, dispatch, state }) {
        return axios.post(`surveys`)
            .then(response => {
                return response;
            }).catch(e => {
                //
            });
    },
    getSingleSurvey({ commit, state }, id) {
        return axios.get(`surveys/${id}`)
            .then(response => {
                commit("setSurvey", response.data);
                return response;
            }).catch(e => {
                //
            });
    },
    updateSurvey({ commit, state }, payload) {
        return axios.post(`surveys/${payload.id}/update`, payload)
            .then(response => {
                return response;
            }).catch(e => {
                //
            })
    },
    deleteSurvey({ commit, dispatch, state }, id) {
        return axios.delete(`surveys/${id}`)
            .then(response => {
                dispatch("getSurveys");
                return response;
            })
            .catch(e => {
                //
            });
    },
    storeQuestion({ commit, dispatch, state }, payload) {
        return axios.post(`surveys/question/store`, payload)
            .then(response => {
                dispatch("getSingleSurvey", payload.survey_id);
                return response;
            }).catch(e => {
                //
            });
    },
    updateQuestion({ commit, dispatch, state }, payload) {
        return axios.post(`surveys/question/${payload.id}/update`, payload)
            .then(response => {
                dispatch("getSingleSurvey", payload.survey_id);
                return response;
            }).catch(e => {
                //
            });
    },
    deleteQuestion({ commit, dispatch, state }, payload) {
        return axios.delete(`surveys/question/${payload.id}`)
            .then(response => {
                dispatch("getSingleSurvey", payload.survey_id);
                return response;
            })
            .catch(e => {
                //
            });
    },
    sendMessage({ commit, dispatch, state }, payload) {
        return axios.post(`surveys/${payload.survey_id}/send`, payload)
            .then(response => {
                return response;
            }).catch(e => {
                //
            })
    },
    storeSurveyResponse({ commit, dispatch, state }, payload) {
        return axios.post(`surveys/response/store`, payload)
            .then(response => {
                return response;
            }).catch(e => {
                //
            });
    },
    getAllResponses({ commit, dispatch, state }, id) {
        return axios.get(`surveys/responses/${id}`)
            .then(response => {
                commit("setResponses", response.data);
                return response;
            }).catch(e => {
                //
            });
    }
};

const mutations = {
    setSurveys(state, value) {
        state.surveys = value.data;
        state.responses = [];
        if(state.surveys.own.length <= 0) {
            state.activeSurvey = null;
        } else {
            if(!state.activeSurvey) {
                state.activeSurvey = state.surveys.own[0].id;
            }
        }
    },
    setSurvey(state, value) {
        state.survey = value.data;
    },
    setActiveListIndex(state, value) {
        state.activeListIndex = value;
    },
    setResponses(state, value) {
        state.responses = value.data;
    }
};

export default {
    namespaced: true,
    state: getDefaultState,
    getters,
    actions,
    mutations
}
