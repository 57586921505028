import axios from 'axios';
export function useProduction()
{    
    const initDefaultSettings = async (projectId) => {
        if(!projectId) return {};
        const data = await axios.get(`projects/${projectId}?solid_object=true`).then(({data})=>data.data)
        return data?.default_settings
    }

    const ScenarioLink = (projectId, defaultIds) => 
    {
        const landing = {
            name: 'programmatic.scenario',
            params: {
                id: projectId
            }
        }

        const detail = {
            name: 'programmatic.scenario.view',
            params: {
                id: projectId,
                scenario_id: defaultIds?.scenario_id
            }
        }
        
        return defaultIds['scenario_id'] ? detail : landing
    }

    const RoadmapsLink = (projectId, defaultIds) => 
    {
        const landing = {
            name: 'roadmap',
            params: {
                id: projectId,
            }
        }

        const detail = {
            name: 'roadmap.show',
            params: {
                id: projectId,
                roadmap_id: defaultIds?.roadmap_id
            }
        }
        
        return defaultIds['roadmap_id'] ? detail : landing
    }

    const CommunicatorLink = (projectId, defaultIds) => 
    {
        const landing = {
            name: 'communicators',
            params: {
                id: projectId,
            }
        }

        const detail = {
            name: 'communicator',
            params: {
                id: projectId,
                communicator_id: defaultIds?.communicator_id
            }
        }
        
        return defaultIds['communicator_id'] ? detail : landing
    }

    return {
        initDefaultSettings,
        ScenarioLink,
        RoadmapsLink,
        CommunicatorLink
    }
}