<template>
  <div class="ml-5 i-h-50">
    <div
        class="_my_item flex flex-col justify-center relative i-h-50 border-solid cursor-pointer border-color-transparent"
        :class="{
            'hor-tiles-max': (expansionSelf === true && checkSelected === true || expansionGlobal === true),
            'i-border-1 border-primary-one active': checkSelected || appearance == 1,
            'i-border-b-1 border-primary-one' : appearance == 2,
            'shadow-two' : appearance == 0,
            'rounded i-border-1' : appearance != 2,
            'border-secondary-five' : (appearance == 1 || appearance == 2) && !checkSelected,
            'border-primary-one rounded' : checkSelected
        }"
        :style="{'background': appearance == 0 ? getBg() : 'transparent' }"
        @click="toggleActive([identifier, 'identifier']), activePropertyOption()"
    >
      <div v-show="identifier.id == activeModuleCollection.id" class="action_items_for_build action_items">
        <button title="Information" @click="contextmenu($event, identifier)">
          <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.000650114 6.66667C0.000650275 2.98477 2.98542 -4.5235e-07 6.66732 -2.91409e-07C10.3492 -1.30468e-07 13.334 2.98477 13.334 6.66667C13.334 10.3486 10.3492 13.3333 6.66732 13.3333C2.98709 13.3293 0.00469163 10.3469 0.000650114 6.66667ZM6.55265 1.33333C3.61849 1.36488 1.26095 3.76074 1.27665 6.69503C1.2925 9.62931 3.67565 11.9997 6.60999 11.9997C9.54431 11.9997 11.9275 9.62931 11.9433 6.69503C11.959 3.76074 9.60148 1.36488 6.66732 1.33333L6.55265 1.33333Z" fill="#8D8D8D"/>
            <path d="M6.50431 5.072C6.26431 5.072 6.06698 5.00267 5.91231 4.864C5.76298 4.72 5.68831 4.544 5.68831 4.336C5.68831 4.12267 5.76298 3.94667 5.91231 3.808C6.06698 3.664 6.26431 3.592 6.50431 3.592C6.73898 3.592 6.93098 3.664 7.08031 3.808C7.23498 3.94667 7.31231 4.12267 7.31231 4.336C7.31231 4.544 7.23498 4.72 7.08031 4.864C6.93098 5.00267 6.73898 5.072 6.50431 5.072ZM7.18431 5.536V10H5.81631V5.536H7.18431Z" fill="#8D8D8D"/>
          </svg>
        </button>
        <button title="Create" @click="()=>{ canCreate(project.cans)&&createNewData(identifier) }">
          <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.000650114 6.66667C0.000650275 2.98477 2.98542 -4.5235e-07 6.66732 -2.91409e-07C10.3492 -1.30468e-07 13.334 2.98477 13.334 6.66667C13.334 10.3486 10.3492 13.3333 6.66732 13.3333C2.98709 13.3293 0.00469163 10.3469 0.000650114 6.66667ZM6.55265 1.33333C3.61849 1.36488 1.26095 3.76074 1.27665 6.69503C1.2925 9.62931 3.67565 11.9997 6.60999 11.9997C9.54431 11.9997 11.9275 9.62931 11.9433 6.69503C11.959 3.76074 9.60148 1.36488 6.66732 1.33333L6.55265 1.33333ZM3.33398 7.33333L3.33398 6L6.00065 6L6.00065 3.33333L7.33398 3.33333L7.33398 6L10.0007 6L10.0007 7.33333L7.33398 7.33333L7.33398 10L6.00065 10L6.00065 7.33333L3.33398 7.33333Z" fill="#8D8D8D"/>
          </svg> 
        </button>
        <button title="Delete" @click="canDelete(project.cans) && rmParent($event, identifier)">
          <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.000650114 6.66691C0.000650275 2.98501 2.98542 0.000243688 6.66732 0.000243849C10.3492 0.00024401 13.334 2.98501 13.334 6.66691C13.334 10.3488 10.3492 13.3336 6.66732 13.3336C2.98709 13.3295 0.00469163 10.3471 0.000650114 6.66691ZM6.55265 1.33358C3.61849 1.36512 1.26095 3.76098 1.27665 6.69527C1.2925 9.62956 3.67565 11.9999 6.60999 11.9999C9.54431 11.9999 11.9275 9.62956 11.9433 6.69527C11.959 3.76098 9.60148 1.36512 6.66732 1.33358L6.55265 1.33358ZM3.33398 7.33358L3.33398 6.00024L6.00065 6.00024L7.33398 6.00024L10.0007 6.00024L10.0007 7.33358L7.33398 7.33358L6.00065 7.33358L3.33398 7.33358Z" fill="#8D8D8D"/>
          </svg>
        </button>
      </div>
      <div
          class="flex justify-start items-center i-pl-15 i-pr-15 cursor-pointer"
      >
        <h4
            class="fs-13 fw-600 font-poppins text-primary-three i-pt-5"
            :class="appearance == 0 ? 'text-primary-three' : 'text-secondary-one'"
            v-show="indexTitle"
        >
          {{ getIndexTitle }}
        </h4>
        <input
            type="text"
            style="height: 25px"
            :style="{ 'padding-left': indexTitle && getIndexTitle != '' ? '5px' : '0px' }"
            @keyup="temporaryUpdate($event, identifier)"
            @change="regularUpdate($event, identifier)"
            v-model="identifier.title"
            v-on:blur="$emit('close-menu')"
            class="block w-full fw-600 focus:outline-none bg-transparent fs-13 font-poppins text-primary-three i-mt-5 placeholder-secondary-five ellipse cursor-pointer"
            :class="{
                'py-2': (expansionSelf === true && checkSelected == true) || expansionGlobal == true,
                'py-3': !((expansionSelf === true && checkSelected == true) || expansionGlobal == true),
                'cursor-pointer': checkSelected,
                'text-primary-three': appearance == 0,
                'text-secondary-one': appearance != 0,
            }"
            placeholder="Identifier"
            :disabled="!canEdit(project.cans)"
            :id="'l1_' + identifier.id"
            autocomplete="off"
        />
      </div>
      <div
        class="flex justify-start"
        v-if="
            (expansionSelf === true && checkSelected == true) ||
            expansionGlobal == true
      ">
        <textarea
            class="fs-12 font-poppins text-primary-three i-pl-15 i-pr-15 w-full cursor-text bg-transparent bld-txtarea mb-3"
            :style="{'background': appearance == 0 ? getBg() : 'transparent' }"
            :class="{
                'cursor-pointer': checkSelected == false,
                'text-primary-three bld-content-area': appearance == 0,
                'text-secondary-one bld-content-area-alt': appearance != 0
            }"
            placeholder="Add descriptive text"
            @keyup="temporaryUpdate($event, identifier)"
            @change="regularUpdate($event, identifier)"
            v-model="identifier.description"
            :disabled="!canEdit(project.cans)"
            autocomplete="off"
        ></textarea>
      </div>
    </div>
  </div>
</template>

<script setup>
  import usePermission from '@/composable/usePermission.js'
  const { canCreate, canEdit, canDelete } = usePermission()
</script>

<script>
import {mapGetters} from "vuex";
import buildCommon from "../../common/build";

export default {
  data() {
    return {
      actionDiv: true,
      buildCommon: null,
      levelColorCodes: [],
      editingMode: false,
    };
  },
  computed: {
    ...mapGetters({
      expansion: "GET_ELEMENT_DETAIL_STATUS",
      activeModuleBg: "GET_CURRENT_MODULE_BG",
      topSelectedButton: "GET_IDENTIFIER_TOP_OPTION",
      moduleSettings: "build/currentSettings",
      activeModuleCollection: "build/activeModuleCollection",
      colorCodes: "global/colorCodes",
      project: "project/project"
    }),
    // numberedTitle() {
    //     return (
    //         "I" +
    //         this.identification +
    //         "." +
    //         this.convertInt(this.index + 1, 3)
    //     );
    // },
    getIndexTitle() {
      if (this.indexTitle) {
          return this.codeTitle + this.numberedTitle
      }
      return ''
    },
    checkSelected() {
      if (
          this.selected_item[1] == "identifier" &&
          this.selected_item[0].id == this.identifier.id
      ) {
        return true;
      }
      return false;
    },
  },
  props: [
    "index",
    "identification",
    "expansionSelf",
    "expansionGlobal",
    "identifier",
    "codeTitle",
    "numberedTitle",
    "selected_item",
    "viewer",
    "appearance",
    "indexTitle",
    "contextmenu"
  ],
  methods: {
    enableEditingMode() {
      const self = this;
      setTimeout(function () {
        self.editingMode = true;
        let itemId = self.identifier.id;
        setTimeout(function () {
          let element = document.getElementById('l1_' + itemId);
          if(element) {
            element.click();
            element.focus();
          }
        }, 100);
      }, 100);
    },
    enteringNonEditableMode() {
      this.editingMode = false;
    },
    activePropertyOption() {
      this.$emit('activePropertyOption');
    },
    getLevelWiseColorCode(level) {
      let indexLevel = level - 1;
      return this.colorCodes.slice((indexLevel * 9), ((indexLevel * 9) + 9));
    },
    rmParent(e, identifier) {
      if (this.viewer) {
        return;
      }
      this.$store.dispatch("build/deleteItem", identifier);
    },
    convertInt(int, length) {
      var len = length - ("" + int).length;
      return (len > 0 ? new Array(++len).join("0") : "") + int;
    },
    toggleActive(arr) {
      this.$emit("active", arr);

    },
    createNewData(obj) {
      if (this.viewer) {
        return;
      }
      let itemObj = Object.assign({}, obj);
      let title = this.moduleSettings.collections[1].title;
      this.$Progress.start();
      itemObj.from_right_side = false;
      itemObj.title = title;
      itemObj.item_order = itemObj.order + 1;
      itemObj.relation = {};
      this.$store.dispatch("build/storeData", itemObj).then(response => {
        if (response) {
          setTimeout(() => {
            this.$parent.setMasterView();
          }, 2000);
        }
        this.$Progress.finish();

      });
    },
    temporaryUpdate(e, obj) {
      if(this.viewer) {
        return;
      }
      if (e.keyCode !== 46) {
        this.$store.dispatch("build/temporaryUpdate", obj);
      }
    },
    regularUpdate(e, obj) {
      if(this.viewer) {
        return;
      }
      if (e.keyCode !== 46) {
        let item = {
          title: obj.title,
          description: obj.description,
          id: obj.id
        };
        this.$store.dispatch("build/regularUpdate", item);
      }
    },
    getBg() {
      if (this.identifier.background_color) {
        return this.identifier.background_color
      }
      let settings = this.moduleSettings.collections[1];
      if (settings) {
        if(settings.custom_bg) {
          return settings.custom_bg;
        }
        return this.levelColorCodes[settings.color];
      }
      return "";
    },
    getPropertyData(id) {
      this.$store.dispatch("build/getProperties", id);
      this.$store.dispatch("build/getActiveModuleCollection", id)
    },
  },
  created() {
    this.buildCommon = buildCommon;
    this.levelColorCodes = this.getLevelWiseColorCode(1);
  }
};
</script>
<style>
  .action_items_for_build{
    display: flex;
    gap: 6px;
    position: absolute;
    top: 100%;
    padding: 8px 0;
    visibility: hidden;
  }
  ._my_item:is(.active) .action_items_for_build{
    visibility: visible;
  }
</style>