<template>
    <div>
        <div class="flex items-center justify-between">
            <input
                class="fs-12 fw-600 text-primary-one pr-1 w-full"
                ref="title"
                v-model=" item.title"
                type="text"
                @blur="$emit('updateItem', {item: item, file: null})"
            >
            <!-- <span v-else class="s-12 fw-600 text-primary-one">{{ item.title }}</span> -->
            <input type="file" class="hidden" ref="ins_file" @change="(e)=>{$emit('updateItem', {item:item, file: e.target.files[0]})}">
            <div @click="$refs.ins_file.click()"><MenuAlt class="w-3 h-3 text-primary-one cursor-pointer" /></div>
        </div>

        <textarea
            @input="autoHeight($event)"
            @blur="$emit('updateItem', {item: item, file: null})"
            class="fs-10 fw-400 text-primary-one w-full mt-3"
            v-model=" item.description"
        >

        </textarea>
        <!-- <Paragraph
            v-else
            class="mt-3"
            :is-list="true"
            :paragraph="item.description"
        /> -->

        <slot></slot>

        <span class="fs-12 text-secondary-one fw-400 block mt-4">{{ $lodash.format(item.created_at, 'dd/MM/yyyy') }}</span>
    </div>            
</template>

<script>
// import Paragraph from '@/components/InstMenu/right-navigation-drawer/components/Paragraph.vue'

export default {
    name: 'Insight',
    components: {
        // Paragraph
    },
    props: {
        item: Object
    },
    methods: {
        autoHeight(e) {
        let textarea = e.target
        textarea.addEventListener("input", function (e) {
            this.style.height = "auto";
            this.style.height = this.scrollHeight + "px";
        });
        }
    }
}
</script>

<style>
textarea {
    resize: none !important;
}
</style>