export default[
    {
        path: '/project/:id/brainstorm',
        component: () => import('@/views/screens/brainstorm/Index.vue'),
        name: 'brainstorm'
    },
    {
        path: '/project/:id/brainstorm/:brainstorm_id',
        component: () => import('@/views/screens/brainstorm/Show.vue'),
        name: 'brainstorm.show'
    },
    {
        path: '/project/:id/assessment',
        component: () => import('@/views/screens/assessment/AssessmentView.vue'),
        name: 'intel.assess'
    },
    {
        path: '/project/:id/definitions',
        component: () => import('@/views/screens/definition/Index.vue'),
        name: 'definition'
    },
    {
        path: '/project/:id/definitions/:definition_id',
        component: () => import('@/views/screens/definition/Show.vue'),
        name: 'definition.show'
    },
    {
        path: '/project/:id/scenario',
        component: () => import('@/views/screens/programmatic/Scenario.vue'),
        name: 'programmatic.scenario'
    },
    {
        path: '/project/:id/scenario/:scenario_id',
        component: () => import('@/views/screens/programmatic/ProgrammaticView.vue'),
        name: 'programmatic.scenario.view'
    },

]