<template>
    <div class="space-y-4">
        <div v-for="(item, i) in items" :key="i"  class="flex justify-between flex-wrap">
            <span :class="textClass">{{ item.title }}</span>
            <span :class="textClass">{{ item.value }}</span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TitleValue',
    data: () => ({
        textClass: 'fs-14 fw-500 text-secondary-one'
    }),
    props: {
        items: {
            required: true
        }
    }
}
</script>