<template>
    <svg width="40" height="40" viewBox="0 0 40 40" class="cursor-pointer" fill="none" xmlns="http://www.w3.org/2000/svg">
        <title>{{ title }}</title>
        <rect x="0.5" y="0.5" width="39" height="39" rx="3.5" :fill="activeRectFill" :stroke="activeStroke"/>
        <rect x="10.5" y="10.5" width="12.7496" height="12.7496" :stroke="activeFill"/>
        <path d="M16.75 16.751L29.4996 16.751L29.4996 29.5006L16.75 29.5006L16.75 16.751Z" :stroke="activeFill"/>
    </svg>
</template>

<script setup>
    import useSVG from '@/composable/icons/useSVG';
    const props = defineProps({
        title: String,
        active: Boolean
    });
    const { activeFill, activeStroke,  activeRectFill} =  useSVG(props);
</script>