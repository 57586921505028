import axios from 'axios';

function getDefaultState() {
    return {
        data: [],
        currentData: [],
        level: 1,
        library: 1,
        projectModules: [],
        currentSettings: {},
        collectionProps: {},
        activeModuleCollection: {},
        contxtmenuCollection: null,
        leftNavigatorSelectedModule: null,
        libraryList: [],
        workspaceModules: [],
        brainstorms: [],
        activeBrainstorm: null,
        modulePropertySet: []
    }
}

const getters = {
    data: state => state.data,
    currentData: state => state.currentData,
    level: state => state.level,
    library: state => state.library,
    currentSettings: state => state.currentSettings,
    projectModules: state => state.projectModules,
    collectionProps: state => state.collectionProps,
    activeModuleCollection: state => state.activeModuleCollection,
    contxtmenuCollection: state => state.contxtmenuCollection,
    libraryList: state => state.libraryList,
    navigatorModule: state => state.leftNavigatorSelectedModule,
    workspaceModules: state => state.workspaceModules,
    brainstorms: state => state.brainstorms,
    activeBrainstorm: state => state.activeBrainstorm,
    modulePropertySet: state => state.modulePropertySet,
}

const actions = {
    getModules({ commit, state }, id) {
        return axios.get(`modules/${id}/project`)
            .then(response => {
                commit("setModules", response.data);
                return response.data;
            })
            .catch(e => {
                // TODO
            });
    },
    async toggleLeftNavigatorModuleSelection({ commit, state, dispatch }, id) {
      state.leftNavigatorSelectedModule = id;
      dispatch('getModuleDataOnLeftNavigator', id)
      await dispatch('getModulePropertySet', id)
      let module = state.projectModules.find(item => item.id === id);
      if(module) {
          let collectionSettings = JSON.parse(module.collection_settings);
          commit("setCurrentSettings", collectionSettings);
      }
      return true;
    },
    getProperties({ commit, state }, id) {
        return axios.get(`properties/${id}`)
            .then(response => {
                commit("setProperties", response.data);
                return response.data;
            })
            .catch(e => {
                // TODO
            });
    },
    getActiveModuleCollection({ commit, state }, obj) {
        commit("setActiveModuleCollection", obj);
    },
    getContxtmenuCollection({ commit, state }, payload) {
        commit("setContxtmenuCollection", payload);
    },
    getBrainstorms({ commit, state }, id) {
        return axios.get(`brainstorm/${id}/collection`)
            .then(response => {
                commit("setBrainstorms", response.data);
                return response.data;
            })
            .catch(e => {
                // TODO
            });
    },
    getActiveBrainstorm({ commit, state }, id) {
        return axios.get(`brainstorm/${id}`)
            .then(response => {
                commit("setActiveBrainstorm", response.data);
                return response.data;
            })
            .catch(e => {
                // TODO
            });
    },
    updatePropertyItem({ commit, state, dispatch }, payload) {
        return axios.post(`properties/update`, payload)
            .then(response => {
                dispatch('getModuleDataOnLeftNavigator', payload.module_id)
                return response;
            })
            .catch(e => {
                //
            });
    },
    updatePropertyItemFromBuild({ commit, state, dispatch }, payload) {
        return axios.post(`properties/updateFromBuild`, payload)
            .then(response => {
                return response;
            })
            .catch(e => {
                //
            });

    },
    getModuleDataOnLeftNavigator({ commit, state }, moduleId) {
        return axios
            .get(`modules/build/${moduleId}`)
            .then(response => {
                commit('setData', response.data);
                return response;
            })
            .catch(error => {
                // TODO error handling
            });
    },
    fetchIndexData({commit, state}, payload) {
        let moduleId = payload.module;

        return axios
            .get(`modules/build/${moduleId}`)
            .then(response => {
                commit('setData', response.data)
                commit("setCurrentSettings", JSON.parse(state.projectModules.find(el => el.id == moduleId).collection_settings));
                return response;
            })
            .catch(error => {
                // TODO error handling
            })
            .finally(() => {
                //
            })
    },
    storeData({commit, dispatch, state}, obj) {
        let data = {
            global: false,
            module_replace: false,
            title: obj.title ? obj.title : 'Title here',
            description: obj.from_right_side ? obj.description : '',
            module_id: obj.module_id,
            parent_id: obj.parent_id,
            level: obj.level,
            index: obj.index === null ? null : obj.index,
            module_id_from_drag: obj.module_id_from_drag ? obj.module_id_from_drag : null,
            item_order: obj.item_order ? obj.item_order : null,
            tag: obj.tag ? obj.tag : 'Add Tag'
        };

        return axios.post('modules/collections', data)
            .then(response => {
                dispatch("getModuleDataOnLeftNavigator", obj.module_id)
                // if(obj.from_right_side) {
                //     dispatch("fetchIndexData", {module: obj.module_id});
                // } else {
                //     let itemObj = {
                //         response: response.data.data,
                //         request: obj
                //     };
                //     commit("pushNewItem", itemObj);
                // }
                dispatch("loadCurrentData");
                let module = state.projectModules.find(item => item.id === state.leftNavigatorSelectedModule);
                if(module) {
                    dispatch("loadGlobalLibraries", module.project_id);
                }
                return response;
            })
            .catch(error => {
                // TODO error handling
            })
            .finally(() => {
                //
            })
    },
    storeGroupData({commit, dispatch, state}, obj) {
        return axios.post('modules/collections/group', obj)
            .then(response => {
                dispatch("getModuleDataOnLeftNavigator", obj.module_id);
                dispatch("loadCurrentData");
                let module = state.projectModules.find(item => item.id === state.leftNavigatorSelectedModule);
                if(module) {
                    dispatch("loadGlobalLibraries", module.project_id);
                }
                return response;
            })
            .catch(error => {
                // TODO error handling
            })
            .finally(() => {
                //
            })
    },
    storeDataFromLibrary({ commit, dispatch, state }, payload) {
      return axios.post(`modules/collections`, payload)
          .then(response => {
              dispatch("getModuleDataOnLeftNavigator", payload.module_id);
              return response;
          })
          .catch(e => {
              //
          });
    },
    replaceModule({ commit, dispatch, state }, payload) {
      return axios.post(`modules/collections`, payload)
          .then(response => {
              if(response.data.data) {
                  state.leftNavigatorSelectedModule = response.data.data.id;
                  dispatch("getModules", response.data.data.project_id);
                  dispatch("getModuleDataOnLeftNavigator", response.data.data.id);
              }
              return response;
          })
          .catch(e => {
              console.log(e);
          });
    },
    multiStoreData({ commit, dispatch, state }, data) {
        return axios.post(`modules/collections/multi-store`, data)
        .then(response => {
            return response;
        })
        .catch(e => {
            // TODO error handling
        })
        .finally(() => {
            // TODO
        });
    },
    reOrderItem({ commit, dispatch, state, rootState }, data) {
        return axios.post(`modules/collections/reorder-item`, data)
        .then(response => {
            if(parseInt(data.parent_id) !== parseInt(data.old_parent)) {
                dispatch("getModuleDataOnLeftNavigator", state.leftNavigatorSelectedModule);
                // dispatch("fetchIndexData", {module: rootState.module_selection.moduleLevel});
            }
            return response;
        })
        .catch(e => {
            // TODO error handling
        })
        .finally(() => {
            // TODO
        });
    },
    storeBrainstormData({ commit, dispatch, state }, data) {
        return axios.post(`brainstorm`, data)
        .then(response => {

        })
        .catch(e => {
            // TODO error handling
        })
        .finally(() => {
            // TODO
        });
    },
    temporaryUpdate({ commit, dispatch, state }, obj) {
        commit("setSavedData", obj);
    },
    regularUpdate({ commit, dispatch, state }, obj) {
        delete obj.order;
        delete obj.parent_id;
        delete obj.updated_at;
        delete obj.created_at;
        delete obj.created_by;
        commit("setSavedData", obj);
        return axios.post(`modules/collections/${obj.id}/update`, obj)
            .then(response => {
                //
            })
            .catch(error => {
                // TODO error handling
            }).finally(() => {
                // TODO
            });
    },
    deleteItem({ commit, dispatch, state }, obj) {
        return axios.delete(`modules/collections/${obj.id}`)
            .then(response => {
                dispatch("getModuleDataOnLeftNavigator", obj.module_id);
                if(state.library === 1) {
                    dispatch("loadCurrentData");
                }
                return response;
            })
            .catch(e => {
                // TODO error handling
            })
            .finally(() => {
                // TODO
            });
    },
    loadCurrentData({ commit, dispatch, state, rootState }) {
        let moduleId = 0;
        let moduleObj = state.projectModules[rootState.module_selection.moduleLevel - 1];
        if(moduleObj) {
            moduleId = moduleObj.id;
        }
        return axios.get(`modules/${moduleId}/collections/current?level=${state.level}`)
            .then(response => {
                commit("setCurrentData", response.data.data);
                return response;
            })
            .catch(e => {
                // TODO
            })
            .finally(() => {
                // TODO
            });
    },
    loadBottomGlobal({ commit, dispatch, state, rootState }, id) {
        return axios.get(`workspaces/${id}/level-collections?level=${state.level}`)
            .then(response => {
                commit("setCurrentData", response.data.data);
                return response;
            })
            .catch(e => {
                //
            });
    },
    loadGlobalLibraries({ commit, state }, id) {
        return axios.get(`workspaces/${id}/collections`)
            .then(response => {
                commit("setLibraries", response.data);
                return response;
            })
            .catch(e => {
                //
            });
    },
    loadTemplate({ commit, state }) {
        state.libraryList = [];
    },
    moveToViewerMode({ commit, state }) {
        // state.data = [];
    },
    moveToEditorMode({ commit, dispatch, state }) {
        dispatch("getModuleDataOnLeftNavigator", state.leftNavigatorSelectedModule);
    },
    loadViewerModeData({ commit, state }, payload) {
        return axios.get(`modules/viewers/${payload.id}?level=${payload.level}`)
            .then(response => {
                commit('setData', response.data.data);
                return response;
            })
            .catch(e => {
                //
            });
    },
    loadWorkspaceModules({ commit, state }, id) {
        return axios.get(`workspaces/${id}/modules`)
            .then(response => {
                commit("setWorkspaceModules", response.data);
                return response;
            })
            .catch(e => {
                //
            })
    },
    updateBrainstormItem({ commit, state }, payload) {
        return axios.post(`brainstorm/${payload.id}/update`, payload)
            .then(response => {
                return response;
            })
            .catch(e => {
                //
            });
    },
    getModulePropertySet({ commit, state }, id) {
        return axios.get(`properties/set/${id}`)
            .then(response => {
                let data = response.data && response.data.data ? response.data.data : [];
                commit("setModulePropertySet", data);
                return response.data;
            })
            .catch(e => {
                // TODO
            });
    },
    tempSetPropertySet({ commit, state }, payload) {
        commit("setTempPropertySet", payload);
    },
    updateModule({ commit, state }, payload) {
        return axios.post(`modules/${payload.id}/update`, payload)
            .then(response => {
                return response;
            })
            .catch(e => {
                //
            });
    }
}

const mutations = {
    setModules(state, value) {
        state.projectModules = value.data;
    },
    setProperties(state, value) {
        state.collectionProps = value.data;
    },
    setActiveModuleCollection(state, value) {
        state.activeModuleCollection = {};
        state.activeModuleCollection = value;
    },
    setContxtmenuCollection(state, value) {
        state.contxtmenuCollection = value;
    },
    setBrainstorms(state, value) {
        state.brainstorms = value.data;
    },
    setActiveBrainstorm(state, value) {
        state.activeBrainstorm = value.data;
    },
    setData(state, value) {
        state.data = value.data
    },
    setCurrentData(state, value) {
        state.currentData = value
    },
    setCurrentSettings(state, value) {
        state.currentSettings = value
    },
    setSavedData(state, value) {
        let item = state.currentData.find(item => parseInt(item.id) === parseInt(value.id));
        if(item) {
            item.title = value.title;
            item.description = value.description;
        }
    },
    deleteFromCurrentData(state, value) {
        let itemIndex = state.currentData.findIndex(item => item.id === value);
        if(itemIndex) {
            state.currentData.splice(itemIndex, 1);
        }
    },
    pushNewItem(state, itemObj) {
        let itemLevel = parseInt(itemObj.response.level);
        if(itemLevel === 1) {
            state.data.push(itemObj.response);
        } else {
            if(itemObj.request.relation && itemObj.request.relation.l1) {
                let l1Obj = state.data.find(l1 => parseInt(l1.id) === parseInt(itemObj.request.relation.l1));
                if(l1Obj) {
                    if(itemObj.request.relation.l2) {
                        let l2Obj = l1Obj.child.find(l2 => parseInt(l2.id) === parseInt(itemObj.request.relation.l2));
                        if(l2Obj) {
                            if(itemObj.request.relation.l3) {
                                let l3Obj = l2Obj.child.find(l3 => parseInt(l3.id) === parseInt(itemObj.request.relation.l3));
                                if(l3Obj) {
                                    l3Obj.child.push(itemObj.response);
                                }
                            } else {
                                l2Obj.child.push(itemObj.response);
                            }
                        }
                    } else {
                        l1Obj.child.push(itemObj.response);
                    }
                }
            }
        }
    },
    setLibraries(state, value) {
        // value.data.forEach(item => {
        //    item.clicked = false;
        // });
        state.libraryList = value.data;
    },
    setWorkspaceModules(state, value) {
        state.workspaceModules = value.data;
    },
    setModulePropertySet(state, value) {
        state.modulePropertySet = [];
        state.modulePropertySet = value;
    },
    setTempPropertySet(state, value) {
        state.modulePropertySet = [];
        state.modulePropertySet = value;
    },
};

export default {
    namespaced: true,
    state: getDefaultState,
    getters,
    actions,
    mutations
}
