<template>
    <!-- <modal name="ps-create-modal" @closed="closeModal()" :height="'auto'" :classes="'bg-primary-three'"> -->
        <div class="p-10" style="width: 680px;">
            <div class="flex justify-between border-b-2 border-primary-one">
              <h3 class="font-poppins text-primary-one fs-20 fw-500 mb-5">Create Project Scenario</h3>
            </div>
            <div class="i-mt-30">
                <div>
                    <h4 class="font-poppins text-secondary-one fs-14">Scenario Title:</h4>
                    <input type="text" class="bg-transparent mt-2 font-poppins text-secondary-one fs-14 placeholder-secondary-two w-full px-4 h-10 i-border-1 border-solid border-secondary-five rounded" v-model="scenario.title" placeholder="Type here" v-click-outside="outsideTitleInput">
                </div>

                <div class="i-mt-30">
                    <h4 class="font-poppins text-secondary-one fs-14">Category</h4>
                    <input type="text" class="bg-transparent mt-2 font-poppins text-secondary-one fs-14 placeholder-secondary-two w-full px-4 h-10 i-border-1 border-solid border-secondary-five rounded" v-model="scenario.category" placeholder="Type here" v-click-outside="outsideCategoryInput">
                </div>

                <div class="flex justify-between items-center gap-6 mt-5 mb-6">
                    <div class="relative flex gap-12 justify-between items-center px-4 py-3 rounded border border-secondary-five">
                        <label @click="showColorPanel">
                            <svg width="30" height="16" viewBox="0 0 30 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <mask id="mask0_14924_29940" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="30" height="16">
                                    <rect width="30" height="16" fill="#C4C4C4"/>
                                </mask>
                                <g mask="url(#mask0_14924_29940)">
                                    <rect x="15.4844" width="14.5161" height="8" fill="#19A77C"/>
                                    <rect width="14.5161" height="7" transform="matrix(1 0 0 -1 15.4844 16)" fill="#0085FF"/>
                                    <rect width="14.5161" height="8" transform="matrix(-1 0 0 1 14.5156 0)" fill="#D6D6D6"/>
                                    <rect x="14.5156" y="16" width="14.5161" height="7" transform="rotate(-180 14.5156 16)" fill="#FE5656"/>
                                </g>
                            </svg>
                            <input ref="colorPicker" type="color" v-model="color" class="color_picker_wrapper absolute top-0 left-0 opacity-0 cursor-pointer" />
                        </label>

                        <Switch :callBack="(res)=>{scenario.is_default = (res==false) ? 0 : 1}" text="Preview"/>
                    </div>
                    <p>Please select a color for the scenario marker</p>
                </div>

                <div class="i-mt-30 flex gap-6 items-center">
                    <label class=" flex gap-3 items-center cursor-pointer">
                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M19.35 10.04C18.67 6.59 15.64 4 12 4 9.11 4 6.6 5.64 5.35 8.04 2.34 8.36 0 10.91 0 14c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96zM19 18H6c-2.21 0-4-1.79-4-4 0-2.05 1.53-3.76 3.56-3.97l1.07-.11.5-.95C8.08 7.14 9.94 6 12 6c2.62 0 4.88 1.86 5.39 4.43l.3 1.5 1.53.11c1.56.1 2.78 1.41 2.78 2.96 0 1.65-1.35 3-3 3zM8 13h2.55v3h2.9v-3H16l-4-4z"/></svg>
                        <span>File Upload</span>
                        <input @input="(e)=>{onChange(e), fileUpload(file)}" hidden type="file" accept="image/x-png,image/jpeg" />
                    </label>
                    <img v-if="previewURL" class="w-10 h-8 object-cover" :src="previewURL" />
                </div>

                <div class="flex i-mt-30">
                    <button class="shadow round-6px fs-14 fw-600 font-poppins i-pl-35 i-pr-35 py-3 bg-secondary-one text-primary-three shadow-one" @click="createScenario(() => previewURL = '')" :disabled="disableClick">Create now</button>
                    <button class="round-6px focus:outline-none fs-14 fw-600 font-poppins px-7 py-3 i-border-1 border-secondary-one ml-5 text-primary-one" @click="()=>{$emit('closeModal'); previewURL=''}" :disabled="disableClick">Cancel</button>
                </div>
            </div>
        </div>
    <!-- </modal> -->
</template>



<script setup>
    import Switch from '@/elements/Switch.vue'
    // import { categories } from '@/mock/category'
    import { useFile } from '@/composable/useFile.js'
    const { onChange, previewURL, file } = useFile()
</script>

<script>
    import {mapGetters} from "vuex";
    export default {
        name: "CreateScenario",
        props: ['dModal'],
        data() {
            return {
                color: '#888',
                disableClick: false,
                scenario: {
                    title: '',
                    category: '',
                    is_default: 0
                },
                scenarioPlaceholder: {
                    title: 'Your Scenario',
                    category: 'Your Category'
                },
                formData: new FormData()
            }
        },
        computed: {
            ...mapGetters({
                loading: "GET_LOADING_STATE"
            })
        },
        watch: {
            dModal: function (val) {
                if(val) {
                    this.$modal.show('ps-create-modal');
                }
            }
        },
        methods: {
            showColorPanel() {
                this.$refs.colorPicker.click()
            },
            closeModal() {
                this.scenario = {
                    title: '',
                    category: ''
                };
                this.scenarioPlaceholder = {
                    title: 'Your Scenario',
                    category: 'Your Category'
                };
                this.$modal.hide('ps-create-modal');
                this.$emit("hideCreateModal");
            },
            appends(data) {
                for(let key in data){
                    this.formData.append(key, data[key])
                }
            },
            fileUpload(file){
                this.scenario.image = file
            },
            createScenario(callback = () => {})
            {
                const self = this;
                self.$Progress.start();
                self.$store.state.loading = true;
                self.scenario.settings = {color: this.color}

                self.scenario.project_id = self.$route.params.id;

                this.appends(this.scenario)
                self.disableClick = true;
                self.$store.dispatch('programmatic/storeScenario', this.formData).then(response => {
                    self.disableClick = false;
                    self.$store.dispatch("project/getSolidObject", self.$route.params.id)

                    if(response) {
                        self.$store.dispatch("programmatic/loadScenarios", self.$route.params.id);
                        callback()
                        self.closeModal();
                        self.$store.state.loading = false;
                    } else {
                        self.$store.state.loading = false;
                        alert('Something went wrong!!!')
                    }
                    self.$Progress.finish();
                    this.$emit('closeModal')
                });

            },

            outsideTitleInput() {
                this.scenarioPlaceholder.title = 'Your Scenario'
            },
            outsideCategoryInput() {
                this.scenarioPlaceholder.category = 'Your Category'
            }
        }
    }
</script>

<style scoped>
    .round-6px{
        border-radius: 6px;
    }
    .shadow{
        box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.15);
    }
    .one-color-stash {
        display: none;
    }
</style>
<style>
    .one-color-stash {
        display: none;
    }
</style>
