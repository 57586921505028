import axios from 'axios';
import route from '../../../routes/routes';

function getDefaultState() {
    return {
        assessments: [],
        assessmentModule: {},
        comments: [],
        workingAssessment: null,
        assessmentInfo: null,
        assessmentValues: [],
        assessmentResponderValues: [],
        projects: [],
        insights: [],
        participationStatus: false,
        propsChecked: [],
        assessInfo: null
    }
}

const getters = {
    assessments: state => state.assessments,
    assessmentModule: state => state.assessmentModule,
    comments: state => state.comments,
    workingAssessment: state => state.workingAssessment,
    assessmentInfo: state => state.assessmentInfo,
    assessmentValues: state => state.assessmentValues,
    assessmentResponderValues: state => state.assessmentResponderValues,
    projects: state => state.projects,
    insights: state => state.insights,
    participationStatus: state => state.participationStatus,
    propsChecked: state => state.propsChecked,
    assessInfo: state => state.assessInfo,
}

const actions = {
    getProjectAndUserOfWorkspace({ commit, dispatch, state }, id) {
      return axios.get(`workspaces/projects/${id}`)
          .then(response => {
              commit("setProjects", response.data);
              return response;
          }).catch(e => {
              console.log(e);
          });
    },
    getAssessments({ commit, dispatch, state }, payload) {
        return axios.get(`projects/assessments/${payload.id}`)
            .then(response => {
                commit("setAssessments", response.data);
                return response;
            }).catch(e => {
                //
            });
    },
    getScenarioCollection({ commit, dispatch, state }, payload) {
        let queryParams = route.history.current.query.from_notification ? '?accept_assignee=' + route.history.current.query.from_notification : '';
        return axios.get(`projects/assessments/${payload.assessment_id}/scenario${queryParams}`)
            .then(response => {
                commit("setAssessmentModule", response.data);
                return response;
            }).catch(e => {
                //
            });
    },
    storeAssessment({commit, dispatch, state}, obj) {
        return axios.post('projects/assessments/store', obj)
            .then(response => {
                dispatch("getAssessments", {
                    id: obj.project_id
                });
                if(response.data.data) {
                    state.workingAssessment = response.data.data.id;
                    state.assessmentInfo = response.data.data;
                }
                return response.data;
            })
            .catch(error => {
                // TODO error handling
            })
            .finally(() => {
                //
            })
    },
    updateAssessment({ commit, state }, payload) {
        return axios.post(`projects/assessments/${payload.id}/update`, payload)
            .then(response => {
                return response;
            }).catch(e => {
                //
            });
    },
    deleteItem({ commit, dispatch }, payload) {
        return axios.delete(`projects/assessments/${payload.assessment_id}`)
            .then(response => {
                dispatch("getAssessments", {
                    id: payload.id
                });
                return response;
            }).catch(e => {
                //
            });
    },
    getComments({ commit, dispatch, state }, payload) {
        return axios.get(`projects/assessments/comments/${payload.assessment_id}`)
            .then(response => {
                commit('setComments', response.data);
                return response;
            }).catch(e => {
                //
            });
    },
    storeComment({ commit, dispatch, state }, payload) {
        return axios.post(`projects/assessments/comments/store`, payload)
            .then(response => {
                if(response.data.data) {
                    state.comments.unshift(response.data.data);
                }
                return response;
            }).catch(e => {
                //
            })
    },
    getInsights({ commit, dispatch, state }, payload) {
        return axios.get(`projects/assessments/insights/${payload.assessment_id}`)
            .then(response => {
                commit('setInsights', response.data);
                return response;
            }).catch(e => {
                //
            });
    },
    storeInsight({ commit, dispatch, state }, payload) {
        return axios.post(`projects/assessments/insights/store`, payload)
            .then(response => {
                if(response.data.data) {
                    dispatch('getInsights', {
                        assessment_id: payload.assessment_id
                    })
                }
                return response;
            }).catch(e => {
                //
            })
    },
    updateInsight({ commit, dispatch, state }, payload) {
        axios.post(`projects/assessments/insights/${payload.id}/update`, payload)
            .then(response => {
                if(response.data.data) {
                    dispatch('getInsights', {
                        assessment_id: payload.assessment_id
                    })
                }
            })
            .catch(error => {
                // TODO error handling
            }).finally(() => {
                // TODO
            });
    },
    deleteInsight({ commit, dispatch }, payload) {
        return axios.delete(`projects/assessments/insights/${payload.id}`)
            .then(response => {
                dispatch("getInsights", {
                    assessment_id: payload.assessment_id
                });
                return response;
            }).catch(e => {
                //
            });
    },
    setWorkingAssessment({ commit, dispatch, state }, id) {
        state.workingAssessment = id;
        state.assessmentInfo = state.assessmentModule.assessment;
    },
    sendMessage({ commit, dispatch, state }, payload) {
        return axios.post(`projects/assessments/send/${state.assessmentInfo.id}`, payload)
            .then(response => {
                return response;
            }).catch(e => {
                //
            })
    },
    getAssessmentValueForCreator({ commit, state }, payload) {
        return axios.post(`projects/assessments/reviews/${payload.assessment_id}/creator/initial`, payload)
            .then(response => {
                commit("setAssessmentValue", response.data);
                return response;
            }).catch(e => {
                //
            });
    },
    getAssessmentFilteredValue({ commit, state }, payload) {
        return axios.post(`projects/assessments/reviews/${payload.assessment_id}/creator/filter`, payload)
            .then(response => {
                commit("setAssessmentValue", response.data);
                return response;
            }).catch(e => {
                //
            });
    },
    getAssessmentValueForResponder({ commit, state }, payload) {
        return axios.get(`projects/assessments/reviews/${payload.assessment_id}/responder`)
            .then(response => {
                commit("setAssessmentResponderValue", response.data);
                return response;
            }).catch(e => {
                //
            });
    },
    getAssigneeParticipationStatus({ commit, state }, payload) {
        return axios.get(`projects/assessments/assignee/${payload.id}`)
            .then(response => {
                commit("setAssigneeParticipationStatus", response.data);
                return response;
            }).catch(e => {
                //
            });
    },
    storeAssessmentValue({ commit, dispatch, state }, payload) {
        return axios.post(`projects/assessments/reviews/${payload.assessment_id}/store`, payload)
            .then(response => {
                dispatch("getAssessmentValueForResponder", {assessment_id: payload.assessment_id})
                return response;
            }).catch(e => {
               //
            });
        },
    storeAssessmentHistory({ commit, dispatch, state }, payload) {
        return axios.post(`projects/assessments/reviews/${payload.assessment_id}/history/store`, payload.data)
            .then(response => {
                dispatch("getAssessmentValueForResponder", {assessment_id: payload.assessment_id})
                return response;
            }).catch(e => {
                //
            });
        },
    removeAssignee({ commit, dispatch, state }, payload) {
        return axios.delete(`projects/assessments/assignee/${payload.assessment_assignee_id}`)
            .then(response => {
                dispatch("getAssessments", payload);
            }).catch(e => {
                //
            });
    },
    updateAssignee({ commit, dispatch, state }, payload) {
        return axios.post(`projects/assessments/assignee/${payload.id}/update`, payload)
            .then(response => {
                dispatch("getAssigneeParticipationStatus", payload);
            }).catch(e => {
                //
            });
    },
    setPropsChecked({ commit, state }, payload) {
        state.propsChecked = payload;
    },
    setAssessInfo({ commit, state }, payload) {
        state.assessInfo = payload;
    }
};

const mutations = {
    setAssessments(state, value) {
        state.assessments = value.data
    },
    setAssessmentModule(state, value) {
        state.assessmentModule = value.data;//assessment_module-> sceanriocollecion -> [assessment_properties]
        state.assessmentModule.collection_settings = JSON.parse(state.assessmentModule.collection_settings);
        state.assessmentInfo = state.assessmentModule.assessment;

        let property = state.assessmentModule.assessment.property;
        property.property_collections = state.assessmentModule.assess_properties;
        state.assessmentModule.system_property = property;
    },
    setAssessmentValue(state, value) {
        state.assessmentValues = value.data;
    },
    setAssessmentResponderValue(state, value) {
        state.assessmentResponderValues = value.data;
    },
    setComments(state, value) {
        state.comments = value.data;
    },
    setProjects(state, value) {
        state.projects = value.data;
    },
    setInsights(state, value) {
        value.data.forEach(el => {
            if (el.properties) {
                el.properties = JSON.parse(el.properties)
            }
            else {
                el.properties = []
            }
        });
        state.insights = value.data;
    },
    setAssigneeParticipationStatus(state, value) {
        state.participationStatus = value.data.participation_status;
    },
};

export default {
    namespaced: true,
    state: getDefaultState,
    getters,
    actions,
    mutations
}
