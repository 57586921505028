export default [
    {
        path: '/workspace',
        component: () => import(/* webpackChunkName: "workspaceDashboard" */ '@/views/screens/workspace/Index.vue'),
        name: 'workspace_dashboard',
        redirect: {
            name: 'workspace.myProjects'
        },
        children: [
            {
                path: '',
                component: () => import(/* webpackChunkName: "myProject" */ '@/views/screens/workspace/MyProjects.vue'),
                name: 'workspace.myProjects',
            },
            {
                path: 'how-it-works',
                component: () => import(/* webpackChunkName: "HowItWorks" */ '@/views/screens/workspace/HowItWorks.vue'),
                name: 'workspace.howItWorks',
            },
            {
                path: 'default-templates',
                component: () => import(/* webpackChunkName: "DefaultTemplates" */ '@/views/screens/workspace/DefaultTemplates.vue'),
                name: 'workspace.defaultTemplates',
            },
            {
                path: 'user-profile',
                component: () => import(/* webpackChunkName: "UserProfile" */ '@/views/screens/workspace/UserProfile.vue'),
                name: 'workspace.userProfile',
                redirect: { name: 'workspace.myProfile'},
                children: [
                    {
                        path: 'my-profile',
                        component: () => import(/* webpackChunkName: "UserProfile" */ '@/views/screens/workspace/Profile.vue'),
                        name: 'workspace.myProfile',
                    },
                    {
                        path: 'plans-billing',
                        component: () => import(/* webpackChunkName: "UserProfile" */ '@/views/screens/workspace/PlansBilling.vue'),
                        name: 'workspace.plansBilling',
                    },
                    {
                        path: 'subscription',
                        component: () => import(/* webpackChunkName: "Subscription" */ '@/views/screens/workspace/subscription/Index.vue'),
                        name: 'workspace.subscription',
                        redirect: { name: 'subscription.plans'},
                        children: [
                            {
                                path: 'plans/workspace/:workspace_id',
                                component: () => import(/* webpackChunkName: "Subscription" */ '@/views/screens/workspace/subscription/Plans.vue'),
                                name: 'subscription.plans',
                            },
                            {
                                path: 'payment/workspace/:workspace_id/plan/:plan_id',
                                component: () => import(/* webpackChunkName: "Subscription" */ '@/views/screens/workspace/subscription/Payment.vue'),
                                name: 'subscription.payment',
                                props: true
                            },
                            {
                                path: 'summary/:plan_id',
                                component: () => import(/* webpackChunkName: "Subscription" */ '@/views/screens/workspace/subscription/Summary.vue'),
                                name: 'subscription.summary',
                                props: true
                            },
                            {
                                path: 'success',
                                component: () => import(/* webpackChunkName: "Subscription" */ '@/views/screens/workspace/subscription/Success.vue'),
                                name: 'subscription.success',
                            },
                            {
                                path: 'failed',
                                component: () => import(/* webpackChunkName: "Subscription" */ '@/views/screens/workspace/subscription/Failed.vue'),
                                name: 'subscription.failed',
                            },
                        ]
                    },
                    {
                        path: 'contribution-system',
                        component: () => import(/* webpackChunkName: "UserProfile" */ '@/views/screens/workspace/ContributionSystem.vue'),
                        name: 'workspace.contributionSystem',
                    },
                    {
                        path: 'members',
                        component: () => import(/* webpackChunkName: "UserProfile" */ '@/views/screens/workspace/Members.vue'),
                        name: 'workspace.members',
                    },
                    {
                        path: 'settings',
                        component: () => import(/* webpackChunkName: "UserProfile" */ '@/views/screens/workspace/Setting.vue'),
                        name: 'workspace.settings',
                    },
                ]
            }

        ]
    },
    {
        path: '/workspace/:id/settings',
        component: () => import(/* webpackChunkName: "settings" */ '@/views/screens/workspace/settings'),
        name: 'workspace_settings'
    },
    {
        path: '/workspace/:workspaceId/project/:projectId/planning',
        component: () => import('@/views/screens/planning/Index'),
        name: 'implementationPlaning'
    }
]