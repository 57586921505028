<template>
   <svg class="inline" version="1.1" viewBox="0 0 3.962 3.142" xmlns="http://www.w3.org/2000/svg">
    <g transform="translate(-90.19 -139.2)">
    <g transform="matrix(.2646 0 0 .2646 89.53 138.1)" fill="none">
    <g >
        <path d="m6.875 5h10" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.2"/>
        <path d="m6.875 10h10" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.2"/>
        <path d="m6.875 15h10" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.2"/>
        <path d="m3.438 5.938c0.5178 0 0.9375-0.4197 0.9375-0.9375s-0.4197-0.9375-0.9375-0.9375-0.9375 0.4197-0.9375 0.9375 0.4197 0.9375 0.9375 0.9375z" fill="currentColor"/>
        <path d="m3.438 10.94c0.5178 0 0.9375-0.4197 0.9375-0.9375 0-0.5178-0.4197-0.9375-0.9375-0.9375s-0.9375 0.4197-0.9375 0.9375c0 0.5178 0.4197 0.9375 0.9375 0.9375z" fill="currentColor"/>
        <path d="m3.438 15.94c0.5178 0 0.9375-0.4197 0.9375-0.9375s-0.4197-0.9375-0.9375-0.9375-0.9375 0.4197-0.9375 0.9375 0.4197 0.9375 0.9375 0.9375z" fill="currentColor"/>
    </g>
    </g>
    </g>
    </svg>
</template>

<script>
export default {
    name: "ListView"
}
</script>
