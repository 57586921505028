import axios from 'axios';

function getDefaultState() {
    return {
        notifications: [],
        types: []
    }
}

const getters = {
    notifications: state => state.notifications,
    types: state => state.types,
}

const actions = {
    getNotifications({commit, state}) {
        return axios.get(`notifications`)
            .then(response => {
                commit('setNotifications', response.data);
                return response;
            }).catch(error => {
                // TODO error handling
            });
    },
    getNotificationTypes({commit, state}) {
        return axios.get('notification-types')
            .then(response => {
                commit("setNotificationTypes", response.data);
                return response;
            }).catch(e => {
                // TODO error handling
            });
    },
    markAllAsRead({ commit, dispatch }) {
        return axios.post('mark-all-as-read')
            .then(response => {
                dispatch("getNotifications");
                return response;
            }).catch(e => {
                // TODO error handling
            });
    },
    markAsRead({ commit, dispatch }, payload) {
        return axios.post(`mark-as-read`, {
            id: payload.id
        }).then(response => {
        }).catch(e => {
            console.log(e);
        })
    }
};

const mutations = {
    setNotifications(state, value) {
        state.notifications = value.data;
    },
    setNotificationTypes(state, value) {
        state.types = value.data;
    }
};

export default {
    namespaced: true,
    state: getDefaultState,
    getters,
    actions,
    mutations
}
