<template>
    <svg class="inline"  viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M24.375 15L19.6875 5.625L15 15L19.6875 24.375L24.375 15Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M10.3125 5.625L5.625 15L10.3125 24.375" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M15 5.625L10.3125 15L15 24.375" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
</template>

<script>
export default {
    name: "Scenario"
}
</script>