<template>
    <div class="flex gap-3" :class="isTitleBottom ? 'flex-col' : ''" :title="hoverItem">
        <ImageSquare
            :path="imagePath"
        />
        <div>
            <p v-if="title" class="fs-13 fw-600 text-primary-one i-n-mt-4">{{ title }}</p>
            <p v-if="subtitle" class="fs-10 fw-400 text-secondary-two">{{ subtitle }}</p>
        </div>
    </div>
</template>

<script>
import ImageSquare from '@/elements/atom/ImageSquare.vue'
export default {
    name: 'AvatarWithName',
    components: {
        ImageSquare
    },
    props: {
        imagePath: String,
        isTitleBottom: Boolean,
        title: String,
        subtitle: String,
        hoverItem: String
    }

}
</script>
