<template>
    <div class="expand-item">
    <div class="flex">
      <h2 class="fs-18 fw-500 mt-2 mb-1" >{{ getMaxValue(property.property_collections, 'sample_value') }}</h2>
    </div>
    <div class="grid grid-cols-2 gap-8">
        <div>
            <p class="fw-700 fs-14">{{ getDefinition(property.property_collections, 1) }}</p>
            <p class="fw-400 fs-10 italic">{{ getDefinition(property.property_collections, 3) }}</p>
        </div>
        <div class="w-50 pl-4 flex justify-between items-end chartcontent pb-2">
          <!-- <div class="flex justify-end bg-primary-four opacity-75 relative bar-radius shadow-two"
               v-for="(propCollection, propCIndex) in getPropertyValue(item)"
               :key="propCIndex"
               :class="{'opacity-100': item.choosed_property_param_id && parseInt(item.choosed_property_param_id) === parseInt(propCollection.property_collection_id)}"
               :style="{'width': propCollection.width + 'px', 'height': propCollection.sample_value * 10 + '%'}">

          </div> -->
          <div class="flex justify-end bg-primary-four relative bar-radius shadow-two opacity-75"
               v-for="(parameter, pIndex) in getModulePropertyParams(property)"
               :key="pIndex"
               :class="{'opacity-100': checkMaxValue(getMaxValue(property.property_collections), parameter)}"
               :style="{'width': getWidth + 'px', 'height': getParameterValue(parameter) * 10 + '%'}">
          </div>
        </div>
    </div>
    <div class="fs-10">
        <property-param :properties="property.property_collections" :activeScenarioCollection="activeData" :index="index" />
    </div>

  </div>
</template>

<script>
import {mapGetters} from "vuex";
import PropertyParam from "@/components/InstMenu/right-navigation-drawer/components/PropertyParam";

export default {
    name: 'MetricChart',
    components: {
      PropertyParam
    },
    props: {
      activeData: {
        type: Object
      },
      activePageData: {
        type: Object
      },
      property: {
        type: Object
      },
      index: {
        type: Number
      }
    },
    data() {
      return {
        width: 140
      }
    },
    computed: {
      ...mapGetters({
        definitionSets: 'definition/definitionSets'
      }),
      getWidth() {
        return (this.width - (this.property.property_collections.length * 7)) / this.property.property_collections.length;
      }
    },
    methods: {
      getModulePropertyParams(property) {
        if(property && property.property_collections.length > 0) {
          return property.property_collections.filter(param => !param.option || param.option == 1);
        }
        return [];
      },
      getParameterValue(parameter) {
        let parameterValueObj = this.activeData.properties.find(item => item.property_collection_id === parameter.id);
        if(parameterValueObj) {
          return parameterValueObj.sample_value;
        }
        return 0;
      },
      getMaxValue(parameters, key = null) {
        let maxObject = null;
        if(parameters) {
          parameters = parameters.map(item => {
            return !item.option || item.option == 1 ? item.id : null
          });
          if(parameters.length > 0) {
            let activeParameters = this.activeData.properties.filter(item => parameters.includes(item.property_collection_id));
            maxObject = activeParameters.reduce(function (prev, current) {
              return (prev.sample_value > current.sample_value) ? prev : current
            });
          }
        }

        return key ? (maxObject[key] ? maxObject[key].toFixed(2) : 0.00) : maxObject;
      },
      getDefinition(parameters, type) {
        let parameter = this.getMaxValue(parameters);
        let value = parameter ? parameter.sample_value : 0;
        let definitionId = null;
        if(parameter) {
          let propertyCollection = parameters.find(data => data.id === parameter.property_collection_id);
          definitionId = propertyCollection ? propertyCollection.definition_id : null;
        }
        if(definitionId) {
          let definition = this.definitionSets.find(data => parseInt(data.id) === parseInt(definitionId));
          if(definition && definition.items.length > 0) {
            let definitionRangeArray = definition.items.find(item => item.type === type);
            if(definitionRangeArray) {
              if(definitionRangeArray && definitionRangeArray.range_with_definition && definitionRangeArray.range_with_definition.length > 0) {
                if(value > 0) {
                  value = value - .000001;
                }
                value = Math.floor(value / 2);
                if(value > 4) {
                  value = 4;
                }
                return definitionRangeArray.range_with_definition[value].definition;
              }
            }
          }
        }
        return 'Not found';
      },
      checkMaxValue(maxObj, parameter) {
        return !!(maxObj && maxObj.property_collection_id && maxObj.property_collection_id === parameter.id);
      }
   }
}
</script>

<style scoped>
.chartcontent {
  height: 60px;
  width: 145px
}
.bar-radius {
  border-radius: 2px 2px 0 0;
}
</style>
