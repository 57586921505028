<template>
    <svg width="40" height="40" viewBox="0 0 40 40" class="cursor-pointer" fill="none" xmlns="http://www.w3.org/2000/svg">
        <title>{{ title }}</title>
        <rect x="0.5" y="0.5" width="39" height="39" rx="3.5" :fill="activeRectFill" :stroke="activeStroke"/>
        <g filter="url(#filter0_d_216_52)">
        <rect x="10.5" y="10.5" width="19" height="19" rx="1.5" :stroke="activeFill" stroke-linecap="round" stroke-linejoin="round"/>
        <rect x="13.5" y="13.5" width="13" height="3" :stroke="activeFill" stroke-linecap="round" stroke-linejoin="round"/>
        <rect x="13.5" y="18.5" width="13" height="3" :stroke="activeFill" stroke-linecap="round" stroke-linejoin="round"/>
        <rect x="13.5" y="23.5" width="13" height="3" :stroke="activeFill" stroke-linecap="round" stroke-linejoin="round"/>
        </g>
        <defs>
        <filter id="filter0_d_216_52" x="-3" y="-1" width="46" height="46" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="2"/>
        <feGaussianBlur stdDeviation="4"/>
        <feComposite in2="hardAlpha" operator="out"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0.172549 0 0 0 0 0.203922 0 0 0 0 0.254902 0 0 0 0.1 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_216_52"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_216_52" result="shape"/>
        </filter>
        </defs>
    </svg>
</template>

<script setup>
    import useSVG from '@/composable/icons/useSVG';
    const props = defineProps({
        title: String,
        active: Boolean
    });
    const { activeFill, activeStroke,  activeRectFill} =  useSVG(props);
</script>