export default [
    {
        path: '/login',
        component: () => import('@/views/auth/Login.vue'),
        name: 'login'
    },
    {
        path: '/register',
        component: () => import('@/views/auth/Register.vue'),
        name: 'register'
    },
    {
        path: '/verify-notice',
        component: () => import('@/views/auth/VerifyNotification.vue'),
        name: 'verify_notice'
    },
    {
        path: '/verify-account',
        component: () => import('@/views/auth/VerifyAccount.vue'),
        name: 'verify_account'
    },
    {
        path: '/reset/password',
        component: () => import('@/views/auth/ResetPassword.vue'),
        name: 'reset_password'
    },
    {
        path: '/reset/password/email',
        component: () => import('@/views/auth/ResetPasswordMail.vue'),
        name: 'reset_password_email'
    },
]