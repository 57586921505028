import { indexOf } from "lodash"

export default {
    setConcepts: (state, data) => state.concepts = data,
    setBrainstorms: (state, data) => state.brainstorms = data,
    setProject: (state, data) => state.project = data,
    setScenarios: (state, data) => state.scenarios = data,
    setAssessmentComments: (state, data) => state.assessmentsComments = data,
    setComments: (state, data) => state.comments = data,
    setAssessmentInsights: (state, data) => state.assessmentsInsights = data,
    setAssociations: (state, data) => state.associations = data,
    setAssociationsByScenarioCollection: (state, data) => state.associationsByScenarioCollection = data,
    setInsights: (state, data) => state.insights = data,
    setInsight: (state, data) => {
        let insight = state.insights.find( item => item.id == data.id)
        // console.log(state.insights.indexOf(insight))
        state.insights.splice(state.insights.indexOf(insight),1, data)
        // insight = data
    },
    setOpenInsightCreateForm: (state, value) => state.openInsightCreateForm = value,
}
