export default [
    {
        path: '/project/:id/communicator',
        component: () => import('@/views/screens/communicator/Index'),
        name: 'communicators'
    },
    {
        path: '/project/:id/communicator/library',
        component: () => import('@/views/screens/communicator/v2/Library.vue'),
        name: 'communicators.library'
    },
    {
        path: '/project/:id/communicator/my-communication',
        component: () => import('@/views/screens/communicator/v2/MyCommunication.vue'),
        name: 'communicators.mycommunication'
    },
    {
        path: '/project/:id/communicator/:communicator_id',
        component: () => import('@/views/screens/communicator/v2/Index'),
        name: 'communicator'
    }
]
