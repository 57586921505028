<template>
    <div class="flex flex-col justify-center items-center">
        <component
            :content="componentName.title"  
            v-tippy="{ animateFill: false, theme : 'light bordered' }"
            @click.native="switchIcon"
            class="h-12 cursor-pointer opacity-70 hover:opacity-100"
            :is="componentName.name"
        >
        </component>
        <div class="flex gap-2 mt-3">
            <div
                v-for="n in 3" 
                :key="n" 
                @click="setCurrentComponentIndex(n-1)"
                :class="[currentComponentIndex == (n-1) ? 'bg-gray-700' : 'bg-gray-300']"
                class="w-2 h-2 rounded-full cursor-pointer"
            ></div>
        </div>
    </div>
     
</template>

<script setup>
import { computed } from 'vue'

// eslint-disable-next-line no-undef
const props = defineProps({
    currentComponentIndex: Number,
    setCurrentComponentIndex: Function
});
const components = [{name: 'IconProduction', title: 'Production'}, {name: 'IconLight', title: 'Teamwork'}, {name: 'IconDoughnutChart', title: 'Manage'}]
const componentName = computed(() => {
    return components[props.currentComponentIndex]
})

const switchIcon = () => {
    let currentIndex = components.indexOf(componentName.value)
    const nextIndex = ++currentIndex % components.length;
    props.setCurrentComponentIndex(nextIndex)
}

</script>