<template>
    <svg class="inline" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.99988 0.5C7.75689 0.5 10 2.74311 10 5.49988C10 8.25689 7.75689 10.5 4.99988 10.5C2.24311 10.5 0 8.25689 0 5.49988C0 2.74311 2.24311 0.5 4.99988 0.5ZM9.47376 5.49988C9.47376 3.033 7.46676 1.02624 4.99988 1.02624C2.533 1.02624 0.526238 3.033 0.526238 5.49988C0.526238 7.96676 2.533 9.97376 4.99988 9.97376C7.46676 9.97376 9.47376 7.96676 9.47376 5.49988Z" fill="currentColor"/>
        <path d="M2.88938 7.36787C2.78675 7.26474 2.78675 7.09846 2.88938 6.99533C2.9925 6.8927 3.15903 6.8927 3.26167 6.99533C3.70896 7.44312 4.30378 7.68958 4.93635 7.68958C5.56892 7.68958 6.16375 7.44312 6.61104 6.99533C6.6626 6.94426 6.72995 6.91836 6.7973 6.91836C6.86466 6.91836 6.93201 6.94426 6.98357 6.99533C7.08621 7.09846 7.08621 7.26474 6.98357 7.36787C6.43636 7.91483 5.70955 8.21582 4.93635 8.21582C4.16315 8.21582 3.43634 7.91483 2.88938 7.36787Z" fill="currentColor"/>
        <path d="M6.77758 3.888V4.94072C6.77758 5.08603 6.65965 5.20396 6.51434 5.20396C6.36927 5.20396 6.25134 5.08603 6.25134 4.94072V3.888C6.25134 3.74293 6.36927 3.625 6.51434 3.625C6.65965 3.625 6.77758 3.74293 6.77758 3.888Z" fill="currentColor"/>
        <path d="M3.62103 3.888V4.94072C3.62103 5.08603 3.5031 5.20396 3.35778 5.20396C3.21272 5.20396 3.09479 5.08603 3.09479 4.94072V3.888C3.09479 3.74293 3.21272 3.625 3.35778 3.625C3.5031 3.625 3.62103 3.74293 3.62103 3.888Z" fill="currentColor"/>
    </svg>
</template>

<script>
export default {
    name: "Aa"
}
</script>
