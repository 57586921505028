<template>
    <svg width="40" height="40" viewBox="0 0 40 40" class="cursor-pointer" fill="none" xmlns="http://www.w3.org/2000/svg">
        <title>{{ title }}</title>
        <rect x="0.5" y="0.5" width="39" height="39" rx="3.5" :fill="activeRectFill" :stroke="activeStroke"/>
        <rect width="0.999999" height="10" transform="matrix(7.49339e-08 -1 -1 -2.54983e-08 30 12)" :fill="activeFill"/>
        <rect width="1" height="10" transform="matrix(7.11871e-08 -1 -1 -2.68403e-08 30 25)" :fill="activeFill"/>
        <rect width="1" height="6" transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 16 22)" :fill="activeFill"/>
        <rect width="1" height="15" transform="matrix(1 0 0 -1 15 29)" :fill="activeFill"/>
        <rect width="1" height="10" transform="matrix(7.49338e-08 -1 -1 -2.54983e-08 30 18)" :fill="activeFill"/>
        <rect width="1" height="10" transform="matrix(7.11871e-08 -1 -1 -2.68403e-08 30 31)" :fill="activeFill"/>
    </svg>
</template>

<script setup>
    import useSVG from '@/composable/icons/useSVG';
    const props = defineProps({
        title: String,
        active: Boolean
    });
    const { activeFill, activeStroke,  activeRectFill} =  useSVG(props);
</script>