<template>
  <div>
    <SettingsAccordion
        :title="property.title"
        v-model="scale1"
        class="mb-2 mt-3"
        :text-classes="'text-primary-one fs-14 fw-500'"
    >
      <MetricChart
          :active-data="activeData"
          :active-page-data="activePageData"
          :property="property"
          :index="index" />
    </SettingsAccordion>
  </div>
</template>

<script>
import SettingsAccordion from '@/components/roadmap/canvas/widgets/SettingsAccordion';
import MetricChart from '@/components/InstMenu/right-navigation-drawer/components/MetricChart.vue';
export default {
  name: "MetricsProperty",
  components: {
    SettingsAccordion,
    MetricChart
  },
  props: {
    activeData: {
      type: Object
    },
    activePageData: {
      type: Object
    },
    property: {
      type: Object
    },
    index: {
      type: Number
    }
  },
  data: () => ({
    scale1: true,
  }),
}
</script>

<style scoped>

</style>
