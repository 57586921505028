<template>
  <div
    id="app"
    class="antialiased overlay relative font-poppins"
    style="width: 100%;"
    :class="[
        $route.name == 'login' || $route.name == 'register' || $route.name == 'verify_notice' || $route.name == 'verify_account' || $route.name == 'reset_password' || $route.name == 'reset_password_email' ? 'i-window' : '']"
    >
    <vue-progress-bar></vue-progress-bar>
    <div v-if="this.$route.name === 'home' || this.$route.name === 'contact' || this.$route.name === 'login' || this.$route.name === 'register' || this.$route.name === 'reset_password' || this.$route.name === 'reset_password_email' || this.$route.name === 'survey.page' || this.$route.name === 'verify_account' || this.$route.name === 'verify_notice'" class="h-full">
      <router-view></router-view>
    </div>
    <div v-else class="bg-body flex flex-col">
      <router-view></router-view>
    </div>
    <WorkspaceCreateModal />

    <transition name="slide-fade">
      <NavigationDrawer v-if="projectId && showRightDrawer"/>
    </transition>

  </div>
</template>

<script setup>
</script>

<script>
  import NavigationDrawer from '@/components/InstMenu/right-navigation-drawer/NavigationDrawer.vue';
  import WorkspaceCreateModal from '@/components/workspace/WorkspaceCreateModal.vue'
  import { mapGetters } from 'vuex';

  export default {
    name: 'App',
    components: {
      NavigationDrawer,
      WorkspaceCreateModal
    },
    methods: {

      toggleWorkspace(index) {
        this.$store.dispatch("workspace/toggleWorkspace", index);
        this.$store.dispatch("workspace/getDirectories", this.workspaces[index].id);
        this.$store.dispatch("workspace/getWorkspaceData", {
          type: 1,
          workspace_id: this.workspaces[index].id
        });
        if(this.$route.name === 'workspace_settings') {
          this.$store.dispatch("workspace/getWorkspaceSettingsInfo", this.workspaces[index].id);
        }
      },
    },
    computed: {
        ...mapGetters({
            workspaces: 'workspace/workspaces',
            activeWorkspace: 'workspace/activeWorkspace',
            project: "project/project",
            showRightDrawer: 'getRightBarVisibility',
        }),
        projectId() {
            return this.$route.params.id
        }
    },
    created() {
      this.$store.dispatch("notification/getNotificationTypes");
      this.$store.dispatch("global/getColorCodes");
      if(['workspace_dashboard','profile','workspace_settings'].includes(this.$route.name)) {
        this.$store.dispatch("workspace/fetchWorkspaces", 1).then(response => {
            if(response && response.data) {
                this.$store.dispatch("workspace/toggleWorkspace", 0);
                this.$store.dispatch("workspace/getDirectories", this.workspaces[0].id);
                this.$store.dispatch("workspace/getWorkspaceData", {
                    type: 1,
                    workspace_id: this.workspaces[0].id
                });
            }
        });
    }
    let user = localStorage.getItem("_user");
      if(user) {
        user = JSON.parse(user);
        if(user) {
          this.$store.dispatch("notification/getNotifications");
        }
    }

      if(this.project.workspace_id) {
        this.$store.dispatch("project/getSolidObject", this.$route.params.id);
      }
      this.$store.dispatch('getUser')
    }
  }
</script>

<style>
.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}
.bg-body {
  background: #EDEDED;
  /*bg-secondary-three*/
}
</style>
