import { render, staticRenderFns } from "./IconSquarsFour.vue?vue&type=template&id=0378f3ee&scoped=true&"
import script from "./IconSquarsFour.vue?vue&type=script&setup=true&lang=js&"
export * from "./IconSquarsFour.vue?vue&type=script&setup=true&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0378f3ee",
  null
  
)

export default component.exports