import axios from 'axios';

function getDefaultState() {
    return {
        activeStickyNote: {}
    }
}

const getters = {
    activeStickyNote: state => state.activeStickyNote
}

const actions = {
    
}

const mutations = {
    setActiveStickyNote(state, data)
    {
        state.activeStickyNote = data
    }
}

export default {
    namespaced: true,
    state: getDefaultState,
    getters,
    actions,
    mutations
}
