import { render, staticRenderFns } from "./CreateScenario.vue?vue&type=template&id=87562cb2&scoped=true&"
import script from "./CreateScenario.vue?vue&type=script&setup=true&lang=js&"
export * from "./CreateScenario.vue?vue&type=script&setup=true&lang=js&"
import style0 from "./CreateScenario.vue?vue&type=style&index=0&id=87562cb2&prod&scoped=true&lang=css&"
import style1 from "./CreateScenario.vue?vue&type=style&index=1&id=87562cb2&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "87562cb2",
  null
  
)

export default component.exports