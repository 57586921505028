<template>
    <div class="relative">
        <AvatarWithName
            :title="getCommentor(comment)"
            :subtitle="time(comment)"
            :image-path="getCommentImage(comment)"
        />
        <Paragraph
            class="i-ml-43"
            :is-list="isList"
            :paragraph="comment.comment"
        />
        <CommentFooter
            class="i-ml-43 mt-3"
            :isComment="true"
            :time="time(comment)"
            :showReply="showReplay"
            :replies="replies"
            :comment="comment"
            :toggleReply="toggleReply"
            :toggleReplies="toggleReplies"
        />
        <div class="space-y-3 mt-4" v-if="replies">
            <div class="i-ml-43" v-for="reply in  comment.replies" :key="reply.id">
                <AvatarWithName
                    :title="getCommentor(reply)"
                    :subtitle="time(reply)"
                    :image-path="getCommentImage(reply)"
                />
                <Paragraph
                    class="i-ml-43"
                    :is-list="isList"
                    :paragraph="reply.comment"
                />
                 <CommentFooter
                    class="i-ml-43 mt-3"
                    :isComment="false"
                    :time="time(reply)"
                    :showReply="showReplay"
                    :replies="replies"
                    :comment="reply"
                    :toggleReply="toggleReply"
                    :toggleReplies="toggleReplies"
                />
            </div>
        </div>

        <div v-if="showReplay" class="i-ml-43">
              <textarea class="bg-transparent placeholder-secondary-five border-secondary-two border rounded mt-4 px-2 py-2 fs-14" placeholder="Reply here" v-model="replyText" style="width: 100%">

              </textarea>
          <div class="space-x-5 mt-3 mb-5">
            <button class="bg-secondary-one rounded-sm text-white i-h-30 fw-400" @click="addReply(comment)"
                    :class="{'fs-10 w-12': !isList, 'fs-12 w-16' : isList}">Reply</button>
            <button @click="toggleReply" class="border border-secondary-two rounded-sm text-secondary-two i-h-30 fw-400"
                    :class="{'fs-10 w-12': !isList, 'fs-12 w-16' : isList}">Cancel</button>
          </div>
        </div>
    </div>
</template>

<script>
import Paragraph from '@/components/InstMenu/right-navigation-drawer/components/Paragraph.vue'
import TitleValue from '@/components/InstMenu/right-navigation-drawer/components/TitleValue.vue'
import AvatarWithName from '@/components/InstMenu/right-navigation-drawer/components/AvatarWithName'
import CommentFooter from '@/components/InstMenu/right-navigation-drawer/module/comments/CommentFooter.vue'
import ProfileImage from "@/assets/images/icons/profile/profile.png"
import {mapActions, mapGetters} from "vuex";

export default {
    name: 'Comment',
    props: {
        comment: Object,
        isList: Boolean,
        activeId: {
          type: Number
        }
    },
    components: {
        Paragraph,
        TitleValue,
        AvatarWithName,
        CommentFooter
    },
    data: () => ({
        showReplay: false,
        replyText: null,
        replies: false,
        image_path: ProfileImage
    }),
    computed: {
      ...mapGetters({
        profileData: "profile/data",
      }),
    },
    methods: {
        ...mapActions({
          storeComment: 'drawer/storeComment'
        }),
        toggleReply() {
            this.showReplay = !this.showReplay;
            this.replyText = '';
        },
        toggleReplies() {
          this.replies = !this.replies;
          this.replyText = '';
        },
        getCommentor(comment) {
          if(comment && comment.created_by) {
            let name = '';
            if(comment.created_by.first_name) {
              name = comment.created_by.first_name + ' ';
            }
            if(comment.created_by.last_name) {
              name = name + comment.created_by.last_name;
            }
            if(name) {
              return name;
            }
            return 'Not found';
          }
          return 'Not found';
        },
        time(comment) {
          if(comment && comment.created_at) {
            return this.$lodash.ago(comment.created_at);
          }
          return "Not found";
        },
        getCommentImage(comment) {
          return comment && comment.created_by && comment.created_by.image && comment.created_by.image.file_path ? comment.created_by.image.file_path : this.image_path;
        },
        addReply(comment) {
            let data = {
              scenario_collection_id: this.activeId,
              comment: this.replyText,
              comment_id: comment.id
            };

            this.replyText = '';
            this.toggleReply();
            data.created_by = Object.assign({}, this.profileData);
            data.created_by.image = { file_path: this.profileData.image };
            data.created_at = new Date();
            if(comment.replies) {
              comment.replies.unshift(data);
            } else {
              comment.replies = [data];
            }

            this.storeComment(data);
        }
    }
}
</script>
<style>
textarea {
    resize: none
}
</style>
