import axios from 'axios';

function getDefaultState() {
    return {
        assessments: [],
        assessment: null,
        assessmentData: [],
        intelModule: null,
        intelLists: [],
        intelItem: {},
        canvasStyle: 0,
        parameters: [],
        canvasTwo: {
            module: null,
            parameters: [],
            definitions: ['Low', "High"]
        },
    }
}

const getters = {
    assessments: state => state.assessments,
    assessment: state => state.assessment,
    assessmentData: state => state.assessmentData,
    intelModule: state => state.intelModule,
    intelLists: state => state.intelLists,
    intelItem: state => state.intelItem,
    parameters: state => state.parameters,
    canvasTwo: state => state.canvasTwo,
}

const actions = {
    toggleModule({ commit, dispatch, state }, payload) {
        state.intelModule = payload;
        state.parameters = [];
    },
    loadIntelLists({ commit, dispatch, state }, payload) {
        return axios.get(`brainstorms/intels/${payload.project_id}`)
            .then(response => {
                commit("setIntelLists", response.data);
                return response;
            }).catch(e => {
                console.log(e);
            });
    },
    getIntel({ commit, dispatch, state }, payload) {
        return axios.get(`brainstorms/intels/show/${payload.intel_id}`)
            .then(response => {
                commit("setIntel", response.data);
                return response;
            }).catch(e => {
                console.log(e);
            });
    },
    storeIntel({ commit, dispatch, state }, payload) {
        return axios.post(`brainstorms/intels`, payload)
            .then(response => {
                dispatch("loadIntelLists", payload);
                return response;
            }).catch(e => {
                console.log(e);
            });
    },
    updateIntel({ commit, dispatch, state }, payload) {
        return axios.post(`brainstorms/intels/update/${payload.id}`, payload)
            .then(response => {
                if(payload.stop) {
                    //
                } else {
                    dispatch("getIntel", {
                        intel_id: payload.id
                    })
                }
                return response;
            }).catch(e => {
                //
            });
    },
    setIntelStyle({ commit, dispatch, state }, index) {
        state.canvasStyle = index;
    },
    setParameters({ commit, dispatch, state }, payload) {
        if(!payload.default) {
            let paramIndex = state.parameters.findIndex(data => parseInt(data) === parseInt(payload.item.id));
            if(paramIndex >= 0) {
                state.parameters.splice(paramIndex, 1);
            } else {
                state.parameters.push(payload.item.id);
            }
        } else {
            state.parameters = payload.items;
        }
    },
    setCanvasTwoModule({ commit, dispatch, state }, payload) {
        state.canvasTwo.module = payload;
    },
    setCanvasTwoParameters({ commit, dispatch, state }, payload) {
        let paramIndex = state.canvasTwo.parameters.findIndex(data => parseInt(data) === parseInt(payload.id));
        if(paramIndex >= 0) {
            state.canvasTwo.parameters.splice(paramIndex, 1);
        } else {
            state.canvasTwo.parameters.push(payload.id);
        }
    },
    getAssessments({ commit, dispatch, state }, payload) {
        return axios.get(`projects/assessments/scenario/${payload.scenario_id}/module/${payload.module_id}`)
            .then(response => {
                commit("setAssessments", response.data);
                return response;
            }).catch(e => {
                //
            });
        },
    getAssessmentData({ commit, dispatch, state }, payload) {
        return axios.post(`projects/assessments/intel`, payload)
            .then(response => {
                commit("setAssessmentData", response.data);
                return response;
            }).catch(e => {
                //
            });
    }
};

const mutations = {
    setIntelLists(state, value) {
        state.intelLists = value.data;
    },
    setIntel(state, value) {
        state.intelItem = value.data;
        if(value.data.module_id) {
            state.parameters = JSON.parse(value.data.parameters);
        } else {
            state.parameters = value.data.scenario_settings.parameters;
        }
    },
    setAssessments(state, value) {
        state.assessments = value.data
    },
    setAssessment(state, value) {
        state.assessment = value
    },
    setAssessmentData(state, value) {
        state.assessmentData = value.data
    },
};

export default {
    namespaced: true,
    state: getDefaultState,
    getters,
    actions,
    mutations
}
