<template>
    <div>
        <p class="fs-14 fw-500" :class="titleTextColor" v-if="title">
            {{ getTitle(title) }}
        </p>
        <p :class="textColor" v-if="subtitle">
            {{ getSubtitle(subtitle) }}
        </p>
        <p :class="paragraphTextColor" class="fw-400 fs-12 mt-1" v-if="paragraph">
            {{ getParagraph(paragraph) }}
        </p>
    </div>
</template>

<script>
export default {
    name: 'Paragraph',
    props: {
        isList: {
            type: Boolean,
            required: true
        },
        titleTextColor: {
            default: 'text-black',
        },
        paragraphTextColor: {
            default: 'text-secondary-one',
        },
        title: String,
        subtitle: String,
        paragraph: String
    },
    methods: {
        getTitle(title) {
            if(this.isList) {
                if(title.length >= 45) {
                    return title.substring(0, 45) + '...'
                }
                return title
            }
            if(!this.isList) {
                if(title.length >= 15) {
                    return title.substring(0, 15) + '...'
                }
                return title
            }
        },
        getSubtitle(subtitle) {
            return subtitle
        },
        getParagraph(paragraph) {
            if(!this.isList) {
                if(paragraph.length >= 50) {
                    return paragraph.substring(0, 50) + '...'
                }
            }

            return paragraph
        }
    }
}
</script>