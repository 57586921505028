import axios from 'axios';


const state = function() {
    return {
        scenarios: [],
        configuredModules: [],
        breadcrumbs: []
    }
}

const getters = {
    configuredScenarios: (state) => state.configuredScenarios
}

const mutations = {
    setScenarios: (state, payload) => state.scenarios = payload,
    setShowDropdown: (state, payload) => state.showDropdown = payload,
    setBreadcrumbs: (state, payload) => { state.breadcrumbs = payload },
}

const actions = {
    /* Scenario template */
    getScenarios: async ({ commit }, payload) => {
       return await axios.get(`projects/scenarios/${payload.project_id}`).then(({ data }) => {
            commit('setScenarios', data.data);
        });
    },
    getConfiguredScenarios: async (context, communicatorId) => {
        return await axios.get(`master-scenario/communicator-module-template/${communicatorId}`).then(({ data }) => data.data);
    },
    /* Module template */
    getConfiguredModules: async (context, {masterScenarioId, scenarioId}) => {
        return await axios.get(`master-scenario/module/master-scenario/${masterScenarioId}/scenarios/${scenarioId}`).then(({ data }) => {
            return data.data.map(item => {
                item.parameters = JSON.parse(item.parameters)
                return item;
            });
        });
    },

    updateModuleConfiguration: async (context, {id, payload}) => {
      return await  axios.post(`/master-scenario/module/${id}/update`, payload);
    },
    /* useCase Template */
    getConfiguredWidgets: async (context, moduleId) => {
        return await axios.get(`master-scenario/module-layout-widget/master-scenario-module-layout/${moduleId}`).then(({ data }) => data.data);
    },
    getWidgets: async (context, layoutId) => {
       return await axios.get(`roadmaps/widget/roadmap-layout/${layoutId}`).then(({ data }) => data.data);
    },
    saveConfigurationWidget: async (context, payload) => {
      return await axios.post(`master-scenario/module-layout-widget/store`, payload);
    },
    /*People template */
    deleteScenario: async (context, id) => {
        return await axios.delete(`master-scenario/${id}`)
    },
    deleteModule: async (context, id) => {
        return await axios.delete(`master-scenario/module/${id}`)
    },
    deleteModuleLayoutWidget: async (context, id) => {
        return await axios.delete(`master-scenario/module-layout-widget/${id}`)
    }
}
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
