export default [
    {
        path: '/project/:id/roadmaps',
        component: () => import('@/views/screens/roadmap/Index.vue'),
        name: 'roadmap'
    },
    {
        path: '/project/:id/roadmaps/:roadmap_id',
        component: () => import('@/views/screens/roadmap/Show.vue'),
        name: 'roadmap.show'
    },
    {
        path: '/project/:id/strategic-roadmap/high-level-data',
        component: () => import('@/views/screens/roadmap/strategicRoadmap/highLevelData/Index.vue'),
        name: 'roadmap.highlevel.data'
    },
    {
        path: '/project/:id/strategic-roadmap/roadmap-concept',
        component: () => import('@/views/screens/roadmap/strategicRoadmap/roadmapConcept/Index.vue'),
        name: 'planning'
    },
    {
        path: '/project/:id/strategic-roadmap/high-level-roadmap',
        component: () => import('@/views/screens/roadmap/strategicRoadmap/highLevelRoadmap/Index.vue'),
        name: 'high-level-roadmap'
    },
]