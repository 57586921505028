<template>
    <textarea 
        :ref="functionalRef"
        v-model="localValue"
        :placeholder="placeholder"
        :disabled="isDisabled"
        class="fs-12 w-full bg-transparent prop-item-desc break-words none-resize"
    >
    </textarea>
</template>

<script setup>
import { ref, watch, computed, onMounted } from 'vue'

const props = defineProps({
    value: String,
    isDisabled: Boolean,
    placeholder: {
        default: 'No description available'
    }
})

const element = ref()
const functionalRef = el => element.value = el

const emit = defineEmits(['input', 'onType'])

const localValue = computed({
    get() {
        return props.value
    },
    set(val) {
        emit('input', val)
        emit('onType', val)
    }
})

const adjustHeight = (element) => {
    if(!element?.style) return 
    setTimeout(() => {
        element.style.height = "auto";
        element.style.height = element.scrollHeight + "px";
    }, 100);
}

watch(
  () => props.value,
  (newValue, oldValue) => {
    // newValue === oldValue
    adjustHeight(element.value)
  },
)

onMounted(() => {
    adjustHeight(element.value)
})
</script>