<template>
  <v-chart :options="chartOptions"></v-chart>
</template>

<script>
import ECharts from "vue-echarts";

export default {
  name: "ELineChart",
  components: { "v-chart": ECharts },
  props: ['width', 'height'],
  data() {
    return {
      chartOptions: null
    }
  },
  methods: {
    getOption() {
      this.chartOptions = {
        xAxis: {
          show: true,
          type: 'category',
          axisLabel: {
            show: true
          },
          splitLine: {
            show: false,
            lineStyle: {
              color: ['E7E7E7']
            }
          },
          data: ['JAN', 'APR', 'JUL', 'OCT', 'DEC']
        },
        yAxis: {
          type: 'value',
          show: true,
          axisLabel: {
            show: false
          },
          splitLine: {
            lineStyle: {
              color: "#3C3C3F",
              // type: "dashed"
            }
          }
        },
        series: [{
          data: [147, 260, 180, 200, 229, ],
          type: 'line',
          symbol: "none",
          lineStyle: {
            color: '#FFFFFF',
            width: .6
          }
        }],
      };
    }
  },
  mounted() {
    this.getOption();
  }
}
</script>

<style scoped>
  .echarts {
    width: 290px !important;
    height: 260px !important;
    margin-top: -35px;
    margin-left: -30px;
    margin-bottom: -60px;
  }
  .echarts div {
    width: 290px !important;
    height: 260px !important
  }
  .echarts canvas {
    width: 290px !important;
    height: 260px !important;
  }
</style>
