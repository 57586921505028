<template>
    <img
        :style="{
            width: imageSize+'px',
            height: imageSize+'px',
        }"
        :src="path"
        alt=""
    >
</template>

<script>
export default {
    name: 'ImageSquare',
    props: {
        path: {
            type: String,
            required: true
        },
        imageSize: {
            type: [String, Number],
            default: 32
        }
    }
}
</script>