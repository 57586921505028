import { render, staticRenderFns } from "./CircularMenu.vue?vue&type=template&id=6790b23b&"
import script from "./CircularMenu.vue?vue&type=script&setup=true&lang=js&"
export * from "./CircularMenu.vue?vue&type=script&setup=true&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports