<template>
  <div
      v-show="
            (levelSelf >= 2 && checkSelected) ||
            (levelSelf >= 2 && checkParentSelected) ||
            (levelSelf == 3 && checkChildSelected) ||
            (levelSelf >= 2 && checkSiblingSelected) ||
            levelGlobal >= 2
        "
  >
    <div
        class="flex flex-row mt-8 cursor-pointer"
        :class="{
                'hor-tiles-max':
                    (expansionSelf == true && checkSelected == true) ||
                    (expansionSelf == true && checkParentSelected == true) ||
                    expansionGlobal == true,
            }"
    >
      <div
          class="relative i-h-50 bld-level-w flex flex-col border-solid cursor-pointer border-color-transparent"
          :class="{
                    'hor-tiles-max': (expansionSelf === true && checkSelected === true) || (expansionSelf === true && checkParentSelected === true) || expansionGlobal === true,
                    'i-border-1 border-primary-one': checkSelected || appearance == 1,
                    'i-border-b-1 border-primary-one' : appearance == 2,
                    'shadow-two' : appearance == 0,
                    'rounded i-border-1' : appearance != 2,
                    'border-secondary-five' : (appearance == 1 || appearance == 2) && !checkSelected,
                    'border-primary-one rounded' : checkSelected
                }"
          :style="{'background': appearance == 0 ? getBg() : 'transparent' }"
          @click="toggleActive([areaMeasure, 'area_measure']), activePropertyOption()"
      >
        <div
            class="flex relative justify-start items-center i-pl-15 i-pr-15 cursor-pointer"
        >
          <h4
              class="fs-13 fw-600 font-poppins text-primary-three i-pt-5"
              :class="appearance == 0 ? 'text-primary-three' : 'text-secondary-one'"
              v-show="indexTitle"
          >
            {{ getSymbolCode + numberedTitle }}
          </h4>
          <input
              v-on:blur="$emit('close-menu')"
              type="text"
              :style="{ 'padding-left': indexTitle && (moduleSettings.index_id || moduleSettings.index_number)  ? '5px' : '0px' }"
              @keyup="temporaryUpdate($event, areaMeasure)"
              @change="regularUpdate($event, areaMeasure)"
              v-model="areaMeasure.title"
              class="block w-full fw-600 focus:outline-none pl-2 bg-transparent fs-13 font-poppins text-primary-three i-mt-5 placeholder-secondary-five ellipse cursor-pointer"
              :class="{
                            'py-2': (expansionSelf === true && checkSelected == true) || expansionGlobal == true,
                            'py-3': !((expansionSelf === true && checkSelected == true) || expansionGlobal == true),
                            'cursor-pointer': checkSelected,
                            'text-primary-three': appearance == 0,
                            'text-secondary-one': appearance != 0
                        }"
              placeholder="Child"
              :disabled="viewer"
              :id="'l3_' + areaMeasure.id"
              autocomplete="off"
          />
          <div v-show="areaMeasure.id == activeModuleCollection.id" class="action_items flex">
            <button @click="menuHandler($event, areaMeasure)" title="Information">
              <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.000650114 6.66667C0.000650275 2.98477 2.98542 -4.5235e-07 6.66732 -2.91409e-07C10.3492 -1.30468e-07 13.334 2.98477 13.334 6.66667C13.334 10.3486 10.3492 13.3333 6.66732 13.3333C2.98709 13.3293 0.00469163 10.3469 0.000650114 6.66667ZM6.55265 1.33333C3.61849 1.36488 1.26095 3.76074 1.27665 6.69503C1.2925 9.62931 3.67565 11.9997 6.60999 11.9997C9.54431 11.9997 11.9275 9.62931 11.9433 6.69503C11.959 3.76074 9.60148 1.36488 6.66732 1.33333L6.55265 1.33333Z" fill="#8D8D8D"/>
                <path d="M6.50431 5.072C6.26431 5.072 6.06698 5.00267 5.91231 4.864C5.76298 4.72 5.68831 4.544 5.68831 4.336C5.68831 4.12267 5.76298 3.94667 5.91231 3.808C6.06698 3.664 6.26431 3.592 6.50431 3.592C6.73898 3.592 6.93098 3.664 7.08031 3.808C7.23498 3.94667 7.31231 4.12267 7.31231 4.336C7.31231 4.544 7.23498 4.72 7.08031 4.864C6.93098 5.00267 6.73898 5.072 6.50431 5.072ZM7.18431 5.536V10H5.81631V5.536H7.18431Z" fill="#8D8D8D"/>
              </svg>
            </button>
            <button @click="canCreate(project.cans)&&createNewData(areaMeasure)" title="Create">
              <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.000650114 6.66667C0.000650275 2.98477 2.98542 -4.5235e-07 6.66732 -2.91409e-07C10.3492 -1.30468e-07 13.334 2.98477 13.334 6.66667C13.334 10.3486 10.3492 13.3333 6.66732 13.3333C2.98709 13.3293 0.00469163 10.3469 0.000650114 6.66667ZM6.55265 1.33333C3.61849 1.36488 1.26095 3.76074 1.27665 6.69503C1.2925 9.62931 3.67565 11.9997 6.60999 11.9997C9.54431 11.9997 11.9275 9.62931 11.9433 6.69503C11.959 3.76074 9.60148 1.36488 6.66732 1.33333L6.55265 1.33333ZM3.33398 7.33333L3.33398 6L6.00065 6L6.00065 3.33333L7.33398 3.33333L7.33398 6L10.0007 6L10.0007 7.33333L7.33398 7.33333L7.33398 10L6.00065 10L6.00065 7.33333L3.33398 7.33333Z" fill="#8D8D8D"/>
              </svg>
            </button>
            <button title="Delete" @click="canDelete(project.cans)&&rmAreaMeasure($event, areaMeasure)">
              <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.000650114 6.66691C0.000650275 2.98501 2.98542 0.000243688 6.66732 0.000243849C10.3492 0.00024401 13.334 2.98501 13.334 6.66691C13.334 10.3488 10.3492 13.3336 6.66732 13.3336C2.98709 13.3295 0.00469163 10.3471 0.000650114 6.66691ZM6.55265 1.33358C3.61849 1.36512 1.26095 3.76098 1.27665 6.69527C1.2925 9.62956 3.67565 11.9999 6.60999 11.9999C9.54431 11.9999 11.9275 9.62956 11.9433 6.69527C11.959 3.76098 9.60148 1.36512 6.66732 1.33358L6.55265 1.33358ZM3.33398 7.33358L3.33398 6.00024L6.00065 6.00024L7.33398 6.00024L10.0007 6.00024L10.0007 7.33358L7.33398 7.33358L6.00065 7.33358L3.33398 7.33358Z" fill="#8D8D8D"/>
              </svg>
            </button>
          </div>
          <!--                  <h3 v-if="!editingMode" @click="enableEditingMode()" class="block w-full fw-600 focus:outline-none pl-2 bg-transparent fs-13 font-poppins text-primary-three i-mt-5 placeholder-secondary-two cursor-pointer"-->
          <!--                      :style="{ 'padding-left': indexTitle && (moduleSettings.index_id || moduleSettings.index_number)  ? '5px' : '0px' }"-->
          <!--                      :class="{-->
          <!--                        'py-2': (expansionSelf === true && checkSelected == true) || expansionGlobal == true,-->
          <!--                        'py-3': !((expansionSelf === true && checkSelected == true) || expansionGlobal == true),-->
          <!--                        'text-primary-three': appearance == 0,-->
          <!--                        'text-secondary-one': appearance != 0-->
          <!--                      }"-->
          <!--                      :title="areaMeasure.title">-->
          <!--                    {{ areaMeasure.title.length > 26 ? areaMeasure.title.substr(0, 26) + '...' : areaMeasure.title }}-->
          <!--                  </h3>-->
        </div>

        <div
            class="flex justify-start"
            v-if="
                        (expansionSelf == true && checkSelected == true) ||
                        (expansionSelf == true &&
                            checkParentSelected == true) ||
                        expansionGlobal == true
                    "
        >
                    <textarea
                        class="fs-12 font-poppins text-primary-three i-pl-15 i-pr-15 w-full bg-transparent bld-txtarea mb-3"
                        :class="{
                          'cursor-pointer': checkSelected == false,
                          'text-primary-three bld-content-area': appearance == 0,
                          'text-secondary-one bld-content-area-alt': appearance != 0
                        }"
                        placeholder="Add descriptive text"
                        @keyup="temporaryUpdate($event, areaMeasure)"
                        @change="regularUpdate($event, areaMeasure)"
                        v-model="areaMeasure.description"
                        :disabled="viewer"
                        autocomplete="off"
                        style="margin-top: -6px;"
                    ></textarea>
        </div>
      </div>
    </div>
    <draggable group="measure" @end="updateStore($event)" v-bind="dragOptions">
      <transition-group
          mode="out-in"
          name="slide"
          :area-measure-parent-data-id="areaMeasure.id"
          :area-parent-data-id="area"
          :identifier-parent-id="identifier"
      >
        <measure-input-bar
            v-for="(l4Collection, index) in areaMeasure.child"
            :key="l4Collection.id"
            :area="area"
            :areaMeasure="areaMeasure.id"
            :measure="l4Collection"
            :identifier="identifier"
            :pNumberedTitle="numberedTitle"
            :index="index"
            :levelSelf="levelSelf"
            :levelGlobal="levelGlobal"
            :expansionSelf="
                        expansionSelf == true && checkSelected == true
                            ? true
                            : expansionSelf
                    "
            :expansionGlobal="expansionGlobal"
            :selected_item="selected_item"
            :appearance="appearance"
            :indexTitle="indexTitle"
            @add="addMeasure"
            @active="toggleActive"
            @close-menu="$emit('close-menu')"
            @showCntxtmenu="$emit('showCntxtmenu')"
            @activePropertyOption="activePropertyOption"
            :measure-data-id="l4Collection.id"
            :data-id="l4Collection.id"
            :ameasure-data-id="areaMeasure.id"
            class="cursor-move"
            :viewer="viewer"
        />
      </transition-group>
    </draggable>
  </div>
</template>


<script setup>
  import usePermission from '@/composable/usePermission.js'
  const { canCreate, canEdit, canDelete } = usePermission()
</script>

<script>
import {mapActions, mapGetters} from "vuex";
import buildCommon from "../../common/build";

export default {
  data() {
    return {
      actionDiv: true,
      buildCommon: null,
      levelColorCodes: [],
      editingMode: false
    };
  },
  props: [
    "area",
    "index",
    "areaMeasure",
    "pNumberedTitle",
    "expansionSelf",
    "expansionGlobal",
    "levelSelf",
    "levelGlobal",
    "selected_item",
    "identifier",
    "viewer",
    "appearance",
    "indexTitle"
  ],
  computed: {
    ...mapGetters({
      measuresList: "Measures/data",
      expansion: "GET_ELEMENT_DETAIL_STATUS",
      activeModuleBg: "GET_CURRENT_MODULE_BG",
      topSelectedButton: "GET_IDENTIFIER_TOP_OPTION",
      moduleSettings: "build/currentSettings",
      activeModuleCollection: "build/activeModuleCollection",
      project: "project/project",
      colorCodes: "global/colorCodes"
    }),
    getSymbolCode() {
      let settings = this.moduleSettings.collections[3]
      let showCode = this.moduleSettings.index_id
      let code = showCode ? settings.index_id : ''
      return code
    },
    numberedTitle() {
      let showNumber = this.moduleSettings.index_number
      let title = showNumber ? this.pNumberedTitle + '.' + (this.index + 1) : ''
      return title;
    },
    loadChilds() {
      const self = this;
      return this.$store.getters["Measures/data"].filter(function (el) {
        return parseInt(el.parent) === parseInt(self.areaMeasure.id);
      });
    },
    checkSelected() {
      if (
          this.selected_item[1] == "area_measure" &&
          this.selected_item[0].id == this.areaMeasure.id
      ) {
        return true;
      }
      return false;
    },
    checkParentSelected() {
      if (
          this.selected_item[1] == "area" &&
          this.selected_item[0].id == this.area
      ) {
          return true;
      }
      return false;
    },
    checkChildSelected() {
      // if (
      //     this.selected_item[1] == "measure" &&
      //     this.areaMeasure.id ==
      //         this.$store.getters["Measures/data"].find(
      //             (el) => el.id == this.selected_item[0]
      //         ).parent
      // ) {
      //     return true;
      // }
      return true;
    },
    checkSiblingSelected() {
      // if (
      //     this.selected_item[1] == "area_measure" &&
      //     this.area ==
      //         this.$store.getters["AreaMeasures/data"].find(
      //             (el) => el.id == this.selected_item[0]
      //         ).parent
      // ) {
      //     return true;
      // }
      return true;
    },
    dragOptions() {
      return {
        animation: 300,
        // group: "description",
        disabled: this.viewer,
        ghostClass: "ghost",
        class: 'cursor-default'
      };
    },
  },
  methods: {
    ...mapActions({
      storeMeasure: "Measures/storeData",
      destroyMeasure: "Measures/destroyData",
    }),
    menuHandler(e, item) {
      if(!this.viewer) {
        item.top = e.clientY
        item.left = e.clientX
        this.$emit('showCntxtmenu')
        this.$store.dispatch('build/getContxtmenuCollection', item)
      }
    },
    enableEditingMode() {
      const self = this;
      setTimeout(function () {
        self.editingMode = true;
        let itemId = self.areaMeasure.id;
        setTimeout(function () {
          let element = document.getElementById('l3_' + itemId);
          if (element) {
            element.focus();
            element.click();
          }
        }, 100);
      }, 100);
    },
    enteringNonEditableMode() {
      this.editingMode = false;
    },
    activePropertyOption() {
      this.$emit('activePropertyOption');
    },
    getLevelWiseColorCode(level) {
      let indexLevel = level - 1;
      return this.colorCodes.slice((indexLevel * 9), ((indexLevel * 9) + 9));
    },
    convertInt(int, length) {
      var len = length - ("" + int).length;
      return (len > 0 ? new Array(++len).join("0") : "") + int;
    },
    rmAreaMeasure(e, areaMeasure) {
      if (this.viewer) {
        return;
      }
      this.$store.dispatch("build/deleteItem", areaMeasure);
    },
    addMeasure() {
      // this.changeTransition("slide");

      let newMeasure = {
        title: "Measure",
        parent: this.areaMeasure.id,
      };
      this.storeMeasure(newMeasure);

      // $("#scroller" + this.item.id).animate(
      //     {
      //         scrollTop: $("#scroller" + this.item.id)[0].scrollHeight,
      //     },
      //     800
      // );
    },
    cloneTheMeasure({id}) {
      let index = this.measuresList.findIndex(
          (measure) => measure.id === id
      );
      if (index >= 0) {
        return this.measuresList[index];
      }
    },
    toggleActive(arr) {
      this.$emit("active", arr);
      // this.$store.dispatch("build/getProperties", arr[0]);
      // this.$store.dispatch("build/getActiveModuleCollection", arr[0])
    },
    createNewData(obj) {
      if (this.viewer) {
        return;
      }
      let itemObj = Object.assign({}, obj);
      let title = this.moduleSettings.collections[3].title;
      this.$Progress.start();
      itemObj.title = title;
      itemObj.item_order = itemObj.order + 1;
      itemObj.from_right_side = false;
      itemObj.relation = {
        l1: this.identifier,
        l2: this.area
      };
      this.$store.dispatch("build/storeData", itemObj).then(response => {
        this.$Progress.finish();
      });
    },
    updateStore(e) {
      this.$Progress.start();
      let oldParent = e.from.getAttribute("area-measure-parent-data-id");
      let newParent = e.to.getAttribute("area-measure-parent-data-id");
      let itemId = e.item.getAttribute("measure-data-id");

      let data = {
        parent_id: parseInt(newParent),
        old_parent: parseInt(oldParent),
        id: parseInt(itemId),
        index: e.newIndex,
        old_index: e.oldIndex
      };

      this.$store.dispatch("build/reOrderItem", data).then(response => {
        this.$Progress.finish();
      });
    },
    temporaryUpdate(e, obj) {
      if (this.viewer) {
        return;
      }
      if (e.keyCode !== 46) {
        this.$store.dispatch("build/temporaryUpdate", obj);
      }
    },
    regularUpdate(e, obj) {
      if (this.viewer) {
        return;
      }
      if (e.keyCode !== 46) {
        let item = {
          title: obj.title,
          description: obj.description,
          id: obj.id
        };
        this.$store.dispatch("build/regularUpdate", item);
      }
    },
    getBg() {
      if (this.areaMeasure.background_color) {
        return this.areaMeasure.background_color
      }
      let settings = this.moduleSettings.collections[3];
      if (settings) {
        if(settings.custom_bg) {
          return settings.custom_bg;
        }
        return this.levelColorCodes[settings.color];
      }
      return "";
    },
  },
  created() {
    this.buildCommon = buildCommon;
    this.levelColorCodes = this.getLevelWiseColorCode(3);
  }
};
</script>

<style scoped>
  .action_items{
    gap: 5px;
    position: absolute;
    top: 100%;
    left: 0;
    padding: 6px 0;
    z-index: 10;
  }
</style>