<template>
  <div class="relative" :class="[rootMarginClasses]">
    <input type="number" class="bg-transparent text-primary-one fs-14 rounded-sm i-border-1 border-secondary-five px-2"
      :title="`${property.suffix} ${parameter.sample_value}`"
      :class="[inputClasses]" :style="{'border': item.child && item.child.length <= 0 ? '' : 'none'}"
      :disabled="item && ((item.child && item.child.length > 0) || item.is_module) || viewer || !canEdit(project.cans)"
      v-model="parameter.sample_value" @input="updateData(parameter)" />
<!--    <span class="absolute hidden" :id="'value-hover-'+index+'-'+pIndex" :style="{'left': '48%', top: '-25px'}">-->
<!--      {{ property.suffix }} {{ parameter.sample_value }}-->
<!--    </span>-->
  </div>
</template>
<script setup>
  import usePermission from '@/composable/usePermission.js'
  const { canDelete, canCreate, canEdit } = usePermission()
</script>

<script>
import {mapActions, mapGetters} from 'vuex';
export default {
  name: "InputProperty",
  props: {
    property: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    pIndex: {
      type: Number,
      required: true
    },
    item: {
      type: Object,
      required: true
    },
    rootMarginClasses: {
      type: String,
      default: ''
    },
    inputClasses: {
      type: String,
      default: 'input-parameter'
    },
    viewer: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      parameter: {
        sample_value: 0
      }
    }
  },
  computed: {
    ...mapGetters({
      project: "project/project"
    }),
  },
  created() {
    this.setSampleValue(this.item, this.property);
  },
  watch: {
    item: function (val) {
      this.setSampleValue(this.item, this.property);
    }
  },
  methods: {
    setSampleValue(item, property) {
      let parameter = null;
      if(item.is_module) {
        parameter = item.temp_properties_value.find(prop => prop.property_collection_id === property.id)
      } else {
        parameter = item.properties.find(prop => prop.property_collection_id === property.id)
      }

      if(parameter) {
        this.parameter = parameter;
      } else {
        this.parameter = {
          scenario_collection_id: this.item.id,
          sc_id: this.item.id,
          property_collection_id: property.id,
          sample_value: 0
        };
      }
    },
    updateData(parameter) {
      parameter.property_collection_id = this.property.id;
      parameter.id = this.property.id;
      this.$emit("updateParameterData", parameter);
    },
  },
}
</script>

<style scoped>
.input-parameter {
  width: 110px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
  -moz-appearance: textfield;
}
</style>
