<template>
    <div>
        <button class="fs-10 test-secondary-twp" @click="toggleSeeAll()">{{ showAll ? 'See Less' : 'See More' }}</button>
    </div>
</template>

<script>
export default {
    name: 'ShowAll',
    props: {
        showAll: Boolean,
        toggleSeeAll: Function
    }
}
</script>