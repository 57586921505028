<template>
    <div class="flex gap-1 items-center">
<!--        <HappyFaceIcon class="w-3 h-3 text-secondary-two" />-->
<!--        <BarIcon class="w-3 h-3 text-secondary-two" />-->
        <span v-if="isComment" @click="toggleReply()" class="fs-12 fw-500 cursor-pointer" :class="showReply ? 'text-secondary-one' : 'text-primary-four'">Reply</span>
        <BarIcon v-if="isComment" class="w-3 h-3 text-secondary-two" />
        <span v-if="isComment && comment.replies && comment.replies.length > 0" @click="toggleReplies()" class="fs-10 cursor-pointer" :class="replies ? 'text-primary-one' : 'text-secondary-two'">
          {{ comment && comment.replies ? comment.replies.length : 0 }} Replies
        </span>
        <BarIcon v-if="isComment && comment.replies && comment.replies.length > 0" class="w-3 h-3 text-secondary-two" />
        <span class="fs-10 text-secondary-two">{{ time }}</span>
    </div>
</template>

<script>
export default {
    name: 'CommentFooter',
    props: {
        toggleReply: Function,
        toggleReplies: Function,
        showReply: Boolean,
        replies: Boolean,
        isComment: Boolean,
        time: String,
        comment: Object
    }
}
</script>
