import { isEmpty } from 'lodash';
import store  from '@/store';

const getBrainstormInvitations = (to) => {
    const brainstormId = to.params.brainstorm_id;
    return store.dispatch('brainstormv1/getInvitations', brainstormId);
}

const getProject = (to) => {
    let projectId = to.params.id;
    return store.dispatch('project/getSolidObject', projectId);
}
const getUser = () => {
    return JSON.parse(localStorage.getItem('_user'));
}

const immigration = async (to) => {
    let invitations = await getBrainstormInvitations(to);
    // Gate 1: if has non expired valid url 
    let hasValidUrl = invitations.some(item => Boolean(item.till_valid));
    if(hasValidUrl) {
        return true;
    }
    // Gate 2: If loggedIn user has invitation
    let user = getUser();
    let hasInvitation = invitations.some(item => item.user_id === user.id);
    if(hasInvitation) {
        return true;
    }
    // Gate 3: If loggedIn user has access to project then he can access brainstorm
    let project = await getProject(to);
    if(project && !isEmpty(project?.cans)) {
        return true;
    }
    
    return false;
}

const dataset = ['teamwork.brainstorm', 'teamwork.mindmap', 'teamwork.canvas', 'teamwork.concepts', 'teamwork.diagrammatic']
export default (router) => {
    router.beforeEach( async (to, from, next) => {
        if(dataset.includes(to.name)) {
            if(!await immigration(to)) {
                next({
                    name: 'permission.deny'
                });
            }
            next();
        }
        next();
    });
}
