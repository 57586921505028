<template>
    <svg width="40" height="40" viewBox="0 0 40 40" class="cursor-pointer" fill="none" xmlns="http://www.w3.org/2000/svg">
        <title>{{ title }}</title>
        <rect x="0.5" y="0.5" width="39" height="39" rx="3.5" :fill="activeRectFill" :stroke="activeStroke"/>
        <path d="M30 19L30 21L10 21L10 19L30 19Z" :fill="activeFill"/>
        <path d="M30 10L30 12L10 12L10 10L30 10Z" :fill="activeFill"/>
        <path d="M30 28L30 30L10 30L10 28L30 28Z" :fill="activeFill"/>
    </svg>
</template>

<script setup>
import useSVG from '@/composable/icons/useSVG';
    const props = defineProps({
        title: String,
        active: Boolean
    });
    const { activeFill, activeStroke,  activeRectFill} =  useSVG(props);
</script>