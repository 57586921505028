<template>
    <svg class="inline" viewBox="0 0 7 5" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3.90962 4.41483C3.71057 4.69919 3.28943 4.69919 3.09038 4.41483L0.550712 0.786732C0.31874 0.455343 0.555816 5.34363e-08 0.960328 9.23261e-08L6.03967 5.80656e-07C6.44418 6.19545e-07 6.68126 0.455343 6.44929 0.786732L3.90962 4.41483Z" fill="currentColor"/>
    </svg>

</template>

<script>
export default {
    name: "ChevronSolid"
}
</script>
