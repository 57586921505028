const state = {
    saveModal: {},
    uploadModal: {}
};

const customGetters = {
    GET_SAVE_MODAL(state) {
        return state.saveModal;
    },
    GET_UPLOAD_MODAL(state) {
        return state.uploadModal;
    },
};

const customActions = {
    ACT_TOGGLE_SAVE_MODAL: function(context, option) {
        context.commit("MUT_TOGGLE_SAVE_MODAL", option);
    },
    ACT_TOGGLE_UPLOAD_MODAL: function(context, option) {
        context.commit("MUT_TOGGLE_UPLOAD_MODAL", option);
    },
};

const customMutations = {
    MUT_TOGGLE_SAVE_MODAL: function(state, payload) {
        state.saveModal = payload;
    },
    MUT_TOGGLE_UPLOAD_MODAL: function(state, payload) {
        state.uploadModal = payload;
    },
};

export default {
    state,
    getters: customGetters,
    mutations: customMutations,
    actions: customActions
}
