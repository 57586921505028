import { uniqBy } from 'lodash'
import store from "../../index";

export const getModuleBg = (module, level = 1) => {
    let colorCodes = store.getters["global/colorCodes"];
    let collectionSettings = JSON.parse(module.collection_settings);
    if(collectionSettings && collectionSettings.collections.length > 0) {
        let indexLevel = level - 1;
        if(collectionSettings.collections[indexLevel].custom_bg) {
            return collectionSettings.collections[indexLevel].custom_bg;
        }
        let colors = colorCodes.slice((indexLevel * 9), ((indexLevel * 9) + 9));
        return colors[collectionSettings.collections[level].color];
    }
    return null;
};

export const getFlattenCollections = (items) => {
    let flatten = (arr) => {
        let data = [];
        if(!arr) {
          return 
        }
        arr.forEach(recursiveFlatten);
        function recursiveFlatten(item) {
          data.push(item);
          if (item.child && item.child.length > 0) {
            item.child.forEach((child) => {
              recursiveFlatten(child);
            });
          }
        }
        return data;
      }  
      return uniqBy(flatten(items), 'id')
}
