<template>
  <div class="track-box relative">
    <span v-if="isVisible" class="fs-10 absolute" style="top: -7px; left: -20px; }">{{ Number(getHoverTitle).toFixed(1) }}</span>
    <input class="range absolute left-0 top-0 cursor-pointer bg-secondary-four w-full" min="0" max="10" step=".1"
        
        :title="getHoverTitle"
        type="range"
        v-if="checkVisibility(item)"
        v-model="parameter.sample_value"
        @change="updateData(parameter)"
        @mousedown="onMouseDown"
        @mouseup="onMouseUp"
    />
    <div class="value-track absolute left-0 top-0 bg-secondary-five w-full" v-else></div>
    <div class="progress-track absolute left-0 top-0 bg-primary-five"
         :title="getHoverTitle"
         :style="{'width': (parameter.sample_value * 10) +'%'}">
    </div>
<!--    <span class="absolute hidden" :id="'value-hover-'+index+'-'+pIndex" :style="{'left': '48%', top: '-25px'}">-->
<!--      {{ !property.format || parseInt(property.format) === 1 ? parameter.sample_value : (parameter.sample_value * 10) + '%' }}-->
<!--    </span>-->
  </div>
</template>

<script setup>
  import usePermission from '@/composable/usePermission.js'
  const { canEdit, canCreate, canDelete } = usePermission()
</script>

<script>
import {mapGetters} from "vuex";
export default {
  name: "PropertySample",
  props: {
    property: {
      type: Object,
      required: true
    },
    index: {
      type: [Number, String],
      required: true
    },
    pIndex: {
      type: Number,
      required: true
    },
    item: {
      type: Object,
      required: true
    },
    viewer: {
      type: Boolean,
      default: false
    },
    hasAssessment: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      parameter: {
        sample_value: 0
      },
      isVisible: false
    }
  },
  computed: {
    ...mapGetters({
      project: "project/project"
    }),
    getHoverTitle() {
      return this.property.format && parseInt(this.property.format) === 1 ? this.parameter.sample_value : (this.parameter.sample_value * 10) + '%';
    }
  },
  created() {
    this.setSampleValue(this.item, this.property);
  },
  watch: {
    item: {
        handler: function (val) {
            this.setSampleValue(this.item, this.property);
        },
        deep: true
    }
  },
  methods: {
    setSampleValue(item, property) {
      // if(!item.temp_properties_value) {
      //   return
      // }
      let parameter = null;
      if(item.is_module) {
        parameter = item.temp_properties_value.find(prop => prop.property_collection_id === property.id)
      } else {
        parameter = item.properties.find(prop => prop.property_collection_id === property.id)
      }

      if(parameter) {
        this.parameter = parameter;
      } else {
        this.parameter = {
          sample_value: 0,
          scenario_collection_id: this.item.id,
          sc_id: this.item.id,
          property_collection_id: this.property.id
        }
      }
    },
    onMouseDown() {
        this.isVisible = true;
    },
    onMouseUp() {
        this.isVisible = false;
    },
    updateData(parameter) {
      parameter.property_collection_id = this.property.id;
      parameter.id = this.property.id;
      this.$emit("updateParameterData", parameter);
    },

    checkVisibility(item) {
      if(item?.i_am && item.i_am == 'module') return false
      if(item?.child && item.child.length) return false
      if(item?.is_module) return false
      return true
    }
  }
}
</script>

<style scoped>
.track-box {
  width: 140px;
}
.value-track, .progress-track, .range {
  height: 2px;
}
.range {
  outline: none;
  -webkit-appearance: none;
}
input[type='range']::-webkit-slider-thumb {
  width: 20px;
  -webkit-appearance: none;
  height: 10px !important;
  cursor: pointer;
  background: #00A7FE;
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.15);
  border-radius: 1px;
}
</style>
