export default {
    data: () => ({
        isList: true,
        showAll: false,
    }),
    methods: {
        setLayoutType(value) {
            this.isList = value
        }, 
        toggleSeeAll() {
            this.showAll = !this.showAll
        },
        getIterables(items) {
            if(items.length < 3) {
                return items
            }
            if(this.showAll) {
                return items
            }
            if(!this.showAll) {
                return items.slice(0,2)
            }
        },

        getCollection (properties, hrId) {
            let collection
            for(let property of properties) {
                collection = property.property_collections.find(collection => collection.id == hrId)
                if(collection) break
            }
            return collection
        },
        makeConceptTitleValueItems(hrCollection, vrCollection) {
            return [
                {
                    title: 'Horizontal Parameter:',
                    value: hrCollection ? hrCollection.title : ''
                },
                {
                    title: 'Vertical Parameter:',
                    value: vrCollection? vrCollection.title : ''
                },
            ]
        },
        getTitleValueItems(hrId, vrId, properties) {
            if(properties) {
                let hrCollection = this.getCollection(properties, hrId)
                let vrCollection = this.getCollection(properties, vrId)
                return this.makeConceptTitleValueItems(hrCollection, vrCollection)
            }
            return []
        }
    }
}