<template>
    <svg width="40" height="40" viewBox="0 0 40 40" class="cursor-pointer" fill="none" xmlns="http://www.w3.org/2000/svg">
        <title>{{ title }}</title>
        <rect x="0.5" y="0.5" width="39" height="39" rx="3.5" :fill="activeRectFill" :stroke="activeStroke"/>
        <g filter="url(#filter0_d_214_16)">
            <path d="M28.6093 10H10.3974L10 11.2583L17.0199 18.2119V25.0993L22.3179 30V18.2119L29.2715 11.2583L28.6093 10Z" :stroke="activeFill" shape-rendering="crispEdges"/>
        </g>
        <defs>
            <filter id="filter0_d_214_16" x="1.43115" y="3.5" width="36.4546" height="37.6436" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset dy="2"/>
                <feGaussianBlur stdDeviation="4"/>
                <feComposite in2="hardAlpha" operator="out"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0.172549 0 0 0 0 0.203922 0 0 0 0 0.254902 0 0 0 0.1 0"/>
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_214_16"/>
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_214_16" result="shape"/>
            </filter>
        </defs>
    </svg>
</template>

<script setup>
    import useSVG from '@/composable/icons/useSVG';
    const props = defineProps({
        title: String,
        active: Boolean
    });
    const { activeFill, activeStroke,  activeRectFill} =  useSVG(props);
</script>