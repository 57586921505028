// import axios from 'axios';

const state = {
    topOption: 'line',
    detailView: false
};

const customGetters = {
    GET_IDENTIFIER_TOP_OPTION(state) {
        return state.topOption;
    },
    GET_ELEMENT_DETAIL_STATUS(state) {
        return state.detailView;
    }
};

const customActions = {
    ACT_IDENTIFIER_TOP_OPTION: function (context, payload) {
        context.commit("SET_IDENTIFIER_TOP_OPTION", payload);
    },
    ACT_ELEMENT_DETAIL_STATUS: function (context, payload) {
        context.commit("SET_ELEMENT_DETAIL_STATUS", payload);
    }
};

const customMutations = {
    SET_IDENTIFIER_TOP_OPTION: function (state, payload) {
        state.topOption = payload;
    },
    SET_ELEMENT_DETAIL_STATUS: function (state, payload) {
        state.detailView = payload;
    }
};

export default {
    state,
    getters: customGetters,
    mutations: customMutations,
    actions: customActions
}
