import axios from 'axios';

function getDefaultState() {
    return {
        data: [],
    }
}

const getters = {
    data: state => state.data,
}

const actions = {
    fetchIndexData({commit, state}, collectionId) {
        
        axios
            .get(`properties/insights/${collectionId}/collection`)
            .then(response => {
                commit('setData', response.data.data)
            })
            .catch(error => {
                // TODO error handling
            })
            .finally(() => {
                //
            })
    },
    storeData({commit, dispatch, state}, obj) {

        axios.post('properties/insights', obj)
            .then(response => {
                // dispatch("fetchIndexData", {collectionId: obj.collection_id});
            })
            .catch(error => {
                // TODO error handling
            })
            .finally(() => {
                //
            })
    },
    updateData({ commit, dispatch, state }, obj)
    {
        let formData = new FormData()
        for(let key in obj){
            formData.append(key, obj[key])
        }

        axios.post(`properties/insights/${obj.id}/update`, formData)
            .then(response => {
                // dispatch("fetchIndexData", {collectionId: obj.collection_id});
            })
            .catch(error => {
                // TODO error handling
            }).finally(() => {
                // TODO
            });
    },
    deleteItem({ commit, dispatch, state }, obj) {
        axios.delete(`properties/insights/${obj.id}`)
            .then(response => {
                
            })
            .catch(e => {
                // TODO error handling
            })
            .finally(() => {
                // TODO
            });
    }
}

const mutations = {
    setData(state, value) {
        state.data = value
    },
};

export default {
    namespaced: true,
    state: getDefaultState,
    getters,
    actions,
    mutations
}
