<template>
  <svg
    class="inline box-plus"
    :color="color"
    fill="none"
    version="1.1"
    viewBox="0 0 4.315 4.44"
    xml:space="preserve"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m2.031 1.074v0.9805h-0.9824v0.3418h0.9824v0.9805h0.3418v-0.9805h0.9805v-0.3418h-0.9805v-0.9805zm2.284-1.074-0.3596-3.141e-8 -1e-7 1.438h0.3596zm0 2.877h-0.3596l-2e-7 1.438h0.3596zm-4.315 1.203-3.527e-8 0.3596 4.315 4e-7v-0.3596zm-2.299e-8 0.2344 0.3596 1e-7 8e-8 -4.315-0.3596-8.963e-10zm4.315-3.956v-0.3596l-4.315-2.142e-7 -3.527e-8 0.3596z"
      fill="currentColor"
      style="-inkscape-stroke: none"
    />
  </svg>
</template>

<script>
export default {
    name: 'PlusSquareIcon',
    props: {
        color: String
    }
}
</script>

<style>
.box-plus{
  transform: none !important;
}
</style>