export default {
    getConcepts: (state) => state.concepts,
    getBrainstorms: (state) => state.brainstorms,
    getProject: (state) => state.project,
    getScenarios: (state) => state.scenarios,
    getAssessmentComments: (state) => state.assessmentsComments,
    getComments: (state) => state.comments,
    getAssessmentInsights: (state) => state.assessmentsInsights,
    getAssociations: (state) => state.associations,
    associationsByScenarioCollection: (state) => state.associationsByScenarioCollection,
    getInsights: (state) => state.insights,
    getOpenInsightsCreateForm: (state) => state.openInsightCreateForm
}
