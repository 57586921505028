<template>
  <div>
    <div class="relative">
      <img src="/images/instrat_logo.svg" alt="logo" class="i-insider-logo">
      <NavToggle v-if="projectId" />
    </div>
    
    <div class="ws-boxes flex mt-10">
      <div class="flex workspace-scroll" v-click-outside="hideContextMenu">
        <div v-for="(workspace, wsIndex) in workspaces"
             :key="wsIndex"
             class="ws-box bg-primary-four font-poppins fs-16 text-primary-two p-2 uppercase fw-bold cursor-pointer rounded-sm"
             :class="{'ml-5': wsIndex > 0, 'i-border-1 shadow-two border-primary-one': parseInt(selectedWorkspace) === parseInt(workspace.id)}"
             @click="toggleWorkspace(workspace.id, wsIndex)"
             @contextmenu="rightClickHandler($event, workspace)">
          {{ workspace.name.substring(0, 2) }}
        </div>
      </div>
      <div class="ws-box bg-primary-one cursor-pointer rounded-sm flex items-center justify-content-center p-3"
           :class="{'ml-5': workspaces.length > 0}" @click="showWorkspaceCreateModal()">
        <img src="/images/icons/workspace/plus_icon.svg" alt="icon">
      </div>
    </div>
    <div class="ws-info-box i-mt-34">
      <h2 class="font-poppins fs-14 fw-600 text-primary-one">Active: {{ activeWorkspaceObj ? activeWorkspaceObj.name : '' }}</h2>
      <router-link :to="{ name: 'workspace_settings', params: { id: project.workspace_id }, query: { type: 'project', id: project.id, option: 'style' }}">
        <h2 class="font-poppins fs-14 fw-600 text-primary-one opacity-50 i-mt-15">Style Control</h2>
      </router-link>
      <router-link :to="{ name: 'definition', params: { id: project.id }}">
        <h2 class="font-poppins fs-14 fw-600 text-primary-one opacity-50 i-mt-15">Definitions</h2>
      </router-link>
      <router-link :to="{ name: 'workspace.members', query: { type: 'project', id: project.id }}">
        <h2 class="font-poppins fs-14 fw-600 text-primary-one opacity-50 i-mt-15">Role & Members</h2>
      </router-link>
    </div>

    <div class="absolute bg-primary-three shadow-two fs-14 text-primary-one font-poppins py-3 px-3 rounded"
         style="width: 80px; height: 80px"
         v-if="showContextMenu && canEdit(contextWorkspace.cans)"
         :style="{'left': contextPos.left + 'px', 'top': contextPos.top + 'px'}">
      <ul>
        <li class="fw-600 cursor-pointer" @click="editWorkspace()" :class="{'pointer-events-none': !canEdit(contextWorkspace.cans)}">Edit</li>
        <li class="fw-600 cursor-pointer mt-3" @click="deleteWorkspace()" :class="{'pointer-events-none': !canDelete(contextWorkspace.cans)}">Delete</li>
      </ul>
    </div>

    <Modal
      v-model="showEditWorkspace"
    >
      <edit-workspace 
        :dModal="eModal"
        :ref="'workspace_edit'"
        @toggleEditModal="toggleEditModal" 
        @close="showEditWorkspace = false"
      />
    </Modal>
    
    <Nav v-model="showNavigation" @close="showNavigation=false"/>
  </div>
</template>

<script setup>
import NavToggle from '@/components/navigation/components/NavToggle.vue'
import usePermission from '@/composable/usePermission.js'
import useNavigation from '@/components/navigation/useNavigation.js'
import Nav from '@/components/navigation/Nav.vue'


const { canDelete, canCreate, canEdit } = usePermission()
const { projectId, showNavigation } = useNavigation()
</script>

<script>
import {mapGetters} from "vuex"
import EditWorkspace from "../workspace/WorkspaceEditModal"
import Modal from '@/elements/atom/Modal'

export default {
  name: "WorkspaceLeftMenu",
  components: {EditWorkspace, Modal},
  created() {
    const self = this;
    if(self.project.workspace_id) {
      self.$store.dispatch("project/getSolidObject", self.$route.params.id).then(response => {
        if(response && response.data) {
          self.selectedWorkspace = self.project.workspace_id;
        }
      });
    } else {
      self.$store.dispatch("project/getSolidObject", self.$route.params.id).then(response => {
        if(response && response.data) {
          self.selectedWorkspace = self.project.workspace_id;
        }
      });
    }

    if(self.workspaces.length <= 0) {
      self.$store.dispatch("workspace/fetchWorkspaces", 1).then(response => {
        if(response && response.data) {
          let wsIndex = self.workspaces.findIndex(item => parseInt(item.id) === parseInt(self.project.workspace_id));
          if(wsIndex >= 0) {
            self.$store.dispatch("workspace/toggleWorkspace", wsIndex);
          } else {
            self.$store.dispatch("workspace/toggleWorkspace", 0);
          }
        }
      });
    }
  },
  data() {
    return {
      selectedWorkspace: 0,
      showEditWorkspace: false,
      eModal: false,
      contextPos: {
        top: 0,
        left: 0
      },
      showContextMenu: false,
      contextWorkspace: null,
    }
  },
  computed: {
    ...mapGetters({
      workspaces: 'workspace/workspaces',
      activeWorkspaceObj: "workspace/activeWorkspaceObj",
      project: "project/project"
    }),
  },
  methods: {
    showWorkspaceCreateModal() {
      this.$store.dispatch("workspace/toggleWorkspaceModal", true);
    },
    toggleWorkspace(id, index) {
      const self = this;
      self.selectedWorkspace = id;
      self.$store.dispatch("workspace/toggleWorkspace", index);
    },
    setWorkspace(id) {
      const self = this;
      self.selectedWorkspace = id;
      let workspaceIndex = self.workspaces.findIndex(item => item.id === id);
      if(workspaceIndex >= 0) {
        self.$store.dispatch("workspace/toggleWorkspace", workspaceIndex);
      }
    },
    rightClickHandler(e, workspace) {
      e.preventDefault();
      this.showContextMenu = true;
      this.contextPos.left = e.pageX || e.clientX;
      this.contextPos.top = e.pageY || e.clientY;
      this.contextWorkspace = workspace;
    },
    hideContextMenu() {
      this.showContextMenu = false;
      this.contextWorkspace = null;
    },
    editWorkspace() {
      // this.eModal = true;
      this.showEditWorkspace = true
      this.showContextMenu = false
      this.$refs.workspace_edit.setEditableData(this.contextWorkspace);
    },
    deleteWorkspace() {
      this.$Progress.start();
      this.$store.dispatch("workspace/deleteWorkspace", {
        id: this.contextWorkspace.id
      }).then(response => {
        this.$Progress.finish();
      })
    },
    toggleEditModal() {
      this.eModal = false;
      this.contextWorkspace = null;
    },
  }
}
</script>

<style scoped>

</style>
