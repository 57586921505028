<template>
    <div class="mb-4">
        <div class="flex gap-2 justify-between items-center border-b border-color-secondary-five pb-4">
            <h1 class="fw-700 fs-14 text-primary-one truncate">{{ title }}</h1>
            <div class="flex items-center gap-2">
                <button v-if="showEdit" @click="editable = !editable">
                    <svg class="opacity-40" :class="editable && 'opacity-100'" width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6.00391 13.5H3.00391C2.8713 13.5 2.74412 13.4473 2.65035 13.3535C2.55658 13.2598 2.50391 13.1326 2.50391 13V10.2071C2.50391 10.1414 2.51684 10.0764 2.54197 10.0158C2.56709 9.95509 2.60392 9.89997 2.65035 9.85354L10.1504 2.35354C10.2441 2.25977 10.3713 2.20709 10.5039 2.20709C10.6365 2.20709 10.7637 2.25977 10.8575 2.35354L13.6504 5.14643C13.7441 5.2402 13.7968 5.36738 13.7968 5.49999C13.7968 5.63259 13.7441 5.75977 13.6504 5.85354L6.00391 13.5Z" stroke="#8D8D8D" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M8.50391 4L12.0039 7.5" stroke="#8D8D8D" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>                    
                </button>
                <button @click="() => {openMenu = !openMenu; storeOpenMenu(openMenu); }">
                    <svg class="transform rotate-0" :class="openMenu && 'rotate-180'" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M3 10L8 5L13 10" stroke="#8D8D8D" stroke-width="1.5" stroke-linecap="square" stroke-linejoin="round"/>
                    </svg>
                </button>
            </div>
        </div>

        <div v-if="openMenu">
            <div>
                <div v-for="(menu, index) in getMenus" :class="hasGroup && 'border-b border-color-secondary-five'" :key="`menuitem-${index}`" >
                    <div v-for="(item, i) in menu" :key="`menu-${index}-${i}`">
                        <div v-if="editable || item.active"  class="flex justify-between items-center fs-14 fw-500" :key="index">
                            <router-link :to="item.to" exact class="flex gap-3 items-center py-3">
                                <component :is="iconComponents[item.icon]" />
                                {{ item.title }}
                            </router-link>
                            <div class="flex items-center gap-6">
                                <router-link exact v-if="item?.landing_page" :to="item.landing_page_to">
                                    <IconGreenM />
                                </router-link>
                                <Checkbox 
                                    v-if="editable" 
                                    class="text-16 mt-0 -mr-2" 
                                    v-model="item.active" 
                                    @click="visibilityToggleMenu(item.menu_serial)" 
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue'
import Checkbox from '@/components/dashboard/sidebar/components/right-sidebar/Checkbox.vue'
import useNavigation from '@/components/navigation/useNavigation.js'
import IconGreenM from '@/components/navigation/components/icons/IconGreenM.vue'

const { iconComponents, visibilityToggleMenu, checkActiveMenu, storeOpenMenu, checkMenuOpen, getLastOpenTeamId } = useNavigation()

const props = defineProps({
    title: String,
    menus: Array,
    hasGroup: false,
    teamId: [String, Number],
    showEdit: {
        type: Boolean,
        default: false
    },
    // editable: {
    //     type: Boolean,
    //     default: false
    // }
})

const openMenu = ref(false)
const editable = ref(false)

const getMenus = computed(() => {
    return props.menus.map(menu => (
        menu.map(item => {
            item.active = checkActiveMenu(item.menu_serial)
            return item
        })
    ))
})

onMounted(() => {
    openMenu.value = checkMenuOpen()
    // location.href = `#team-${getLastOpenTeamId(props.teamId)}`
    history.replaceState(null, null, ' ');
})
</script>

<style scoped>
    .router-link-active{
        font-weight: 600;
        color: #000;
    }
</style>