<template>
    <div>
        <svg 
            class="cursor-pointer transition duration-150" 
            :class="[upHere ? 'text-secondary-two' : name == moduleName ? 'text-primary-four' : 'text-secondary-three']" 
            @mouseover="upHere = true, $emit('onHover', name)" 
            @mouseleave="upHere = false, $emit('onHover', name)" 
            width="31.11mm" height="15.27mm" 
            version="1.1" viewBox="0 0 31.11 15.27" 
            xmlns="http://www.w3.org/2000/svg"
        >
            <g transform="translate(-104.7 -101.7)" fill="none">
                <g transform="matrix(.2646 0 0 .2646 104.8 101.8)">
                    <path d="m1.473 21.92c-1.645-2.877-0.6529-6.556 2.302-8.056 16.94-8.597 35.69-13.08 54.74-13.05s37.79 4.58 54.7 13.23c2.95 1.51 3.929 5.193 2.275 8.064l-18.08 31.36c-1.655 2.871-5.315 3.832-8.313 2.421-9.571-4.504-20.04-6.864-30.66-6.882-10.63-0.0179-21.1 2.307-30.69 6.779-3.003 1.401-6.66 0.4274-8.305-2.449z" fill="currentColor" mask="url(#path-1-inside-1_9871_280546)" stroke="#fff" stroke-width="2"/>
                </g>
                <g transform="matrix(.2646 0 0 .2646 115.8 104.5)" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" :class="[upHere || name == moduleName ? 'text-white' : 'text-secondary-two']">
                    <path d="m24.38 15-4.688-9.375-4.688 9.375 4.688 9.375z"/>
                    <path d="m10.31 5.625-4.688 9.375 4.688 9.375"/>
                    <path d="M 15,5.625 10.3125,15 15,24.375"/>
                </g>
            </g>
        </svg>
    </div>
</template>

<script>
export default {
    name: 'Scenario',
    data: () => ({
        upHere: false,
        name: 'roadmap'
    }),
    props: {
        moduleName: {
            type: String,
            required: true
        }
    }
}
</script>