<template>
    <div class="relative flex justify-center items-center mb-2">
        <component :is="currentMenu"></component>
        <ComponentSwitcher
            class="absolute"
            :currentComponentIndex="currentComponentIndex"
            :setCurrentComponentIndex="setCurrentComponentIndex"
        />
    </div>
</template>

<script setup>
import ComponentSwitcher from '@/components/InstMenu/right-navigation-drawer/module/risk-management/circular-menu/ComponentSwitcher.vue'
import useCircularMenu from '@/components/InstMenu/right-navigation-drawer/module/risk-management/circular-menu/useCircularMenu';


const { 
    currentMenu,
    currentComponentIndex,
    setCurrentComponentIndex
} = useCircularMenu()

</script>