<template>
    <RightNavComponentWrapper>
        <div>socialCapability</div>
    </RightNavComponentWrapper>
</template>

<script>
    export default {
        name : 'SocialCapability'
    }
</script>