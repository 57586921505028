<template>
    <svg class="inline" viewBox="0 0 7 2" fill="none" xmlns="http://www.w3.org/2000/svg">
        <line x1="0.5" y1="1" x2="7" y2="1" stroke="currentColor" stroke-width="1"/>
    </svg>
</template>

<script>
export default {
    name: "MinusIcon"
}
</script>