<template>
    <svg class="navigator-menu" width="246" height="246" viewBox="0 0 246 246" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M123 0C190.931 8.90808e-06 246 55.069 246 123C246 190.931 190.931 246 123 246C55.0689 246 -8.90808e-06 190.931 0 123C8.90808e-06 55.069 55.069 -8.90808e-06 123 0Z" fill="white"/>

        <router-link
            content="Scenario Management"
            v-tippy="{ animateFill: false, theme : 'light bordered' }"
            :to="{
                name: 'identification.build',
                params: {
                    id: project.id
                }
            }"
            class="svg-parent"
        >
            <path d="M69.2266 16.7554C86.0224 8.23009 104.623 3.78282 123.515 3.81462C142.407 3.84642 160.992 8.35631 177.759 16.9381C180.171 18.1723 181.009 21.2079 179.623 23.6125L161.547 54.9761C160.177 57.3521 157.102 58.2039 154.526 56.9919C144.823 52.4252 134.211 50.0327 123.437 50.0145C112.663 49.9964 102.044 52.3532 92.3249 56.8872C89.7454 58.0906 86.6725 57.2284 85.3111 54.8478L67.3401 23.4235C65.9624 21.0143 66.811 17.9815 69.2266 16.7554Z" fill="currentColor"/>

            <g class="svg-child">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M132.947 29.2263L128.2 38.7199C128.114 38.8914 127.939 38.9998 127.747 38.9998C127.555 38.9998 127.38 38.8914 127.294 38.7199L122.547 29.2263C122.476 29.0838 122.476 28.916 122.547 28.7735L127.294 19.2799C127.38 19.1084 127.555 19 127.747 19C127.939 19 128.114 19.1084 128.2 19.2799L132.947 28.7735C133.018 28.916 133.018 29.0838 132.947 29.2263ZM127.747 20.6385L123.566 28.9999L127.747 37.3613L131.928 28.9999L127.747 20.6385Z" fill="currentColor"/>
                <path d="M118.48 19.0536C118.73 19.1786 118.831 19.4828 118.706 19.7329L114.072 29L118.706 38.2671C118.831 38.5172 118.73 38.8214 118.48 38.9464C118.229 39.0715 117.925 38.9701 117.8 38.72L113.053 29.2264C112.982 29.0839 112.982 28.9161 113.053 28.7736L117.8 19.28C117.925 19.0299 118.229 18.9285 118.48 19.0536Z" fill="currentColor"/>
                <path d="M123.453 19.7329C123.578 19.4828 123.476 19.1786 123.226 19.0536C122.976 18.9285 122.672 19.0299 122.547 19.28L117.8 28.7736C117.729 28.9161 117.729 29.0839 117.8 29.2264L122.547 38.72C122.672 38.9701 122.976 39.0715 123.226 38.9464C123.476 38.8214 123.578 38.5172 123.453 38.2671L118.819 29L123.453 19.7329Z" fill="currentColor"/>
            </g>
        </router-link>

        <router-link
            content="Programmatic Roadmapping"
            v-tippy="{ animateFill: false, theme : 'light bordered' }"
            :to="_RoadmapsLink"
            class="svg-parent"
        >
            <path d="M188.086 22.8906C203.952 33.1236 217.193 46.9879 226.692 63.3691C236.191 79.7504 241.647 98.1291 242.648 116.982C242.791 119.687 240.587 121.937 237.812 121.946L201.612 122.062C198.87 122.071 196.588 119.839 196.343 117.003C195.418 106.292 192.144 95.8885 186.726 86.5448C181.308 77.201 173.905 69.1923 165.068 63.0682C162.728 61.4469 161.924 58.3583 163.294 55.9826L181.376 24.6223C182.763 22.218 185.81 21.4223 188.086 22.8906Z" fill="currentColor"/>
            <g class="svg-child">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M216 67H200C199.448 67 199 67.4477 199 68V84C199 84.5523 199.448 85 200 85H216C216.552 85 217 84.5523 217 84V68C217 67.4477 216.552 67 216 67ZM200 66C198.895 66 198 66.8954 198 68V84C198 85.1046 198.895 86 200 86H216C217.105 86 218 85.1046 218 84V68C218 66.8954 217.105 66 216 66H200Z" fill="currentColor"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M208 80.5C210.485 80.5 212.5 78.4853 212.5 76C212.5 73.5147 210.485 71.5 208 71.5C205.515 71.5 203.5 73.5147 203.5 76C203.5 78.4853 205.515 80.5 208 80.5ZM208 81C210.761 81 213 78.7614 213 76C213 73.2386 210.761 71 208 71C205.239 71 203 73.2386 203 76C203 78.7614 205.239 81 208 81Z" fill="currentColor"/>
                <path d="M206 76C206 74.8954 206.895 74 208 74C209.105 74 210 74.8954 210 76C210 77.1046 209.105 78 208 78C206.895 78 206 77.1046 206 76Z" fill="currentColor"/>
            </g>
        </router-link>

        <router-link
            content="Present and communicate"
            v-tippy="{ animateFill: false, theme : 'light bordered' }"
            :to="_CommunicatorLink"
            class="svg-parent"
        >
            <path d="M242.685 128.877C241.807 147.718 236.479 166.115 227.102 182.546C217.725 198.977 204.595 212.922 188.819 223.261C186.554 224.746 183.501 223.973 182.097 221.579L163.785 190.352C162.398 187.986 163.179 184.892 165.507 183.253C174.29 177.071 181.628 169.018 186.976 159.646C192.325 150.275 195.526 139.861 196.381 129.154C196.608 126.317 198.875 124.07 201.617 124.061L237.817 123.944C240.592 123.935 242.811 126.171 242.685 128.877Z" fill="currentColor"/>
            <g class="svg-child">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M201.411 167.307C201.411 167.044 201.627 166.831 201.892 166.831H209.108C209.373 166.831 209.589 167.044 209.589 167.307C209.589 167.57 209.373 167.783 209.108 167.783H201.892C201.627 167.783 201.411 167.57 201.411 167.307Z" fill="currentColor"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M205.5 172.662C205.766 172.662 205.981 172.875 205.981 173.138L205.981 179.941C205.981 180.204 205.766 180.417 205.5 180.417C205.234 180.417 205.019 180.204 205.019 179.941L205.019 173.138C205.019 172.875 205.234 172.662 205.5 172.662Z" fill="currentColor"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M211.753 180.524C211.753 180.787 211.538 181 211.272 181H199.728C199.462 181 199.247 180.787 199.247 180.524C199.247 180.261 199.462 180.048 199.728 180.048H211.272C211.538 180.048 211.753 180.261 211.753 180.524Z" fill="currentColor"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M196 161.476C196 161.213 196.215 161 196.481 161H214.519C214.785 161 215 161.213 215 161.476V173.138C215 173.401 214.785 173.614 214.519 173.614H196.481C196.215 173.614 196 173.401 196 173.138V161.476ZM196.962 161.952V172.662H214.038V161.952H196.962Z" fill="currentColor"/>
            </g>
        </router-link>
        
        <router-link
            content="Recurring Assessments "
            v-tippy="{ animateFill: false, theme : 'light bordered' }"
            :to="{
                name: 'intel.assess',
                params: {
                    id: project.id,
                }
            }"
          class="svg-parent">
            <path d="M178.555 229.279C161.608 238.114 142.76 242.769 123.591 242.813C104.421 242.858 85.5518 238.291 68.5638 229.534C66.1559 228.293 65.3261 225.255 66.7189 222.855L84.8849 191.543C86.2611 189.171 89.3393 188.328 91.9113 189.547C101.754 194.214 112.539 196.639 123.483 196.614C134.427 196.588 145.201 194.113 155.022 189.4C157.588 188.169 160.67 188.998 162.057 191.363L180.369 222.59C181.773 224.985 180.957 228.026 178.555 229.279Z" fill="currentColor"/>
            <g class="svg-child">
                <path d="M113 210H133V211.067H113V210Z" fill="currentColor"/>
                <path d="M113 220H122V221H113V220Z" fill="currentColor"/>
                <path d="M129 220H133V221H129V220Z" fill="currentColor"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M128.269 219.063H122.069V221.68H128.269V219.063ZM121.002 217.996V222.746H129.335V217.996H121.002Z" fill="currentColor"/>
                <path d="M113 229H133V230H113V229Z" fill="currentColor"/>
            </g>
        </router-link>

        <g class="svg-parent">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M58.3306 223.601C42.4133 213.287 29.1541 199.315 19.6814 182.818C10.2088 166.32 4.82597 147.826 3.94263 128.88C3.81646 126.174 6.03498 123.938 8.81034 123.947L45.0101 124.063C47.7525 124.072 50.0193 126.319 50.2461 129.156C51.1073 139.926 54.3415 150.399 59.7465 159.813C65.1515 169.226 72.5663 177.299 81.4338 183.472C83.7699 185.098 84.5676 188.188 83.1927 190.561L65.0442 221.883C63.6528 224.285 60.6041 225.074 58.3306 223.601Z" fill="currentColor"/>
            <g class="svg-child">
                <path d="M35.4444 173C35.4444 172.632 35.7429 172.333 36.1111 172.333H39.3333V169.111C39.3333 168.743 39.6318 168.444 40 168.444C40.3682 168.444 40.6667 168.743 40.6667 169.111V172.333H43.8889C44.2571 172.333 44.5556 172.632 44.5556 173C44.5556 173.368 44.2571 173.667 43.8889 173.667H40.6667V176.889C40.6667 177.257 40.3682 177.556 40 177.556C39.6318 177.556 39.3333 177.257 39.3333 176.889V173.667H36.1111C35.7429 173.667 35.4444 173.368 35.4444 173Z" fill="currentColor"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M30 173C30 167.477 34.4772 163 40 163C45.5228 163 50 167.477 50 173C50 178.523 45.5228 183 40 183C34.4772 183 30 178.523 30 173ZM40 164.333C35.2135 164.333 31.3333 168.214 31.3333 173C31.3333 177.786 35.2135 181.667 40 181.667C44.7865 181.667 48.6667 177.786 48.6667 173C48.6667 168.214 44.7865 164.333 40 164.333Z" fill="currentColor"/>
            </g>
        </g>

        <router-link
            content="Content Framework Build"
            v-tippy="{ animateFill: false, theme : 'light bordered' }"
            :to="_ScenarioLink"
            class="svg-parent"
        >
            <path d="M3.98323 116.969C4.98915 98.0489 10.4821 79.6082 20.0441 63.1856C29.606 46.7629 42.9316 32.8828 58.8891 22.6682C61.1707 21.2077 64.215 22.0138 65.5931 24.4229L83.5677 55.8451C84.9294 58.2254 84.1146 61.3113 81.7695 62.9245C72.878 69.0415 65.4251 77.062 59.9696 86.4319C54.514 95.8018 51.2179 106.243 50.2875 116.995C50.0421 119.831 47.7608 122.063 45.0184 122.053L8.81861 121.933C6.04328 121.924 3.83937 119.674 3.98323 116.969Z" fill="currentColor"/>
            <g class="svg-child">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M39.7869 66.0503C39.921 65.9832 40.0788 65.9832 40.2129 66.0503L49.7366 70.8122C49.8979 70.8928 49.9998 71.0577 49.9998 71.2381C49.9998 71.4184 49.8979 71.5833 49.7366 71.664L40.2129 76.4259C40.0788 76.4929 39.921 76.4929 39.7869 76.4259L30.2632 71.664C30.1019 71.5833 30 71.4184 30 71.2381C30 71.0577 30.1019 70.8928 30.2632 70.8122L39.7869 66.0503ZM31.541 71.2381L39.9999 75.4676L48.4588 71.2381L39.9999 67.0086L31.541 71.2381Z" fill="currentColor"/>
                <path d="M30.0504 80.549C30.168 80.3137 30.454 80.2184 30.6892 80.336L40 84.9914L49.3108 80.336C49.546 80.2184 49.832 80.3137 49.9496 80.549C50.0672 80.7842 49.9719 81.0702 49.7367 81.1878L40.213 85.9497C40.0789 86.0168 39.9211 86.0168 39.787 85.9497L30.2633 81.1878C30.0281 81.0702 29.9328 80.7842 30.0504 80.549Z" fill="currentColor"/>
                <path d="M30.6892 75.5741C30.454 75.4565 30.168 75.5519 30.0504 75.7871C29.9328 76.0223 30.0281 76.3084 30.2633 76.426L39.787 81.1878C39.9211 81.2549 40.0789 81.2549 40.213 81.1878L49.7367 76.426C49.9719 76.3084 50.0672 76.0223 49.9496 75.7871C49.832 75.5519 49.546 75.4565 49.3108 75.5741L40 80.2295L30.6892 75.5741Z" fill="currentColor"/>
            </g>
        </router-link>
    </svg>
</template>

<script setup>
    import store from '@/store';
    import { computed, ref, watchEffect, onMounted } from 'vue';
    import { useProduction } from '@/components/shared/stickyMenu/useProduction.js'

    const { initDefaultSettings, generateLink, ScenarioLink, RoadmapsLink, CommunicatorLink } = useProduction()

    const _ScenarioLink = ref({name: ''})
    const _RoadmapsLink = ref({name: ''})
    const _CommunicatorLink = ref({name: ''})


    const workspace = computed(() => store.getters['workspace/activeWorkspaceObj']);
    const project = computed(() => store.getters['project/project']);

    watchEffect(async ()=>
    {
        if(!project.value) return;

        let _project = project.value;

        let Ids = await initDefaultSettings(_project.id)
        _ScenarioLink.value     = ScenarioLink(_project.id, Ids)
        _RoadmapsLink.value     = RoadmapsLink(_project.id, Ids)
        _CommunicatorLink.value = CommunicatorLink(_project.id, Ids)
    })

</script>

<style >
    g{
        outline: none;
    }
    a{
        outline: none;
    }
    .navigator-menu a.router-link-active{
       color: var(--color-primary-four);
   }
   .navigator-menu a.router-link-active > .svg-child{
       color: #fff;
   }
   .svg-parent{
       color: #EDEDED;
       cursor: pointer;
   }
   .svg-child {
       color: gray
   }
   .svg-parent:hover {
       color: #8D8D8D;
   }
   .svg-parent:hover > .svg-child {
       color: white;
   }

</style>
